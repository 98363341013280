import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TimeSeriesBarChart, { IDetectedChartData } from "components/charts/TimeSeriesChart";

export interface IDetectedCountData {
    count: string;
    inTime: string;
}
export interface IConfig {
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
    label: string;
    maintainAspectRatio: boolean;
}

type DetectedCountChartProps = {
    data: IDetectedCountData[];
    config: IConfig;
};

interface RangeTime {
    startTime: string;
    endTime: string;
}

const mergeOutOfRangeData = (data: IDetectedCountData[], { startTime, endTime }: RangeTime) => {
    const beforeStartTimeData = [];
    const afterEndTimeData = [];
    if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
            if (new Date(data[i].inTime).getTime() < new Date(startTime).getTime()) {
                beforeStartTimeData.push(data[i]);
            }
            if (new Date(data[i].inTime).getTime() > new Date(endTime).getTime()) {
                afterEndTimeData.push(data[i]);
                data.splice(i, 1);
            }
        }

        if (beforeStartTimeData.length > 0) {
            // get only the count value of before data array
            const beforeCountArray = beforeStartTimeData.map((item) => +item.count);
            // get the total count  of before data
            const count = beforeCountArray.reduce((a, b) => a + b, 0);
            // assign the total count of before value to first data which is inside the range

            if (data.length > 0) {
                data[0].count = (parseInt(data[0].count, 10) + count).toString();
            }
        }
        if (afterEndTimeData.length > 0) {
            const afterCountArray = afterEndTimeData.map((item) => +item.count);
            const count = afterCountArray.reduce((a, b) => a + b, 0);
            if (data.length > 0) {
                data[data.length - 1].count = (parseInt(data[0].count, 10) + count).toString();
            }
        }
    }
    return data;
};

export default function DetectedCountChart({ data, config }: DetectedCountChartProps) {
    const [processedData, setProcessedData] = useState<IDetectedChartData[]>([]);
    const { startTime, endTime, timeType } = useSelector((state) => state.time);

    useEffect(() => {
        const modifyData = mergeOutOfRangeData(data, { startTime, endTime });
        const valueList = modifyData.map((l: IDetectedCountData) => {
            return {
                x: new Date(l?.inTime),
                y: +l.count,
            };
        });
        setProcessedData(valueList);
    }, [data]);

    const options = {
        maintainAspectRatio: config?.maintainAspectRatio,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                type: "time",
                min: new Date(startTime),
                max: new Date(endTime),
                time: {
                    unit: timeType,
                    round: timeType,
                },
            },
            y: {
                ticks: {
                    precision: 0,
                },
            },
        },
    };

    return (
        <div>
            <TimeSeriesBarChart data={processedData} {...config} options={options} />
        </div>
    );
}
