/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    styled,
    AutocompleteChangeReason,
    Grid,
    InputLabel,
    Dialog,
    DialogContent,
    CircularProgress,
    Typography,
    IconButton,
} from "@mui/material";
import IotVisionButton from "components/common/IotVisionButton";
import IotVisionTextFiled from "components/common/IotVisionTextFiled";
import SectionHeading from "components/headings/SectionHeading";
import PaperWrapper from "components/wrappers/PaperWrapper";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import CommonDateTimePicker from "components/common/CommonDateTimePicker";
import IotVisionAutoComplete from "components/common/IotVisonAutoComplete";
import IotVisionChipInput from "components/common/IotVisionChipInput";
import ArticleIcon from "@mui/icons-material/Article";
import { useState } from "react";
import { executeCreateReport } from "api/app-taxi-ranking/taxi-reports.api";
import { ICreateReportSchedule, IMetadataSearchReportConfig } from "types/reports/interfaces";
import { Repeats, ReportPeriod, ReportTypes } from "types/reports/enums";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { CgArrowsExpandRight } from "react-icons/cg";
import FullScreenDialog from "components/dialogs/FullScreenDialog";

interface AutoLabelId {
    label: string;
    id: string;
}

interface CreateReportForm {
    fromDate?: Date;
    toDate?: Date;
    isDaily: AutoLabelId;
    plateNumbers: string[];
    reason: string;
    endDate?: Date;
}

const CustomizedIotVisionSearchButton = styled(IotVisionButton)(() => ({
    color: "white !important",
    height: "37px",
}));

const CustomizedIotVisionClearButton = styled(IotVisionButton)(({ theme }) => ({
    height: "37px",
    color: theme.palette.primary.main,
    backgroundColor: "white",
    "&:hover": {
        background: "white",
    },
}));

const CustomizedDialogContent = styled(DialogContent)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    minHeight: "200px",
    minWidth: "400px",
}));

const CustomizedTypography = styled(Typography)(() => ({
    color: "#21315b",
}));

const CustomizedCircularProgress = styled(CircularProgress)(() => ({
    color: "#21315b",
}));

const CustomizedInputLabel = styled(InputLabel)(() => ({
    fontWeight: "bold",
    color: "#21315b",
    whiteSpace: "pre-wrap",
}));

const defaultValues: CreateReportForm = {
    plateNumbers: [],
    isDaily: {
        label: "",
        id: "",
    },
    reason: "",
};

const booleanOptions: AutoLabelId[] = [
    {
        id: "yes",
        label: "Yes",
    },
    {
        id: "no",
        label: "No",
    },
];

const CreateReport = () => {
    const { appCode } = useSelector((state) => state.auth);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isFullScreenModalOpen, setIsFullScreenModalOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const {
        handleSubmit,
        setValue,
        control,
        reset,
        watch,
        clearErrors,
        formState: { errors },
    } = useForm<CreateReportForm>({
        defaultValues,
    });

    const onSubmit: SubmitHandler<CreateReportForm> = async (data) => {
        setIsModalOpen(true);
        setIsLoading(true);
        const isDaily = data?.isDaily?.id === "yes";
        const startDateTime = new Date(new Date().setHours(24, 0, 0, 0));
        const body: ICreateReportSchedule<IMetadataSearchReportConfig> = {
            type: ReportTypes.METADATA_SEARCH_REPORT,
            allowOneTimeAndRecurringSchedules: isDaily && !!data?.fromDate && !!data?.toDate,
            scheduleRecurringConfig: {
                endingDate: isDaily
                    ? data?.endDate
                        ? new Date(data.endDate.setSeconds(59, 999)).toISOString()
                        : "infinite"
                    : null,
                startDateTime: isDaily ? startDateTime.toISOString() : "NEXT_TICK",
                repeats: isDaily ? Repeats.DAILY : Repeats.NONE,
            },
            reportConfig: {
                parameters: {
                    searchField: "field3",
                    searchValue: data.plateNumbers,
                },
                reason: data.reason,
            },
        };
        if (!!data?.fromDate && !!data?.toDate) {
            body.reportConfig.startDate = new Date(data.fromDate.setSeconds(0, 0)).toISOString();
            body.reportConfig.endDate = new Date(data.toDate.setSeconds(59, 999)).toISOString();
        }
        if (isDaily) {
            body.reportConfig.reportPeriodType = ReportPeriod.DAILY;
            body.reportConfig.statsPeriod = 1;
        }
        try {
            await executeCreateReport(body);
            setIsLoading(false);
        } catch (error) {
            setIsModalOpen(false);
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        reset(defaultValues);
        navigate(`/${appCode}/plate-search-reports`);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        handleCancel();
    };

    const fromDate = watch("fromDate");
    const toDate = watch("toDate");
    const isDaily = watch("isDaily");

    const Form = (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container item xs={12} sm={12} md={12} lg={12} marginBottom={2}>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                    <CustomizedInputLabel htmlFor="fromDate">From</CustomizedInputLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                        name="fromDate"
                        rules={{
                            required: {
                                value: !!toDate || isDaily.id === "no",
                                message: "Field is required",
                            },
                        }}
                        control={control}
                        render={({ field: { value } }) => (
                            <CommonDateTimePicker
                                maxDate={toDate}
                                onChange={(date: Date | null): void => {
                                    setValue("fromDate", date || undefined);
                                    clearErrors("fromDate");
                                }}
                                errorMessage={errors.fromDate && errors.fromDate.message}
                                value={value || null}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} marginBottom={2}>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                    <CustomizedInputLabel htmlFor="toDate">To</CustomizedInputLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                        name="toDate"
                        rules={{
                            required: {
                                value: !!fromDate || isDaily.id === "no",
                                message: "Field is required",
                            },
                            validate: (value) => {
                                const maxValidDate = new Date(new Date().setMinutes(new Date().getMinutes() - 1, 0, 0));
                                return value ? value <= maxValidDate || "Maximum date exceeded" : true;
                            },
                        }}
                        control={control}
                        render={({ field: { value } }) => (
                            <CommonDateTimePicker
                                minDate={fromDate}
                                maxDate={new Date(new Date().setMinutes(new Date().getMinutes() - 1, 0, 0))}
                                onChange={(date: Date | null): void => {
                                    setValue("toDate", (date && new Date(date.setSeconds(0, 0))) || undefined);
                                    clearErrors("toDate");
                                }}
                                errorMessage={errors.toDate && errors.toDate.message}
                                value={value || null}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} marginBottom={2}>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                    <CustomizedInputLabel htmlFor="plateNumbers">Plate numbers</CustomizedInputLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                        name="plateNumbers"
                        rules={{ validate: (value) => !!value.length || "Field is required" }}
                        control={control}
                        render={({ field: { value } }) => (
                            <IotVisionChipInput
                                chips={value}
                                shouldModifyInputUpperCase
                                onChipAdd={(chips) => {
                                    setValue("plateNumbers", [...value, ...chips]);
                                    clearErrors("plateNumbers");
                                }}
                                onChipDelete={(chip) =>
                                    setValue(
                                        "plateNumbers",
                                        value.filter((item) => item !== chip),
                                    )
                                }
                                onClearAll={() => setValue("plateNumbers", [])}
                                error={!!errors.plateNumbers}
                                helperText={errors.plateNumbers && errors.plateNumbers.message}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} marginBottom={2}>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                    <CustomizedInputLabel htmlFor="reason">Enter your reason</CustomizedInputLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                        name="reason"
                        rules={{ required: { value: true, message: "Field is required" } }}
                        control={control}
                        render={({ field: { value } }) => (
                            <IotVisionTextFiled
                                fullWidth
                                value={value}
                                onChange={(event) => {
                                    setValue("reason", event?.target?.value);
                                    clearErrors("reason");
                                }}
                                error={!!errors.reason}
                                helperText={errors.reason && errors.reason.message}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} marginBottom={2}>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                    <CustomizedInputLabel htmlFor="isDaily">
                        Do you want to generate the report daily?
                    </CustomizedInputLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                        name="isDaily"
                        rules={{
                            validate: (value) => value?.id === "no" || value?.id === "yes" || "Field id required",
                        }}
                        control={control}
                        render={({ field: { value } }) => (
                            <IotVisionAutoComplete
                                options={booleanOptions}
                                value={value}
                                onChange={(
                                    event: React.SyntheticEvent,
                                    value: AutoLabelId,
                                    reason: AutocompleteChangeReason,
                                ) => {
                                    if (reason === "clear") {
                                        setValue("isDaily", {
                                            label: "",
                                            id: "",
                                        });
                                    } else {
                                        setValue("isDaily", {
                                            label: value?.label,
                                            id: value?.id,
                                        });
                                    }
                                    clearErrors("isDaily");
                                }}
                                renderInput={(params: any) => (
                                    <IotVisionTextFiled
                                        fullWidth
                                        {...params}
                                        error={!!errors.isDaily}
                                        helperText={errors.isDaily && errors.isDaily.message}
                                    />
                                )}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} marginBottom={2}>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                    <CustomizedInputLabel htmlFor="endDate">
                        Generate the daily report until? (Optional)
                    </CustomizedInputLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                        name="endDate"
                        control={control}
                        render={({ field: { value } }) => (
                            <CommonDateTimePicker
                                minDate={new Date(new Date().setHours(24, 1, 0, 0))}
                                shouldDisableFutureDate={false}
                                onChange={(date: Date | null): void => {
                                    setValue("endDate", date || undefined);
                                }}
                                isDisabled={isDaily.id === "no"}
                                value={value || null}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} marginTop={5}>
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={6} md={2} lg={2} xl={2} marginRight={1}>
                        <CustomizedIotVisionSearchButton type="submit" fullWidth>
                            Create
                        </CustomizedIotVisionSearchButton>
                    </Grid>
                    <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                        <CustomizedIotVisionClearButton fullWidth onClick={handleCancel}>
                            Cancel
                        </CustomizedIotVisionClearButton>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );

    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={10} md={10} lg={10} xl={10} marginBottom={2}>
                    <SectionHeading heading="Create Plate Search Report" icon={<ArticleIcon className="icon" />} />
                </Grid>
            </Grid>
            <PaperWrapper>
                <Grid container>
                    <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                        <IconButton className="expand-btn" onClick={() => setIsFullScreenModalOpen(true)} disableRipple>
                            <CgArrowsExpandRight />
                        </IconButton>
                    </Grid>
                </Grid>
                {Form}
            </PaperWrapper>
            <FullScreenDialog
                modalShow={isFullScreenModalOpen}
                onClose={() => setIsFullScreenModalOpen(false)}
                childComponent={
                    <PaperWrapper>
                        <Grid container>
                            <Grid item xs={12} sm={10} md={10} lg={10} xl={10} marginBottom={5}>
                                <SectionHeading
                                    heading="Create Plate Search Report"
                                    icon={<ArticleIcon className="icon" />}
                                />
                            </Grid>
                        </Grid>
                        {Form}
                    </PaperWrapper>
                }
            />
            <Dialog open={isModalOpen}>
                <CustomizedDialogContent>
                    {isLoading && <CustomizedCircularProgress />}
                    <CustomizedTypography variant="body">
                        {isLoading ? "Sending report request..." : "Report request successful"}
                    </CustomizedTypography>
                    {!isLoading && (
                        <CustomizedIotVisionSearchButton onClick={handleModalClose}>
                            Close
                        </CustomizedIotVisionSearchButton>
                    )}
                </CustomizedDialogContent>
            </Dialog>
        </>
    );
};

export default CreateReport;
