import { SignInRequest, SignInResponse } from "app-auth";
import ExecuteAsync from "utils/api-client";
import { ApiEndpoints } from "configs";
import { RequestOptions } from "axios-types";
import { ScopesResponse } from "types";

export function executeSignInRequest(data: SignInRequest) {
    return ExecuteAsync<SignInRequest, SignInResponse>({
        method: "POST",
        url: "/signin",
        data,
    });
}

export function executeSignUpRequest(data: SignInRequest) {
    return ExecuteAsync<SignInRequest, SignInResponse>({
        method: "POST",
        url: "/signup",
        data,
    });
}

export function executeGetCurrentUserScopesRequest(options?: RequestOptions) {
    return ExecuteAsync<null, ScopesResponse>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: "/users/me/scopes",
        signal: options?.signal,
    });
}
