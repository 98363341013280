const NoImage = (): JSX.Element => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                minHeight: "300px",
                height: "100%",
                backgroundColor: "#e0e0e0",
            }}
        >
            <div>Snapshot not available</div>
        </div>
    );
};

export default NoImage;
