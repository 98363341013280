import { Grid, Typography } from "@mui/material";
import React from "react";

interface PageAlertHeaderProps {
    lastUpdated: Date | null;
}

const PageAlertHeader: React.FC<PageAlertHeaderProps> = ({ lastUpdated }) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="header1">Incidents</Typography>
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    marginTop: "-12px",
                    marginLeft: "5px",
                }}
            >
                {lastUpdated && (
                    // eslint-disable-next-line max-len
                    <Typography variant="body">{`Last incident reported on ${lastUpdated.toLocaleString()}`}</Typography>
                )}
            </Grid>
        </Grid>
    );
};

export default PageAlertHeader;
