/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pageSize: 10,
};

const slice = createSlice({
    name: "pnrIncident",
    initialState,
    reducers: {},
});

export default slice.reducer;
