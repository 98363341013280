import React, { useEffect, useState } from "react";
import { toPng } from "html-to-image";
import { formatDate } from "utils/date-format";
import { useSelector } from "react-redux";
import { IoMdDownload } from "react-icons/io";
import FullScreenDialog from "components/dialogs/FullScreenDialog";
import { Grid, IconButton } from "@mui/material";
import { capitalizeFirstLetter } from "utils/common";
import { CardHeader } from "features/app-air-quality/common";
import DateView from "components/dates/DateView";
import { enAU } from "date-fns/locale";
import { FilterTypes } from "types/chart-configs/enums/common";
import { useParams } from "react-router-dom";
import { NOT_APPLICABLE_SHORT } from "constants/common.constants";

/* eslint react/jsx-indent: ["off"] */
type Filters = { [key: string]: { [type: string]: string } };

interface DownloadChartProps {
    modalShow: boolean;
    closeModal: (status: boolean) => void;
    logoId?: string;
    chartId: string;
    chartComponent: React.ReactElement;
    exportName: string;
    filters?: Filters;
    chartHeader: string;
}

export const timeFormatOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
};

const removableComponents = ["download-icon-id", "fullscreen-close-icon-id"];

const DownloadChart: React.FC<DownloadChartProps> = ({
    chartComponent,
    chartId,
    closeModal,
    logoId,
    modalShow,
    exportName,
    filters,
    chartHeader,
}) => {
    const logo = logoId ? document.getElementById(logoId) : null;
    const { appCode } = useSelector((state) => state.auth);
    const { leafLocations } = useSelector((state) => state.newLocation);
    const params = useParams();
    const [room, setRoom] = useState<string | null | undefined>("");

    useEffect(() => {
        if (params.roomId && leafLocations && leafLocations.length) {
            const filteredRoom = leafLocations.filter((leaf) => leaf.id === params.roomId)[0];
            setRoom(filteredRoom ? filteredRoom.name : NOT_APPLICABLE_SHORT);
        }
    }, [params]);

    const handleDownloadChart = async (removableCompIds: string[]) => {
        const chartComp = document.getElementById(chartId);
        const filter = (node: HTMLElement) => {
            return !removableCompIds.includes(node.id);
        };
        if (chartComp) {
            const image = await toPng(chartComp, {
                quality: 1,
                backgroundColor: "white",
                filter,
            });
            const link = document.createElement("a");
            link.download = `${exportName}_${formatDate(new Date(), timeFormatOptions)}.png`;
            link.href = image;
            link.click();
        }
    };
    return (
        <FullScreenDialog
            modalShow={modalShow}
            onClose={closeModal}
            childComponent={
                <div style={{ height: "100%", position: "relative" }}>
                    <Grid
                        item
                        sx={{
                            display: "flex",
                            gap: "10px",
                        }}
                    >
                        <CardHeader>{chartHeader}</CardHeader>
                        <IconButton
                            id="download-icon-id"
                            className="download-btn"
                            onClick={() => handleDownloadChart(removableComponents)}
                            disableRipple
                        >
                            <IoMdDownload />
                        </IconButton>
                    </Grid>
                    {params.roomId ? <Grid item>Room: {room}</Grid> : null}
                    {filters
                        ? Object.keys(filters).map((key) => (
                              <Grid item>
                                  {capitalizeFirstLetter(key)}:{" "}
                                  {filters[key].type === FilterTypes.DATE ? (
                                      <DateView
                                          dateFormat="Ppp"
                                          options={{
                                              locale: enAU,
                                          }}
                                      >
                                          {filters[key].value}
                                      </DateView>
                                  ) : (
                                      filters[key].value
                                  )}
                              </Grid>
                          ))
                        : null}
                    <div style={{ position: "absolute", top: "2%", right: "6%" }}>
                        <Grid item>
                            <img src={logo?.getAttribute("src") ?? undefined} alt="" />
                        </Grid>
                        <Grid item>{appCode}</Grid>
                    </div>
                    {chartComponent}
                </div>
            }
        />
    );
};

export default DownloadChart;
