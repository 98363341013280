import React, { useEffect, useState } from "react";
import { AutocompleteChangeReason, AutocompleteRenderInputParams, Grid, styled } from "@mui/material";
import IotVisionTextFiled from "components/common/IotVisionTextFiled";
import IotVisionButton from "components/common/IotVisionButton";
import { URLSearchParamsInit } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { CommonObjectLiteral, DropDownTypes } from "types/reports/interfaces";
import IotVisionAutoComplete from "components/common/IotVisonAutoComplete";
import { DayTypes, WeekDays, WeekendDays } from "enums/days.enum";
import { humanize } from "utils/common";

const CustomizedIotVisionSearchButton = styled(IotVisionButton)(() => ({
    color: "white !important",
    height: "37px",
}));

const CustomizedIotVisionTextField = styled(IotVisionTextFiled)(() => ({
    backgroundColor: "white !important",
    borderRadius: "20px",
}));

const CustomizedIotVisionClearButton = styled(IotVisionButton)(({ theme }) => ({
    height: "37px",
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
    "&:hover": {
        background: "transparent",
    },
}));

const typeOfDaySelectableItems = Object.values(DayTypes).map((type) => ({ id: type, label: humanize(type) }));

const weekdaySelectableItems = Object.values(WeekDays).map((type) => ({
    id: type.toLowerCase(),
    label: humanize(type),
}));

const weekendSelectableItems = Object.values(WeekendDays).map((type) => ({
    id: type.toLowerCase(),
    label: humanize(type),
}));

const mergedDayList = [...weekdaySelectableItems, ...weekendSelectableItems];

interface IIncidentTrendsFilter {
    lga?: DropDownTypes;
    dayOfWeek?: DropDownTypes;
    dayType?: DropDownTypes;
    incidentType?: DropDownTypes;
}

export interface ISearchParams {
    lga: string;
    dayOfWeek: string;
    dayType: string;
    incidentType: string;
}

interface IIncidentTrendsFilterPanelProps {
    setSearchParams: (params: CommonObjectLiteral) => void;
    searchParams: CommonObjectLiteral;
    lgaList?: DropDownTypes[];
    IncidentTypes: DropDownTypes[];
}

const IncidentTrendsFilterPanel: React.FC<IIncidentTrendsFilterPanelProps> = ({
    searchParams,
    setSearchParams,
    IncidentTypes,
    lgaList,
}) => {
    const {
        handleSubmit,
        setValue,
        control,
        reset,
        formState: { isDirty, isValid },
    } = useForm<IIncidentTrendsFilter>({
        defaultValues: {
            lga: {
                label: "",
                id: "",
            },
            dayOfWeek: {
                label: "",
                id: "",
            },
            incidentType: {
                label: "",
                id: "",
            },
            dayType: {
                label: "",
                id: "",
            },
        },
    });
    const { dayType, incidentType, dayOfWeek, lga } = searchParams || {};
    const [incidentTypes, setIncidentTypes] = useState<DropDownTypes[]>([]);

    useEffect(() => {
        if (IncidentTypes) setIncidentTypes(IncidentTypes);
    }, [IncidentTypes]);

    useEffect(() => {
        reset({
            lga: lgaList?.find((item) => item.id === lga) || {
                label: "",
                id: "",
            },
            dayOfWeek: selectDayByDayType(dayOfWeek),
            incidentType: incidentTypes.find((item) => item.id === incidentType) || {
                label: "",
                id: "",
            },
            dayType: typeOfDaySelectableItems.find((item) => item.id === dayType) || {
                label: "",
                id: "",
            },
        });
    }, [searchParams, incidentTypes, lgaList]);

    const selectDayByDayType = (selectedDay?: WeekDays | WeekendDays) => {
        if (selectedDay)
            return (
                mergedDayList.find((item) => item.id === selectedDay));
        return {
            label: "",
            id: "",
        };
    };

    const handleClear = (): void => {
        reset({
            lga: {
                label: "",
                id: "",
            },
            dayOfWeek: {
                label: "",
                id: "",
            },
            incidentType: {
                label: "",
                id: "",
            },
            dayType: {
                label: "",
                id: "",
            },
        });
        setSearchParams({});
    };

    const onSubmit: SubmitHandler<IIncidentTrendsFilter> = (data: IIncidentTrendsFilter): void => {
        const searchParamData: URLSearchParamsInit | undefined = {
            ...(data?.lga?.id ? { lga: data?.lga.id } : {}),
            ...(data?.dayType?.id ? { dayType: data?.dayType.id } : {}),
            ...(data?.dayOfWeek?.id ? { dayOfWeek: data?.dayOfWeek.id } : {}),
            ...(data?.incidentType?.id ? { incidentType: data?.incidentType.id } : {}),
        };
        setSearchParams(searchParamData);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1} marginTop={1}>
                {lgaList &&
                <Grid item xs={12} sm={12} md={4} lg={2}>
                    <Controller
                        name="lga"
                        control={control}
                        render={({ field: { value } }) => (
                            <IotVisionAutoComplete
                                options={lgaList}
                                value={value?.label}
                                onChange={(
                                    event: React.SyntheticEvent,
                                    value: DropDownTypes,
                                    reason: AutocompleteChangeReason,
                                ) => {
                                    if (reason === "clear") {
                                        setValue(
                                            "lga",
                                            {
                                                label: "",
                                                id: "",
                                            },
                                            { shouldDirty: true },
                                        );
                                    } else {
                                        setValue(
                                            "lga",
                                            {
                                                label: value?.label,
                                                id: value?.id,
                                            },
                                            { shouldDirty: true },
                                        );
                                    }
                                }}
                                renderInput={(params: AutocompleteRenderInputParams) => (
                                    <CustomizedIotVisionTextField
                                        {...params}
                                        placeholder="LGA"
                                        fullWidth
                                    />
                                )}
                            />
                        )}
                    />
                </Grid>
                }
                <Grid item xs={12} sm={12} md={4} lg={2}>
                    <Controller
                        name="dayType"
                        control={control}
                        render={({ field: { value } }) => (
                            <IotVisionAutoComplete
                                options={typeOfDaySelectableItems}
                                value={value?.label}
                                onChange={(
                                    event: React.SyntheticEvent,
                                    value: DropDownTypes,
                                    reason: AutocompleteChangeReason,
                                ) => {
                                    if (reason === "clear") {
                                        setValue(
                                            "dayType",
                                            {
                                                label: "",
                                                id: "",
                                            },
                                            { shouldDirty: true },
                                        );
                                    } else {
                                        setValue(
                                            "dayType",
                                            {
                                                label: value?.label,
                                                id: value?.id,
                                            },
                                            { shouldDirty: true },
                                        );
                                        setValue(
                                            "dayOfWeek",
                                            {
                                                label: "",
                                                id: "",
                                            },
                                            { shouldDirty: true },
                                        );
                                    }
                                }}
                                renderInput={(params: AutocompleteRenderInputParams) => (
                                    <CustomizedIotVisionTextField
                                        {...params}
                                        placeholder="Weekday/Weekend"
                                        fullWidth
                                    />
                                )}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={2}>
                    <Controller
                        name="dayOfWeek"
                        control={control}
                        render={({ field: { value } }) => (
                            <IotVisionAutoComplete
                                options={mergedDayList}
                                value={value?.label}
                                onChange={(
                                    event: React.SyntheticEvent,
                                    value: DropDownTypes,
                                    reason: AutocompleteChangeReason,
                                ) => {
                                    if (reason === "clear") {
                                        setValue(
                                            "dayOfWeek",
                                            {
                                                label: "",
                                                id: "",
                                            },
                                            { shouldDirty: true },
                                        );
                                    } else {
                                        setValue(
                                            "dayOfWeek",
                                            {
                                                label: value?.label,
                                                id: value?.id,
                                            },
                                            { shouldDirty: true },
                                        );
                                        setValue(
                                            "dayType",
                                            {
                                                label: "",
                                                id: "",
                                            },
                                            { shouldDirty: true },
                                        );
                                    }
                                }}
                                renderInput={(params: AutocompleteRenderInputParams) => (
                                    <CustomizedIotVisionTextField {...params} placeholder="Day of week" fullWidth />
                                )}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={2}>
                    <Controller
                        name="incidentType"
                        control={control}
                        render={({ field: { value } }) => (
                            <IotVisionAutoComplete
                                options={incidentTypes}
                                value={value?.label}
                                onChange={(
                                    event: React.SyntheticEvent,
                                    value: DropDownTypes,
                                    reason: AutocompleteChangeReason,
                                ) => {
                                    if (reason === "clear") {
                                        setValue(
                                            "incidentType",
                                            {
                                                label: "",
                                                id: "",
                                            },
                                            { shouldDirty: true },
                                        );
                                    } else {
                                        setValue(
                                            "incidentType",
                                            {
                                                label: value?.label,
                                                id: value?.id,
                                            },
                                            { shouldDirty: true },
                                        );
                                    }
                                }}
                                renderInput={(params: AutocompleteRenderInputParams) => (
                                    <CustomizedIotVisionTextField {...params} placeholder="Incident type" fullWidth />
                                )}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Grid container spacing={1}>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <CustomizedIotVisionSearchButton type="submit" disabled={!isDirty || !isValid} fullWidth>
                                Filter
                            </CustomizedIotVisionSearchButton>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <CustomizedIotVisionClearButton fullWidth onClick={handleClear}>
                                Clear
                            </CustomizedIotVisionClearButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default IncidentTrendsFilterPanel;
