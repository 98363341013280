import { Grid } from "@mui/material";
import { AppOversizedVehicles } from "constants/app.constants";
import PageAlertHeader from "features/app-oversized-vehicles/incidents/header/IncidentTableHeader";
import PageAlerts from "features/app-oversized-vehicles/incidents/IncidentsPage";
import React from "react";
import { Helmet } from "react-helmet";
import AqIcon from "assets/images/platformity-logo.png";

interface AlertsProps {}

const Incident: React.FC<AlertsProps> = () => {
    return (
        <>
            <Helmet>
                <title>{AppOversizedVehicles} | Detections</title>
                <link rel="icon" id="aq-incident" type="image/png" href={AqIcon} sizes="32x32" />
            </Helmet>
            <Grid container>
                <Grid item xs={12}>
                    <PageAlertHeader lastUpdated="" />
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "24px" }}>
                    <PageAlerts />
                </Grid>
            </Grid>
        </>
    );
};

export default Incident;
