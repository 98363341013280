import { AppTitlePrefix } from "constants/app.constants";
import { Helmet } from "react-helmet";
import ReportHeading from "components/headings/ReportHeading";
import { Box, Divider, Grid } from "@mui/material";
import { LoadingWrapper } from "components/wrappers/LoadingWrapper";
import { useSelector } from "react-redux";
import { ReportCard } from "features/app-taxi-ranking/card/ReportCard";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { executeGetReportAndReportData } from "api/app-taxi-ranking/taxi-reports.api";
import { CommonObjectLiteral, IReportConfig } from "types/reports/interfaces";
import { toggleReportLoading } from "reducers/report.reducer";
import { useAppDispatch } from "store";
import { addPeriodSuffix, generateReportChart } from "utils/report-chart-utils";
import StatusCodes from "enums/status-code.enums";
import { reportConfigProvider } from "configs/reports/report-structures/config-provider";
import NswLogo from "../../../assets/images/nsw-logo.png";


const initialReportConfig: IReportConfig = {
    reportTitle: "",
    cards: [],
    graphs: [],
};

export interface ReportPeriod {
    from: string;
    to: string;
}

const dateTimeFormatOptions: Intl.DateTimeFormatOptions = { day: "2-digit", month: "long", year: "numeric" };

const formatDateIntoReportFormat = (date: Date) => {
    return date.toLocaleString("en-AU", dateTimeFormatOptions).replace(",", "");
};

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
export default function Report() {
    const reportAbortControllerRef = useRef<AbortController | null>(null);
    const [reportData, setReportData] = useState<any>([]);
    const [reportConfig, setReportConfig] = useState<IReportConfig>(initialReportConfig);
    const [reportPeriod, setReportPeriod] = useState<ReportPeriod>({from: "", to: ""});
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { appCode } = useSelector((state) => state.auth);
    const { isReportLoading } = useSelector((state) => state.report);
    const { reportId } = useParams<{ reportId: string }>();

    useEffect(() => {
        if (reportId) {
            dispatch(toggleReportLoading(true));
            getReportDataFromAPI(reportId);
        }
        return () => {
            reportAbortControllerRef.current?.abort();
        }
    }, [reportId]);

    const getReportDataFromAPI = async (reportId: string) => {
        try {
            reportAbortControllerRef.current = new AbortController();
            const { signal } = reportAbortControllerRef.current;
            const reportData = await executeGetReportAndReportData({reportId}, {signal});
            setReportData(reportData);
            if (reportData) {
                setReportPeriod({from: reportData.reportFrom, to: reportData.reportTo});
                const config = reportConfigProvider(reportData.reportType);
                if (config) {
                    setReportConfig(config);
                } else {
                    setReportConfig(initialReportConfig);
                }
            }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: Error | any) {
            if (error?.response?.status === StatusCodes.FORBIDDEN) {
                navigate(`/${appCode}/reports/forbidden`, { replace: true });
                return;
            }
            if (error?.response?.status === StatusCodes.NOT_FOUND) {
                navigate(`/${appCode}/reports/report-not-found`, { replace: true });
                return;
            }
            console.log(error);
        } finally {
            dispatch(toggleReportLoading(false));
        }
    };

    return (
        <>
            <Helmet>
                <title>{AppTitlePrefix} | Report</title>
                <link rel="icon" id="tr-events" type="image/png" href={NswLogo} sizes="32x32" />
            </Helmet>
            {isReportLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                    <LoadingWrapper isLoading />
                </Box>
            ) : (
                <>
                    <div className="report-heading">
                        <ReportHeading
                            heading={reportConfig.reportTitle}
                            timerange={`${formatDateIntoReportFormat(
                                new Date(reportPeriod.from),
                            )} - ${formatDateIntoReportFormat(new Date(reportPeriod.to))}`}
                        />
                    </div>
                    <Divider className="report-top-divider" variant="middle" />
                    <div>
                        <div className="card-section-heading">
                            <div className="title">General Info</div>
                            <Grid container spacing={4}>
                                {reportConfig.cards.map((card, index) => {
                                    const relatedValues: CommonObjectLiteral[] = [];
                                    if (card.relatedAttributes && card.relatedAttributes.length > 0) {
                                        card.relatedAttributes.forEach((att) => {
                                            relatedValues.push({
                                                [att]: reportData?.storage?.report?.stats[att],
                                            });
                                        });
                                    }
                                    return (
                                        <ReportCard
                                            key={index}
                                            icon={card.icon}
                                            value={reportData?.storage?.report?.stats[card.attributeName]}
                                            text={card.text}
                                            dataType={card.dataType}
                                            relatedValues={relatedValues}
                                        />
                                    );
                                })}
                            </Grid>
                            <Grid container spacing={4} sx={{ marginTop: "10px" }}>
                                {reportConfig.graphs.map((graph, index) => {
                                    return (
                                        <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
                                            {generateReportChart(
                                                graph.graphType,
                                                graph.shouldAddPeriodSuffix
                                                    ? addPeriodSuffix(
                                                          graph.graphTitle,
                                                          reportData?.reportPeriodType,
                                                      )
                                                    : graph.graphTitle,
                                                graph.processorFunction(
                                                    reportData?.storage?.report?.graphs[graph.attributeName],
                                                    reportData?.reportPeriodType,
                                                ),
                                                graph.options,
                                            )}
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
