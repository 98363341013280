import { Location } from "reducers/newLocation.reducer";

export const getChildrenCounts = (nodes: Location[]) => {
    /* eslint @typescript-eslint/no-explicit-any: ["off"] */
    return nodes.reduce((acc: any, node: Location) => {
        if (!acc[node.id]) {
            acc[node.id] = {};
        }
        if (node.children && Array.isArray(node.children) && node.children.length > 0) {
            acc[node.id] = node.children.length;
            acc = {
                ...acc,
                ...getChildrenCounts(node.children),
            };
        } else {
            acc[node.id] = 0;
        }

        return acc;
    }, {});
};

export default getChildrenCounts;
