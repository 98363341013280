import { useAuth0 } from "@auth0/auth0-react";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncThunkConfig } from "reduxjs-toolkit";

export const fetchToken = createAsyncThunk<string, void, AsyncThunkConfig>("token", async () => {
    const { getAccessTokenSilently } = useAuth0();

    let token = "";
    try {
        token = await getAccessTokenSilently();
    } catch (e) {
        console.log(e);
    }
    return Promise.resolve(token);
});
