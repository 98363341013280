import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { DoughnutConfig } from "types";
import { getDoughnutBackGroundColorBaseLabels } from "utils/common";
import { LoadingWrapper } from "components/wrappers/LoadingWrapper";
import DoughnutChart from "../charts/DoughnutChart";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
interface DoughnutChartCardProps {
    title: string;
    count: string;
    icon: any;
    isChart: boolean;
    className: string;
    customConfig?: DoughnutConfig;
    data: IDoughnutChartCardData[];
    labelName?: string;
    styles?: {
        [key: string]: unknown;
    };
    isLoading?: boolean;
}

export interface IDoughnutChartCardData {
    count: string;
    [key: string]: string;
}
const defaultConfig: DoughnutConfig = {
    backgroundColor: ["#6B439D", "#C62034", "#F4C621", "#21315B", "#D6DDEF", "#32D1DB"],
    hoverOffset: 4,
    maintainAspectRatio: false,
};

const DoughnutChartCard: React.FC<DoughnutChartCardProps> = ({
    title,
    count,
    icon,
    isChart,
    className,
    customConfig = defaultConfig,
    data,
    labelName,
    styles,
    isLoading = false,
}) => {
    const [labels, setLabels] = useState<string[]>([]);
    const [processedData, setProcessedData] = useState<number[]>([]);
    const [totalCount, setTotalCount] = useState<number | null>(null);
    const [config, setConfig] = useState<DoughnutConfig>(customConfig);
    useEffect(() => {
        const valueList: number[] = [];
        const doughnutLabels: string[] = [];
        let total = 0;
        data.forEach((element: IDoughnutChartCardData) => {
            const str = labelName as keyof typeof element;
            valueList.push(+element.count);
            doughnutLabels.push(element[str]);
            total += +element.count;
        });
        setProcessedData(valueList);
        setTotalCount(total);
        setLabels(doughnutLabels);
    }, [data]);

    useEffect(() => {
        setConfig({
            ...config,
            backgroundColor: getDoughnutBackGroundColorBaseLabels(processedData),
        });
    }, [processedData]);

    return (
        <Grid item className="donut-card-wrapper" style={styles}>
            <div className="donut-title-wrapper">
                <div className={className}>{icon}</div>
                <h4>{title}</h4>
            </div>
            <LoadingWrapper
                sx={{
                    marginTop: "21px",
                }}
                isLoading={isLoading}
            >
                <div className="donut-figure-wrapper">
                    {isChart && processedData.length !== 0 && totalCount !== 0 ? (
                        <DoughnutChart labels={labels} data={processedData} config={config} count={totalCount} />
                    ) : (
                        <span className="count">{count}</span>
                    )}
                </div>
            </LoadingWrapper>
        </Grid>
    );
};
export default DoughnutChartCard;
