import React from "react";
import IncidentLayout from "layouts/app-taxi-ranking/incidents";
import ProtectedLayout from "layouts/common/general/ProtectedLayout";
import Logs from "pages/common/logs/logs";
import WithScopes from "components/common/WithScopes";
import { SCOPES } from "enums/scopes.enum";

const root = {
    path: "logs",
    element: (
        <ProtectedLayout>
            <IncidentLayout />
        </ProtectedLayout>
    ),
    children: [
        {
            path: "",
            element: (
                <WithScopes filterScopes={[SCOPES.VIEW_AUDIT_LOGS_PAGE]}>
                    <Logs />
                </WithScopes>
            ),
        },
    ],
};
export default root;
