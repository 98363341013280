import { ReactElement, useMemo } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

type WithScopesProps = {
    filterScopes: string[];
    children: ReactElement;
    isFullScreen?: boolean;
};

const WithScopes = ({ filterScopes, children, isFullScreen = false }: WithScopesProps): JSX.Element => {
    const { scopes, appCode } = useSelector((state) => state.auth);
    const location = useLocation();
    const toPath = isFullScreen ? "/forbidden" : `/${appCode}/forbidden`
    const isAuthorized = useMemo(() => {
        return scopes?.some((scp) => {
            const splitScope = scp.split(":");
            splitScope.pop();
            const joinedScope = splitScope.join(":");
            return filterScopes.includes(joinedScope)
        });
    }, [filterScopes]);
    return isAuthorized ? children : <Navigate to={toPath} state={{ from: location }} replace />;
};

export default WithScopes;
