import { Typography } from "@mui/material";
import React from "react";

interface AlertHeaderProps {}

const AlertHeader: React.FC<AlertHeaderProps> = () => {
    return <Typography variant="subTitle">Incidents</Typography>;
};

export default AlertHeader;
