/* eslint-disable react/prop-types */
import {
    AutocompleteChangeReason,
    AutocompleteRenderInputParams,
    Box,
    Grid,
    Popper,
    PopperProps,
    styled,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { executeGetUsersRequest } from "api/app-taxi-ranking/taxi-incidents.api";
import CommonDateRangePicker, { DatePickerValue, pickerRanges } from "components/common/CommonDateRangePicker";
import IotVisionButton from "components/common/IotVisionButton";
import IotVisionTextFiled from "components/common/IotVisionTextFiled";
import IotVisionAutoComplete from "components/common/IotVisonAutoComplete";
import ProtectedView from "components/common/ProtectedView";
import { SCOPES } from "enums/scopes.enum";
import { useEffect, useRef, useState } from "react";
import Avatar from "react-avatar";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { URLSearchParamsInit, useSearchParams } from "react-router-dom";

interface DropdownValue {
    id: string;
    label: string;
}

interface LocationOptions {
    name: string;
    value: DropdownValue[];
}

interface Options {
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any[];
}

interface AutoLabelId {
    label: string;
    id: string;
}

interface AutoLabelAssignee {
    name: string;
    // eslint-disable-next-line camelcase
    user_id: string;
    picture: string;
    email: string;
}

interface VideoFilterFormField {
    createdAt?: DatePickerValue;
    locationId?: AutoLabelId;
    userId?: AutoLabelAssignee;
}

interface VideoRequestsFilterPanelProps {
    locationOptions: LocationOptions;
}

const assigneeOptions: Options = {
    name: "Requested By",
    value: [],
};

interface dropDownValue {
    name: string;
    value: string[];
}

const StyledAutocompletePopper = styled((popperProps: PopperProps) => {
    const theme = useTheme();
    const isBelowLgScreen = useMediaQuery(theme.breakpoints.down("lg"));
    return (
        <Popper
            {...popperProps}
            style={{
                width: "fit-content",
            }}
            placement={isBelowLgScreen ? "bottom" : "bottom-start"}
        />
    );
})(({ theme }) => {
    const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
    return {
        "& .MuiAutocomplete-listbox": {
            height: "100%",
            maxHeight: isXlScreen ? "600px" : "200px",
        },
    };
});

const VideoRequestsFilterPanel: React.FC<VideoRequestsFilterPanelProps> = ({ locationOptions }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const filtersAbortControllerRef = useRef<AbortController | null>(null);
    const UsersFilterAbortControllerRef = useRef<AbortController | null>(null);
    const [dropDownTypesValues, setDropDownTypes] = useState<Options[]>([]);
    const [pickerValue, setPickerValue] = useState<DatePickerValue>({
        fromTime: "",
        toTime: "",
        range: "",
    });

    const { fromTime, toTime, locationId, userId } = Object.fromEntries([...searchParams]) || {};

    const {
        handleSubmit,
        setValue,
        control,
        reset,
        formState: { isDirty, isValid },
    } = useForm<VideoFilterFormField>({
        defaultValues: {
            locationId: { label: "", id: "" },
            createdAt: pickerValue,
            userId: { name: "", user_id: "", email: "", picture: "" },
        },
    });

    useEffect(() => {
        dropDownTypesValues.forEach((type: dropDownValue) => defineDropDownValues(type));
    }, [dropDownTypesValues]);

    useEffect(() => {
        reset({
            locationId: locationId
                ? {
                      label: findLabel(locationOptions, locationId),
                      id: locationId,
                  }
                : { label: "", id: "" },
            createdAt: {
                fromTime: fromTime || "",
                toTime: toTime || "",
            },
            userId: userId
                ? {
                      name: findLabel(assigneeOptions, userId, "name"),
                      user_id: userId,
                      email: "",
                      picture: "",
                  }
                : { name: "", user_id: "", email: "", picture: "" },
        });
        setPickerValue({ fromTime: fromTime || "", toTime: toTime || "", range: pickerRanges.Custom || "" });
    }, [searchParams, dropDownTypesValues]);

    useEffect(() => {
        getDropDownTypesValues();
        return () => {
            filtersAbortControllerRef.current?.abort();
        };
    }, [locationId, userId]);

    const findLabel = (options: Options, id: string, type?: string): string => {
        if (options?.value && Array.isArray(options?.value) && options.value.length !== 0) {
            if (type) {
                return options?.value?.find((s: AutoLabelAssignee) => s.user_id === id)?.name;
            }

            return options?.value?.find((s: AutoLabelId) => s.id === id)?.label;
        }
        return "";
    };

    const defineDropDownValues = (type: Options) => {
        switch (type.name) {
            case "users":
                assigneeOptions.name = type.name;
                assigneeOptions.value = type.value;
                break;
            default:
        }
    };

    const getDropDownTypesValues = async () => {
        try {
            const filters = [];
            UsersFilterAbortControllerRef.current = new AbortController();
            const { signal } = UsersFilterAbortControllerRef.current;
            const response = await executeGetUsersRequest({}, { signal });
            if (response && Array.isArray(response)) {
                filters.push({
                    name: "users",
                    value: response,
                });
            }
            setDropDownTypes(filters);
        } catch (e) {
            console.log(e);
        }
    };

    const handleClear = (): void => {
        reset({
            locationId: { label: "", id: "" },
            createdAt: {
                fromTime: "",
                toTime: "",
            },
            userId: { name: "", user_id: "", email: "", picture: "" },
        });
        setPickerValue({
            fromTime: "",
            toTime: "",
            range: "",
        });
        setSearchParams({
            page: "1",
        });
    };

    const onSubmit: SubmitHandler<VideoFilterFormField> = (data) => {
        const searchParamData: URLSearchParamsInit | undefined = {
            page: "1",
            ...(data?.createdAt?.fromTime ? { fromTime: data?.createdAt?.fromTime } : {}),
            ...(data?.createdAt?.toTime ? { toTime: data?.createdAt?.toTime } : {}),
            ...(data?.locationId?.id ? { locationId: data?.locationId?.id } : {}),
            ...(data?.userId?.user_id ? { userId: data?.userId?.user_id } : {}),
        };
        setSearchParams(searchParamData);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={4} lg={5}>
                    <Controller
                        name="locationId"
                        control={control}
                        render={({ field: { value } }) => (
                            <IotVisionAutoComplete
                                options={locationOptions.value}
                                value={value}
                                onChange={(
                                    event: React.SyntheticEvent,
                                    value: AutoLabelId,
                                    reason: AutocompleteChangeReason,
                                ) => {
                                    if (reason === "clear") {
                                        setValue(
                                            "locationId",
                                            {
                                                label: "",
                                                id: "",
                                            },
                                            { shouldDirty: true },
                                        );
                                    } else {
                                        setValue(
                                            "locationId",
                                            {
                                                label: value?.label,
                                                id: value?.id,
                                            },
                                            { shouldDirty: true },
                                        );
                                    }
                                }}
                                renderInput={(params: AutocompleteRenderInputParams) => (
                                    <IotVisionTextFiled {...params} fullWidth placeholder="Location" />
                                )}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={2}>
                    <Controller
                        name="createdAt"
                        control={control}
                        render={() => (
                            <CommonDateRangePicker
                                value={pickerValue}
                                shouldDisplayPredefinedValues={false}
                                customPlaceHolder="Requested At"
                                onPickerMenuClose={() => {
                                    setPickerValue({
                                        ...pickerValue,
                                    });
                                }}
                                onDatePickerApply={(startTime, endTime, range) => {
                                    setPickerValue({
                                        fromTime: startTime,
                                        toTime: endTime,
                                        range,
                                    });
                                    setValue("createdAt.fromTime", startTime, {
                                        shouldDirty: true,
                                    });
                                    setValue("createdAt.toTime", endTime);
                                    setValue("createdAt.range", range);
                                }}
                                onDatePickerClear={() => {
                                    setValue("createdAt.fromTime", "", {
                                        shouldDirty: true,
                                    });
                                    setValue("createdAt.toTime", "");
                                    setPickerValue({
                                        fromTime: "",
                                        toTime: "",
                                        range: pickerRanges.Custom,
                                    });
                                }}
                                buttonText="Clear"
                            />
                        )}
                    />
                </Grid>
                <ProtectedView shouldHide filterScopes={[SCOPES.MANUAL_VIDEO_REQUEST_ADMIN_SCOPE]}>
                    <Grid item xs={12} sm={12} md={4} lg={2}>
                        <Controller
                            name="userId"
                            control={control}
                            render={({ field: { value } }) => (
                                <IotVisionAutoComplete
                                    PopperComponent={(params) => <StyledAutocompletePopper {...params} />}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    value={value}
                                    options={assigneeOptions.value}
                                    onChange={(
                                        event: React.SyntheticEvent,
                                        value: AutoLabelAssignee,
                                        reason: AutocompleteChangeReason,
                                    ) => {
                                        if (reason === "clear") {
                                            setValue(
                                                "userId",
                                                {
                                                    name: "",
                                                    user_id: "",
                                                    email: "",
                                                    picture: "",
                                                },
                                                { shouldDirty: true },
                                            );
                                        } else {
                                            setValue("userId", value, {
                                                shouldDirty: true,
                                            });
                                        }
                                    }}
                                    renderInput={(params: AutocompleteRenderInputParams) => (
                                        <IotVisionTextFiled {...params} fullWidth placeholder="Requested By" />
                                    )}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            sx={{
                                                "& img": {
                                                    mr: 2,
                                                    flexShrink: 0,
                                                },
                                            }}
                                            {...props}
                                            key={option.user_id}
                                        >
                                            <Avatar alt="profile" round src={option.picture} size="40" />
                                            <div
                                                style={{
                                                    marginLeft: "8px",
                                                }}
                                            >
                                                {option.name}
                                                <br />

                                                <span
                                                    style={{
                                                        fontSize: "12px",
                                                    }}
                                                >
                                                    {option.email}
                                                </span>
                                            </div>
                                        </Box>
                                    )}
                                />
                            )}
                        />
                    </Grid>
                </ProtectedView>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Grid container spacing={1}>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <IotVisionButton
                                type="submit"
                                fullWidth
                                disabled={!isDirty || !isValid}
                                sx={{
                                    height: "37px",
                                    color: "white !important",
                                }}
                            >
                                Search
                            </IotVisionButton>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <IotVisionButton
                                fullWidth
                                sx={{
                                    height: "37px",
                                    backgroundColor: "white",
                                    color: "#6b439d !important",
                                    "&:hover": {
                                        background: "white",
                                    },
                                }}
                                onClick={handleClear}
                            >
                                Clear
                            </IotVisionButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default VideoRequestsFilterPanel;
