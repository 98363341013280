import React from "react";
import Grid from "@mui/material/Grid";
import { LoadingWrapper } from "components/wrappers/LoadingWrapper";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
interface StatsCardProps {
    title: string;
    count: number;
    icon: any;
    isLoading?: boolean;
    wrapperStyles?: string;
    iconStyles?: string;
}

const StatsCard: React.FC<StatsCardProps> = ({
    title,
    count,
    icon,
    isLoading = false,
    wrapperStyles = "stat-card",
    iconStyles = "Icon-wrapper",
}) => {
    return (
        <Grid item className={wrapperStyles}>
            <div className="title-wrapper">
                <h4>{title}</h4>
            </div>
            <LoadingWrapper isLoading={isLoading} className="loading-wrapper">
                <div className="figure-wrapper">
                    <div className={iconStyles}>{icon}</div>
                    <span className="count">{count}</span>
                </div>
            </LoadingWrapper>
        </Grid>
    );
};

export default StatsCard;
