import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { COLOURS } from "constants/colours.constants";
import LineChart from "components/charts/LineChart";
import { DetectionOvertimeResponse } from "types";

type SynchronizedLineChartProps = {
    data: DetectionOvertimeResponse[];
    title: string;
    fromTime: string;
    toTime: string;
    timeUnit?: string;
};
export interface XYData {
    x: Date;
    y: number;
}
export interface processChartData {
    label: string;
    data: XYData[];
    borderColor: string;
    tension: number;
}
export default function SynchronizedLineChart({ data, title, fromTime, toTime, timeUnit }: SynchronizedLineChartProps) {
    const [processedData, setProcessedData] = useState<processChartData[]>([]);

    useEffect(() => {
        processData(data);
    }, [data]);

    function processData(data: DetectionOvertimeResponse[]): void {
        const uniques = Array.from(new Set(data.map((item: DetectionOvertimeResponse) => item.classification)));
        const dataset = uniques.map((i, index: number) => {
            const subset = data.filter((j: DetectionOvertimeResponse) => j.classification === i);
            return {
                label: i,
                data: subset.map((l: DetectionOvertimeResponse) => {
                    return {
                        x: l.inTime,
                        y: l.count,
                    };
                }),
                borderColor: COLOURS[index],
                tension: 0.1,
            };
        });
        setProcessedData(dataset);
    }

    return (
        <Grid item xs={12} sm={12} md={12} lg={12} className="fullChartWrapper">
            <span className="chartHearder">{title} </span>
            <LineChart isFillEmpty data={processedData} fromTime={fromTime} toTime={toTime} timeUnit={timeUnit} />
        </Grid>
    );
}
