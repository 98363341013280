/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable import/prefer-default-export */
import videojs from "video.js";
import { CommonObjectLiteral } from "types";
import Player from "video.js/dist/types/player";
import Plugin from "video.js/dist/types/plugin";
import { ZoomFunction, ZoomFunctionType } from "./zoom-function";
import { ZoomModalContent } from "./zoom-modal-content";

type PluginOptions = {
    listeners: {
        change: (state: CommonObjectLiteral) => void;
        click: () => void;
    };
    zoom: (state: number) => void;
    flip: (state: string) => void;
    move: (moveX: number, moveY: number) => void;
} & Plugin;

type ElementType = {
    title: string;
    onclick: () => void;
} & Element;

const Component = videojs.getComponent("Component");

export class ZoomModal extends Component {
    playerEl: HTMLElement;

    plugin: PluginOptions;

    function: ZoomFunction;

    constructor(player: Player, options: CommonObjectLiteral) {
        super(player, options);
        this.playerEl = player.el() as HTMLElement;
        this.plugin = options.plugin;
        this.function = new ZoomFunction(player, options);
        player.on("playing", () => {
            this.listeners();
        });
    }

    createEl() {
        const modal = videojs.dom.createEl("div", {
            className: "vjs-zoom-duck__container",
        });
        const content = new ZoomModalContent(this.options_);
        modal.innerHTML = content.getContent() as string;
        return modal;
    }

    listeners() {
        const buttons = this.playerEl.getElementsByClassName("vjs-zoom-duck__button");
        const buttonArray = Array.from(buttons);
        (buttonArray as ElementType[]).forEach((button: ElementType) => {
            const [, action]: string[] = button.id.split("__");
            button.onclick = () => this.function[action as keyof ZoomFunctionType]();
            button.title = action.charAt(0).toUpperCase() + action.slice(1);
        });
    }

    toggle() {
        const [modal] = this.playerEl.getElementsByClassName("vjs-zoom-duck__container");
        modal.classList.toggle("open");
        this.plugin.listeners.click();
    }

    open() {
        const [modal] = this.playerEl.getElementsByClassName("vjs-zoom-duck__container");
        modal.classList.add("open");
        this.plugin.listeners.click();
    }

    close() {
        const [modal] = this.playerEl.getElementsByClassName("vjs-zoom-duck__container");
        modal.classList.remove("open");
        this.plugin.listeners.click();
    }
}
