import ExecuteAsync from "utils/api-client";
import { ApiEndpoints } from "configs";
import { createQueryParams } from "utils/create-query-params";
import { RequestOptions } from "axios-types";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
export function executeGetDetectionsByClassifications(
    data?: { locationId?: string; classifications: string },
    options?: RequestOptions,
) {
    const response = ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/reporting/detections/values/latest${createQueryParams(data)}`,
        signal: options?.signal,
    });
    return response;
}
