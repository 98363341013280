import React, { useEffect, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import PaperWrapper from "components/wrappers/PaperWrapper";
import { CgArrowsExpandRight } from "react-icons/cg";
import FullScreenDialog from "components/dialogs/FullScreenDialog";
import { useSelector } from "react-redux";
import TimeSeriesChart, { IDetectedChartData } from "../../../components/charts/TimeSeriesChart";

export interface IAverageWaitingTimeData {
    inTime: Date;
    max: number;
}
interface IConfig {
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
    label: string;
    maintainAspectRatio: boolean;
}

type AverageWaitingTimeChartProps = {
    data: IAverageWaitingTimeData[];
    title: string;
    config: IConfig;
};

export default function AverageWaitingTimeChart({ data, title, config }: AverageWaitingTimeChartProps) {
    const [processedData, setProcessedData] = useState<IDetectedChartData[]>([]);

    const [modalShow, setModalShow] = React.useState(false);
    const { startTime, endTime, timeType } = useSelector((state) => state.time);

    useEffect(() => {
        const valueList = data.map((l: IAverageWaitingTimeData) => {
            return {
                x: l.inTime,
                y: +(l.max / 60000),
            };
        });

        setProcessedData(valueList);
    }, [data]);

    const handleClick = (status: boolean) => {
        setModalShow(status);
    };

    const options = {
        maintainAspectRatio: config?.maintainAspectRatio,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                type: "time",
                min: new Date(startTime).valueOf(),
                max: new Date(endTime).valueOf(),
                time: {
                    unit: timeType,
                    round: timeType,
                },
            },
        },
    };

    return (
        <>
            <PaperWrapper>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={1} marginBottom={1}>
                        <Grid item className="chart-padding-0">
                            <span className="chartHearder">{title}</span>
                        </Grid>
                        <Grid item className="chart-padding-0">
                            <IconButton className="expand-btn" onClick={() => handleClick(true)} disableRipple>
                                <CgArrowsExpandRight />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <div>
                        <TimeSeriesChart data={processedData} options={options} {...config} />
                    </div>
                </Grid>
            </PaperWrapper>
            <FullScreenDialog
                modalShow={modalShow}
                onClose={handleClick}
                childComponent={
                    <div>
                        <TimeSeriesChart data={processedData} {...config} options={options} />
                    </div>
                }
            />
        </>
    );
}
