/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Box,
    CircularProgress,
    CircularProgressProps,
    IconButton,
    IconButtonProps,
    styled,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import {
    GridColumns,
    GridRenderCellParams,
    GridRowsProp,
    GridRowClassNameParams,
    GridRowParams,
    GridColumnHeaderTitle,
    GridColDef,
    GridSortModel,
} from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useLocation, useSearchParams } from "react-router-dom";
import { pagePath } from "pages/app-dept-air-quality";
import { executeGetIncidentData } from "api/app-dept-air-quality/air-incidents.api";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import { setPageIndex, setPageSize } from "reducers/aq-incident.reducer";
import { capitalCase } from "capital-case";
import { AQDemoIncidentNames, AQDemoIncidentTypes } from "constants/aqIncidentAttributes";
import { StyledDataTable } from "../../common";

const INCIDENT_PAGE_PAGESIZE = 10;
const ROOM_PAGE_PAGESIZE = 3;

const StyledSortIcon = styled((iconButtonProps: IconButtonProps) => (
    <IconButton
        size="small"
        {...iconButtonProps}
        // eslint-disable-next-line react/no-children-prop
        children={
            <KeyboardArrowDownIcon
                sx={{
                    color: "#001B4F",
                }}
            />
        }
    />
))(() => ({}));

const StyledLoadingSpinner = styled((circularProgressProps: CircularProgressProps) => (
    <Box
        sx={{
            color: "red",
            zIndex: 1000,
            position: "absolute",
            left: "50%",
            top: "50%",
        }}
    >
        <CircularProgress {...circularProgressProps} />
    </Box>
))(() => ({}));

interface RoomAlertsTableProps {
    locationId?: string;
    setLastUpdated?: React.Dispatch<React.SetStateAction<Date | null>>;
}

enum SortOrder {
    ASC = "asc",
    DESC = "desc",
}
interface fetchIncidentOptions {
    pageIndex: number;
    pageSize: number;
    locationId?: string;
    idInc?: string;
    status?: string;
    fromTime?: string;
    toTime?: string;
    incident?: string;
    type?: string;
    sortColum?: string;
    sortOrder?: "asc" | "desc";
}

export const getAlertName = (incident: string) => {
    if (incident) {
        if (Object.keys(AQDemoIncidentNames).includes(incident)) {
            return AQDemoIncidentNames[incident];
        }
        return capitalCase(incident);
    }
};

export const getAlertType = (type: string) => {
    if (type) {
        if (Object.keys(AQDemoIncidentTypes).includes(type)) {
            return AQDemoIncidentTypes[type];
        }
        return capitalCase(type);
    }
};

const AlertsTable: React.FC<RoomAlertsTableProps> = ({ locationId, setLastUpdated }) => {
    const theme = useTheme();
    const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
    const { pathname } = useLocation();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [rows, setRows] = useState<GridRowsProp[]>([]);
    const [total, setTotal] = useState<number>(0);
    const { pageIndex, pageSize } = useSelector((state) => state.aqIncident);
    const [isAlertPage, setIsAlertPage] = useState<boolean>(false);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
    const dispatch = useAppDispatch();
    const incidentsAbortControllerRef = useRef<AbortController | null>(null);
    const [searchParams] = useSearchParams();

    const { incidentId, status, fromTime, toTime, range, alert, type } = Object.fromEntries([...searchParams]);
    const locId = searchParams.get("locationId");

    const columns: GridColumns = [
        {
            field: "alert",
            headerName: "Incident",
            align: "left",
            hideSortIcons: true,
            sortable: false,
            width: isXlScreen ? 355 : 300,
            disableColumnMenu: true,
        },
        {
            field: "type",
            headerName: "Type",
            align: "left",
            hideSortIcons: true,
            renderHeader: (params) => {
                const { colDef } = params;
                return (
                    <>
                        <GridColumnHeaderTitle
                            label={colDef.headerName || colDef.field}
                            description={colDef.description}
                            columnWidth={colDef.width || isXlScreen ? 250 : 150}
                        />

                        <div className="MuiDataGrid-iconButtonContainer">
                            <StyledSortIcon />
                        </div>
                    </>
                );
            },
            renderCell: (params: GridRenderCellParams) => params.row.type,
            width: isXlScreen ? 250 : 150,
            disableColumnMenu: true,
        },
        {
            field: "time",
            align: "left",
            hideSortIcons: true,
            renderHeader: (params) => {
                const { colDef } = params;
                return (
                    <>
                        <GridColumnHeaderTitle
                            label={colDef.headerName || colDef.field}
                            description={colDef.description}
                            columnWidth={colDef.width || isXlScreen ? 250 : 160}
                        />

                        <div className="MuiDataGrid-iconButtonContainer">
                            <StyledSortIcon />
                        </div>
                    </>
                );
            },
            width: isXlScreen ? 350 : 180,
            headerName: "Date and Time",
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => <>{new Date(params.row.time).toLocaleString()}</>,
        },
        {
            field: "status",
            align: "left",
            headerName: "Status",
            width: 120,
            hideSortIcons: true,
            renderHeader: (params) => {
                const { colDef } = params;
                return (
                    <>
                        <GridColumnHeaderTitle
                            label={colDef.headerName || colDef.field}
                            description={colDef.description}
                            columnWidth={colDef.width || 120}
                        />

                        <div className="MuiDataGrid-iconButtonContainer">
                            <StyledSortIcon />
                        </div>
                    </>
                );
            },
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => <>{params.row.status?.label}</>,
        },
    ];
    const ClassRoomField: GridColDef = {
        field: "room",
        headerName: "Classroom",
        align: "left",
        hideSortIcons: true,
        renderHeader: (params) => {
            const { colDef } = params;
            return (
                <GridColumnHeaderTitle
                    label={colDef.headerName || colDef.field}
                    description={colDef.description}
                    columnWidth={colDef.width || isXlScreen ? 250 : 150}
                />
            );
        },
        width: isXlScreen ? 250 : 150,
        disableColumnMenu: true,
        sortable: false,
    };

    const fetchData = async (pageIndex: number, pageSize: number) => {
        try {
            incidentsAbortControllerRef.current = new AbortController();
            const { signal } = incidentsAbortControllerRef.current;

            let options: fetchIncidentOptions = {
                pageIndex,
                pageSize,
            };
            if (locationId) options.locationId = locationId;
            if (sortModel.length > 0) {
                options.sortColum = sortModel[0].field === "time" ? "dateTime" : sortModel[0].field;
                if (sortModel[0].sort !== null) options.sortOrder = sortModel[0].sort;
            }
            options = addFilters(options);
            const { incident, total, lastReportedAt } = await executeGetIncidentData(options, { signal });
            setLastUpdated?.(new Date(lastReportedAt));
            const rows = incident.map((i: any) => {
                return {
                    id: i.id,
                    alert: getAlertName(i.incident),
                    type: getAlertType(i.type),
                    room: i.location.name,
                    time: i.dateTime,
                    status: { id: i.status.id, label: i.status.name },
                };
            });
            setTotal(total);
            setRows(rows);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };
    const addFilters = (options: fetchIncidentOptions): fetchIncidentOptions => {
        const filterData = Object.fromEntries([...searchParams]);
        if (filterData.locationId) {
            options.locationId = filterData.locationId;
        }
        if (filterData.incidentId) {
            options.idInc = filterData.incidentId;
        }
        if (filterData.fromTime) {
            options.fromTime = filterData.fromTime;
        }
        if (filterData.toTime) {
            options.toTime = filterData.toTime;
        }
        if (filterData.alert) {
            options.incident = filterData.alert;
        }
        if (filterData.type) {
            options.type = filterData.type;
        }
        if (filterData.status) {
            options.status = filterData.status;
        }

        return options;
    };
    const onPageChange = (newPage: number) => {
        setLoading(true);
        fetchData(newPage + 1, pageSize);
        dispatch(setPageIndex(newPage + 1));
    };

    useEffect(() => {
        setIsAlertPage(pathname.includes(pagePath.Incident));
    }, [pathname]);

    useEffect(() => {
        setLoading(true);
        const recordCount = !locationId ? INCIDENT_PAGE_PAGESIZE : ROOM_PAGE_PAGESIZE;
        dispatch(setPageSize(recordCount));
        fetchData(pageIndex, recordCount);

        return () => {
            incidentsAbortControllerRef.current?.abort();
        };
    }, [locationId, incidentId, status, fromTime, toTime, range, alert, type, sortModel, locId]);

    if (isAlertPage) columns.splice(1, 0, ClassRoomField);

    const handleSortModelChange = (newModel: GridSortModel) => {
        dispatch(setPageIndex(1));
        setSortModel(newModel);
    };

    return (
        <StyledDataTable
            autoHeight
            loading={loading}
            isRowSelectable={(params: GridRowParams) => params.row.status.name !== "Closed"}
            getRowClassName={
                (params: GridRowClassNameParams) => (params.row.status.name === "Closed" ? "disableRow" : "")
                // eslint-disable-next-line react/jsx-curly-newline
            }
            paginationMode="server"
            columns={columns}
            rows={rows}
            pageSize={isAlertPage ? INCIDENT_PAGE_PAGESIZE : ROOM_PAGE_PAGESIZE}
            page={pageIndex ? pageIndex - 1 : 1}
            rowCount={total}
            onPageChange={onPageChange}
            hideFooterPagination={false}
            sortingMode="server"
            sortingOrder={[SortOrder.ASC, SortOrder.DESC]}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            disableSelectionOnClick
            components={{ LoadingOverlay: StyledLoadingSpinner }}
        />
    );
};

export default AlertsTable;
