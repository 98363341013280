import { useEffect } from "react";
import { useLocation } from "react-router";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
const ScrollTopWrapper = (props: { children: any }) => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    const { children } = props;
    return <> {children}</>;
};

export default ScrollTopWrapper;
