interface Props {
    value: string[];
    itemStyle?: React.CSSProperties;
}
export const TableMultiLinerCell = ({ value, itemStyle }: Props) => {
    return (
        <div
            style={{
                lineHeight: "normal",
                display: "flex",
                flexDirection: "column",
            }}
        >
            {value?.map((item: string) => (
                <span style={itemStyle} key={item}>
                    {item}
                </span>
            ))}
        </div>
    );
};
