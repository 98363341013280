/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AlertsField } from "types/incident/alert-filter";

const initialState = {
    filters: {} as AlertsField,
    pageIndex: 1,
    pageSize: 10,
};

const slice = createSlice({
    name: "aqIncident",
    initialState,
    reducers: {
        setPageIndex(state, action: PayloadAction<any>) {
            const index = action.payload ?? 1;
            state.pageIndex = index;
        },
        setPageSize(state, action: PayloadAction<any>) {
            const size = action.payload ?? 10;
            state.pageSize = size;
        },
        clearPageIndex(state) {
            const index = 1;
            state.pageIndex = index;
        },
    },
});

export const { setPageIndex, setPageSize, clearPageIndex } = slice.actions;
export default slice.reducer;
