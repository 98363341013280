/* eslint-disable react/state-in-constructor */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
import Player from "video.js/dist/types/player";
import { CommonObjectLiteral } from "types";
import videojs from "video.js";
import { ZoomFunction } from "./zoom-function";

export const ZOOM_INTERVAL = 10; // in milliseconds

export const SEEKING_INTERVAL = 50; // in milliseconds

const Component = videojs.getComponent("Component");

export class ZoomGesture extends Component {
    lastZoomTime: number;

    pointers: CommonObjectLiteral;

    function: ZoomFunction;

    playerEl: HTMLElement;

    state: CommonObjectLiteral;

    isSeeking = false;

    lastPointerUpTime: number | undefined;

    constructor(player: Player, options: CommonObjectLiteral) {
        super(player, options);
        this.lastZoomTime = 0;
        this.pointers = {};
        this.playerEl = player.el() as HTMLElement;
        this.state = options.state;
        this.function = new ZoomFunction(player, options);

        player.on("seeking", () => {
            if (this.lastPointerUpTime && Date.now() - this.lastPointerUpTime > SEEKING_INTERVAL) this.isSeeking = true;
        });

        player.on("playing", () => {
            this.gesture();
        });
    }

    gesture() {
        const onPointerDown = (event: PointerEvent) => {
            this.pointers[event.pointerId] = event;
        };

        const onPointerUp = (event: PointerEvent) => {
            this.isSeeking = false;
            this.lastPointerUpTime = Date.now();
            delete this.pointers[event.pointerId];
        };

        const onPointerMove = (event: PointerEvent) => {
            if (this.isSeeking) return;
            if (!Object.keys(this.pointers).length) return;
            const pointer = this.pointers[event.pointerId];
            if (!pointer) return;
            const moveX = event.clientX - pointer.clientX;
            const moveY = event.clientY - pointer.clientY;
            this.pointers[event.pointerId] = event;
            this.function.moveY(moveX);
            this.function.moveX(moveY);
        };

        const onWheel = (event: WheelEvent) => {
            const currentTime = new Date().getTime();
            if (currentTime - this.lastZoomTime > ZOOM_INTERVAL) {
                this.function.zoomHandler(-0.01 * event.deltaY);
                this.lastZoomTime = currentTime;
            }
        };

        const onDragStart = (event: DragEvent) => {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        };

        this.playerEl.addEventListener("pointerdown", onPointerDown);
        window.addEventListener("pointerup", onPointerUp);
        this.playerEl.addEventListener("pointermove", onPointerMove);
        this.playerEl.addEventListener("wheel", onWheel);
        this.playerEl.addEventListener("dragstart", onDragStart);

        this.player_.on("dispose", () => {
            this.playerEl.removeEventListener("pointerdown", onPointerDown);
            window.removeEventListener("pointerup", onPointerUp);
            this.playerEl.removeEventListener("pointermove", onPointerMove);
            this.playerEl.removeEventListener("wheel", onWheel);
        });
    }
}
