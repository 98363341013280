/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MapState {
    mapFocusCenter?: any;
    mapFocusZoom?: number;
}
const initialState = {
    mapFocusCenter: {
        lat: -24.25,
        lng: 133.416667,
    },
    mapFocusZoom: 4,
} as MapState;

const mapSlice = createSlice({
    name: "map",
    initialState,
    reducers: {
        setMapFocus: (state, action: PayloadAction<any>) => {
            state.mapFocusCenter = action.payload.center;
            state.mapFocusZoom = action.payload.zoom;
        },
        setInitialMapFocus: () => initialState,
    },
});

export const { setMapFocus, setInitialMapFocus } = mapSlice.actions;
export default mapSlice.reducer;
