import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeviceType } from "enums/device.enums";
import { IDevice } from "types";

interface DeviceState {
    allDevices: IDevice[];
    cameraList: IDevice[];
}

const initialState = {
    allDevices: [],
    cameraList: [],
} as DeviceState;

const slice = createSlice({
    name: "devices",
    initialState,
    reducers: {
        setDeviceList(state, action: PayloadAction<IDevice[]>) {
            const list = action.payload;
            state.allDevices = list;
            state.cameraList = list?.filter((device: IDevice) => device.type?.toLowerCase() === DeviceType.CAMERA);
        },
        setCameraList(state, action: PayloadAction<IDevice[]>) {
            state.cameraList = action.payload;
        },
        clearDevices(state) {
            state.allDevices = [];
            state.cameraList = [];
        },
    },
});

export const { setCameraList, setDeviceList, clearDevices } = slice.actions;
export default slice.reducer;
