import React from "react";
import Layout from "layouts/app-ungated-parking";
import { ProtectedLayout } from "layouts/common/general";
import NotFound from "../common/errors/not-found/NotFound";

const root = {
    path: "page-not-found",
    element: (
        <ProtectedLayout>
            <Layout />
        </ProtectedLayout>
    ),
    children: [{ path: "", element: <NotFound /> }],
};
export default root;
