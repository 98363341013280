import { ReactElement, ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { AppDomain, AuthConfig, Applications } from "configs";
import { useAppDispatch } from "store";
import { ActionTypes } from "reducers/action-types/action-types";
import { directUrl as appAirQualityDirectUrl } from "pages/app-air-quality/index";
import { directUrl as appCyclewaysDirectUrl } from "pages/app-cycle-ways/index";
import { directUrl as appDeptAirQualityDirectUrl } from "pages/app-dept-air-quality/index";
import { directUrl as appOversizedVehicleDirectUrl } from "pages/app-oversized-vehicle/index";
import { directUrl as appTaxiRankingDirectUrl } from "pages/app-taxi-ranking/index";
import { directUrl as appParkAndRideDirectUrl } from "pages/app-park-and-ride/index";
import { directUrl as appUngatedParkingDirectUrl } from "pages/app-ungated-parking/index";
import * as scopesConfig from "../../configs/scopes.config";
import AuthLayout from "../../layouts/auth";

const { ClientId, Audience } = AuthConfig;

const {
    AppIdTaxiRanking,
    AppIdCycleways,
    AppIdAirQuality,
    AppIdOversizedVehicles,
    AppIdDEPTAirQuality,
    AppIdParkAndRide,
    AppIdUngatedParking,
} = Applications;

type AuthPageProps = {
    children: ReactNode;
};
const AuthPage = ({ children }: AuthPageProps): ReactElement => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [scopes, setScopes] = useState("");
    const location = useLocation();

    useEffect(() => {
        (async () => {
            const scopesNewlinesString = await fetch(scopesConfig.default).then((response) => response.text());
            const updatedScopes = scopesNewlinesString.split("\r\n").join(" ");
            setScopes(updatedScopes);
        })();
    }, []);

    const onRedirectCallback = (appState: AppState | undefined) => {
        dispatch({ type: ActionTypes.CLEAR_STORE });
        navigate(appState?.returnTo || window.location.pathname);
    };

    const getOrganizationID = (): string | undefined => {
        switch (location.pathname) {
            case appAirQualityDirectUrl:
                return AppIdAirQuality;
            case appCyclewaysDirectUrl:
                return AppIdCycleways;
            case appDeptAirQualityDirectUrl:
                return AppIdDEPTAirQuality;
            case appOversizedVehicleDirectUrl:
                return AppIdOversizedVehicles;
            case appTaxiRankingDirectUrl:
                return AppIdTaxiRanking;
            case appParkAndRideDirectUrl:
                return AppIdParkAndRide;
            case appUngatedParkingDirectUrl:
                return AppIdUngatedParking;
            default:
                return undefined;
        }
    };

    const redirectUri = "/redirect-callback";

    if (scopes) {
        return (
            <Auth0Provider
                domain={AppDomain}
                clientId={ClientId}
                redirectUri={window.location.origin + redirectUri}
                onRedirectCallback={onRedirectCallback}
                audience={Audience}
                scope={scopes}
                organization={getOrganizationID()}
            >
                <AuthLayout>{children}</AuthLayout>
            </Auth0Provider>
        );
    }

    /* eslint react/jsx-no-useless-fragment: ["off"] */
    return <></>;
};

export default AuthPage;
