import React, { useEffect, useState } from "react";
import StatisticsCards from "features/app-park-and-ride/statistics/StatisticsCards";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import { AppParkAndRidePrefix } from "constants/app.constants";

import { useNavigate } from "react-router";
import platformityLogo from "assets/images/platformity-favicon-logo.png";
import { findLocation } from "utils/search-tree";
import { navigateTo404Page } from "utils/navigation";
import { useAppDispatch } from "store";
import { resetTimeRange } from "reducers/time.reducer";
import HeaderDatePicker from "features/common/date-header/HeaderDatePicker";
import { Location } from "../../../reducers/newLocation.reducer";
import LocationChartLayout from "./LocationChartLayout";
import LocationSummaryHeader from "./LocationSummaryCard";

export default function LocationPage() {
    const { locationId } = useParams();
    const { locationsTree, locations } = useSelector((state) => state.newLocation);
    const [selectedLocation, setSelectedLocation] = useState<Location>();
    const [selectedGroup, setSelectedGroup] = useState<Location>();
    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const { appCode } = useSelector((state) => state.auth);

    useEffect(() => {
        if (locationId) {
            const location = locations.find((i) => i.id === locationId);
            if (!location) {
                navigateTo404Page(navigate, appCode, false);
            }
            if (location) {
                setSelectedLocation(location);
            }
        }
        dispatch(resetTimeRange());
    }, [locationId]);

    useEffect(() => {
        dispatch(resetTimeRange());
    }, []);

    useEffect(() => {
        let parent!: Location | undefined;
        if (selectedLocation) {
            const { parentId } = selectedLocation;
            parent = findLocation(locationsTree, parentId);
            if (parent) {
                setSelectedGroup(parent);
            } else {
                navigate(`/${appCode}/page-not-found`, { replace: true });
            }
        }
    }, [selectedLocation]);


    return (
        <>
            <Helmet>
                <title>{AppParkAndRidePrefix} | Location Summary</title>
                <link rel="icon" id="cw-location" type="image/png" href={platformityLogo} sizes="32x32" />
            </Helmet>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <LocationSummaryHeader
                        name={selectedLocation?.name}
                        city={selectedLocation?.city}
                        latitude={selectedLocation?.latitude}
                        longitude={selectedLocation?.longitude}
                        // devices={selectedLocation?.devices}
                        component={0}
                        openHours={selectedLocation?.openHours}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div style={{ maxWidth: "300px", marginLeft: "auto" }}>
                        <HeaderDatePicker />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <StatisticsCards group={selectedGroup} />
                </Grid>
                <Grid item xs={12}>
                    <LocationChartLayout />
                </Grid>
            </Grid>
        </>
    );
}
