import { createSlice } from "@reduxjs/toolkit";

interface SystemState {
    isDrawerOpen: boolean;
    isReportsDrawerOpen: boolean;
}

const initialState = {
    isDrawerOpen: true,
    isReportsDrawerOpen: true,
} as SystemState;

const counterSlice = createSlice({
    name: "system",
    initialState,
    reducers: {
        toggleSideBar(state) {
            state.isDrawerOpen = !state.isDrawerOpen;
        },
        toggleReportsSideBar(state) {
            state.isReportsDrawerOpen = !state.isReportsDrawerOpen;
        },
    },
});

export const { toggleSideBar, toggleReportsSideBar } = counterSlice.actions;
export default counterSlice.reducer;
