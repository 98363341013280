import { Tooltip, TooltipProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
/* eslint @typescript-eslint/no-unused-vars: ["off"] */
interface IIotVisionToolTips {
    additionalProps?: any;
}
const CustomizedToolTips = styled(Tooltip)(
    () => `
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
`,
);

const IotVisionToolTips: React.FC<IIotVisionToolTips & TooltipProps> = ({ additionalProps, ...rest }) => {
    return <CustomizedToolTips {...rest} />;
};

export default IotVisionToolTips;
