/* eslint-disable @typescript-eslint/no-explicit-any */
import { RequestOptions } from "axios-types";
import { ApiEndpoints } from "configs";
import { HistoricalQueryParams, HistoricalResponse } from "types";
import { IDetections } from "types/detections/common";
import ExecuteAsync from "utils/api-client";
import { createQueryParams } from "utils/create-query-params";

export function executeGetHistoricalOccupancyRequest(query: HistoricalQueryParams, options?: RequestOptions) {
    return ExecuteAsync<any, HistoricalResponse[]>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/real-time/historical${createQueryParams(query)}`,
        signal: options?.signal,
        disableNotification: options?.disableNotification,
    });
}

export function executeGetRealTimeDetectionsRequest(data?: IDetections, options?: RequestOptions) {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/real-time/data${createQueryParams(data)}`,
        signal: options?.signal,
        disableNotification: options?.disableNotification,
    });
}
