/* eslint-disable no-unused-expressions */
import React, { useEffect, useMemo, useRef, useState } from "react";
import PaperWrapper from "components/wrappers/PaperWrapper";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import DateView from "components/dates/DateView";
import IotVisionTable from "components/common/IotVisionTable";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { enAU } from "date-fns/locale";
import { Grid, IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import { FaThList } from "react-icons/fa";
import SectionHeading from "components/headings/SectionHeading";
import { styled } from "@mui/material/styles";
import StatusCodes from "enums/status-code.enums";
import { CommonObjectLiteral, PlateSearchReportDetails } from "types/reports/interfaces";
import { executeReportRetrievalRequest } from "api/common/side-panel.api";
import { CgArrowsExpandRight } from "react-icons/cg";
import FullScreenDialog from "components/dialogs/FullScreenDialog";
import TableHeading from "components/headings/TableHeading";
import IotVisionButton from "components/common/IotVisionButton";
import { SCOPES } from "enums/scopes.enum";
import ProtectedView from "components/common/ProtectedView";
import IotVisionToolTips from "components/common/IotVisionToolTips";
import { ReportTypes } from "types/reports/enums";
import { dateRangeStringCreator } from "utils/common";
import { sortOrder } from "../incidents/IncidentsTable";
import ReportListFilterPanel from "./report-list-filter-panel";

export enum ReportRetrievalReportsTableSortKeys {
    CreatedAt = "createdAt",
    ReportTo = "reportTo",
    TaskId = "taskId",
}

const CustomizedSectionHeading = styled(SectionHeading)(() => ({
    background: "transparent !important",
    "& .icon-div": {
        background: "transparent !important",
    },
}));

const CustomizedIotVisionCreateButton = styled(IotVisionButton)(() => ({
    color: "white !important",
    height: "37px",
    display: "flex",
    alignItems: "center",
    marginTop: "4px",
    marginRight: "25px",
}));

const CustomizedTypographyDiv = styled("div")(() => ({
    color: "white !important",
    whiteSpace: "nowrap",
    marginLeft: "10px",
}));

const tableColumns: GridColDef[] = [
    {
        field: "metaField1",
        headerName: "License Plates",
        renderCell: (params: GridRenderCellParams) => (
            <IotVisionToolTips title={params.row.metaField1?.join(", ") || ""} arrow>
                <div>{params.row.metaField1?.join(", ") || ""}</div>
            </IotVisionToolTips>
        ),
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
    },
    {
        field: "reportFrom",
        headerName: "Report Period",
        flex: 1.5,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => (
            <IotVisionToolTips
                title={
                    <span>
                        {dateRangeStringCreator(params.row.reportFrom, params.row.reportTo, "P")}
                    </span>
                }
                arrow
            >
                <span>
                    {dateRangeStringCreator(params.row.reportFrom, params.row.reportTo, "P")}
                </span>
            </IotVisionToolTips>
        ),
    },
    {
        field: "createdAt",
        headerName: "Generated Date",
        renderCell: (params: GridRenderCellParams) => (
            <IotVisionToolTips
                title={
                    <span>
                        <DateView dateFormat="Pp" options={{ locale: enAU }}>
                            {params.row.createdAt}
                        </DateView>
                    </span>
                }
                arrow
            >
                <span>
                    <DateView dateFormat="Pp" options={{ locale: enAU }}>
                        {params.row.createdAt}
                    </DateView>
                </span>
            </IotVisionToolTips>
        ),
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
    },
    {
        field: "requestedBy",
        headerName: "Requested By",
        renderCell: (params: GridRenderCellParams) => (
            <IotVisionToolTips title={params.row.requestedBy?.username || ""} arrow>
                <div>{params.row.requestedBy?.username || ""}</div>
            </IotVisionToolTips>
        ),
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
    },
    {
        field: "reportScheduleType",
        headerName: "Type",
        renderCell: (params: GridRenderCellParams) => (
            <IotVisionToolTips title={params.row.reportScheduleType || ""} arrow>
                <div>{params.row.reportScheduleType || ""}</div>
            </IotVisionToolTips>
        ),
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
    },
    {
        field: "recordCount",
        headerName: "Records",
        renderCell: (params: GridRenderCellParams) => (
            <IotVisionToolTips title={params.row.recordCount} arrow>
                <div>{params.row.recordCount}</div>
            </IotVisionToolTips>
        ),
        flex: 0.5,
        sortable: false,
        disableColumnMenu: true,
    },
    {
        field: "id",
        headerName: "",
        renderCell: (params: GridRenderCellParams) => (
            <Link to={`${params.row.id}`}>View</Link>
        ),
        flex: 0.5,
        align: "right",
        sortable: false,
        disableColumnMenu: true,
    },
];

function ReportListTable() {
    const [page, setPage] = useState<number>(0);
    const [rowsCount, setRowsCount] = useState<number>(0);
    const [reportData, setReportData] = React.useState<PlateSearchReportDetails[]>([]);
    const [nextStartKey, setNextStartKey] = useState<string | null>(null);
    const [pageKeyArray, setPageKeyArray] = useState<string[]>([]);
    const [previousPageIndex, setPreviousPageIndex] = useState<number>(0);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [isReportFetched, setIsReportFetched] = useState<boolean>(false);
    const reportListFilterAbortControllerRef = useRef<AbortController | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { pageSize } = useSelector((store) => store.report);
    const { appCode } = useSelector((store) => store.auth);
    const capturedSearchParams = useMemo(() => Object.fromEntries([...searchParams]) || {}, [searchParams]);
    const [modalShow, setModalShow] = React.useState(false);

    useEffect(() => {
        getReportDataWithPaginationDetails(1, capturedSearchParams);
        setPage(0);
        return () => {
            setReportData([]);
            setRowsCount(0);
            setNextStartKey(null);
            reportListFilterAbortControllerRef.current?.abort();
        };
    }, [capturedSearchParams]);

    const clearPagination = () => {
        setNextStartKey(null);
        setPageKeyArray([]);
    };

    const removeArrayDuplicate = (arr: string[]): string[] => {
        return Array.from(new Set(arr));
    };

    const handleSearchParams = (params: CommonObjectLiteral) => {
        setSearchParams(params);
    };

    const handleClick = (status: boolean) => {
        setModalShow(status);
    };

    const handleCreateButtonClick = () => {
        navigate("create");
    };

    const getReportDataWithPaginationDetails = (pageIndex: number, searchParamData: CommonObjectLiteral) => {
        if (pageIndex === 1) {
            executeGetReportDataEndPoint(null, searchParamData);
            setPreviousPageIndex(0);
            nextStartKey && setPageKeyArray(removeArrayDuplicate([...pageKeyArray, nextStartKey]));
        } else {
            if (previousPageIndex === pageIndex) {
                executeGetReportDataEndPoint(pageKeyArray[pageIndex - 2], searchParamData);
            } else {
                executeGetReportDataEndPoint(nextStartKey, searchParamData);
                nextStartKey && setPageKeyArray(removeArrayDuplicate([...pageKeyArray, nextStartKey]));
            }
            setPreviousPageIndex(pageIndex - 1);
        }
    };

    async function executeGetReportDataEndPoint(pageKey: string | null, params: CommonObjectLiteral): Promise<void> {
        try {
            setIsReportFetched(true);
            reportListFilterAbortControllerRef.current = new AbortController();
            const { signal } = reportListFilterAbortControllerRef.current;
            setTableLoading(true);
            let response = null;
            response = await executeReportRetrievalRequest(
                {
                    ...params,
                    sortKey: ReportRetrievalReportsTableSortKeys.CreatedAt,
                    pageSize,
                    startKey: pageKey,
                    reportType: ReportTypes.METADATA_SEARCH_REPORT,
                },
                { signal },
            );
            if (Array.isArray(response?.data)) {
                const {data, nextStartKey: startKey, total} = response;
                setReportData(data);
                setRowsCount(total);
                setNextStartKey(startKey);
            }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: Error | any) {
            if (err?.response?.status === StatusCodes.FORBIDDEN) {
                navigate(`/${appCode}/forbidden`, {replace: true});
                return;
            }
            if (err?.response?.status === StatusCodes.NOT_FOUND) {
                navigate(`/${appCode}/plate-search-reports/page-not-found`, {replace: true});
                return;
            }
            console.error(err);
        } finally {
            setIsReportFetched(false);
            setTableLoading(false);
        }
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={7} md={7} lg={9} xl={9} marginBottom={2}>
                    <CustomizedSectionHeading
                        heading="Plate Search Reports"
                        icon={
                            <FaThList className="icon" />
                        }
                    />
                </Grid>
                <ProtectedView
                    filterScopes={[
                        SCOPES.CREATE_METADATA_SEARCH_REPORT,
                    ]}
                    shouldHide
                >
                    <Grid
                        item 
                        xs={12} 
                        sm={5} 
                        md={5} 
                        lg={3} 
                        xl={3} 
                        marginTop={0.1}
                        display="flex"
                        justifyContent="flex-end"
                    >
                        <CustomizedIotVisionCreateButton type="button" onClick={handleCreateButtonClick}>
                            <CustomizedTypographyDiv>+ Create New Report</CustomizedTypographyDiv>
                        </CustomizedIotVisionCreateButton>
                    </Grid>
                </ProtectedView>
            </Grid>
            <PaperWrapper>
                <Grid container>
                    <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                        <IconButton className="expand-btn" onClick={() => handleClick(true)} disableRipple>
                            <CgArrowsExpandRight />
                        </IconButton>
                    </Grid>
                </Grid>
                <ReportListFilterPanel
                    searchParams={capturedSearchParams}
                    setSearchParams={handleSearchParams}
                    onClear={() => {
                        clearPagination();
                    }}
                />
                <IotVisionTable
                    sortingOrder={[sortOrder.DESC, sortOrder.ASC]}
                    loading={tableLoading}
                    columns={tableColumns}
                    rows={reportData}
                    sortingMode="server"
                    pageSize={pageSize}
                    page={page}
                    rowCount={rowsCount}
                    onPageChange={(newPage) => {
                        getReportDataWithPaginationDetails(newPage + 1, capturedSearchParams);
                        setPage(newPage);
                    }}
                    rowsPerPageOptions={[pageSize]}
                    hideFooterPagination={false}
                    componentsProps={{
                        pagination: isReportFetched && {
                            sx: {
                                "& button": {
                                    backgroundColor: "transparent",
                                    color: "rgba(0, 0, 0, 0.26)",
                                    pointerEvents: "none",
                                    cursor: "default",
                                },
                            },
                          },
                    }}
                />
            </PaperWrapper>
            <FullScreenDialog
                modalShow={modalShow}
                onClose={handleClick}
                childComponent={
                    <PaperWrapper>
                        <Grid container>
                            <Grid item xs={12} sm={7} md={7} lg={9} xl={9} marginBottom={2}>
                                <TableHeading heading="Plate Search Reports" />
                            </Grid>
                            <ProtectedView
                                filterScopes={[
                                    SCOPES.CREATE_METADATA_SEARCH_REPORT,
                                ]}
                                shouldHide
                            >
                                <Grid
                                    item 
                                    xs={12} 
                                    sm={5} 
                                    md={5} 
                                    lg={3} 
                                    xl={3} 
                                    marginTop={0.1}
                                    display="flex"
                                    justifyContent="flex-end"
                                >
                                    <CustomizedIotVisionCreateButton
                                        type="button"
                                        sx={{marginRight: 0}}
                                        onClick={handleCreateButtonClick}
                                    >
                                        <CustomizedTypographyDiv>+ Create New Report</CustomizedTypographyDiv>
                                    </CustomizedIotVisionCreateButton>
                                </Grid>
                            </ProtectedView>
                        </Grid>
                        <ReportListFilterPanel
                            searchParams={capturedSearchParams}
                            setSearchParams={handleSearchParams}
                            onClear={() => {
                                clearPagination();
                            }}
                        />
                        <IotVisionTable
                            sortingOrder={[sortOrder.DESC, sortOrder.ASC]}
                            loading={tableLoading}
                            columns={tableColumns}
                            rows={reportData}
                            sortingMode="server"
                            pageSize={pageSize}
                            page={page}
                            rowCount={rowsCount}
                            onPageChange={(newPage) => {
                                getReportDataWithPaginationDetails(newPage + 1, capturedSearchParams);
                                setPage(newPage);
                            }}
                            rowsPerPageOptions={[pageSize]}
                            hideFooterPagination={false}
                            componentsProps={{
                                pagination: isReportFetched && {
                                    sx: {
                                        "& button": {
                                            backgroundColor: "transparent",
                                            color: "rgba(0, 0, 0, 0.26)",
                                            pointerEvents: "none",
                                            cursor: "default",
                                        },
                                    },
                                  },
                            }}
                        />
                    </PaperWrapper>
                }
            />
        </>
    );
}

export default ReportListTable;
