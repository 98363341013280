import React, { useEffect, useState } from "react";
import SectionHeading from "components/headings/SectionHeading";
import { AiFillAppstore } from "react-icons/ai";
import StatisticsCards from "features/app-cycle-ways/statistics/StatisticsCards";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import { AppTitlePrefix } from "constants/app.constants";
import { useNavigate } from "react-router";
import HeaderDatePicker from "features/common/date-header/HeaderDatePicker";
import { findLocation } from "utils/search-tree";
import { navigateTo404Page } from "utils/navigation";
import NswLogo from "../../../assets/images/nsw-logo.png";
import { Location } from "../../../reducers/newLocation.reducer";
import LocationSummaryHeader from "./LocationSummaryCard";
import LocationChartLayout from "./LocationChartLayout";

export default function LocationPage() {
    const { locationId } = useParams();
    const { locationsTree, locations } = useSelector((state) => state.newLocation);
    const [selectedLocation, setSelectedLocation] = useState<Location>();
    const [selectedGroup, setSelectedGroup] = useState<Location>();

    const navigate = useNavigate();
    const { appCode } = useSelector((state) => state.auth);

    useEffect(() => {
        if (locationId) {
            const location = locations.find((i) => i.id === locationId);
            if (!location) {
                navigateTo404Page(navigate, appCode, false);
            }
            if (location) {
                setSelectedLocation(location);
            }
        }
    }, [locationId]);

    useEffect(() => {
        let parent!: Location | undefined;
        if (selectedLocation) {
            const { parentId } = selectedLocation;
            parent = findLocation(locationsTree, parentId);
            if (parent) {
                setSelectedGroup(parent);
            } else {
                navigate(`/${appCode}/page-not-found`, { replace: true });
            }
        }
    }, [selectedLocation]);

    return (
        <>
            <Helmet>
                <title>{AppTitlePrefix} | Location Summary</title>
                <link rel="icon" id="cw-location" type="image/png" href={NswLogo} sizes="32x32" />
            </Helmet>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <LocationSummaryHeader
                        name={selectedLocation?.name}
                        city={selectedLocation?.city}
                        latitude={selectedLocation?.latitude}
                        longitude={selectedLocation?.longitude}
                        component={0}
                        openHours={selectedLocation?.openHours}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={8} lg={8} xl={9}>
                    <SectionHeading heading="Location Summary" icon={<AiFillAppstore className="icon" />} />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                    <div style={{ maxWidth: "300px", marginLeft: "auto" }}>
                        <HeaderDatePicker />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <StatisticsCards group={selectedGroup} />
                </Grid>
                <Grid item xs={12}>
                    <LocationChartLayout />
                </Grid>
            </Grid>
        </>
    );
}
