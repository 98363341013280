import { setNotifier } from "context/notification";
import mime from "mime";

export const getFileName = (url: string): string => {
    const start = url?.lastIndexOf("/");
    const end = url?.indexOf("?");
    return url.slice(start + 1, end);
};

export const saveFile = async (
    url: string | (() => (Promise<string | undefined>)),
    suggestedName: string,
): Promise<void> => {
    const supportsFileSystemAccess =
        "showSaveFilePicker" in window &&
        (() => {
            try {
                return window.self === window.top;
            } catch {
                return false;
            }
        })();
    if (supportsFileSystemAccess) {
        const extension = suggestedName.split(".").pop() as string;
        const mimeType = mime.getType(extension) as `${string}/${string}`;
        try {
            const fileHandler = await window.showSaveFilePicker({
                suggestedName,
                ...(extension && mimeType
                    ? {
                          excludeAcceptAllOption: true,
                          types: [
                              {
                                  accept: {
                                      [mimeType]: `.${extension}`,
                                  },
                              },
                          ],
                      }
                    : {}),
            });
            const fileURL = typeof url === "function" ? await url() : url;
            const writable = await fileHandler.createWritable();
            if (fileURL) {
                (async () => {
                    const beforeunload = (event: BeforeUnloadEvent) => {
                        event.preventDefault();
                    };
                    setNotifier()({
                        type: "info",
                        message: "File saving is started in the background",
                        isAutoClose: true,
                    });
                    window.addEventListener("beforeunload", beforeunload);
                    try {
                        const res = await fetch(fileURL);
                        await res.body?.pipeTo(writable);
                        setNotifier()({
                            type: "success",
                            message: "File saving completed",
                            isAutoClose: true,
                        });
                    } catch {
                        setNotifier()({
                            type: "error",
                            message: "Error while saving file",
                            isAutoClose: true,
                        });
                    } finally {
                        window.removeEventListener("beforeunload", beforeunload);
                    }
                })();
            }
            return;
        } catch (err) {
            console.error(err);
            return;
        }
    }
    const fileURL = typeof url === "function" ? await url() : url;
    if (fileURL) {
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = suggestedName;
        link.click();
    }
};
