/* eslint-disable react/prop-types */
import { AutocompleteChangeReason, AutocompleteRenderInputParams, Grid } from "@mui/material";
import CommonDateRangePicker, { DatePickerValue, pickerRanges } from "components/common/CommonDateRangePicker";
import IotVisionButton from "components/common/IotVisionButton";
import IotVisionTextFiled from "components/common/IotVisionTextFiled";
import IotVisionAutoComplete from "components/common/IotVisonAutoComplete";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { URLSearchParamsInit, useSearchParams } from "react-router-dom";
import { CommonObjectLiteral } from "types/reports/interfaces";

interface DropdownValue {
    id: string;
    label: string;
}

interface AutoLabelId {
    label: string;
    id: string;
}

interface VideoFilterFormField {
    dateRange?: DatePickerValue;
    location?: AutoLabelId;
    incidentId?: string;
}

interface IncidentsFilterPanelProps {}

const IncidentsFilterPanel: React.FC<IncidentsFilterPanelProps> = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { leafLocations } = useSelector((store) => store.newLocation);
    const [locationOptions, setLocationOptions] = useState<DropdownValue[]>([]);
    const [pickerValue, setPickerValue] = useState<DatePickerValue>({
        fromTime: "",
        toTime: "",
        range: "",
    });

    const { fromTime, toTime, range, incidentId, locationId } = Object.fromEntries([...searchParams]) || {};

    const {
        handleSubmit,
        setValue,
        control,
        reset,
        trigger,
        formState: { isDirty, isValid },
    } = useForm<VideoFilterFormField>({
        defaultValues: {
            incidentId: "",
            dateRange: pickerValue,
            location: { label: "", id: "" },
        },
    });

    useEffect(() => {
        const modifiedLocations = leafLocations.map(location => ({ id: location.id, label: location.name }));
        setLocationOptions(modifiedLocations);
    }, [leafLocations]);

    useEffect(() => {
        reset({
            location: locationId
                ? {
                      label: findLabel(locationOptions, locationId),
                      id: locationId,
                  }
                : { label: "", id: "" },
            incidentId: incidentId || "",
            dateRange: {
                fromTime: fromTime || "",
                toTime: toTime || "",
                range: range || "",
            },
        });
        setPickerValue({ fromTime: fromTime || "", toTime: toTime || "", range: range || "" });
    }, [searchParams, leafLocations]);

    const handleClear = (): void => {
        reset({
            location: { label: "", id: "" },
            incidentId: "",
            dateRange: {
                fromTime: "",
                toTime: "",
                range: "",
            },
        });
        setPickerValue({
            fromTime: "",
            toTime: "",
            range: "",
        });
        setSearchParams({
            page: "1",
        });
    };
    
    const findLabel = (options: DropdownValue[], id: string): string => {
        const label = options?.find((opt: AutoLabelId) => opt.id === id)?.label;
        if (label) return label;
        return "";
    };

    const onSubmit: SubmitHandler<VideoFilterFormField> = (data) => {
        const searchParamData: CommonObjectLiteral = {
            page: "1",
            ...(data?.dateRange?.fromTime ? { fromTime: data?.dateRange?.fromTime } : {}),
            ...(data?.dateRange?.toTime ? { toTime: data?.dateRange?.toTime } : {}),
            ...(data?.dateRange?.range ? { range: data?.dateRange?.range } : {}),
            ...(data?.incidentId ? { incidentId: data?.incidentId } : {}),
            ...(data?.location?.id ? { locationId: data?.location?.id } : {}),
        };
        setSearchParams(searchParamData || {} as URLSearchParamsInit);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                    <Controller
                        name="location"
                        control={control}
                        render={({ field: { value } }) => (
                            <IotVisionAutoComplete
                                options={locationOptions}
                                value={value}
                                onChange={(
                                    event: React.SyntheticEvent,
                                    value: AutoLabelId,
                                    reason: AutocompleteChangeReason,
                                ) => {
                                    if (reason === "clear") {
                                        setValue(
                                            "location",
                                            {
                                                label: "",
                                                id: "",
                                            },
                                            { shouldDirty: true },
                                        );
                                    } else {
                                        setValue(
                                            "location",
                                            {
                                                label: value?.label,
                                                id: value?.id,
                                            },
                                            { shouldDirty: true },
                                        );
                                    }
                                }}
                                renderInput={(params: AutocompleteRenderInputParams) => (
                                    <IotVisionTextFiled {...params} fullWidth placeholder="Location" />
                                )}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                    <Controller
                        name="dateRange"
                        control={control}
                        render={() => (
                            <CommonDateRangePicker
                                value={pickerValue}
                                onPickerMenuClose={() => {
                                    setPickerValue({
                                        ...pickerValue,
                                    });
                                }}
                                onDatePickerApply={(startTime, endTime, range) => {
                                    setPickerValue({
                                        fromTime: startTime,
                                        toTime: endTime,
                                        range,
                                    });
                                    setValue("dateRange.fromTime", startTime, {
                                        shouldDirty: true,
                                    });
                                    setValue("dateRange.toTime", endTime);
                                    setValue("dateRange.range", range);
                                }}
                                onDatePickerClear={() => {
                                    setValue("dateRange.fromTime", "", {
                                        shouldDirty: true,
                                    });
                                    setValue("dateRange.toTime", "");
                                    setPickerValue({
                                        fromTime: "",
                                        toTime: "",
                                        range: pickerRanges.Custom,
                                    });
                                }}
                                buttonText="Clear"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                    <Controller
                        name="incidentId"
                        control={control}
                        render={({ field: { value }, fieldState: { invalid, error } }) => {
                            return (
                                <IotVisionTextFiled
                                    fullWidth
                                    placeholder="Incident ID"
                                    error={invalid}
                                    label={error?.message}
                                    value={value}
                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                        setValue("incidentId", event.target.value, { shouldDirty: true });
                                        if (invalid) trigger("incidentId");
                                    }}
                                />
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                    <Grid container spacing={1}>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <IotVisionButton
                                type="submit"
                                fullWidth
                                disabled={!isDirty || !isValid}
                                sx={{
                                    height: "37px",
                                    color: "white !important",
                                }}
                            >
                                Apply
                            </IotVisionButton>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <IotVisionButton
                                fullWidth
                                sx={{
                                    height: "37px",
                                    backgroundColor: "white",
                                    color: "#6B439D !important",
                                    "&:hover": {
                                        background: "white",
                                    },
                                }}
                                onClick={handleClear}
                            >
                                Reset
                            </IotVisionButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default IncidentsFilterPanel;
