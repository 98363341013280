/* eslint-disable react/no-unused-prop-types */
import {
    Box,
    CircularProgress,
    CircularProgressProps,
    IconButton,
    IconButtonProps,
    styled,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import {
    GridColumns,
    gridClasses,
    GridRenderCellParams,
    GridRowClassNameParams,
    GridRowParams,
    GridColumnHeaderTitle,
    DataGrid,
    GridColDef,
    GridSortModel,
} from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useLocation, useSearchParams, useParams } from "react-router-dom";
import { pagePath } from "pages/app-oversized-vehicle";
import { executeGetIncidentData } from "api/app-air-quality/air-incidents.api";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import { setPageIndex, setPageSize } from "reducers/aq-incident.reducer";
import { capitalCase } from "capital-case";
import { OpenInNew } from "@mui/icons-material";

const INCIDENT_PAGE_PAGESIZE = 10;
const FIRST_PAGE = 1;

const StyledDataTable = styled(DataGrid)(({ theme }) => ({
    width: "100%",
    [`& .${gridClasses.cell}`]: {
        color: "#001B4F !important",
        borderBottom: "0px",
    },
    [theme.breakpoints.up("xl")]: {
        [`& .${gridClasses.cell}`]: {
            color: "#001B4F !important",
            marginRight: "12px",
            borderBottom: "0px",
        },
        [`& .${gridClasses.columnHeader}`]: {
            marginTop: "-6px",
            marginRight: "12px",
        },
    },
    [`& .${gridClasses.columnHeaders}`]: {
        borderBottom: "0px",
    },
    [`& .${gridClasses.footerContainer}`]: {
        borderTop: "0px",
    },
    [`& .${gridClasses.columnHeaderTitle}`]: {
        color: "#001B4F !important",
    },
    "& .MuiDataGrid-row:nth-child(odd)": {
        backgroundColor: "#F5F8FD",
    },
    "& .disableRow": {
        opacity: 0.4,
    },
}));

const StyledSortIcon = styled((iconButtonProps: IconButtonProps) => (
    <IconButton
        size="small"
        {...iconButtonProps}
        // eslint-disable-next-line react/no-children-prop
        children={
            <KeyboardArrowDownIcon
                sx={{
                    color: "#001B4F",
                }}
            />
        }
    />
))(() => ({}));

const StyledLoadingSpinner = styled((circularProgressProps: CircularProgressProps) => (
    <Box
        sx={{
            zIndex: 1000,
            position: "absolute",
            left: "50%",
            top: "50%",
        }}
    >
        <CircularProgress {...circularProgressProps} />
    </Box>
))(() => ({}));

interface IncidentTableProps {
    locationId?: string;
}

interface fetchIncidentOptions {
    pageIndex: number;
    pageSize: number;
    idInc?: string;
    locationId?: string;
    status?: string;
    fromTime?: string;
    toTime?: string;
    incident?: string;
    type?: string;
    sortColum?: string;
    sortOrder?: string;
}

const IncidentTable: React.FC<IncidentTableProps> = () => {
    const theme = useTheme();
    const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
    const { pathname } = useLocation();
    const [loading, setLoading] = React.useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [rows, setRows] = useState<any[]>([]);
    const [total, setTotal] = useState<number>(0);
    const { locationId } = useParams();
    const [searchParams] = useSearchParams();
    const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
    const { pageIndex, pageSize } = useSelector((state) => state.aqIncident);
    const [isAlertPage, setIsAlertPage] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const { incidentId, status, fromTime, toTime, range } = Object.fromEntries([...searchParams]);
    const locId = searchParams.get("locationId");
    const incidentsAbortControllerRef = useRef<AbortController | null>(null);

    function openSnapshot(incidentId: string) {
        try {
            const url = `/image/snapshot?incidentId=${incidentId}`;
            if (url) {
                window.open(url, "_blank", "noopener, noreferrer");
            }
        } catch (err) {
            // TODO: Display error
            console.error(err);
        }
    }

    const columns: GridColumns = [
        {
            field: "incident",
            headerName: "Detection ID",
            align: "left",
            hideSortIcons: true,
            sortable: true,
            flex: 1,
            disableColumnMenu: true,
            renderHeader: (params) => {
                const { colDef } = params;
                return (
                    <>
                        <GridColumnHeaderTitle
                            label={colDef.headerName || colDef.field}
                            description={colDef.description}
                            columnWidth={colDef.width || isXlScreen ? 250 : 150}
                        />

                        <div className="MuiDataGrid-iconButtonContainer">
                            <StyledSortIcon />
                        </div>
                    </>
                );
            },
        },
        {
            field: "type",
            headerName: "Type",
            align: "left",
            hideSortIcons: true,
            renderHeader: (params) => {
                const { colDef } = params;
                return (
                    <>
                        <GridColumnHeaderTitle
                            label={colDef.headerName || colDef.field}
                            description={colDef.description}
                            columnWidth={colDef.width || isXlScreen ? 250 : 150}
                        />

                        <div className="MuiDataGrid-iconButtonContainer">
                            <StyledSortIcon />
                        </div>
                    </>
                );
            },
            flex: 2,
            disableColumnMenu: true,
        },
        {
            field: "time",
            align: "left",
            hideSortIcons: true,
            renderHeader: (params) => {
                const { colDef } = params;
                return (
                    <>
                        <GridColumnHeaderTitle
                            label={colDef.headerName || colDef.field}
                            description={colDef.description}
                            columnWidth={colDef.width || isXlScreen ? 250 : 160}
                        />

                        <div className="MuiDataGrid-iconButtonContainer">
                            <StyledSortIcon />
                        </div>
                    </>
                );
            },
            headerName: "Date and Time",
            disableColumnMenu: true,
            flex: 2,
            renderCell: (params: GridRenderCellParams) => <>{new Date(params.row.time).toLocaleString()}</>,
        },
        {
            field: "status",
            align: "left",
            headerName: "Status",
            flex: 1,
            hideSortIcons: true,
            renderHeader: (params) => {
                const { colDef } = params;
                return (
                    <>
                        <GridColumnHeaderTitle
                            label={colDef.headerName || colDef.field}
                            description={colDef.description}
                            columnWidth={colDef.width || 120}
                        />

                        <div className="MuiDataGrid-iconButtonContainer">
                            <StyledSortIcon />
                        </div>
                    </>
                );
            },
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => <>{params.row.status?.name}</>,
        },
        {
            field: "snapshot",
            align: "left",
            headerName: "Snapshot",
            flex: 1,
            hideSortIcons: true,
            renderHeader: (params) => {
                const { colDef } = params;
                return (
                    <>
                        <GridColumnHeaderTitle
                            label={colDef.headerName || colDef.field}
                            description={colDef.description}
                            columnWidth={colDef.width || 120}
                        />

                        <div className="MuiDataGrid-iconButtonContainer">
                            <StyledSortIcon />
                        </div>
                    </>
                );
            },
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => (
                <IconButton
                    color="primary"
                    aria-label="open snapshot"
                    component="label"
                    onClick={() => openSnapshot(params.row.id)}
                >
                    <OpenInNew fontSize="small" />
                </IconButton>
            ),
        },
    ];
    const ClassRoomField: GridColDef = {
        field: "room",
        headerName: "Location",
        align: "left",
        hideSortIcons: true,
        renderHeader: (params) => {
            const { colDef } = params;
            return (
                <>
                    <GridColumnHeaderTitle
                        label={colDef.headerName || colDef.field}
                        description={colDef.description}
                        columnWidth={colDef.width || isXlScreen ? 250 : 150}
                    />

                    <div className="MuiDataGrid-iconButtonContainer">
                        <StyledSortIcon />
                    </div>
                </>
            );
        },
        flex: 2,
        disableColumnMenu: true,
    };

    const fetchData = async (pageIndex: number, pageSize: number) => {
        try {
            incidentsAbortControllerRef.current = new AbortController();
            const { signal } = incidentsAbortControllerRef.current;

            let options: fetchIncidentOptions = {
                pageIndex,
                pageSize,
            };
            if (locationId) options.locationId = locationId;
            options = addFilters(options);
            options = addSortQueryParams(options);
            const { incident, total } = await executeGetIncidentData(options, { signal });

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const rows = incident.map((i: any) => {
                return {
                    id: i.id,
                    incident: i.idInc,
                    type: capitalCase(i.type),
                    room: i.location.name,
                    time: i.dateTime,
                    status: i.status,
                };
            });
            setTotal(total);
            setRows(rows);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };
    const addFilters = (options: fetchIncidentOptions): fetchIncidentOptions => {
        const filterData = Object.fromEntries([...searchParams]);
        if (filterData.locationId) {
            options.locationId = filterData.locationId;
        }
        if (filterData.incidentId) {
            options.idInc = filterData.incidentId;
        }
        if (filterData.fromTime) {
            options.fromTime = filterData.fromTime;
        }
        if (filterData.toTime) {
            options.toTime = filterData.toTime;
        }
        return options;
    };

    const addSortQueryParams = (options: fetchIncidentOptions): fetchIncidentOptions => {
        if (sortModel.length > 0) {
            let fieldName: string;
            switch (sortModel[0].field) {
                case "incident":
                    fieldName = "idInc";
                    break;
                case "time":
                    fieldName = "dateTime";
                    break;
                case "room":
                    fieldName = "locationId";
                    break;
                default:
                    fieldName = sortModel[0].field;
            }
            options.sortColum = fieldName;
            options.sortOrder = sortModel[0].sort ?? "";
        }

        return options;
    };

    const onPageChange = (newPage: number) => {
        setLoading(true);
        fetchData(newPage + 1, pageSize);
        dispatch(setPageIndex(newPage + 1));
    };

    const handleSortModelChange = (newModel: GridSortModel) => {
        setSortModel(newModel);
    };

    useEffect(() => {
        setIsAlertPage(pathname.includes(pagePath.Detection));
    }, [pathname]);

    useEffect(() => {
        setLoading(true);
        // eslint-disable-next-line no-unused-expressions
        sortModel.length > 0 && pageIndex !== FIRST_PAGE
            ? dispatch(setPageIndex(FIRST_PAGE))
            : dispatch(setPageSize(INCIDENT_PAGE_PAGESIZE));

        fetchData(pageIndex, INCIDENT_PAGE_PAGESIZE);
        return () => {
            incidentsAbortControllerRef.current?.abort();
        };
    }, [locationId, sortModel, incidentId, status, fromTime, toTime, range, locId]);

    if (isAlertPage) columns.splice(1, 0, ClassRoomField);
    return (
        <StyledDataTable
            autoHeight
            loading={loading}
            isRowSelectable={(params: GridRowParams) => params.row.status.name !== "Closed"}
            getRowClassName={(params: GridRowClassNameParams) =>
                params.row.status.name === "Closed" ? "disableRow" : ""
            }
            paginationMode="server"
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            columns={columns}
            rows={rows}
            pageSize={INCIDENT_PAGE_PAGESIZE}
            page={pageIndex ? pageIndex - 1 : 1}
            rowCount={total}
            onPageChange={onPageChange}
            hideFooterPagination={false}
            disableSelectionOnClick
            components={{ LoadingOverlay: StyledLoadingSpinner }}
        />
    );
};

export default IncidentTable;
