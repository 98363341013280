import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useHasScope = (scope: string): boolean => {
    const [hasScope, setHasScope] = useState<boolean>(false);
    const { scopes } = useSelector((state) => state.auth);
    useEffect(() => {
        const matchedScope = scopes.filter((scp) => {
            const splitScope = scp.split(":");
            splitScope.pop();
            const joinedScope = splitScope.join(":");
            return joinedScope === scope;
        });
        if (matchedScope?.length) setHasScope(true);
    }, [scopes]);
    return hasScope;
};

export default useHasScope;
