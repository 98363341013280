import ProtectedLayout from "layouts/common/general/ProtectedLayout";
import NotFound from "pages/common/errors/not-found/NotFound";
import PlateSearchReportLayout from "layouts/app-taxi-ranking/plate-search-reports";
import WithScopes from "components/common/WithScopes";
import { SCOPES } from "enums/scopes.enum";
import ReportsDataListTable from "./plate-search-reports/reports-data-list-table";
import CreateReport from "./reports/CreateReport";
import ReportListTable from "./plate-search-reports/report-list-table";

const root = {
    path: "plate-search-reports",
    element: (
        <ProtectedLayout>
            <PlateSearchReportLayout />
        </ProtectedLayout>
    ),
    children: [
        {
            path: "",
            element: (
                <WithScopes
                    filterScopes={[
                        SCOPES.READ_METADATA_SEARCH_REPORTS,
                    ]}
                >
                    <ReportListTable />
                </WithScopes>
            ),
        },
        {
            path: ":reportId",
            element: (
                <WithScopes
                    filterScopes={[
                        SCOPES.READ_METADATA_SEARCH_REPORTS,
                    ]}
                >
                    <ReportsDataListTable />
                </WithScopes>
            ),
        },
        {
            path: "create",
            element: (
                <WithScopes filterScopes={[SCOPES.CREATE_METADATA_SEARCH_REPORT]}>
                    <CreateReport />
                </WithScopes>
            ),
        },
        {
            path: "page-not-found",
            element: <NotFound />,
        },
    ],
};
export default root;
