export const ASC_ORDER = "asc";
export const DESC_ORDER = "desc";

export const MAP_REGION_ZOOM_LEVEL = 7;
export const MAP_ZONE_ZOOM_LEVEL = 10;

export const DITTO_MARK = "\u3003";
export const NOT_APPLICABLE_SHORT = "N/A";

export const ALL_ENTITY_ACCESS = "*";

// error codes starting points
export const SUCCESS_CODES_STARTING_POINT = 200;
export const REDIRECTING_CODES_STARTING_POINT = 300;
export const CLIENT_ERROR_CODES_STARTING_POINT = 400;
export const SERVER_ERROR_CODES_STARTING_POINT = 500;

// errors
export const ERROR_YOU_DO_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION =
    "You don't have permission to perform this action";
export const ERROR_INTERNAL_SERVER_ERROR = "Internal Server Error";
export const ERROR_TOOLTIP_NO_CAMERA_DEVICE = "No camera device";
