import React, { useState, useEffect } from "react";
import { Grid, styled } from "@mui/material";
import CommonDateRangePicker, { DatePickerValue, pickerRanges } from "components/common/CommonDateRangePicker";
import IotVisionTextFiled from "components/common/IotVisionTextFiled";
import IotVisionButton from "components/common/IotVisionButton";
import { URLSearchParamsInit } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { CommonObjectLiteral } from "types/reports/interfaces";
import { PLACEHOLDER_IN_TIME } from "constants/report.constants";

const CustomizedIotVisionSearchButton = styled(IotVisionButton)(() => ({
    color: "white !important",
    height: "37px",
}));

const CustomizedIotVisionClearButton = styled(IotVisionButton)(({theme}) => ({
    height: "37px",
    backgroundColor: "white",
    color: theme.palette.primary.main,
    "&:hover": {
        background: "white",
    },
}));

interface IReportDataFilter {
    dateTime?: DatePickerValue;
    licensePlate?: string;
}
export interface ISearchParams {
    metaField1: string;
    fromTime: string;
    toTime: string;
}
interface IReportDataFilterPanelProps {
    onClear: () => void;
    setSearchParams: (params: CommonObjectLiteral) => void;
    searchParams: CommonObjectLiteral;
}

const ReportDataFilterPanel: React.FC<IReportDataFilterPanelProps> = ({ onClear, searchParams, setSearchParams }) => {
    const { fromTime, toTime, metaField1 } = searchParams || {};
    const [pickerValue, setPickerValue] = useState<DatePickerValue>({
        fromTime: "",
        toTime: "",
        range: PLACEHOLDER_IN_TIME,
    });

    const {
        handleSubmit,
        setValue,
        control,
        trigger,
        reset,
        formState: { isValid },
    } = useForm<IReportDataFilter>({
        defaultValues: {
            licensePlate: "",
            dateTime: pickerValue,
        },
    });

    useEffect(() => {
        const rangeValue = (fromTime && toTime) ? pickerRanges.Custom : PLACEHOLDER_IN_TIME;
        reset({
            licensePlate: metaField1 || "",
            dateTime: {
                fromTime: fromTime || "",
                toTime: toTime || "",
                range: rangeValue,
            },
        });
        setPickerValue({
            fromTime: fromTime || "",
            toTime: toTime || "",
            range: rangeValue,
        });
    }, [searchParams]);

    const handleClear = (): void => {
        onClear();
        reset({
            licensePlate: "",
            dateTime: {
                fromTime: "",
                toTime: "",
                range: PLACEHOLDER_IN_TIME,
            },
        });
        setPickerValue({
            fromTime: "",
            toTime: "",
            range: PLACEHOLDER_IN_TIME,
        });
        setSearchParams({});
    };

    const onSubmit: SubmitHandler<IReportDataFilter> = (data: IReportDataFilter): void => {
        const searchParamData: URLSearchParamsInit | undefined = {
            ...(data?.dateTime?.fromTime ? { fromTime: data?.dateTime?.fromTime } : {}),
            ...(data?.dateTime?.toTime ? { toTime: data?.dateTime?.toTime } : {}),
            ...(data?.licensePlate ? { metaField1: data?.licensePlate } : {}),
        };
        setSearchParams(searchParamData);
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1} marginTop={{xs: "15px"}}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                        name="licensePlate"
                        control={control}
                        render={({ field: { value }, fieldState: { invalid, error } }) => {
                            return (
                                <IotVisionTextFiled
                                    fullWidth
                                    placeholder="License Plate"
                                    error={invalid}
                                    label={error?.message}
                                    value={value}
                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                        setValue("licensePlate", event.target.value);
                                        if (invalid) trigger("licensePlate");
                                    }}
                                />
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                        name="dateTime"
                        control={control}
                        render={() => (
                            <CommonDateRangePicker
                                value={pickerValue}
                                shouldDisplayPredefinedValues={false}
                                customPlaceHolder={PLACEHOLDER_IN_TIME}
                                onPickerMenuClose={() => {
                                    setPickerValue({
                                        ...pickerValue,
                                    });
                                }}
                                onDatePickerApply={(start, end) => {
                                    setPickerValue({
                                        fromTime: start,
                                        toTime: end,
                                        range: pickerRanges.Custom,
                                    });
                                    setValue("dateTime.fromTime", start);
                                    setValue("dateTime.toTime", end);
                                    setValue("dateTime.range", pickerRanges.Custom);
                                }}
                                onDatePickerClear={() => {
                                    setValue("dateTime.fromTime", "");
                                    setValue("dateTime.toTime", "");
                                    setValue("dateTime.range", PLACEHOLDER_IN_TIME);
                                    setPickerValue({
                                        fromTime: "",
                                        toTime: "",
                                        range: PLACEHOLDER_IN_TIME,
                                    });
                                }}
                                buttonText="Clear"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Grid container spacing={1}>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <CustomizedIotVisionSearchButton
                                type="submit"
                                disabled={!isValid}
                                fullWidth
                            >
                                Search
                            </CustomizedIotVisionSearchButton>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <CustomizedIotVisionClearButton
                                fullWidth
                                onClick={handleClear}
                            >
                                Clear
                            </CustomizedIotVisionClearButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default ReportDataFilterPanel;
