import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import { eachHourOfInterval } from "date-fns";
import { CgArrowsExpandRight } from "react-icons/cg";
import { AiFillPrinter } from "react-icons/ai";
import FullScreenDialog from "components/dialogs/FullScreenDialog";
import DownloadChart from "components/download/chart-download";
import { generateAverageLines } from "utils/historical-chart";
import { AnnotationOptions } from "chartjs-plugin-annotation";
import { FilterTypes } from "types/chart-configs/enums/common";
import HistoricalAnalysisChart, { ChartData } from "./chart/HistoricalAnalysisChart";
import { CardHeader } from "../common";
import HistoricalAnalysisFilterHeader, { HistoricalAnalysisField } from "./filter/HistoricalAnalysisFilter";

interface HistoricalAnalysisProps {
    chartData: ChartData[];
    windowGap?: number;
    initialFilterValues: HistoricalAnalysisField;
    isChartLegendDisplay?: boolean;
    onApply: (value: HistoricalAnalysisField) => void;
    onClear: (value: HistoricalAnalysisField) => void;
    defaultFilterValues: HistoricalAnalysisField;
}
export interface Time {
    fromTime: string;
    toTime: string;
}

export interface HistoricalData {
    time: Date;
    avg: number;
    max: number;
    min: number;
}
export interface HistoricalDataLocation {
    id: string;
    name: string;
}

export interface LocationHistoricalData {
    data: HistoricalData[];
    location: HistoricalDataLocation;
}

// temporary demo functions
export const getDemoChartData = (limit: number, time: Time) => {
    const finalArr = [];
    const numArr = [];
    while (numArr.length < limit) {
        const r = Math.floor(Math.random() * 10) + 1;
        if (numArr.indexOf(r) === -1) numArr.push(r);
    }

    const dateArr = eachHourOfInterval(
        {
            start: new Date(time?.fromTime),
            end: new Date(time?.toTime),
        },
        { step: 3 },
    );

    for (let i = 0; i < limit; i++) {
        finalArr.push({
            x: dateArr[i],
            y: numArr[i],
        });
    }
    return finalArr;
};

const HistoricalAnalysis: React.FC<HistoricalAnalysisProps> = ({
    chartData,
    windowGap,
    defaultFilterValues,
    initialFilterValues,
    isChartLegendDisplay,
    onApply,
    onClear,
}): JSX.Element => {
    const [modalShow, setModalShow] = React.useState(false);
    const [printModalShow, setPrintModalShow] = React.useState(false);
    const [filterChanged, setFilterChanged] = React.useState(false);
    const [averageLines, setAverageLines] = useState<AnnotationOptions[]>([]);

    const handleClick = (status: boolean): void => {
        setModalShow(status);
    };

    useEffect(() => {
        setAverageLines([]);
        setFilterChanged(true);
    }, [chartData]);

    useEffect(() => {
        if (!averageLines.length && filterChanged) {
            setAverageLines(generateAverageLines(chartData));
            setFilterChanged(false);
        }
    }, [averageLines]);

    const handlePrintModal = (status: boolean) => {
        setPrintModalShow(status);
    };
    return (
        <Grid container>
            <Box
                sx={() => ({
                    padding: "20px",
                    borderRadius: "20px",
                    width: "100%",
                    height: "auto",
                    background: "#FFFFFF",
                    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.03)",
                })}
                className="paper-wrapper"
            >
                <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                    <IconButton className="expand-btn" onClick={() => handleClick(true)} disableRipple>
                        <CgArrowsExpandRight />
                    </IconButton>
                </Grid>
                <Grid
                    sx={{
                        paddingTop: "15px",
                        paddingBottom: "9px",
                        display: "flex",
                        gap: "10px",
                    }}
                    item
                    xs={12}
                    md="auto"
                >
                    <CardHeader>Historical Analysis</CardHeader>
                    <Tooltip placement="bottom" title="Print view" arrow>
                        <IconButton className="download-btn" onClick={() => handlePrintModal(true)} disableRipple>
                            <AiFillPrinter />
                        </IconButton>
                    </Tooltip>
                </Grid>

                <Grid
                    sx={{
                        paddingBottom: "24px",
                    }}
                    xs={12}
                    md="auto"
                >
                    <HistoricalAnalysisFilterHeader
                        defaultFilterValues={defaultFilterValues}
                        initialValues={initialFilterValues}
                        onApply={(value) => {
                            setAverageLines([]);
                            onApply(value);
                        }}
                        onClear={(value) => {
                            setAverageLines([]);
                            onClear(value);
                        }}
                    />
                </Grid>
                <Grid xs={12} md="auto" sx={{ height: "85%" }}>
                    <HistoricalAnalysisChart
                        isChartLegendDisplay={isChartLegendDisplay}
                        chartDataSet={chartData}
                        time={initialFilterValues?.time}
                        windowGap={windowGap}
                        averageLines={averageLines}
                    />
                </Grid>
            </Box>
            <FullScreenDialog
                modalShow={modalShow}
                onClose={handleClick}
                childComponent={
                    <div style={{ height: "100%" }}>
                        <HistoricalAnalysisChart
                            isChartLegendDisplay={isChartLegendDisplay}
                            chartDataSet={chartData}
                            time={initialFilterValues?.time}
                            windowGap={windowGap}
                            legendComponentId="fullViewLegendComponentId"
                            averageLines={averageLines}
                        />
                    </div>
                }
            />
            <DownloadChart
                chartId="historical-chart-id"
                logoId="tenant-logo-id"
                modalShow={printModalShow}
                closeModal={handlePrintModal}
                exportName="Historical_chart"
                chartHeader="Historical Analysis Chart"
                filters={{
                    from: {
                        type: FilterTypes.DATE,
                        value: initialFilterValues.time.fromTime,
                    },
                    to: {
                        type: FilterTypes.DATE,
                        value: initialFilterValues.time.toTime,
                    },
                    type: { type: FilterTypes.TEXT, value: initialFilterValues.type },
                }}
                chartComponent={
                    <HistoricalAnalysisChart
                        isChartLegendDisplay={isChartLegendDisplay}
                        chartDataSet={chartData}
                        time={initialFilterValues?.time}
                        windowGap={windowGap}
                        legendComponentId="fullViewLegendComponentId"
                        enableTitleY
                        averageLines={averageLines}
                    />
                }
            />
        </Grid>
    );
};

export default HistoricalAnalysis;
