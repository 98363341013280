/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import PaperWrapper from "components/wrappers/PaperWrapper";
import { CgArrowsExpandRight } from "react-icons/cg";
import FullScreenDialog from "components/dialogs/FullScreenDialog";
import { useSelector } from "react-redux";
import { VehiclesOvertimeResponse } from "pages/app-park-and-ride/location/LocationChartLayout";
import TimeSeriesChart from "../../../components/charts/TimeSeriesChart";

type DetectedVehiclesChartProps = {
    data: VehiclesOvertimeResponse[];
    title: string;
    config: IConfig;
    icon?: any;
    field1?: string;
    field2?: string;
};
interface IConfig {
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
    label: string;
    maintainAspectRatio: boolean;
}
interface IChartData {
    x: Date;
    y: number;
}
export default function DetectedVehiclesChart({ data, title, config, icon }: DetectedVehiclesChartProps) {
    const [processedData, setProcessedData] = useState<IChartData[]>([]);

    const [modalShow, setModalShow] = React.useState(false);
    const { startTime, endTime, timeType } = useSelector((state) => state.time);

    const options = {
        maintainAspectRatio: config?.maintainAspectRatio,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                type: "time",
                min: new Date(startTime).valueOf(),
                max: new Date(endTime).valueOf(),
                time: {
                    unit: timeType,
                    round: timeType,
                },
            },
        },
    };

    useEffect(() => {
        data.reduce((acc = [] as VehiclesOvertimeResponse[], itm: VehiclesOvertimeResponse) => {
            const matchIndex = acc.findIndex((el: any) => el.inTime === itm.inTime);
            if (matchIndex === -1) {
                acc.push(itm);
                return acc;
            }
            acc[matchIndex].count = String(Number(itm.count) + Number(acc[matchIndex].count));
            return acc;
        }, [] as VehiclesOvertimeResponse[]);
        const valueList = data.map((l: VehiclesOvertimeResponse): IChartData => {
            return {
                x: new Date(l.inTime),
                y: Number(l.count),
            };
        });
        setProcessedData(valueList);
    }, [data]);

    const handleClick = (status: boolean): void => {
        setModalShow(status);
    };

    return (
        <>
            <PaperWrapper>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={1} gap={1} marginBottom={1}>
                        <Grid item className="chart-padding-0">
                            {icon}
                        </Grid>
                        <Grid item className="chart-padding-0">
                            <span className="chartHearder">{title}</span>
                        </Grid>
                        <Grid item className="chart-padding-0">
                            <IconButton className="expand-btn" onClick={() => handleClick(true)} disableRipple>
                                <CgArrowsExpandRight />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <div>
                        <TimeSeriesChart data={processedData} {...config} options={options} />
                    </div>
                </Grid>
            </PaperWrapper>
            <FullScreenDialog
                modalShow={modalShow}
                onClose={handleClick}
                childComponent={
                    <div>
                        <TimeSeriesChart data={processedData} {...config} options={options} />
                    </div>
                }
            />
        </>
    );
}
