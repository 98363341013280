/* eslint-disable array-callback-return */
import { TreeItemContentProps, useTreeItem } from "@mui/lab";
import { Typography } from "@mui/material";
import React from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "store";
import { setInitialMapFocus, setMapFocus } from "reducers/map.reducer";
import { MAP_REGION_ZOOM_LEVEL, MAP_ZONE_ZOOM_LEVEL } from "constants/common.constants";
import { Location, Coordinate } from "reducers/newLocation.reducer";
import { findLocation } from "utils/search-tree";

const CustomTreeContent = React.forwardRef(function CustomContent(props: TreeItemContentProps, ref) {
    const { classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon } = props;

    const { disabled, expanded, selected, handleExpansion, handleSelection } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    const { locationsTree } = useSelector((state) => state.newLocation);
    const { rootPath } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const handleSelectionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        handleSelection(event);
        handleExpansion(event);
        navigateOnNodeSelect();
    };

    const navigateOnNodeSelect = () => {
        if (nodeId === "root") {
            navigate(rootPath);
            dispatch(setInitialMapFocus());
        } else {
            const node = findLocation(locationsTree, nodeId);

            if (node?.type === "location") {
                navigate(`${rootPath}/location/${node?.id}`);
            } else {
                navigate(`${rootPath}/${node?.id}`);
                dispatch(setMapFocus(getMapFocusZoomCoordinateBaseGroupType(node)));
            }
        }
    };

    function getMapFocusZoomCoordinateBaseGroupType(group?: Location | null) {
        const coordinates: Coordinate[] = [];
        let zoom;
        if (group?.type === "zone") {
            group.children?.map((child: Location) => {
                const coordinate: Coordinate = {
                    lat: parseFloat(child.latitude),
                    lng: parseFloat(child.longitude),
                };
                if (coordinate.lat && coordinate.lng) {
                    coordinates.push(coordinate);
                }
            });
            zoom = MAP_ZONE_ZOOM_LEVEL;
        } else {
            group?.children?.map((parent: Location) => {
                parent.children?.map((child: Location) => {
                    const coordinate: Coordinate = {
                        lat: parseFloat(child.latitude),
                        lng: parseFloat(child.longitude),
                    };
                    if (coordinate.lat && coordinate.lng) {
                        coordinates.push(coordinate);
                    }
                });
            });

            zoom = MAP_REGION_ZOOM_LEVEL;
        }
        return { center: findCenterMapFocus(coordinates), zoom };
    }

    function findCenterMapFocus(markers: Coordinate[]) {
        let lat = 0;
        let lng = 0;

        for (let i = 0; i < markers.length; i++) {
            lat += markers[i].lat;
            lng += markers[i].lng;
        }
        lat /= markers.length;
        lng /= markers.length;

        return { lat, lng };
    }

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
        <div
            className={clsx(className, classes.root, {
                [classes.expanded]: expanded,
                [classes.selected]: selected,
                [classes.focused]: false,
                [classes.disabled]: disabled,
            })}
            onClick={handleSelectionClick}
            ref={ref as React.Ref<HTMLDivElement>}
        >
            <div className={classes.iconContainer}>{icon}</div>
            <Typography component="div" className={classes.label}>
                {label}
            </Typography>
        </div>
    );
});

export default CustomTreeContent;
