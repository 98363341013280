import React, { memo, useEffect, useState } from "react";
import { Box, Grid, IconButton } from "@mui/material";
import PaperWrapper from "components/wrappers/PaperWrapper";
import { CgArrowsExpandRight } from "react-icons/cg";
import FullScreenDialog from "components/dialogs/FullScreenDialog";
import { useSelector } from "react-redux";
import LineChart from "components/charts/LineChart";
import { HistoricalResponse } from "types";
import { MINUTES_15_IN_MILLISECOND, ONE_DAY_IN_MILLISECOND } from "constants/time.constants";
import { getRandomColor } from "utils/common";
import { LoadingWrapper } from "components/wrappers/LoadingWrapper";
import { reduceScaleUnitFromTime } from "utils/chart";

type ParkingMeanChartProps = {
    data: HistoricalResponse[];
    title: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon?: any;
    loading: boolean;
};

export interface processChartData {
    label: string;
    data: XYData[];
    borderColor: string;
    tension: number;
    backgroundColor?: string;
    type?: string;
    strokeStyle?: string;
    fillStyle?: string;
}

export interface XYData {
    x: Date;
    y: number;
}

function HistoricalOccupancyChart({ data = [], title, icon, loading }: ParkingMeanChartProps) {
    const [processedData, setProcessedData] = useState<processChartData[]>([]);
    const [modalShow, setModalShow] = React.useState(false);
    const [isDataProcessing, setIsDataProcessing] = React.useState(false);
    const { startTime, endTime, timeType } = useSelector((state) => state.time);

    useEffect(() => {
        setIsDataProcessing(true);
        processData(data);
    }, [data]);

    function processData(rawData: HistoricalResponse[]): void {
        const result = rawData
            .reduce((acc: XYData[], current: HistoricalResponse) => {
                const epoch = new Date(current.timestamp).getTime();
                const start = new Date(epoch - (epoch % MINUTES_15_IN_MILLISECOND));
                const matchIndex = acc.findIndex(({ x }) => x.getTime() === start.getTime());
                if (matchIndex === -1) {
                    acc.push({
                        x: start,
                        y: current.value,
                    });
                    return acc;
                }
                if (acc[matchIndex].y < current.value) acc[matchIndex].y = current.value;
                return acc;
            }, [])
            .sort((a, b) => a.x.getTime() - b.x.getTime());
        if (result.length > 0) {
            const start = result[0];
            const end = result[result.length - 1];
            result.unshift({
                x: new Date(start.x.getTime() - ONE_DAY_IN_MILLISECOND),
                y: start.y,
            });

            result.push({
                x: new Date(end.x.getTime() + ONE_DAY_IN_MILLISECOND),
                y: end.y,
            });
        }
        const lineColor = getRandomColor();
        setProcessedData([
            {
                label: "Bicycle count",
                data: result.sort((first, second) => first.x.getTime() - second.x.getTime()),
                borderColor: lineColor,
                backgroundColor: lineColor,
                fillStyle: lineColor,
                strokeStyle: lineColor,
                type: "line",
                tension: 0.01,
            },
        ]);
        setIsDataProcessing(false);
    }

    const handleClick = (status: boolean): void => {
        setModalShow(status);
    };

    const checkAndAdjustHourFromTime = (time: string) => {
        const timeObject = new Date(time);
        if (timeObject.getUTCMinutes() === 0) return time;
        return reduceScaleUnitFromTime(timeType!, timeObject);
        
    };

    return (
        <>
            <PaperWrapper>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={1} gap={1} marginBottom={1}>
                        <Grid item className="chart-padding-0">
                            {icon}
                        </Grid>
                        <Grid item className="chart-padding-0">
                            <span className="chartHearder">{title}</span>
                        </Grid>
                        <Grid item className="chart-padding-0">
                            <IconButton className="expand-btn" onClick={() => handleClick(true)} disableRipple>
                                <CgArrowsExpandRight />
                            </IconButton>
                        </Grid>
                    </Grid>
                    {loading || isDataProcessing ? (
                        <Box display="flex" justifyContent="center" alignItems="center" minHeight="20vh">
                            <LoadingWrapper isLoading />
                        </Box>
                    ) : (
                        <LineChart
                            data={processedData}
                            fromTime={checkAndAdjustHourFromTime(startTime)}
                            toTime={endTime}
                            timeUnit={timeType}
                            useLegend={false}
                            roundTimeDisabled
                            yAxisDecimalDisabled
                        />
                    )}
                </Grid>
            </PaperWrapper>
            <FullScreenDialog
                modalShow={modalShow}
                onClose={handleClick}
                childComponent={
                    <div>
                        {loading || isDataProcessing ? (
                            <Box display="flex" justifyContent="center" alignItems="center" minHeight="20vh">
                                <LoadingWrapper isLoading />
                            </Box>
                        ) : (
                            <LineChart
                                data={processedData}
                                fromTime={checkAndAdjustHourFromTime(startTime)}
                                toTime={endTime}
                                timeUnit={timeType}
                                useLegend={false}
                                roundTimeDisabled
                                yAxisDecimalDisabled
                            />
                        )}
                    </div>
                }
            />
        </>
    );
}

export default memo(HistoricalOccupancyChart);
