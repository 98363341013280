import React, { useState, useEffect, useRef } from "react";
import { AutocompleteChangeReason, AutocompleteRenderInputParams, Grid } from "@mui/material";
import {
    getLogsActionListDropDownRequest,
    getLogsSectionListDropDownRequest,
    getLogsUserListDropDownRequest,
} from "api/common/logs.api";
import CommonDateRangePicker, { DatePickerValue, pickerRanges } from "components/common/CommonDateRangePicker";
import IotVisionAutoComplete from "components/common/IotVisonAutoComplete";
import IotVisionTextFiled from "components/common/IotVisionTextFiled";
import IotVisionButton from "components/common/IotVisionButton";
import { useSearchParams, URLSearchParamsInit } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

interface AutoLabelId {
    label: string;
    id: string;
}
interface ILogFilter {
    dateTime: DatePickerValue;
    section?: string;
    action?: string;
    userId?: AutoLabelId;
    externalId?: string;
}
interface LogsFilterPanelProps {
    onClear: () => void;
}

const LogsFilterPanel: React.FC<LogsFilterPanelProps> = ({ onClear }) => {
    const [sectionList, setSectionList] = useState([]);
    const [actionList, setActionList] = useState([]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [userList, setUserList] = useState<any>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { fromTime, toTime, section, action, userId, externalId, range } =
        Object.fromEntries([...searchParams]) || {};
    const userAbortControllerRef = useRef<AbortController | null>(null);
    const actionAbortControllerRef = useRef<AbortController | null>(null);
    const sectionAbortControllerRef = useRef<AbortController | null>(null);
    const [pickerValue, setPickerValue] = useState<DatePickerValue>({
        fromTime: "",
        toTime: "",
        range: pickerRanges.DateRange,
    });

    const {
        handleSubmit,
        setValue,
        control,
        trigger,
        reset,
        formState: { isValid },
    } = useForm<ILogFilter>({
        defaultValues: {
            externalId: "",
            section: "",
            action: "",
            userId: { label: "", id: "" },
            dateTime: pickerValue,
        },
    });

    useEffect(() => {
        reset({
            externalId: externalId || "",
            section: section || "",
            action: action || "",
            userId: userId ? { label: findLabel(userList, userId), id: userId } : { label: "", id: "" },
            dateTime: {
                fromTime: fromTime || "",
                toTime: toTime || "",
                range: range || pickerRanges.DateRange,
            },
        });
        setPickerValue({
            fromTime: fromTime || "",
            toTime: toTime || "",
            range: range || pickerRanges.DateRange,
        });
    }, [searchParams, userList]);

    useEffect(() => {
        fetchData();
        return () => {
            setSectionList([]);
            setUserList([]);
            setActionList([]);
            sectionAbortControllerRef.current?.abort();
            userAbortControllerRef.current?.abort();
            actionAbortControllerRef.current?.abort();
        };
    }, []);

    const fetchData = async () => {
        await getLogSectionDropdownVales();
        await getLogUserDropdownVales();
        await getLogActionDropdownVales();
    };

    const findLabel = (list: AutoLabelId[], id: string): string | undefined => {
        return list?.find((s: AutoLabelId) => s.id === id)?.label;
    };

    async function getLogSectionDropdownVales(): Promise<void> {
        try {
            sectionAbortControllerRef.current = new AbortController();
            const { signal } = sectionAbortControllerRef.current;
            const response = await getLogsSectionListDropDownRequest({
                signal,
            });
            if (response !== (null || undefined)) {
                setSectionList(response);
            }
        } catch (e) {}
    }
    async function getLogUserDropdownVales(): Promise<void> {
        try {
            userAbortControllerRef.current = new AbortController();
            const { signal } = userAbortControllerRef.current;
            const response = await getLogsUserListDropDownRequest({ signal });
            if (response !== (null || undefined) && Array.isArray(response)) {
                setUserList(response);
            }
        } catch (e) {}
    }
    async function getLogActionDropdownVales(): Promise<void> {
        try {
            actionAbortControllerRef.current = new AbortController();
            const { signal } = actionAbortControllerRef.current;
            const response = await getLogsActionListDropDownRequest({ signal });
            if (response !== (null || undefined)) {
                setActionList(response);
            }
        } catch (e) {}
    }

    const handleClear = (): void => {
        onClear();
        reset({
            externalId: "",
            section: "",
            action: "",
            userId: { label: "", id: "" },
            dateTime: {
                fromTime: "",
                toTime: "",
                range: pickerRanges.DateRange,
            },
        });
        setPickerValue({
            fromTime: "",
            toTime: "",
            range: pickerRanges.DateRange,
        });
        setSearchParams({});
    };

    const onSubmit: SubmitHandler<ILogFilter> = (data: ILogFilter): void => {
        const searchParamData: URLSearchParamsInit | undefined = {
            ...(data?.section ? { section: data?.section } : {}),
            ...(data?.action ? { action: data?.action } : {}),
            ...(data?.dateTime?.fromTime ? { fromTime: data?.dateTime?.fromTime } : {}),
            ...(data?.dateTime?.toTime ? { toTime: data?.dateTime?.toTime } : {}),
            ...(data?.dateTime?.range && data?.dateTime?.range !== pickerRanges.DateRange
                ? { range: data?.dateTime?.range }
                : {}),
            ...(data?.userId?.id ? { userId: data?.userId?.id } : {}),
            ...(data?.externalId ? { externalId: data?.externalId } : {}),
        };
        setSearchParams(searchParamData);
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Controller
                        name="dateTime"
                        control={control}
                        render={() => (
                            <CommonDateRangePicker
                                value={pickerValue}
                                onPickerMenuClose={() => {
                                    setPickerValue({
                                        ...pickerValue,
                                    });
                                }}
                                onDatePickerApply={(start, end, range) => {
                                    setPickerValue({
                                        fromTime: start,
                                        toTime: end,
                                        range,
                                    });
                                    setValue("dateTime.fromTime", start);
                                    setValue("dateTime.toTime", end);
                                    setValue("dateTime.range", range);
                                }}
                                onDatePickerClear={() => {
                                    setValue("dateTime.fromTime", "");
                                    setValue("dateTime.toTime", "");
                                    setValue("dateTime.range", pickerRanges.DateRange);
                                    setPickerValue({
                                        fromTime: "",
                                        toTime: "",
                                        range: pickerRanges.DateRange,
                                    });
                                }}
                                buttonText="Clear"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Controller
                        name="section"
                        control={control}
                        render={({ field: { value } }) => (
                            <IotVisionAutoComplete
                                options={sectionList}
                                value={value}
                                onChange={(
                                    event: React.SyntheticEvent,
                                    value: string,
                                    reason: AutocompleteChangeReason,
                                ) => {
                                    if (reason === "clear") {
                                        setValue("section", "");
                                    } else {
                                        setValue("section", value);
                                    }
                                }}
                                renderInput={(params: AutocompleteRenderInputParams) => (
                                    <IotVisionTextFiled {...params} placeholder="Sections" fullWidth />
                                )}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Controller
                        name="action"
                        control={control}
                        render={({ field: { value } }) => (
                            <IotVisionAutoComplete
                                options={actionList}
                                value={value}
                                onChange={(
                                    event: React.SyntheticEvent,
                                    value: string,
                                    reason: AutocompleteChangeReason,
                                ) => {
                                    if (reason === "clear") {
                                        setValue("action", "");
                                    } else {
                                        setValue("action", value);
                                    }
                                }}
                                renderInput={(params: AutocompleteRenderInputParams) => (
                                    <IotVisionTextFiled {...params} placeholder="Actions" fullWidth />
                                )}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Controller
                        name="externalId"
                        control={control}
                        rules={{
                            required: false,
                            maxLength: {
                                value: 9,
                                message: "9 digits can be allowed",
                            },
                            pattern: {
                                value: /^\d+$/,
                                message: "Should be integer",
                            },
                        }}
                        render={({ field: { value }, fieldState: { invalid, error } }) => {
                            return (
                                <IotVisionTextFiled
                                    fullWidth
                                    placeholder="ID"
                                    error={invalid}
                                    label={error?.message}
                                    value={value}
                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                        setValue("externalId", event.target.value);
                                        if (invalid) trigger("externalId");
                                    }}
                                />
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Controller
                        name="userId"
                        control={control}
                        render={({ field: { value } }) => (
                            <IotVisionAutoComplete
                                options={userList}
                                value={value?.label}
                                onChange={(
                                    event: React.SyntheticEvent,
                                    value: AutoLabelId,
                                    reason: AutocompleteChangeReason,
                                ) => {
                                    if (reason === "clear") {
                                        setValue("userId", {
                                            label: "",
                                            id: "",
                                        });
                                    } else {
                                        setValue("userId", {
                                            label: value?.label,
                                            id: value?.id,
                                        });
                                    }
                                }}
                                renderInput={(params: AutocompleteRenderInputParams) => (
                                    <IotVisionTextFiled {...params} placeholder="Username" fullWidth />
                                )}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Grid container spacing={1}>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <IotVisionButton
                                type="submit"
                                disabled={!isValid}
                                fullWidth
                                sx={{
                                    color: "white !important",
                                    height: "37px",
                                }}
                            >
                                Search
                            </IotVisionButton>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <IotVisionButton
                                fullWidth
                                sx={{
                                    height: "37px",
                                    backgroundColor: "white",
                                    color: "primary.main",
                                    "&:hover": {
                                        background: "white",
                                    },
                                }}
                                onClick={handleClear}
                            >
                                Clear
                            </IotVisionButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default LogsFilterPanel;
