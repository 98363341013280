import AdapterDateFns from "@mui/lab/AdapterDateFns";
import EventIcon from "@mui/icons-material/Event";
import { Grid, Menu, styled, Box } from "@mui/material";
import { CalendarPicker, LocalizationProvider, PickersDay, PickersDayProps, StaticDatePicker } from "@mui/lab";
import IotVisionTextFiled from "components/common/IotVisionTextFiled";
import {
    endOfDay,
    endOfMonth,
    endOfWeek,
    format,
    isSameDay,
    isWithinInterval,
    startOfDay,
    startOfMonth,
    startOfWeek,
} from "date-fns";
import React, { useEffect, useState } from "react";
import IotVisionButton from "components/common/IotVisionButton";
import IotVisionChip from "components/common/IotVisionChip";
import DateView from "components/dates/DateView";
import { IStartEndDate } from "../chart/HistoricalTrendFilter";

interface MultiDatePickerProps {
    typeValue: string;
    onChangeDay: (value: IStartEndDate[]) => void;
    dayValueArray: IStartEndDate[];
}

interface SelectPanelProps {
    typeVal: string;
    dateArray: IStartEndDate[];
    onPanelDelete: (dateObject: IStartEndDate) => void;
}

interface SelectLabelProps {
    dateObject: IStartEndDate;
    format: string;
    onLabelDelete: (dateObject: IStartEndDate) => void;
}

type CustomPickerDayProps = PickersDayProps<Date> & {
    dayIsBetween: boolean;
    isFirstDay: boolean;
    isLastDay: boolean;
};

const STATIC_YEAR = 2000;

const DateLabel: React.FC<SelectLabelProps> = ({ dateObject, format, onLabelDelete }) => {
    return (
        <IotVisionChip
            color="primary"
            sx={{
                width: "195px",
            }}
            label={
                dateObject && (
                    <Box
                        sx={{
                            width: "150px",
                        }}
                    >
                        <DateView dateFormat={format}>{dateObject?.from}</DateView>
                    </Box>
                )
            }
            onDelete={() => onLabelDelete(dateObject)}
            variant="outlined"
        />
    );
};

const SelectPanel: React.FC<SelectPanelProps> = ({ typeVal, dateArray, onPanelDelete }) => {
    const format = typeVal === "Months" ? "LLLL', 'yyyy" : typeVal === "Weeks" ? "wo' Week of' yyyy" : "PP";
    return (
        <Grid container spacing={1}>
            {dateArray?.map((value: IStartEndDate) => {
                return (
                    <Grid item xs={12}>
                        <DateLabel
                            onLabelDelete={(dateObject) => {
                                onPanelDelete(dateObject);
                            }}
                            key={value?.from}
                            format={format}
                            dateObject={value}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) => prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay, selected }) => ({
    ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.light,
        color: "black !important",
        "&:hover, &:focus": {
            backgroundColor: theme.palette.primary.light,
        },
    }),
    ...(selected && {
        backgroundColor: `${theme.palette.primary.light} !important`,
        color: "black !important",
        "&:hover, &:focus": {
            backgroundColor: theme.palette.primary.light,
        },
    }),
    ...(isFirstDay && {
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%",
        backgroundColor: theme.palette.primary.main,
        "&:hover, &:focus": {
            backgroundColor: theme.palette.primary.main,
        },
    }),
    ...(isLastDay && {
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "50%",
        backgroundColor: theme.palette.primary.main,
        "&:hover, &:focus": {
            backgroundColor: theme.palette.primary.main,
        },
    }),
})) as React.ComponentType<CustomPickerDayProps>;

const MultiDatePicker: React.FC<MultiDatePickerProps> = ({ typeValue, onChangeDay, dayValueArray }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [dayValue, setDayValue] = useState<Date | null>(new Date());
    const open = Boolean(anchorEl);

    useEffect(() => {
        onChangeDay(dayValueArray);
    }, []);
    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (): void => {
        setAnchorEl(null);
    };
    const handleCancel = (): void => {
        setAnchorEl(null);
        setDayValue(new Date());
        onChangeDay([]);
    };
    const handleDateOK = (): void => {
        setAnchorEl(null);
    };

    const renderWeekPickerDay = (
        date: Date,
        selectedDates: Array<Date | null>,
        pickersDayProps: PickersDayProps<Date>,
    ): JSX.Element => {
        if (!dayValue) {
            return <PickersDay {...pickersDayProps} />;
        }

        const start = startOfWeek(dayValue);
        const end = endOfWeek(dayValue);
        const dayIsBetween = isWithinInterval(date, { start, end });
        const isFirstDay = isSameDay(date, start);
        const isLastDay = isSameDay(date, end);

        return (
            <CustomPickersDay
                {...pickersDayProps}
                disableMargin
                dayIsBetween={dayIsBetween}
                isFirstDay={isFirstDay}
                isLastDay={isLastDay}
            />
        );
    };

    const handleDateDelete = (dateObjec: IStartEndDate) => {
        onChangeDay(dayValueArray?.filter((item: IStartEndDate) => item !== dateObjec));
    };

    const removeDuplicateObjectInArray = (arr: IStartEndDate[], key: (vl: IStartEndDate) => void) => {
        return [...new Map(arr.map((x: IStartEndDate) => [key(x), x])).values()];
    };

    const handlePickerDayChange = (newDate: Date): void => {
        onChangeDay(
            removeDuplicateObjectInArray(
                [
                    ...dayValueArray,
                    {
                        from: startOfDay(newDate).toISOString(),
                        to: endOfDay(newDate).toISOString(),
                        title: `${format(newDate, "PPPP")}`,
                    },
                ],
                (day: IStartEndDate) => day.from,
            ),
        );
    };

    const handlePickerMonthChange = (newDate: Date): void => {
        onChangeDay(
            removeDuplicateObjectInArray(
                [
                    ...dayValueArray,
                    {
                        from: startOfMonth(newDate).toISOString(),
                        to: endOfMonth(newDate).toISOString(),
                        title: `${format(newDate, "LLLL', ' yyyy")}`,
                    },
                ],
                (day: IStartEndDate) => day.from,
            ),
        );
    };

    const handlePickerWeekChange = (newDate: Date): void => {
        onChangeDay(
            removeDuplicateObjectInArray(
                [
                    ...dayValueArray,
                    {
                        from: startOfWeek(newDate).toISOString(),
                        to: endOfWeek(newDate).toISOString(),
                        title: `${format(newDate, "wo ' Week of 'yyyy")}`,
                    },
                ],
                (day: IStartEndDate) => day.from,
            ),
        );
    };

    const handleDateSelection = (selectedDate: Date | null, handler: (x: Date) => void) => {
        if (dayValue?.getFullYear() === selectedDate?.getFullYear()) {
            const currentDate = new Date();
            selectedDate?.setHours(
                currentDate.getHours(),
                currentDate.getMinutes(),
                currentDate.getSeconds(),
                currentDate.getMilliseconds(),
            );
        }
        const copyNewDate = selectedDate && new Date(selectedDate).setFullYear(STATIC_YEAR);
        const copyDayValue = dayValue && new Date(dayValue).setFullYear(STATIC_YEAR);
        if (copyNewDate === copyDayValue) return;
        setDayValue(selectedDate);
        // eslint-disable-next-line no-unused-expressions
        selectedDate && handler(selectedDate);
    };

    return (
        <>
            <IotVisionTextFiled
                fullWidth
                sx={{
                    "& .MuiOutlinedInput-input": {
                        height: "17px",
                    },
                }}
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <EventIcon
                            sx={{
                                color: "gray",
                            }}
                        />
                    ),
                }}
                placeholder={`${typeValue}Picker`}
                onClick={(event: React.MouseEvent<HTMLElement>) => handleClick(event)}
            />
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <Box
                    sx={{
                        maxWidth: "650px",
                        width: "100%",
                        maxHeight: "350px",
                        height: "100%",
                        padding: "8px 5px 5px 0px",
                    }}
                >
                    <Grid container spacing={1}>
                        <Grid
                            item
                            xs={8}
                            sx={{
                                maxHeight: "300px",
                                height: "100%",
                                overflow: "hidden",
                            }}
                        >
                            {typeValue === "Months" ? (
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <StaticDatePicker
                                        displayStaticWrapperAs="desktop"
                                        value={dayValue}
                                        openTo="month"
                                        views={["year", "month"]}
                                        disableFuture
                                        onYearChange={(year: Date) => {
                                            setDayValue(year);
                                        }}
                                        onChange={(newDate: Date | null) => {
                                            handleDateSelection(newDate, handlePickerMonthChange);
                                        }}
                                        renderInput={(_params) => <></>}
                                    />
                                </LocalizationProvider>
                            ) : typeValue === "Weeks" ? (
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <StaticDatePicker
                                        displayStaticWrapperAs="desktop"
                                        value={dayValue}
                                        disableFuture
                                        onYearChange={(year: Date) => {
                                            setDayValue(year);
                                        }}
                                        onChange={(newDate: Date | null) => {
                                            handleDateSelection(newDate, handlePickerWeekChange);
                                        }}
                                        renderDay={renderWeekPickerDay}
                                        renderInput={(_params) => <></>}
                                        allowSameDateSelection
                                    />
                                </LocalizationProvider>
                            ) : (
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <CalendarPicker
                                        allowSameDateSelection
                                        disableFuture
                                        date={dayValue}
                                        onYearChange={(year: Date) => {
                                            setDayValue(year);
                                        }}
                                        onChange={(newDate: Date | null) => {
                                            handleDateSelection(newDate, handlePickerDayChange);
                                        }}
                                    />
                                </LocalizationProvider>
                            )}
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    {`Selected ${typeValue}`}
                                </Grid>
                                <Grid item xs={12}>
                                    <SelectPanel
                                        onPanelDelete={(value) => {
                                            handleDateDelete(value);
                                        }}
                                        typeVal={typeValue}
                                        dateArray={dayValueArray}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={8} />
                        <Grid
                            item
                            xs={4}
                            sx={{
                                marginTop: "5px",
                                marginBottom: "5px",
                            }}
                        >
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    {dayValueArray && dayValueArray[0] ? (
                                        <IotVisionButton
                                            onClick={handleDateOK}
                                            sx={{
                                                maxWidth: "10px",
                                                width: "100%",
                                            }}
                                        >
                                            OK
                                        </IotVisionButton>
                                    ) : (
                                        <IotVisionButton
                                            disabled
                                            sx={{
                                                color: "white !important",
                                                maxWidth: "10px",
                                                width: "100%",
                                            }}
                                        >
                                            OK
                                        </IotVisionButton>
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    <IotVisionButton
                                        sx={{
                                            backgroundColor: "white",
                                            color: "primary.main",
                                            maxWidth: "10px",
                                            width: "100%",
                                            "&:hover": {
                                                background: "white",
                                            },
                                        }}
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </IotVisionButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Menu>
        </>
    );
};

export default MultiDatePicker;
