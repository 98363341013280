/* eslint-disable import/prefer-default-export */

import { CommonObjectLiteral } from "types";

export class ZoomModalContent {
    content: string | null;

    state: CommonObjectLiteral;

    constructor(options: CommonObjectLiteral) {
        this.content = null;
        this.state = options.state;
        this.createContent();
    }

    getContent() {
        return this.content;
    }

    createContent() {
		const zoom = `
			<div class="vjs-zoom-duck__container--row">
				<button id="vjs-zoom-duck__zoomIn" class="vjs-zoom-duck__button">
					<span class="vjs-zoom-icons">add</span>
				</button>
				<span class="vjs-zoom-duck__space"></span>
				<button id="vjs-zoom-duck__zoomOut" class="vjs-zoom-duck__button">
					<span class="vjs-zoom-icons">remove</span>
				</button>
			</div>
		`;
		const move = `
			<div class="vjs-zoom-duck__container--row">
				<span class="vjs-zoom-duck__space"></span>
				<button id="vjs-zoom-duck__moveUp" class="vjs-zoom-duck__button">
					<span class="vjs-zoom-icons">arrow_drop_up</span>
				</button>
				<span class="vjs-zoom-duck__space"></span>
			</div>
			<div class="vjs-zoom-duck__container--row">
				<button id="vjs-zoom-duck__moveLeft" class="vjs-zoom-duck__button">
					<span class="vjs-zoom-icons">arrow_left</span>
				</button>
				<button id="vjs-zoom-duck__reset" class="vjs-zoom-duck__button">
					<span class="vjs-zoom-icons">fiber_manual_record</span>
				</button>
				<button id="vjs-zoom-duck__moveRight" class="vjs-zoom-duck__button">
					<span class="vjs-zoom-icons">arrow_right</span>
				</button>
			</div>
			<div class="vjs-zoom-duck__container--row">
				<span class="vjs-zoom-duck__space"></span>
				<button id="vjs-zoom-duck__moveDown" class="vjs-zoom-duck__button">
					<span class="vjs-zoom-icons">arrow_drop_down</span>
				</button>
				<span class="vjs-zoom-duck__space"></span>
			</div>
		`;
        this.content = "";
        if (this.state?.showZoom) {
            this.content += zoom;
        }
        if (this.state?.showMove) {
            this.content += move;
        }
    }
}
