import { MenuItem, MenuItemProps, styled } from "@mui/material";

const SelectMenuItem = styled((SelectMenuItemProps: MenuItemProps) => (
    <MenuItem
        {...SelectMenuItemProps}
        sx={{
            fontSize: "12px",
            fontWeight: 400,
            color: "black",
        }}
    />
))(() => ({}));

export default SelectMenuItem;
