import { Grid, Theme } from "@mui/material";
import CommonDateRangePicker, { DatePickerValue, pickerRanges } from "components/common/CommonDateRangePicker";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { PrimaryButton, SecondaryButton, SelectMenuItem, StyledTextField } from "features/app-dept-air-quality/common";
import { useTheme } from "@mui/styles";
import { useParams } from "react-router";
import { Classification } from "enums/room.enums";
import { AQIncidentClassificationMetadata } from "constants/aqIncidentAttributes";

export interface HistoricalAnalysisField {
    type: string;
    time: DatePickerValue;
}

export interface HistoricalAnalysisFilterClass {
    displayText: string;
    classification: Classification;
}

interface HistoricalAnalysisFilterHeaderProps {
    initialValues: HistoricalAnalysisField;
    onApply: (value: HistoricalAnalysisField) => void;
    onClear: (value: HistoricalAnalysisField) => void;
    defaultFilterValues: HistoricalAnalysisField;
}

const HistoricalAnalysisFilterHeader: React.FC<HistoricalAnalysisFilterHeaderProps> = ({
    onApply,
    onClear,
    initialValues,
    defaultFilterValues,
}): JSX.Element => {
    // tempoary data
    const customToTime = new Date().toISOString();

    const startDate = new Date();
    startDate.setHours(startDate.getHours() - 24);
    const customFromTime = startDate.toISOString();
    const dropdownFilters: HistoricalAnalysisFilterClass[] = AQIncidentClassificationMetadata;
    const theme = useTheme<Theme>();
    const { roomId, schoolId } = useParams();
    const {
        time: { fromTime, toTime, range },
    } = initialValues || {};
    const {
        handleSubmit,
        setValue,
        control,
        reset,
        trigger,
        formState: { isValid },
    } = useForm<HistoricalAnalysisField>({
        defaultValues: {
            type: "temp",
            time: {
                fromTime: "",
                toTime: "",
                range: "",
            },
        },
    });

    useEffect(() => {
        reset(initialValues);
    }, [initialValues]);

    useEffect(() => {
        onApply(defaultFilterValues);
    }, [roomId, schoolId]);

    useEffect(() => {
        onApply({
            type: "temp",
            time: { fromTime, toTime, range },
        });
    }, []);

    const onSubmit: SubmitHandler<HistoricalAnalysisField> = (data): void => {
        const submitFormData: HistoricalAnalysisField = {
            type: data?.type ? data?.type : "",
            time: {
                range: data?.time?.range ? data?.time?.range : range,
                fromTime: data?.time?.fromTime ? data?.time?.fromTime : fromTime,
                toTime: data?.time?.toTime ? data?.time?.toTime : toTime,
            },
        };
        onApply(submitFormData);
    };

    const handleReset = (): void => {
        onClear({
            time: {
                fromTime: customFromTime,
                toTime: customToTime,
                range: pickerRanges.Last24Hours,
            },
            type: "temp",
        });
        reset({
            time: {
                fromTime: customFromTime,
                toTime: customToTime,
                range: pickerRanges.Last24Hours,
            },
            type: "temp",
        });
    };

    const IsValidFunction = (
        startDateTime: string,
        endDateTime: string,
        setWarningMessage: Dispatch<SetStateAction<string>>,
    ) => {
        const isSameDates = new Date(endDateTime).getTime() === new Date(startDateTime).getTime();

        const isBeforeEndDate = new Date(endDateTime).getTime() < new Date(startDateTime).getTime();

        const isFutureTimeSelected =
            new Date(startDateTime).getTime() > new Date().getTime() ||
            new Date(endDateTime).getTime() > new Date().getTime();
        const isMaximumRangeExceeded =
            new Date(endDateTime).getTime() - new Date(startDateTime).getTime() > 24 * 60 * 60 * 1000;
        if (isSameDates) {
            setWarningMessage("Start date and end date must be different");
        } else if (isFutureTimeSelected) {
            setWarningMessage("Date Entered Exceeds Todays Date");
        } else if (isMaximumRangeExceeded) {
            setWarningMessage("Maximum range is 24 hours");
        } else {
            // eslint-disable-next-line no-unused-expressions
            isBeforeEndDate ? setWarningMessage("Start date must be before end date") : setWarningMessage("");
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1} columnSpacing="8px">
                <Grid item xs={12} md={6} lg="auto">
                    <Controller
                        name="type"
                        control={control}
                        render={({ field: { value } }) => (
                            <StyledTextField
                                sx={{
                                    width: "100%",
                                    [theme?.breakpoints.up("lg")]: {
                                        width: "224px",
                                    },

                                    "& .MuiOutlinedInput-root": {
                                        width: "100%",
                                        height: "25px",
                                    },
                                }}
                                select
                                value={value}
                                onChange={(event) => {
                                    setValue("type", event?.target?.value);
                                }}
                            >
                                {dropdownFilters?.map((option: HistoricalAnalysisFilterClass, key: number) => {
                                    return (
                                        <SelectMenuItem key={key} value={option.classification}>
                                            {option.displayText}
                                        </SelectMenuItem>
                                    );
                                })}
                            </StyledTextField>
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg="auto">
                    <Controller
                        name="time"
                        control={control}
                        render={({ field: { value } }) => {
                            return (
                                <CommonDateRangePicker
                                    value={value}
                                    onDatePickerApply={(startTime, endTime, range) => {
                                        setValue("time.fromTime", startTime);
                                        setValue("time.toTime", endTime);
                                        setValue("time.range", range);
                                        trigger("time");
                                    }}
                                    onDatePickerClear={() => {
                                        setValue("time.fromTime", "");
                                        setValue("time.toTime", "");
                                        setValue("time.range", pickerRanges.DateRange);
                                    }}
                                    customIcon={<ArrowDropDownIcon sx={{ color: "#A3A3A3" }} />}
                                    sx={{
                                        [theme?.breakpoints.up("lg")]: {
                                            "& .MuiInputBase-root": {
                                                height: "25px",
                                                width: "250px",
                                            },
                                        },
                                        "& .MuiInputBase-root": {
                                            height: "25px",
                                        },

                                        "& input": {
                                            fontSize: "12px",
                                            "&::placeholder": {
                                                color: "#A3A3A3",
                                                fontWeight: 400,
                                                opacity: 0.99,
                                            },
                                        },
                                    }}
                                    buttonText="Clear"
                                    isValidFunction={IsValidFunction}
                                />
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg="auto">
                    <Grid container spacing={1}>
                        <Grid item xs={6} lg="auto">
                            <PrimaryButton
                                sx={{
                                    width: "100%",
                                    [theme?.breakpoints.up("lg")]: {
                                        width: "120px",
                                    },
                                }}
                                disabled={!isValid}
                                type="submit"
                                fullWidth
                            >
                                Apply
                            </PrimaryButton>
                        </Grid>
                        <Grid item xs={6} lg="auto">
                            <SecondaryButton
                                sx={{
                                    width: "100%",
                                    [theme?.breakpoints.up("lg")]: {
                                        width: "120px",
                                    },
                                }}
                                onClick={handleReset}
                            >
                                Reset
                            </SecondaryButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default HistoricalAnalysisFilterHeader;
