import ExecuteAsync from "utils/api-client";
import { ApiEndpoints } from "configs";
import { createQueryParams } from "utils/create-query-params";
import { RequestOptions } from "axios-types";
import { IByLocation } from "types";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
export async function executeGetIncidentsByLocationsRequest(
    data?: any,
    options?: RequestOptions,
): Promise<IByLocation[]> {
    const response = await ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/incidents/incident/by_location${createQueryParams(data)}`,
        signal: options?.signal,
    });
    return response.map((item: any) => {
        return {
            count: item.count,
            locationGroupIds: item.locationgroupids,
            locationId: item.locationid,
        };
    });
}
