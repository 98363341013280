import { executeGetDetectionsByClassifications } from "api/app-aq-demo/aq-room-api";
import { Classification } from "enums/room.enums";
import { useEffect, useState, Dispatch, SetStateAction } from "react";
import { Grid } from "@mui/material";
import { executeGetHistoricalDataRequest } from "api/app-air-quality/aq-home-layout.api";
import { AQSensorData } from "utils/threshold-data-finder/types";
import { getThresholdData } from "utils/threshold-data-finder";
import { ThresHoldConfigDataSetType } from "utils/threshold-data-finder/enums";
import { ColorObj } from "components/meters/circle-meter/CircleMeter";
import { checkIfDelayed } from "utils/common";
import { useSelector } from "react-redux";
import { isValidLocation } from "utils/is-valid-location";
import ClassroomCard from "../cards/ClassroomCard";

const HISTOGRAM_X_AXIS_LENGTH = 24;
const HISTOGRAM_X_AXIS_INITIAL_VALUES = new Array(HISTOGRAM_X_AXIS_LENGTH).fill(0);
const HISTOGRAM_INITIAL_LABELS = new Array(HISTOGRAM_X_AXIS_LENGTH).fill("");
const INITIAL_GRAPH_COLOR = "#f5f5fb";
const TIME_DELAY_TRIGGER_IN_SECONDS = 300;

export interface CardLoaderData {
    cardData: CardDataType;
    histogramData: HistogramData;
}

interface CardWrapperProps {
    classification: Classification;
    subClassification?: Classification;
    roomId?: string;
    setLastUpdated: Dispatch<SetStateAction<Date | null>>;
    onUpdated: (params: CardLoaderData) => void;
}

interface Event {
    classification: Classification;
    inTime: Date;
    value: number;
}

interface Location {
    id: string;
    lastUpdated: Date;
    name: string;
}

interface LocationSpecificDataItem {
    location: Location;
    events: Event[];
}

interface TimeBasedDataItem {
    time: Date;
    min: number;
    max: number;
    avg: number;
}

interface LocationSpecificHistogramItem {
    data: TimeBasedDataItem[];
    location: Location;
}

interface CardDataType {
    key: string;
    colorsCircleIndicator: ColorObj;
    colorsCircleBackground: ColorObj;
    cardHeading: string;
    cardSubHeading: string;
    circleMeterValue: number;
    circleMeterMaxValue: number;
    innerCircleLabel: string;
    innerCircleValue: string;
    barChartData: number[];
    barChartLabels: string[];
    barChartColor: string;
    latestUpdateAt: Date | undefined;
}

interface HistogramData {
    barChartData: number[];
    barChartLabels: string[];
    barChartColor: string;
}

interface BarChartDataItem {
    time: Date;
    min: number;
    max: number;
    avg: number;
}

const CardLoader = ({ classification, subClassification, roomId, setLastUpdated, onUpdated }: CardWrapperProps) => {
    const INITIAL_CARD_DATA = {
        key: "",
        colorsCircleIndicator: {
            start: INITIAL_GRAPH_COLOR,
            mid: INITIAL_GRAPH_COLOR,
            end: INITIAL_GRAPH_COLOR,
        },
        colorsCircleBackground: {
            start: INITIAL_GRAPH_COLOR,
            mid: INITIAL_GRAPH_COLOR,
            end: INITIAL_GRAPH_COLOR,
        },
        cardHeading: "",
        cardSubHeading: "",
        circleMeterValue: 0,
        circleMeterMaxValue: 1,
        innerCircleLabel: "",
        innerCircleValue: "0",
        barChartData: new Array(HISTOGRAM_X_AXIS_LENGTH).fill(0),
        barChartLabels: HISTOGRAM_INITIAL_LABELS,
        barChartColor: INITIAL_GRAPH_COLOR,
        latestUpdateAt: new Date(),
    };

    const INITIAL_HISTOGRAM_DATA = {
        barChartData: HISTOGRAM_X_AXIS_INITIAL_VALUES,
        barChartLabels: HISTOGRAM_INITIAL_LABELS,
        barChartColor: INITIAL_GRAPH_COLOR,
    };

    const [loading, setLoading] = useState<boolean>(false);
    const [cardDataItem, setCardDataItem] = useState<CardDataType>(INITIAL_CARD_DATA);
    const [histogramItem, setHistogramItem] = useState<HistogramData>(INITIAL_HISTOGRAM_DATA);
    const { locations } = useSelector((state) => state.newLocation);

    const fetchInnerValue = async (classification: string) => {
        const response: LocationSpecificDataItem[] = await executeGetDetectionsByClassifications({
            locationId: roomId,
            classifications: subClassification ? `${classification},${subClassification}` : classification,
        });
        processCardInnerValues(response);
    };

    const fetchHistogramData = async () => {
        const toTime = new Date().toISOString();
        const startDate = new Date();
        startDate.setHours(startDate.getHours() - 24);
        const fromTime = startDate.toISOString();

        const response: LocationSpecificHistogramItem[] = (await executeGetHistoricalDataRequest({
            classification,
            fromTime,
            toTime,
            timeWindowSize: 60,
            isAVG: true,
            locationId: roomId,
        })) as LocationSpecificHistogramItem[];
        processHistogramData(response);
    };

    const processCardInnerValues = (data: LocationSpecificDataItem[]) => {
        const locationSpecificData = data.find(
            (locationSpecificdataItem: LocationSpecificDataItem) => locationSpecificdataItem.location.id === roomId,
        );

        let subHeadingEvent;
        if (subClassification) {
            subHeadingEvent = locationSpecificData?.events.find(
                (event: Event) => event.classification === subClassification,
            );
        }
        const classificationEvent = locationSpecificData?.events.find(
            (event: Event) => event.classification === classification,
        );

        const updatedTime = classificationEvent?.inTime;
        const latestUpdateAt = updatedTime || undefined;

        const thresholdData: AQSensorData | undefined = getThresholdData(
            ThresHoldConfigDataSetType.AQ_SENSOR_THRESHOLD_CONFIGS,
            classification,
            classificationEvent?.value,
        );

        let innerCircleValue;
        if (subClassification) {
            innerCircleValue = classificationEvent?.value ? classificationEvent?.value.toFixed(0).toString() : "0";
        } else {
            innerCircleValue = classificationEvent?.value ? classificationEvent?.value.toFixed(1).toString() : "0";
        }
        setCardDataItem({
            ...cardDataItem,
            cardHeading: thresholdData?.roomPageName ? thresholdData?.roomPageName : thresholdData?.name ?? "",
            key: classification,
            innerCircleValue,
            innerCircleLabel: thresholdData?.measurementType ?? "",
            cardSubHeading:
                subClassification && subHeadingEvent ? generateCardSubHeading(subHeadingEvent, thresholdData) : "",
            circleMeterValue: classificationEvent?.value ? classificationEvent.value : 0,
            colorsCircleIndicator: {
                start: thresholdData?.colorRange?.start ?? INITIAL_GRAPH_COLOR,
                mid: thresholdData?.colorRange?.mid ?? INITIAL_GRAPH_COLOR,
                end: thresholdData?.colorRange?.end ?? INITIAL_GRAPH_COLOR,
            },
            barChartColor: thresholdData?.color ?? INITIAL_GRAPH_COLOR,
            circleMeterMaxValue: thresholdData?.maxThreshold ?? 1,
            latestUpdateAt,
        });

        if (locationSpecificData) {
            setLastUpdated(locationSpecificData?.location?.lastUpdated);
        }
        setLoading(false);
    };

    const processHistogramData = (data: LocationSpecificHistogramItem[]) => {
        const barchartDataSet = data.find(
            (locationSpecificHistogramItem: LocationSpecificHistogramItem) =>
                locationSpecificHistogramItem.location.id === roomId,
        );
        const barChartDataItems = barchartDataSet?.data;
        let barChartData: number[] = new Array(HISTOGRAM_X_AXIS_LENGTH).fill(0);
        let barChartLabels: string[] = new Array(HISTOGRAM_X_AXIS_LENGTH).fill("");

        if (barChartDataItems) {
            barChartData = barChartDataItems.map((b: BarChartDataItem) => b.avg);
            barChartLabels = barChartDataItems.map(
                (t: BarChartDataItem) =>
                    `${new Date(t.time).toLocaleDateString()} ${new Date(t.time).toLocaleTimeString()}`,
            );
        }

        setHistogramItem({
            ...histogramItem,
            barChartData,
            barChartLabels,
        });
    };

    const generateCardSubHeading = (classificationEvent?: Event, thresholdData?: AQSensorData) => {
        return `${classificationEvent?.value.toFixed(2).toString()} ${thresholdData?.subMeasurementType}`;
    };

    useEffect(() => {
        if (isValidLocation(roomId)) {
            setCardDataItem(INITIAL_CARD_DATA);
            setHistogramItem(INITIAL_HISTOGRAM_DATA);
            setLoading(true);
            fetchInnerValue(classification);
            fetchHistogramData();
            setLastUpdated(null);
        }
    }, [roomId, classification, locations]);

    useEffect(() => {
        onUpdated({ cardData: cardDataItem, histogramData: histogramItem });
    }, [cardDataItem, histogramItem]);

    return (
        <Grid item xs={12} sm={12} md={6} lg={3} xl={2}>
            <ClassroomCard
                colorsCircleIndicator={cardDataItem.colorsCircleIndicator}
                colorsCircleBackground={cardDataItem.colorsCircleBackground}
                cardHeading={cardDataItem.cardHeading}
                cardSubHeading={cardDataItem.cardSubHeading}
                circleMeterValue={cardDataItem.circleMeterValue}
                circleMeterMaxValue={cardDataItem.circleMeterMaxValue}
                innerCircleLabel={cardDataItem.innerCircleLabel}
                innerCircleValue={cardDataItem.innerCircleValue}
                barChartData={histogramItem.barChartData}
                barChartLabels={histogramItem.barChartLabels}
                barChartColor={cardDataItem.barChartColor}
                isLoading={loading}
                isExpired={
                    cardDataItem.latestUpdateAt
                        ? checkIfDelayed(TIME_DELAY_TRIGGER_IN_SECONDS, cardDataItem.latestUpdateAt)
                        : true
                }
                lastUpdated={cardDataItem.latestUpdateAt}
            />
        </Grid>
    );
};

export default CardLoader;
