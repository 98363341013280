/* eslint-disable @typescript-eslint/no-explicit-any */
import * as localforage from "localforage";
import { PersistConfig, persistReducer } from "redux-persist";

const persistConfig: PersistConfig<any> = {
    key: "root",
    version: 1,
    storage: localforage,
    blacklist: ["auth", "time", "system", "location", "map"],
};

export default function create(reducers: any) {
    const persistedReducer = persistReducer(persistConfig, reducers);

    return persistedReducer;
}
