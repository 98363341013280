import { Autocomplete, AutocompleteChangeReason, AutocompleteRenderInputParams, Grid, Theme } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { getIncidentFilterDropDownValues } from "api/app-dept-air-quality/air-incidents.api";
import CommonDateRangePicker, { DatePickerValue, pickerRanges } from "components/common/CommonDateRangePicker";
import { PrimaryButton, SecondaryButton, StyledTextField } from "features/app-dept-air-quality/common";
import { pagePath } from "pages/app-dept-air-quality";
import { useLocation, URLSearchParamsInit, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { AlertsField, IdLabel } from "../../../../types/incident/alert-filter";
import { getAlertName, getAlertType } from "../table/AlertsTable";

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
interface filterTypes {
    status: IdLabel[];
    room: IdLabel[];
    type: IdLabel[];
    alert: IdLabel[];
    time: DatePickerValue;
}

interface FilterOption {
    name: string;
    value: any[];
}

const useStyles = makeStyles({
    listbox: {
        fontSize: "12px",
        fontWeight: 400,
    },
    input: {
        "&.MuiAutocomplete-input": {
            marginTop: "-12px",
        },
    },
});

const initialFilters: filterTypes = {
    status: [],
    room: [],
    type: [],
    alert: [],
    time: {
        fromTime: "",
        toTime: "",
        range: pickerRanges.DateRange,
    },
};

const initialPickerValue: DatePickerValue = {
    fromTime: "",
    toTime: "",
    range: pickerRanges.DateRange,
};

const initialFormData = {
    alert: {
        id: "",
        label: "",
    },
    type: {
        id: "",
        label: "",
    },
    time: {
        fromTime: "",
        toTime: "",
        range: pickerRanges.DateRange,
    },
    status: {
        id: "",
        label: "",
    },
    room: {
        id: "",
        label: "",
    },
};

const AlertsFilter: React.FC = (): JSX.Element => {
    const theme = useTheme<Theme>();
    const { pathname } = useLocation();
    const [isAlertPage, setIsAlertPage] = useState<boolean>(false);
    const [filterOptions, setFilterOptions] = useState(initialFilters);
    const locations = useSelector((state) => state.newLocation.locations);
    const [searchParams, setSearchParams] = useSearchParams();
    const [pickerValue, setPickerValue] = useState<DatePickerValue>(initialPickerValue);
    const {
        handleSubmit,
        setValue,
        control,
        reset,
        formState: { isValid },
    } = useForm<AlertsField>({
        defaultValues: initialFormData,
    });

    useEffect(() => {
        setIsAlertPage(pathname.includes(pagePath.Incident));
    }, [pathname]);

    useEffect(() => {
        fetchFilterTypes();
    }, []);

    const { incidentId, locationId, alert, type, fromTime, toTime, range, status } = Object.fromEntries([
        ...searchParams,
    ]);

    const autoCompleteClasses = useStyles();

    useEffect(() => {
        reset({
            room: locationId
                ? {
                      label: findLabel(filterOptions.room, locationId),
                      id: locationId,
                  }
                : { label: "", id: "" },
            status: status ? { label: findLabel(filterOptions.status, status), id: status } : { label: "", id: "" },
            time: {
                fromTime: fromTime || "",
                toTime: toTime || "",
                range: range || pickerRanges.DateRange,
            },
            idInc: incidentId || "",
            type: type ? { label: findLabel(filterOptions.type, type), id: type } : { label: "", id: "" },
            alert: alert ? { label: findLabel(filterOptions.alert, alert), id: alert } : { label: "", id: "" },
        });
        setPickerValue({
            fromTime: fromTime || "",
            toTime: toTime || "",
            range: range || pickerRanges.DateRange,
        });
    }, [searchParams, filterOptions]);

    const findLabel = (options: IdLabel[], id: string): string => {
        if (Array.isArray(options) && options.length !== 0) {
            return options.find((opt) => opt.id === id)?.label ?? "";
        }
        return "";
    };

    const onSubmit: SubmitHandler<AlertsField> = (data): void => {
        const submitFormData: AlertsField = {
            alert: data?.alert ? data?.alert : initialFormData.alert,
            type: data?.type ? data?.type : initialFormData.type,
            ...(data?.room?.id
                ? {
                      room: {
                          id: data?.room?.id,
                          label: data?.room?.label,
                      },
                  }
                : {}),
            time: {
                fromTime: data?.time?.fromTime ? data?.time?.fromTime : "",
                toTime: data?.time?.toTime ? data?.time?.toTime : "",
                range: data?.time?.range ? data?.time?.range : pickerRanges.DateRange,
            },
            status: {
                id: data?.status?.id ? data?.status?.id : "",
                label: data?.status?.label ? data?.status?.label : "",
            },
        };

        const submitSearchParams: URLSearchParamsInit | undefined = {
            pageIndex: "1",
            ...(data?.idInc ? { idInc: data?.idInc } : {}),
            ...(data?.room?.id ? { locationId: data?.room?.id } : {}),
            ...(data?.status.id ? { status: data?.status?.id } : {}),
            ...(data?.time?.fromTime ? { fromTime: data?.time?.fromTime } : {}),
            ...(data?.time?.toTime ? { toTime: data?.time?.toTime } : {}),
            ...(data?.time?.range !== pickerRanges.DateRange ? { range: data?.time?.range } : {}),
            ...(data?.alert?.id ? { alert: data?.alert?.id } : {}),
            ...(data?.type?.id ? { type: data?.type.id } : {}),
        };
        setSearchParams(submitSearchParams);
    };

    const handleReset = (): void => {
        reset(initialFormData);
        setSearchParams({
            pageIndex: "1",
        });
    };

    const fetchFilterTypes = async () => {
        const response: any[] = await getIncidentFilterDropDownValues();
        if (response) {
            response.forEach((fil: FilterOption) => {
                const data = initialFilters;
                switch (fil.name) {
                    case "Type":
                        data.type = fil.value.map((itm) => ({
                            id: itm,
                            label: getAlertType(itm),
                        }));
                        break;
                    case "Incident":
                        data.alert = fil.value.map((itm) => ({
                            id: itm,
                            label: getAlertName(itm),
                        }));
                        break;
                    case "Status":
                        data.status = fil.value;
                        break;
                    default:
                        break;
                }
                setFilterOptions(data);
            });
        }
        const rooms = [...new Set(locations.filter((room) => room.type === "location"))];
        const result = rooms.map((room) => ({ id: room.id, label: room.name }));
        if (rooms) {
            setFilterOptions({
                ...filterOptions,
                room: result,
            });
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1} columnSpacing="8px">
                <Grid item xs={12} md={6} lg={3} xl="auto">
                    <Controller
                        name="alert"
                        control={control}
                        render={({ field: { value } }) => (
                            <Autocomplete
                                disablePortal
                                classes={{
                                    input: autoCompleteClasses.input,
                                    listbox: autoCompleteClasses.listbox,
                                }}
                                options={filterOptions.alert}
                                value={value}
                                onChange={(
                                    event: React.SyntheticEvent,
                                    value: any,
                                    reason: AutocompleteChangeReason,
                                ) => {
                                    if (reason === "clear") {
                                        setValue("alert", {
                                            id: "",
                                            label: "",
                                        });
                                    } else {
                                        setValue("alert", value);
                                    }
                                }}
                                renderInput={(params: AutocompleteRenderInputParams) => (
                                    <StyledTextField
                                        sx={{
                                            [theme?.breakpoints.up("xl")]: {
                                                width: "355px",
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                width: "100%",
                                                height: "25px",
                                            },
                                        }}
                                        {...params}
                                        placeholder="Incident"
                                    />
                                )}
                            />
                        )}
                    />
                </Grid>
                {isAlertPage && (
                    <Grid item xs={12} md={6} lg={3} xl="auto">
                        <Controller
                            name="room"
                            control={control}
                            render={({ field: { value } }) => (
                                <Autocomplete
                                    disablePortal
                                    classes={{
                                        input: autoCompleteClasses.input,
                                        listbox: autoCompleteClasses.listbox,
                                    }}
                                    options={filterOptions.room}
                                    value={value}
                                    onChange={(
                                        event: React.SyntheticEvent,
                                        value: any,
                                        reason: AutocompleteChangeReason,
                                    ) => {
                                        if (reason === "clear") {
                                            setValue("room", {
                                                id: "",
                                                label: "",
                                            });
                                        } else {
                                            setValue("room", value);
                                        }
                                    }}
                                    renderInput={(params: AutocompleteRenderInputParams) => (
                                        <StyledTextField
                                            sx={{
                                                [theme?.breakpoints.up("xl")]: {
                                                    width: "250px",
                                                },
                                                "& .MuiOutlinedInput-root": {
                                                    width: "100%",
                                                    height: "25px",
                                                },
                                            }}
                                            {...params}
                                            placeholder="Classroom"
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                )}
                <Grid item xs={12} md={6} lg={3} xl="auto">
                    <Controller
                        name="type"
                        control={control}
                        render={({ field: { value } }) => (
                            <Autocomplete
                                disablePortal
                                classes={{
                                    input: autoCompleteClasses.input,
                                    listbox: autoCompleteClasses.listbox,
                                }}
                                options={filterOptions.type}
                                value={value}
                                onChange={(
                                    event: React.SyntheticEvent,
                                    value: any,
                                    reason: AutocompleteChangeReason,
                                ) => {
                                    if (reason === "clear") {
                                        setValue("type", {
                                            id: "",
                                            label: "",
                                        });
                                    } else {
                                        setValue("type", value);
                                    }
                                }}
                                renderInput={(params: AutocompleteRenderInputParams) => (
                                    <StyledTextField
                                        sx={{
                                            width: "100%",
                                            [theme?.breakpoints.up("xl")]: {
                                                width: "250px",
                                            },

                                            "& .MuiOutlinedInput-root": {
                                                width: "100%",
                                                height: "25px",
                                            },
                                        }}
                                        {...params}
                                        placeholder="Type"
                                    />
                                )}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3} xl="auto">
                    <Controller
                        name="time"
                        control={control}
                        render={({ field: { value } }) => (
                            <CommonDateRangePicker
                                value={value}
                                onDatePickerApply={(startTime, endTime, range) => {
                                    setValue("time.fromTime", startTime);
                                    setValue("time.toTime", endTime);
                                    setValue("time.range", range);
                                }}
                                onDatePickerClear={() => {
                                    setValue("time", {
                                        fromTime: "",
                                        toTime: "",
                                        range: pickerRanges.DateRange,
                                    });
                                }}
                                sx={{
                                    [theme?.breakpoints.up("xl")]: {
                                        "& .MuiInputBase-root": {
                                            height: "25px",
                                            width: "250px",
                                        },
                                    },
                                    "& .MuiInputBase-root": {
                                        height: "25px",
                                    },

                                    "& input": {
                                        fontSize: "12px",
                                        color: "#A3A3A3",
                                    },
                                }}
                                buttonText="Clear"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3} xl="auto">
                    <Controller
                        name="status"
                        control={control}
                        render={({ field: { value } }) => (
                            <Autocomplete
                                disablePortal
                                classes={{
                                    input: autoCompleteClasses.input,
                                    listbox: autoCompleteClasses.listbox,
                                }}
                                options={filterOptions.status}
                                value={value}
                                onChange={(
                                    event: React.SyntheticEvent,
                                    value: any,
                                    reason: AutocompleteChangeReason,
                                ) => {
                                    if (reason === "clear") {
                                        setValue("status", initialFormData.status);
                                    } else {
                                        setValue("status", value);
                                    }
                                }}
                                renderInput={(params: AutocompleteRenderInputParams) => (
                                    <StyledTextField
                                        sx={{
                                            width: "100%",
                                            [theme?.breakpoints.up("xl")]: {
                                                width: "120px",
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                width: "100%",
                                                height: "25px",
                                            },
                                        }}
                                        {...params}
                                        placeholder="Status"
                                    />
                                )}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3} xl="auto">
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <PrimaryButton
                                sx={{
                                    width: "100%",
                                    [theme?.breakpoints.up("xl")]: {
                                        width: isAlertPage ? "110px" : "120px",
                                    },
                                }}
                                disabled={!isValid}
                                type="submit"
                            >
                                Apply
                            </PrimaryButton>
                        </Grid>
                        <Grid item xs={6}>
                            <SecondaryButton
                                sx={{
                                    width: "100%",
                                    [theme?.breakpoints.up("xl")]: {
                                        width: isAlertPage ? "110px" : "120px",
                                    },
                                }}
                                onClick={handleReset}
                            >
                                Reset
                            </SecondaryButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default AlertsFilter;
