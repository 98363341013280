/* eslint-disable react/no-unused-prop-types */
import {
    Box,
    CircularProgress,
    CircularProgressProps,
    IconButton,
    IconButtonProps,
    styled,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import {
    GridColumns,
    gridClasses,
    GridRenderCellParams,
    GridRowClassNameParams,
    GridRowParams,
    GridColumnHeaderTitle,
    DataGrid,
    GridSortModel,
} from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { capitalCase } from "capital-case";
import IotVisionToolTips from "components/common/IotVisionToolTips";
import DateView from "components/dates/DateView";
import { enAU } from "date-fns/locale";
import { CommonObjectLiteral } from "types/reports/interfaces";
import { OpenInNew } from "@mui/icons-material";
import {
    executeGetIncidentData,
    executeGetIncidentVideoFootageRequest,
} from "api/app-ungated-parking/ungated-parking-incidents-api";
import { IncidentVideoFootageLoading, fetchIncidentOptions } from "types/incident/incident";
import { useQueryParams } from "hooks/useQueryParams";
import { humanize } from "utils/common";

const StyledDataTable = styled(DataGrid)(({ theme }) => ({
    width: "100%",
    [`& .${gridClasses.cell}`]: {
        color: "#001B4F !important",
        borderBottom: "0px",
    },
    [theme.breakpoints.up("xl")]: {
        [`& .${gridClasses.cell}`]: {
            color: "#001B4F !important",
            marginRight: "12px",
            borderBottom: "0px",
        },
        [`& .${gridClasses.columnHeader}`]: {
            marginTop: "-6px",
            marginRight: "12px",
        },
    },
    [`& .${gridClasses.columnHeaders}`]: {
        borderBottom: "0px",
    },
    [`& .${gridClasses.footerContainer}`]: {
        borderTop: "0px",
    },
    [`& .${gridClasses.columnHeaderTitle}`]: {
        color: "#001B4F !important",
    },
    "& .MuiDataGrid-row:nth-child(odd)": {
        backgroundColor: "#f3f0f7",
    },
    "& .disableRow": {
        opacity: 0.4,
    },
}));

const StyledSortIcon = styled((iconButtonProps: IconButtonProps) => (
    <IconButton
        size="small"
        {...iconButtonProps}
        // eslint-disable-next-line react/no-children-prop
        children={
            <KeyboardArrowDownIcon
                sx={{
                    color: "#001B4F",
                }}
            />
        }
    />
))(() => ({}));

const StyledLoadingSpinner = styled((circularProgressProps: CircularProgressProps) => (
    <Box
        sx={{
            zIndex: 1000,
            position: "absolute",
            left: "50%",
            top: "50%",
        }}
    >
        <CircularProgress {...circularProgressProps} />
    </Box>
))(() => ({}));

interface IncidentTableProps {}

const IncidentTable: React.FC<IncidentTableProps> = () => {
    const theme = useTheme();
    const [searchParams, setUrlParams] = useSearchParams();
    let { page } = useQueryParams();
    if (!page) page = 1;
    const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
    const [loading, setLoading] = React.useState<boolean>(false);
    const [rows, setRows] = useState<CommonObjectLiteral[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
    const { pageSize } = useSelector((state) => state.pnrIncident);
    const { incidentId, fromTime, toTime, range, locationId } = Object.fromEntries([...searchParams]);
    const incidentsAbortControllerRef = useRef<AbortController | null>(null);
    const [videoLinkLoading, setVideoLinkLoading] = useState<IncidentVideoFootageLoading>({
        incidentId: [],
        loading: false,
    });

    async function getIncidentFootage(incidentId: string) {
        setVideoLinkLoading(prev => ({
            incidentId: [...prev.incidentId, incidentId],
            loading: true,
        }));
        try {
            const url = await executeGetIncidentVideoFootageRequest(incidentId);
            if (url) {
                window.open(url, "_blank", "noopener,noreferrer");
            }
        } catch (err) {
            console.error(err);
        } finally {
            setVideoLinkLoading(prev => {
                const removableIndex = prev.incidentId.indexOf(incidentId);
                prev.incidentId.splice(removableIndex, 1);
                return {
                    incidentId: prev.incidentId,
                    loading: false,
                }
            });
        }
    }

    const columns: GridColumns = [
        {
            field: "idInc",
            headerName: "Incident ID",
            align: "left",
            hideSortIcons: true,
            sortable: true,
            flex: 1,
            disableColumnMenu: true,
            renderHeader: (params) => {
                const { colDef } = params;
                return (
                    <>
                        <GridColumnHeaderTitle
                            label={colDef.headerName || colDef.field}
                            description={colDef.description}
                            columnWidth={colDef.width || isXlScreen ? 250 : 150}
                        />

                        <div className="MuiDataGrid-iconButtonContainer">
                            <StyledSortIcon />
                        </div>
                    </>
                );
            },
            renderCell: (params: GridRenderCellParams) => (
                <IotVisionToolTips title={params.row.idInc} arrow>
                    <span>{params.row.idInc}</span>
                </IotVisionToolTips>
            ),
        },
        {
            field: "locationId",
            headerName: "Location",
            align: "left",
            hideSortIcons: true,
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            renderHeader: (params) => {
                const { colDef } = params;
                return (
                    <GridColumnHeaderTitle
                        label={colDef.headerName || colDef.field}
                        description={colDef.description}
                        columnWidth={colDef.width || isXlScreen ? 250 : 150}
                    />
                );
            },
            renderCell: (params: GridRenderCellParams) => (
                <IotVisionToolTips title={params.row.location?.name} arrow>
                    <span>{params.row.location?.name}</span>
                </IotVisionToolTips>
            ),
        },
        {
            field: "siteId",
            headerName: "Site",
            align: "left",
            hideSortIcons: true,
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: (params) => {
                const { colDef } = params;
                return (
                    <GridColumnHeaderTitle
                        label={colDef.headerName || colDef.field}
                        description={colDef.description}
                        columnWidth={colDef.width || isXlScreen ? 250 : 150}
                    />
                );
            },
            renderCell: (params: GridRenderCellParams) => (
                <IotVisionToolTips title={params.row.siteId} arrow>
                    <span>{params.row.siteId}</span>
                </IotVisionToolTips>
            ),
        },
        {
            field: "incident",
            align: "left",
            headerName: "Incident Type",
            flex: 1,
            sortable: false,
            hideSortIcons: true,
            disableColumnMenu: true,
            renderHeader: (params) => {
                const { colDef } = params;
                return (
                    <GridColumnHeaderTitle
                        label={colDef.headerName || colDef.field}
                        description={colDef.description}
                        columnWidth={colDef.width || 120}
                    />
                );
            },
            renderCell: (params: GridRenderCellParams) => (
                <IotVisionToolTips title={humanize(params.row.incident)} arrow>
                    <span>{humanize(params.row.incident)}</span>
                </IotVisionToolTips>
            ),
        },
        {
            field: "dateTime",
            align: "left",
            headerName: "Date and Time",
            hideSortIcons: true,
            disableColumnMenu: true,
            flex: 1,
            sortable: true,
            renderHeader: (params) => {
                const { colDef } = params;
                return (
                    <>
                        <GridColumnHeaderTitle
                            label={colDef.headerName || colDef.field}
                            description={colDef.description}
                            columnWidth={colDef.width || isXlScreen ? 250 : 160}
                        />

                        <div className="MuiDataGrid-iconButtonContainer">
                            <StyledSortIcon />
                        </div>
                    </>
                );
            },
            renderCell: (params: GridRenderCellParams) => (
                <IotVisionToolTips
                    title={
                        <span>
                            <DateView dateFormat="Ppp" options={{ locale: enAU }}>
                                {params.row.dateTime}
                            </DateView>
                        </span>
                    }
                    arrow
                >
                    <span>
                        <DateView dateFormat="Ppp" options={{ locale: enAU }}>
                            {params.row.dateTime}
                        </DateView>
                    </span>
                </IotVisionToolTips>
            ),
        },
        {
            field: "video",
            align: "center",
            headerAlign: "center",
            headerName: "Video",
            flex: 1,
            hideSortIcons: true,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: (params) => {
                const { colDef } = params;
                return (
                    <GridColumnHeaderTitle
                        label={colDef.headerName || colDef.field}
                        description={colDef.description}
                        columnWidth={colDef.width || 120}
                    />
                );
            },
            renderCell: (params: GridRenderCellParams) => (
                <IconButton
                    color="primary"
                    aria-label="open snapshot"
                    component="label"
                    onClick={() => getIncidentFootage(params.row.id)}
                >
                    {videoLinkLoading.loading && videoLinkLoading.incidentId.includes(params.row.id) ? (
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <CircularProgress size={18} />
                        </Box>
                    ) : (
                        <OpenInNew fontSize="small" />
                    )}
                </IconButton>
            ),
        },
    ];

    const fetchData = async (pageSize: number) => {
        try {
            setLoading(true);
            incidentsAbortControllerRef.current = new AbortController();
            const { signal } = incidentsAbortControllerRef.current;

            let options: fetchIncidentOptions = {
                pageIndex: 1,
                pageSize,
            };
            options = addFilters(options);
            options = addSortQueryParams(options);
            const { incident, total } = await executeGetIncidentData(options, { signal });
            const rows = incident.map((inc: CommonObjectLiteral) => {
                return {
                    ...inc,
                    siteId: capitalCase(inc.siteId),
                };
            });
            setTotal(total);
            setRows(rows);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const addFilters = (options: fetchIncidentOptions): fetchIncidentOptions => {
        const filterData = Object.fromEntries([...searchParams]);
        if (filterData.locationId) options.locationId = filterData.locationId;
        if (filterData.incidentId) options.idInc = filterData.incidentId;
        if (filterData.fromTime) options.fromTime = filterData.fromTime;
        if (filterData.toTime) options.toTime = filterData.toTime;
        if (filterData.page) options.pageIndex = +filterData.page;
        return options;
    };

    const addSortQueryParams = (options: fetchIncidentOptions): fetchIncidentOptions => {
        if (sortModel.length > 0) {
            let fieldName: string;
            switch (sortModel[0].field) {
                case "incident":
                    fieldName = "idInc";
                    break;
                case "time":
                    fieldName = "dateTime";
                    break;
                default:
                    fieldName = sortModel[0].field;
            }
            options.sortColum = fieldName;
            options.sortOrder = sortModel[0].sort ?? "";
        }

        return options;
    };

    const onPageChange = (newPage: number) => {
        setUrlParams({
            ...Object.fromEntries([...searchParams]),
            page: (newPage + 1).toString(),
        });
    };

    const handleSortModelChange = (newModel: GridSortModel) => {
        setSortModel(newModel);
    };

    useEffect(() => {
        setLoading(true);
        fetchData(pageSize);
        return () => {
            incidentsAbortControllerRef.current?.abort();
        };
    }, [sortModel, incidentId, locationId, fromTime, toTime, range, page]);

    return (
        <StyledDataTable
            autoHeight
            loading={loading}
            isRowSelectable={(params: GridRowParams) => params.row.status.name !== "Closed"}
            getRowClassName={(params: GridRowClassNameParams) =>
                params.row.status.name === "Closed" ? "disableRow" : ""
            }
            paginationMode="server"
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            columns={columns}
            rows={rows}
            pageSize={pageSize}
            page={page ? +page - 1 : 1}
            rowCount={total}
            onPageChange={onPageChange}
            hideFooterPagination={false}
            disableSelectionOnClick
            components={{ LoadingOverlay: StyledLoadingSpinner }}
        />
    );
};

export default IncidentTable;
