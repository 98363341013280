import React from "react";
import Layout from "layouts/app-taxi-ranking";
import Region from "pages/app-taxi-ranking/region";
import Dashboard from "pages/app-taxi-ranking/dashboard";
import Location from "pages/app-taxi-ranking/location";
import { ProtectedLayout } from "layouts/common/general";
import WithScopes from "components/common/WithScopes";
import { SCOPES } from "enums/scopes.enum";

const root = {
    path: "home",
    element: (
        <ProtectedLayout>
            <Layout />
        </ProtectedLayout>
    ),
    children: [
        {
            path: "",
            element: (
                <WithScopes filterScopes={[SCOPES.VIEW_EVENTS_PAGE]}>
                    <Dashboard />
                </WithScopes>
            ),
        },
        {
            path: ":locationId",
            element: (
                <WithScopes filterScopes={[SCOPES.VIEW_EVENTS_PAGE]}>
                    <Region />
                </WithScopes>
            ),
        },
        {
            path: "location/:locationId",
            element: (
                <WithScopes filterScopes={[SCOPES.VIEW_EVENTS_PAGE]}>
                    <Location />
                </WithScopes>
            ),
        },
    ],
    replace: true,
};

export default root;
