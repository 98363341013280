// APP Codes
export const TaxiRankingAppCode = "app-taxi-ranking";
export const CycleWaysAppCode = "app-cycle-ways";
export const AQDemoAppCode = "aq-demo";
export const DEPTAQDemoAppCode = "doe-aq-demo";
export const ParkAndRideAppCode = "park-and-ride";
export const UngatedParkingAppCode = "demo-ungated-parking";

// Route Prefixes
export const AppTitlePrefix = "Transport for NSW";
export const AppAqTitlePrefix = "AQ Demo";
export const AppDeptAqTitlePrefix = "DoE AQ Demo";
export const AppOversizedVehicles = "Oversized Vehicles";
export const AppParkAndRidePrefix = "Park and Ride";
export const AppUngatedParkingPrefix = "Demo - Ungated Parking";

// Theme names
export const DefaultThemeName = "defaultTheme";
export const AQDemoThemeName = "aqDemoTheme";

// Applications
export const APPLICATION_CYCLEWAYS_BIKE_RACKS = "bike-racks";
