import React from "react";
import ParkAndRideLayout from "layouts/app-ungated-parking";
import { ProtectedLayout } from "layouts/common/general";
import Incidents from "./incident/Incidents";

const root = {
    path: "incidents",
    element: (
        <ProtectedLayout>
            <ParkAndRideLayout />
        </ProtectedLayout>
    ),
    children: [
        { path: "", element: <Incidents /> },
    ],
    replace: true,
};

export default root;
