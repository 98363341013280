/* eslint-disable class-methods-use-this */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import videojs from "video.js";

import Player from "video.js/dist/types/player";
import { CommonObjectLiteral } from "types";
import { Observer } from "../observer";
import { DownloadButton } from "./download-button";

export interface DownloadPluginOptions {
    isActiveDownload: boolean;
    callback: null | (() => void);
}

const Plugin = videojs.getPlugin("plugin");

const DEFAULT_OPTIONS = {
    isActiveDownload: false,
    callback: null,
};

class SnapshotDownloadPlugin extends Plugin {
    playerEl: HTMLElement;

    state: DownloadPluginOptions;

    observer: Observer;

    static defaultState: CommonObjectLiteral;

    constructor(player: Player, options: DownloadPluginOptions = {}) {
        super(player);
        this.playerEl = player.el() as HTMLElement;
        this.listeners = {
            click: () => {},
        };
        this.playerEl.style.overflow = "hidden";
        this.state = Object.assign(DEFAULT_OPTIONS, options);
        if (this.state.isActiveDownload) {
            const downloadButton = player
                .getChild("ControlBar")
                ?.addChild("DownloadButton", { plugin: this, state: this.state });
            downloadButton.controlText("Capture Screen");
        }
        this.observer = Observer.getInstance();
    }

    download() {
        if (this.state.isActiveDownload && this.state.callback) {
            this.state.callback();
        }
    }
}

SnapshotDownloadPlugin.defaultState = {};

videojs.registerComponent("downloadButton", DownloadButton);
videojs.registerPlugin("snapshotDownloadPlugin", SnapshotDownloadPlugin);

export default SnapshotDownloadPlugin;
