export const COLOURS = [
    "rgba(50, 209, 219, 1)",
    "rgba(198, 32, 52, 1)",
    "rgba(107, 67, 157, 1)",
    "rgba(64, 249, 19, 0.8)",
    "rgba(248, 233, 43, 0.8)",
    "rgba(248, 43, 49, 0.8)",
    "rgba(187, 20, 246, 0.8)",
];

export const ColorsSemiCircleIndicator = {
    start: "#009E11",
    mid: "#009E11",
    end: "#F8D000",
};

export const ColorsSemiCircleBackground = {
    start: "#DDF0D1",
    mid: "#FDB600",
    end: "#FBCECC",
};
