/* eslint-disable no-unneeded-ternary */
/* eslint @typescript-eslint/no-explicit-any: ["warn"] */
import { Card, Grid, IconButton } from "@mui/material";
import { Chart, ChartData, ChartOptions } from "chart.js";
import {
    REPORT_FONT_FAMILY,
    REPORT_LEGEND_FONT_SIZE,
    REPORT_TITLE_FONT_SIZE,
    REPORT_TOOLTIP_BODY_FONT_SIZE,
} from "constants/report.constants";
import { useState } from "react";
import { Pie } from "react-chartjs-2";
import { CgArrowsExpandRight } from "react-icons/cg";
import FullScreenDialog from "components/dialogs/FullScreenDialog";
import ChartDataLabels from "chartjs-plugin-datalabels";

// The minimal percentage in which a label could be displayed within the graph.
const MINIMAL_LABEL_PERCENTAGE = 5;
interface IPieChartProps {
    title: string;
    data: ChartData<"pie">;
    options?: ChartOptions<"pie">;
    chartCardHeight?: number;
    chartCardWidth?: number;
    isExpandable?: boolean;
}

const PieChart = (pieChartProps: IPieChartProps) => {
    Chart.register(ChartDataLabels);
    Chart.defaults.plugins.datalabels!.display = false;
    const { data, title, chartCardHeight, chartCardWidth, isExpandable } = pieChartProps;
    const [displayExpandedView, setDisplayExpandedView] = useState<boolean>(false);
    let { options } = pieChartProps;
    if (!options) {
        options = {
            plugins: {
                title: {
                    display: isExpandable ? false : true,
                    text: title,
                    font: {
                        family: REPORT_FONT_FAMILY,
                        size: REPORT_TITLE_FONT_SIZE,
                    },
                },
                legend: {
                    labels: {
                        font: {
                            family: REPORT_FONT_FAMILY,
                            size: REPORT_LEGEND_FONT_SIZE,
                        },
                    },
                },
                tooltip: {
                    bodyFont: {
                        family: REPORT_FONT_FAMILY,
                        size: REPORT_TOOLTIP_BODY_FONT_SIZE,
                    },
                    callbacks: {
                        label: (ctx) => {
                            const {dataIndex} = ctx
                            let label = "";
                            label = Number(ctx.dataset.data[dataIndex]).toFixed(2);
                            return label;
                        },
                    },
                },
                datalabels: {
                    formatter: (value: any, ctx: any) => {
                        let sum = 0;
                        const dataArr = ctx.chart?.data?.datasets[0]?.data;
                        const isAllZero = dataArr.every((item: number) => item === 0);
                        if(isAllZero){
                            return ""
                        }
                        dataArr.forEach((data: number) => {
                            sum += data;
                        });
                        const percentage = `${((value * 100) / sum).toFixed(2)}%`;
                        return percentage;
                    },
                    font: {
                        family: REPORT_FONT_FAMILY,
                        weight: "bold",
                    },
                    display: true,
                    color: "black",
                    clamp: false,
                    /** Anchors the label out of the chart where the area is not large enough
                     * to display the label within the graph. */
                    anchor: (context) => {
                        const { dataIndex, dataset } = context;
                        if (Number(dataset.data[dataIndex]) < MINIMAL_LABEL_PERCENTAGE) {
                            return "end";
                        }
                        return "center";
                    },
                },
            },
            maintainAspectRatio: false,
            layout: {
                padding: {
                    bottom: 10,
                },
            },
        };
    }
    return (
        <Card
            className="graph-card"
            elevation={0}
            style={{
                height: chartCardHeight,
                width: chartCardWidth,
                paddingBottom: "50px",
            }}
        >
            {isExpandable ? (
                <>
                    <FullScreenDialog
                        modalShow={displayExpandedView}
                        onClose={() => setDisplayExpandedView(false)}
                        childComponent={
                            <>
                                <Pie options={options} data={data} />
                            </>
                        }
                    />
                    <Grid container justifyContent="center" alignItems="center" direction="row">
                        <Grid item xs={11} sm={11} md={11} lg={11}>
                            <div className="graph-title">{title}</div>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} textAlign="right">
                            <IconButton
                                className="expand-btn-sub"
                                onClick={() => setDisplayExpandedView(true)}
                                disableRipple
                            >
                                <CgArrowsExpandRight />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Pie options={options} data={data} />
                </>
            ) : (
                <Pie data={data} options={options} />
            )}
        </Card>
    );
};

export default PieChart;
