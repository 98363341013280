import { useEffect, useState } from "react";
import SectionHeading from "components/headings/SectionHeading";
import { AiFillAppstore } from "react-icons/ai";
import { Box, Grid } from "@mui/material";
import LocationDetailCard from "components/cards/LocationDetailCard";
import IncidentsStatisticsCards from "features/app-taxi-ranking/statistics/IncidentsStatisticsCards";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { LoadingWrapper } from "components/wrappers/LoadingWrapper";
import { useNavigate } from "react-router";
import HeaderDatePicker from "features/common/date-header/HeaderDatePicker";
import { Location } from "reducers/newLocation.reducer";
import { navigateTo404Page } from "utils/navigation";
import IncidentsTable from "../incidents/IncidentsTable";

const IncidentsLocationPage = (): JSX.Element => {
    const [selectedLocation, setSelectedLocation] = useState<Location>();
    const [parent, setParent] = useState<Location>();
    const { locationId } = useParams();
    const { locations } = useSelector((state) => state.newLocation);
    const navigate = useNavigate();
    const { appCode } = useSelector((state) => state.auth);

    useEffect(() => {
        const selected = locations.find((location) => location.id === locationId);
        if (!selected) {
            navigateTo404Page(navigate, appCode);
        } else {
            setSelectedLocation(selected);
            const selectedParent = locations.find((location) => location.id === selected?.parentId);
            setParent(selectedParent);
        }
    }, [locationId, locations]);

    return (
        <>
            <Grid container spacing={1}>
                {selectedLocation && parent && (
                    <Grid item xs={12}>
                        <LocationDetailCard
                            location={selectedLocation.name}
                            zone={parent.name}
                            latitude={selectedLocation.latitude}
                            longitude={selectedLocation.longitude}
                            enableLiveFootageView={selectedLocation?.metadata?.canViewMerakiFootage}
                        />
                    </Grid>
                )}
                <Grid item xs={12} sm={12} md={8} lg={8} xl={9}>
                    <SectionHeading heading="Incident Summary" icon={<AiFillAppstore className="icon" />} />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                    <div style={{ maxWidth: "300px", marginLeft: "auto" }}>
                        <HeaderDatePicker />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <IncidentsStatisticsCards />
                </Grid>
                <Grid item xs={12}>
                    <IncidentsTable />
                </Grid>
            </Grid>

            {locations.length === 0 && (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                    <LoadingWrapper isLoading />
                </Box>
            )}
        </>
    );
};

export default IncidentsLocationPage;
