import ExecuteAsync from "utils/api-client";
import { ApiEndpoints } from "configs";
import { RequestOptions } from "axios-types";
import createQueryParams from "utils/create-query-params";
import {
    IDownloadReportRequest,
    IDownloadReportResponse,
    IGenerateReportResponse,
    ICreateReportSchedule,
} from "types/reports/interfaces";
/* eslint @typescript-eslint/no-explicit-any: ["off"] */
export const executeGetReportAndReportData = (configs: any, options?: RequestOptions) => {
    const { reportId, ...params} = configs;
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/retrieval-web-api/reports/${reportId}${
            Object.keys(configs).length ? createQueryParams({...(params || {})}) : ""
        }`,
        signal: options?.signal,
    });
};

export const executeGetReportFile = (config: IDownloadReportRequest, options?: RequestOptions) => {
    const { reportId, format } = config;
    return ExecuteAsync<any, IDownloadReportResponse>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/retrieval-web-api/reports/${reportId}/file/${format}`,
        signal: options?.signal,
    });
};

export const executeGenerateReportFile = (config: IDownloadReportRequest, options?: RequestOptions) => {
    const { reportId, format } = config;
    return ExecuteAsync<any, IGenerateReportResponse>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "POST",
        data: {},
        url: `/retrieval-web-api/reports/${reportId}/file/${format}`,
        signal: options?.signal,
    });
};

export const executeCreateReport = <T>(data: ICreateReportSchedule<T>) => {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "POST",
        data,
        url: "/retrieval-web-api/schedules",
    });
};
