interface LegendBoxStyleObject {
    boxHight?: string;
    boxWidth?: string;
}

interface LegendTextStyleObject {
    textFontSize?: string;
    textFontWeight?: string;
    textFontColor?: string;
    textLineHeight?: string;
}
export const getHtmlLegendPlugin = (
    legendBoxStyleObject?: LegendBoxStyleObject,
    legendTextStyleObject?: LegendTextStyleObject,
) => {
    const { boxHight, boxWidth } = legendBoxStyleObject || {};
    const { textFontColor, textFontSize, textFontWeight, textLineHeight } = legendTextStyleObject || {};

    /* eslint @typescript-eslint/no-explicit-any: ["off"] */
    const getOrCreateLegendList = (chart: any, id: string) => {
        const legendContainer = document.getElementById(id);
        let listContainer = legendContainer?.querySelector("ul");
        if (!listContainer) {
            listContainer = document.createElement("ul");
            listContainer.style.marginLeft = window.screen.width >= 1920 ? "70px" : "0px";
            listContainer.style.padding = "0px";
            legendContainer?.appendChild(listContainer);
        }
        return listContainer;
    };

    return {
        id: "htmlLegend",
        /* eslint @typescript-eslint/no-explicit-any: ["off"] */
        afterUpdate(chart: any, args: any, options: any) {
            const ul = getOrCreateLegendList(chart, options.containerID);
            // Remove old legend items
            while (ul.firstChild) {
                ul.firstChild.remove();
            }

            // Reuse the built-in legendItems generator
            const items = chart.options.plugins.legend.labels.generateLabels(chart);

            /* eslint @typescript-eslint/no-explicit-any: ["off"] */
            items.forEach((item: any) => {
                const li = document.createElement("li");
                li.style.alignItems = "center";
                li.style.cursor = "pointer";
                li.style.display = "block";

                // Color box
                const boxSpan = document.createElement("span");
                boxSpan.style.background = item.strokeStyle;
                boxSpan.style.borderColor = item.strokeStyle;
                boxSpan.style.borderWidth = `${item.lineWidth}px`;
                boxSpan.style.display = "inline-block";
                boxSpan.style.height = boxHight || "4px";
                boxSpan.style.marginRight = "10px";
                boxSpan.style.width = boxWidth || "25px";

                // Text
                const textContainer = document.createElement("p");
                textContainer.style.color = textFontColor || "#001B4F";
                textContainer.style.fontSize = textFontSize || "12px";
                textContainer.style.margin = "0px";
                textContainer.style.fontWeight = textFontWeight || "400";
                textContainer.style.padding = "0px";
                textContainer.style.lineHeight = textLineHeight || "18px";
                textContainer.style.textDecoration = item.hidden ? "line-through" : "";

                const text = document.createTextNode(item.text);
                textContainer.appendChild(text);

                // Breaker
                const br = document.createElement("br");

                li.appendChild(boxSpan);
                li.appendChild(br);
                li.appendChild(textContainer);
                li.onclick = () => {
                    chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                    chart.update();
                };
                ul.appendChild(li);
            });
        },
    };
};

export default getHtmlLegendPlugin;
