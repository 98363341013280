import React from "react";
import CycleWaysLayout from "layouts/app-cycle-ways";
import Dashboard from "pages/app-cycle-ways/dashboard";
import Location from "pages/app-cycle-ways/location";
import { ProtectedLayout } from "layouts/common/general";

const root = {
    path: "home",
    element: (
        <ProtectedLayout>
            <CycleWaysLayout />
        </ProtectedLayout>
    ),
    children: [
        { path: "", element: <Dashboard /> },
        { path: ":locationId", element: <Dashboard /> },
        { path: "location/:locationId", element: <Location /> },
    ],
    replace: true,
};

export default root;
