import ExecuteAsync from "utils/api-client";
import { ApiEndpoints } from "configs";
import { createQueryParams } from "utils/create-query-params";
import { RequestOptions } from "axios-types";
import { AssigneeObj, IByLocation } from "types";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
export const executeGetIncidentData = (data?: any, options?: RequestOptions) => {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/incidents/incident/filter${createQueryParams(data)}`,
        signal: options?.signal,
    });
};

export const executeGetUsersRequest = (options?: RequestOptions) => {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: "/users",
        signal: options?.signal,
    });
};

export const getIncidentFilterDropDownValues = (options?: RequestOptions) => {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: "/incidents/incident/filtertypes",
        signal: options?.signal,
    });
};

export function executeGetIncidentByTypeRequest(data?: any, options?: RequestOptions) {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/incidents/incident/by_type${createQueryParams(data)}`,
        signal: options?.signal,
    });
}

export function executeGetIncidentByTaxiTypeRequest(data?: any, options?: RequestOptions) {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/incidents/incident/by_taxi_type${createQueryParams(data)}`,
        signal: options?.signal,
    });
}

export function executeGetIncidentByLgaRequest(data?: any, options?: RequestOptions) {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/incidents/incident/by_lga${createQueryParams(data)}`,
        signal: options?.signal,
    });
}

export function executeGetIncidentByIdRequest(incidentId: string, options?: RequestOptions) {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/incidents/incident/${incidentId}`,
        signal: options?.signal,
    });
}

export function executeGetIncidentVideoFootageRequest(incidentId: string, options?: RequestOptions) {
    return ExecuteAsync<any, string>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/incidents/incident/${incidentId}/video-link`,
        signal: options?.signal,
    });
}

export function executeUpdateIncidentStatusRequest(incidentId: string, data?: any, options?: RequestOptions) {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "PUT",
        url: `/incidents/incident/${incidentId}/status${createQueryParams(data)}`,
        signal: options?.signal,
    });
}

export function executeCreateIncidentCommentRequest(incidentId: string, data: any, options?: RequestOptions) {
    return ExecuteAsync({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "POST",
        url: `/incidents/incident/${incidentId}/comment`,
        data,
        signal: options?.signal,
    });
}

export function executeUpdateIncidentRegoRequest(incidentId: string, data?: any, options?: RequestOptions) {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "PUT",
        url: `/incidents/incident/${incidentId}`,
        data,
        signal: options?.signal,
    });
}

export function executeAssignUserToIncidentRequest(data?: AssigneeObj, options?: RequestOptions) {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "PUT",
        url: "/incidents/incident/assign_user",
        data,
        signal: options?.signal,
    });
}

export function executeGetCommentsByIncidentIdRequest(incidentId: string, options?: RequestOptions) {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/incidents/incident/${incidentId}/incidentComments`,
        signal: options?.signal,
    });
}

export async function executeGetIncidentsByLocationsTaxiRequest(
    data?: any,
    options?: RequestOptions,
): Promise<IByLocation[]> {
    const response = await ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/incidents/incident/by_location${createQueryParams(data)}`,
        signal: options?.signal,
    });
    return response.map((item: any) => {
        return {
            count: item.count,
            locationGroupIds: item.locationgroupids,
            locationId: item.locationid,
        };
    });
}
