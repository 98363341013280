import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { Classification } from "enums/room.enums";
import { AQSensorData } from "utils/threshold-data-finder/types";
import { getThresholdData } from "utils/threshold-data-finder";
import { ThresHoldConfigDataSetType } from "utils/threshold-data-finder/enums";
import CardLoader, { CardLoaderData } from "./CardLoader";

interface RoomStatisticsProps {
    selectedRoom: string | undefined;
    setLastUpdated: Dispatch<SetStateAction<Date | null>>;
    setRoomAQStatus: Dispatch<SetStateAction<AQSensorData | null>>;
}

const ROOM_AQI_VALID_KEYS: Classification[] = [Classification.PM100AQI, Classification.PM10AQI, Classification.PM25AQI];

export interface AqiObtainedStatusItem {
    key: Classification;
    status: boolean;
    value: number;
}

export interface CardKeyItem {
    key: Classification;
    subClassification?: Classification;
}

const displayCards: CardKeyItem[] = [
    {
        key: Classification.PM10AQI,
        subClassification: Classification.PM10,
    },
    {
        key: Classification.PM25AQI,
        subClassification: Classification.PM25,
    },
    {
        key: Classification.PM100AQI,
        subClassification: Classification.PM100,
    },
    {
        key: Classification.VOCAQI,
        subClassification: Classification.VOCPPM,
    },
    {
        key: Classification.TEMP,
    },
    {
        key: Classification.RH,
    },
    {
        key: Classification.ATM,
    },
    {
        key: Classification.CO2,
    },
];

const AQI_OBTAINED_STATUS: AqiObtainedStatusItem[] = [];

const RoomStatisticsCards: React.FC<RoomStatisticsProps> = ({ selectedRoom, setRoomAQStatus, ...rest }) => {
    const navigate = useNavigate();
    const { appCode } = useSelector((state) => state.auth);
    const [aqiObtainedStatus, setAqiObtainedStatus] = useState<AqiObtainedStatusItem[]>(AQI_OBTAINED_STATUS);

    const handleAQIDataObtainedStatus = () => {
        const pm10ThresholdData: AQSensorData | undefined = getThresholdData(
            ThresHoldConfigDataSetType.AQ_SENSOR_THRESHOLD_CONFIGS,
            Classification.PM10AQI,
            aqiObtainedStatus?.find((e) => e.key === Classification?.PM10AQI)?.value,
        );
        const pm25ThresholdData: AQSensorData | undefined = getThresholdData(
            ThresHoldConfigDataSetType.AQ_SENSOR_THRESHOLD_CONFIGS,
            Classification.PM25AQI,
            aqiObtainedStatus?.find((e) => e.key === Classification?.PM25AQI)?.value,
        );
        const pm100ThresholdData: AQSensorData | undefined = getThresholdData(
            ThresHoldConfigDataSetType.AQ_SENSOR_THRESHOLD_CONFIGS,
            Classification.PM100AQI,
            aqiObtainedStatus?.find((e) => e.key === Classification?.PM100AQI)?.value,
        );

        const pmAQIs = [pm10ThresholdData, pm25ThresholdData, pm100ThresholdData];
        const roomAQI = pmAQIs?.reduce((prevValue, currentValue) =>
            (prevValue?.value ?? 0) < (currentValue?.value ?? 0) ? currentValue : prevValue,
        );

        setRoomAQStatus(roomAQI || null);
    };

    useEffect(() => {
        if (!selectedRoom) {
            navigate(`/${appCode}/page-not-found`, { replace: true });
        }
        setRoomAQStatus(null);
        setAqiObtainedStatus([]);
    }, [selectedRoom]);

    useEffect(() => {
        const obtainedKeys: Classification[] = [];
        aqiObtainedStatus.forEach((o) => obtainedKeys.push(o.key));

        // eslint-disable-next-line camelcase
        if (ROOM_AQI_VALID_KEYS.every((valid_key) => obtainedKeys.includes(valid_key))) {
            handleAQIDataObtainedStatus();
        }
    }, [aqiObtainedStatus]);

    const handleCardDataChange = (data: CardLoaderData) => {
        const { innerCircleValue, innerCircleLabel, key } = data.cardData;
        if (data.cardData.cardSubHeading && innerCircleLabel === "AQI") {
            setAqiObtainedStatus([
                ...aqiObtainedStatus,
                {
                    // eslint-disable-next-line radix
                    value: parseInt(innerCircleValue),
                    key: key as Classification,
                    status: true,
                },
            ]);
        }
    };

    return (
        <Grid container spacing={2} columnSpacing="12px" paddingTop={2}>
            {displayCards.map((card, index) => (
                <CardLoader
                    classification={card.key}
                    subClassification={card.subClassification}
                    roomId={selectedRoom}
                    onUpdated={handleCardDataChange}
                    key={index}
                    {...rest}
                />
            ))}
        </Grid>
    );
};

export default RoomStatisticsCards;
