import { Box } from "@mui/material";
import useSize from "hooks/useSize";
import React, { useEffect, useRef } from "react";
import createUniqueElementId from "utils/create-unique-id";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
/* eslint no-unsafe-optional-chaining: ["warn"] */
export interface SemiCircleMeterProps {
    value: number;
    max: number;
    backgroundColor: ColorObj;
    indicatorColor: ColorObj;
    innerTextValue: string;
    innerValueText: string;
    innerValueText2: string;
}
export interface ColorObj {
    start: string;
    mid: string;
    end: string;
}

const SemiCircleMeter: React.FC<SemiCircleMeterProps> = ({
    value,
    max,
    innerTextValue,
    innerValueText,
    innerValueText2,
    backgroundColor,
    indicatorColor,
}) => {
    const uniqueCanvasId = createUniqueElementId();
    const idRef = useRef<string>(uniqueCanvasId);
    const target = useRef(null);
    const size = useSize(target);

    const safeMax = max === 0 ? Number.MIN_VALUE : max;

    function drawIndicator(xCenter: number, yCenter: number, radius: number, ctx: any) {
        if (ctx) {
            const partLengthIndicator = (Math.PI * value) / safeMax;
            const start = Math.PI;
            let gradient = null;
            const xStart = xCenter + Math.cos(start) * radius;
            const xEnd = xCenter + Math.cos(start + partLengthIndicator) * radius;
            const yStart = yCenter + Math.sin(start) * radius;
            const yEnd = yCenter + Math.sin(start + partLengthIndicator) * radius;

            gradient = ctx.createLinearGradient(xStart, yStart, xEnd, yEnd);
            gradient.addColorStop(0, indicatorColor.start);
            gradient.addColorStop(0.5, indicatorColor.mid);
            gradient.addColorStop(1.0, indicatorColor.end);

            ctx.beginPath();
            ctx.lineCap = "round";
            ctx.strokeStyle = gradient;
            ctx.arc(xCenter, yCenter, radius, start, start + partLengthIndicator);
            ctx.lineWidth = 18;
            ctx.stroke();
            ctx.closePath();
        }
    }

    function drawBackground(xCenter: number, yCenter: number, radius: number, ctx: any) {
        if (ctx) {
            const partLengthIndicator = (Math.PI * value) / safeMax;
            const start = Math.PI + partLengthIndicator;
            const partLengthBackground = Math.PI - partLengthIndicator;

            let backgroundGradient = null;
            const xStart = xCenter + Math.cos(start) * radius;
            const xEnd = xCenter + Math.cos(start + partLengthBackground) * radius;
            const yStart = yCenter + Math.sin(start) * radius;
            const yEnd = yCenter + Math.sin(start + partLengthBackground) * radius;

            backgroundGradient = ctx.createLinearGradient(xStart, yStart, xEnd, yEnd);
            backgroundGradient.addColorStop(0, backgroundColor.start);
            backgroundGradient.addColorStop(1.0, backgroundColor.end);

            ctx.beginPath();
            ctx.lineCap = "round";
            ctx.strokeStyle = backgroundGradient;
            ctx.arc(xCenter, yCenter, radius, start, start + partLengthBackground);
            ctx.lineWidth = 18;
            ctx.stroke();
            ctx.closePath();
        }
    }

    function drawText(xCoordinate: number, ctx: any) {
        if (ctx) {
            ctx.textAlign = "center";
            ctx.fillStyle = "#001B4F";
            ctx.font = "bold 30px sans-serif";
            ctx.fillText(innerValueText, xCoordinate, 100);
            ctx.font = "14px sans-serif";
            ctx.fillText(innerTextValue, xCoordinate, 121);
            ctx.fillText(innerValueText2, xCoordinate, 143);
        }
    }

    useEffect(() => {
        const canvas = document.getElementById(idRef.current) as HTMLCanvasElement;
        const ctx = canvas.getContext("2d");
        if (ctx) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
        if (size) {
            drawBackground(146, size?.height + 8, size?.width / 2, ctx);
            drawIndicator(146, size?.height + 8, size?.width / 2, ctx);
            drawText(146, ctx);
        }
        return () => {
            if (ctx) {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
            }
        };
    }, [value, max, innerTextValue, innerValueText, innerValueText2, backgroundColor, indicatorColor, size]);

    return (
        <Box
            id={`boxId${idRef.current}`}
            ref={target}
            style={{ width: "100%", height: "100%" }}
            display="flex"
            justifyContent="center"
            paddingTop="10px"
        >
            <canvas id={idRef.current} style={{ width: "100%", height: "100%" }} />
        </Box>
    );
};

export default SemiCircleMeter;
