import { Box } from "@mui/material";
import useSize from "hooks/useSize";
import React, { useEffect, useRef } from "react";
import createUniqueElementId from "utils/create-unique-id";

/* eslint no-unsafe-optional-chaining: ["warn"] */
export interface CircleMeterProps {
    value: number;
    max: number;
    backgroundColor: ColorObj;
    indicatorColor: ColorObj;
    innerTextCircle: string;
    innerValueTextCircle: string;
}
export interface ColorObj {
    start: string;
    mid: string;
    end: string;
}

const CircleMeter: React.FC<CircleMeterProps> = ({
    value,
    max,
    innerTextCircle,
    innerValueTextCircle,
    indicatorColor,
    backgroundColor,
}) => {
    const uniqueElCanvasId = createUniqueElementId();
    const idRef = useRef<string>(uniqueElCanvasId);
    const target = useRef(null);
    const size = useSize(target);

    function drawIndicator(xCenter: number, yCenter: number, radius: number) {
        const canvas = document.getElementById(idRef.current) as HTMLCanvasElement;
        let ctx = null;
        if (canvas) {
            ctx = canvas.getContext("2d");
        }

        if (ctx) {
            const partLengthIndicator = (2 * Math.PI * value) / max;
            const start = Math.PI;
            let gradient = null;
            const xStart = xCenter + Math.cos(start) * radius;
            const xEnd = xCenter + Math.cos(start + partLengthIndicator) * radius;
            const yStart = yCenter + Math.sin(start) * radius;
            const yEnd = yCenter + Math.sin(start + partLengthIndicator) * radius;

            gradient = ctx.createLinearGradient(xStart, yStart, xEnd, yEnd);
            gradient.addColorStop(0, indicatorColor.start);
            gradient.addColorStop(1.0, indicatorColor.end);

            ctx.beginPath();
            ctx.lineCap = "round";
            ctx.strokeStyle = gradient;
            ctx.arc(xCenter, yCenter, radius, start, start + partLengthIndicator);
            ctx.lineWidth = 15;
            ctx.stroke();
            ctx.closePath();
        }
    }

    function drawBackground(xCenter: number, yCenter: number, radius: number) {
        const canvas = document.getElementById(idRef.current) as HTMLCanvasElement;
        let ctx = null;
        if (canvas) {
            ctx = canvas.getContext("2d");
        }
        if (ctx) {
            const partLengthIndicator = (2 * Math.PI * value) / max;
            const start = Math.PI + partLengthIndicator;
            const partLengthBackground = 2 * Math.PI - partLengthIndicator;

            ctx.beginPath();
            ctx.lineCap = "butt";
            ctx.strokeStyle = backgroundColor.end;
            ctx.arc(xCenter, yCenter, radius, start, start + partLengthBackground);
            ctx.lineWidth = 15;
            ctx.stroke();
            ctx.closePath();
        }
    }

    function drawText(xCoordinate: number) {
        const canvas = document.getElementById(idRef.current) as HTMLCanvasElement;
        let ctx = null;
        if (canvas) {
            ctx = canvas.getContext("2d");
        }
        if (ctx) {
            ctx.textAlign = "center";
            ctx.fillStyle = "#001B4F";
            ctx.font = "15px Poppins";
            ctx.fillText(innerTextCircle, xCoordinate, 65);
            ctx.font = "bold 20px Poppins";
            ctx.fillText(innerValueTextCircle, xCoordinate, 95);
        }
    }

    useEffect(() => {
        const canvas = document.getElementById(idRef.current) as HTMLCanvasElement;
        const ctx = canvas.getContext("2d");
        if (ctx) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
        if (size) {
            drawBackground(size?.width / 2, size?.height / 2, 62);
            drawIndicator(size?.width / 2, size?.height / 2, 62);
            drawText(size?.width / 2);
        }
        return () => {
            const canvasEl = document.getElementById(idRef.current) as HTMLCanvasElement;
            if (canvasEl) {
                const ctxEl = canvas.getContext("2d");
                if (ctxEl) {
                    ctxEl.clearRect(0, 0, canvas.width, canvas.height);
                }
            }
        };
    }, [value, max, innerTextCircle, innerValueTextCircle, indicatorColor, backgroundColor, size]);

    return (
        <Box id={`boxId${idRef.current}`} ref={target} sx={{ minHeight: "157px", marginTop: "-10px" }}>
            <canvas id={idRef.current} width={size?.width} />
        </Box>
    );
};

export default CircleMeter;
