import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
    interface Palette {
        disableColor: Palette["primary"];
    }
    interface PaletteColor {
        lightOverRide?: string;
    }
    interface SimplePaletteColorOptions {
        lightOverRide?: string;
    }

    interface PaletteOptions {
        disableColor?: PaletteOptions["primary"];
    }
}

const defaultTheme = createTheme({
    palette: {
        disableColor: {
            lightOverRide: "rgb(137,137,137) !important",
            main: "#d4d4d4",
            light: "rgb(137,137,137)",
        },

        primary: {
            main: "#6b439d",
        },
        secondary: {
            main: "#7a7a7a",
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 800,
            md: 1024,
            lg: 1366,
            xl: 1920,
        },
    },
});

export { defaultTheme };
