import { Card, CardContent, CardHeader, Divider, Typography } from "@mui/material";
import BarChart from "components/charts/BarChart";
import CircleMeter, { ColorObj } from "components/meters/circle-meter/CircleMeter";
import { LoadingWrapper } from "components/wrappers/LoadingWrapper";
import { styled } from "@mui/material/styles";
import moment from "moment";

interface HomeCardProps {
    colorsCircleIndicator: ColorObj;
    colorsCircleBackground: ColorObj;
    cardHeading: string;
    cardSubHeading: string;
    circleMeterValue: number;
    circleMeterMaxValue: number;
    innerCircleLabel: string;
    innerCircleValue: string;
    barChartData: number[];
    barChartLabels: string[];
    barChartColor: string;
    isLoading?: boolean;
    isExpired?: boolean;
    lastUpdated?: Date;
}

const OutDatedHeading = styled("div")(() => ({
    fontFamily: "Poppins",
    fontWeight: 600,
    fontStyle: "normal",
    fontSize: "20px",
    lineHeight: "30px",
    position: "absolute",
    zIndex: 200,
    top: "20%",
    color: "#DF0000",
    width: "100%",
    textAlign: "center",
}));

const OutDatedSubHeading = styled("div")(() => ({
    fontFamily: "Poppins",
    fontWeight: 600,
    fontStyle: "normal",
    fontSize: "10px",
    lineHeight: "30px",
    position: "absolute",
    zIndex: 200,
    top: "28%",
    color: "#DF0000",
    left: "0",
    width: "100%",
    textAlign: "center",
}));

const ClassroomCard = ({
    colorsCircleIndicator,
    colorsCircleBackground,
    cardHeading,
    cardSubHeading,
    circleMeterValue,
    circleMeterMaxValue,
    innerCircleLabel,
    innerCircleValue,
    barChartData,
    barChartLabels,
    barChartColor,
    isLoading = true,
    isExpired = false,
    lastUpdated,
}: HomeCardProps) => {
    return (
        <Card
            sx={{
                borderRadius: "15px",
                height: 275,
            }}
            elevation={0}
            style={
                isExpired
                    ? {
                          backgroundColor: isExpired ? "#3D3C3C50" : "transparent",
                          zIndex: 100,
                          position: "relative",
                          left: 0,
                      }
                    : {}
            }
        >
            {isExpired && (
                <>
                    <OutDatedHeading>Offline</OutDatedHeading>
                    <OutDatedSubHeading>
                        {lastUpdated
                            ? `Last Updated ${moment(lastUpdated).fromNow()}`
                            : "Unable to fetch last updated time."}
                    </OutDatedSubHeading>
                </>
            )}
            <CardHeader
                sx={{ height: "51px" }}
                title={
                    <Typography
                        fontSize={18}
                        fontWeight="600"
                        color="#001B4F"
                        fontFamily="Poppins"
                        paddingLeft="5px"
                        paddingTop="5px"
                    >
                        {cardHeading}
                        <span
                            style={{
                                color: "#A3A3A3",
                                fontSize: "12px",
                                fontWeight: "400",
                            }}
                        >
                            {`  ${cardSubHeading}`}
                        </span>
                    </Typography>
                }
            />
            <Divider sx={{ color: "#C4C4C4" }} />
            <CardContent>
                <LoadingWrapper style={{ marginTop: "4rem" }} isLoading={isLoading}>
                    <div
                        style={{
                            opacity: isExpired ? "50%" : "100%",
                        }}
                    >
                        <CircleMeter
                            value={circleMeterValue}
                            max={circleMeterMaxValue}
                            innerTextCircle={innerCircleLabel}
                            innerValueTextCircle={innerCircleValue}
                            backgroundColor={colorsCircleBackground}
                            indicatorColor={colorsCircleIndicator}
                        />
                    </div>
                    <BarChart data={barChartData} labels={barChartLabels} backgroundColor={barChartColor} />
                </LoadingWrapper>
            </CardContent>
        </Card>
    );
};

export default ClassroomCard;
