import { ApiEndpoints } from "configs";
import ExecuteAsync from "utils/api-client";
import { createQueryParams } from "utils/create-query-params";
import { RequestOptions } from "axios-types";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
export const getLogsListRequest = (data?: any, options?: RequestOptions) => {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/audit/log-filter${createQueryParams(data)}`,
        signal: options?.signal,
    });
};

export const getLogsSectionListDropDownRequest = (options?: RequestOptions) => {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: "/audit/sections",
        signal: options?.signal,
    });
};

export const getLogsUserListDropDownRequest = (options?: RequestOptions) => {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: "/audit/users",
        signal: options?.signal,
    });
};

export const getLogsActionListDropDownRequest = (options?: RequestOptions) => {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: "/audit/actions",
        signal: options?.signal,
    });
};
export const executeRetrieveSessionByIdRequest = (sessionId: string, data?: any, options?: RequestOptions) => {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/log-reader/${sessionId}`,
        signal: options?.signal,
    });
};
