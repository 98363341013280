import React from "react";
import Layout from "layouts/app-taxi-ranking";
import { ProtectedLayout } from "layouts/common/general";
import Forbidden from "pages/common/errors/forbidden/Forbidden";

const root = {
    path: "forbidden",
    element: (
        <ProtectedLayout>
            <Layout />
        </ProtectedLayout>
    ),
    children: [{ path: "", element: <Forbidden /> }],
};
export default root;
