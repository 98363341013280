import React, { memo, useEffect, useState } from "react";
import { Box, Grid, IconButton } from "@mui/material";
import PaperWrapper from "components/wrappers/PaperWrapper";
import { CgArrowsExpandRight } from "react-icons/cg";
import FullScreenDialog from "components/dialogs/FullScreenDialog";
import { useSelector } from "react-redux";
import LineChart from "components/charts/LineChart";
import { VehiclesOvertimeResponse } from "pages/app-cycle-ways/bike-racks/location/LocationChartLayout";
import { getRandomColor, humanize } from "utils/common";
import { BikeRackStatus, Directions } from "types/chart-configs/enums/common";
import { LoadingWrapper } from "components/wrappers/LoadingWrapper";
import { useParams } from "react-router-dom";
import { fillEmptyPointsWithZeros, reduceScaleUnitFromTime } from "utils/chart";
import { XYData, processChartData } from "./HistoricalOccupancyChart";

type NetOccupancyChartProps = {
    data: VehiclesOvertimeResponse[];
    title: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon?: any;
    loading: boolean;
};

function NetOccupancyChart({ data = [], title, icon, loading }: NetOccupancyChartProps) {
    const [processedData, setProcessedData] = useState<processChartData[]>([]);
    const [modalShow, setModalShow] = React.useState(false);
    const { locationId } = useParams();
    const [isDataProcessing, setIsDataProcessing] = React.useState(false);
    const { startTime, endTime, timeType } = useSelector((state) => state.time);

    useEffect(() => {
        setIsDataProcessing(true);
        processData(data);
    }, [data]);

    useEffect(() => {
        setProcessedData([]);
    }, [locationId]);

    function processData(data: VehiclesOvertimeResponse[]): void {
        const dataset = data.reduce(
            (acc, item) => {
                const itemInTime = new Date(item.inTime);
                let itemCount = 0;
                if (item.classification === BikeRackStatus.ARRIVAL) {
                    itemCount = Number(item.count);
                    acc.in.push({
                        x: itemInTime,
                        y: itemCount,
                    });
                } else if (item.classification === BikeRackStatus.DEPARTURE) {
                    itemCount = Number(item.count);
                    acc.out.push({
                        x: itemInTime,
                        y: itemCount,
                    });
                }
                const netMatchIndex = acc.net.findIndex((el) => el.x.getTime() === itemInTime.getTime());
                if (netMatchIndex === -1) {
                    acc.net.push({
                        x: itemInTime,
                        y: itemCount,
                    });
                } else {
                    itemCount = -1 * Number(item.count);
                    acc.net[netMatchIndex].y += itemCount;
                }
                return acc;
            },
            {
                in: [] as XYData[],
                out: [] as XYData[],
                net: [] as XYData[],
            },
        );

        const lineInColor = processedData[0]?.borderColor || getRandomColor();
        const lineOutColor = processedData[1]?.borderColor || getRandomColor();
        const lineDeltaColor = processedData[2]?.borderColor || getRandomColor();
        setProcessedData([
            {
                label: humanize(Directions.IN),
                data: fillEmptyPointsWithZeros(dataset.in.sort(
                    (first, second) => first.x.getTime() - second.x.getTime(),
                ), startTime, endTime, timeType!),
                borderColor: lineInColor,
                backgroundColor: lineInColor,
                fillStyle: lineInColor,
                strokeStyle: lineInColor,
                type: "line",
                tension: 0.01,
            },
            {
                label: humanize(Directions.OUT),
                data: fillEmptyPointsWithZeros(dataset.out.sort(
                    (first, second) => first.x.getTime() - second.x.getTime(),
                ), startTime, endTime, timeType!),
                borderColor: lineOutColor,
                backgroundColor: lineOutColor,
                fillStyle: lineOutColor,
                strokeStyle: lineOutColor,
                type: "line",
                tension: 0.01,
            },
            {
                label: "Delta",
                data: fillEmptyPointsWithZeros(dataset.net.sort(
                    (first, second) => first.x.getTime() - second.x.getTime(),
                ), startTime, endTime, timeType!),
                borderColor: lineDeltaColor,
                backgroundColor: lineDeltaColor,
                fillStyle: lineDeltaColor,
                strokeStyle: lineDeltaColor,
                type: "line",
                tension: 0.01,
            },
        ]);
        setIsDataProcessing(false);
    }

    const handleClick = (status: boolean): void => {
        setModalShow(status);
    };

    const checkAndAdjustHourFromTime = (time: string) => {
        const timeObject = new Date(time);
        if (timeObject.getUTCMinutes() === 0) return time;
        return reduceScaleUnitFromTime(timeType!, timeObject);
    };

    return (
        <>
            <PaperWrapper>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={1} gap={1} marginBottom={1}>
                        <Grid item className="chart-padding-0">
                            {icon}
                        </Grid>
                        <Grid item className="chart-padding-0">
                            <span className="chartHearder">{title}</span>
                        </Grid>
                        <Grid item className="chart-padding-0">
                            <IconButton className="expand-btn" onClick={() => handleClick(true)} disableRipple>
                                <CgArrowsExpandRight />
                            </IconButton>
                        </Grid>
                    </Grid>
                    {loading || isDataProcessing ? (
                        <Box display="flex" justifyContent="center" alignItems="center" minHeight="20vh">
                            <LoadingWrapper isLoading />
                        </Box>
                    ) : (
                        <LineChart
                            data={processedData}
                            fromTime={checkAndAdjustHourFromTime(startTime)}
                            toTime={endTime}
                            timeUnit={timeType}
                            roundTimeDisabled
                            yAxisDecimalDisabled
                        />
                    )}
                </Grid>
            </PaperWrapper>
            <FullScreenDialog
                modalShow={modalShow}
                onClose={handleClick}
                childComponent={
                    <div>
                        {loading || isDataProcessing ? (
                            <Box display="flex" justifyContent="center" alignItems="center" minHeight="20vh">
                                <LoadingWrapper isLoading />
                            </Box>
                        ) : (
                            <LineChart
                                data={processedData}
                                fromTime={checkAndAdjustHourFromTime(startTime)}
                                toTime={endTime}
                                timeUnit={timeType}
                                roundTimeDisabled
                                yAxisDecimalDisabled
                            />
                        )}
                    </div>
                }
            />
        </>
    );
}

export default memo(NetOccupancyChart);
