/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { flattenTree, getLeafNodes } from "utils/data-manipulation";

interface ObjectLiteral {
    [key: string]: any;
}

export interface ILocationOpenHoursRange {
    to: number;
    from: number;
}

export interface ILocationOpenHours {
    SUNDAY: ILocationOpenHoursRange[] | null | undefined;
    HOLIDAY: ILocationOpenHoursRange[] | null | undefined;
    SATURDAY: ILocationOpenHoursRange[] | null | undefined;
    WEEK_DAY: ILocationOpenHoursRange[] | null | undefined;
}

export interface Location {
    id: string;
    name: string;
    parentId: string;
    tenantId: string;
    type: string;
    longitude: string;
    latitude: string;
    address1: string;
    address2: string;
    city: string;
    country: string;
    state: string;
    externalId: string;
    postCode: number;
    image: string;
    metadata: ObjectLiteral;
    createdAt: Date;
    updatedAt: Date;
    children?: Location[];
    groupIds?: string[];
    openHours?: ILocationOpenHours;
    metaField1?: string | null;
    metaField2?: string | null;
    metaField3?: string | null;
}

export interface LocationWrapper {
    count: Count;
    data: Location[];
}

export interface Count {
    count: number;
}

interface SystemState {
    locationsTree: Location[];
    tree: Location[];
    sideBarTree: any[];
    locations: Location[];
    leafLocations: Location[];
}

export interface Coordinate {
    lat: number;
    lng: number;
}

const initialState = {
    locationsTree: [],
    tree: [],
    sideBarTree: [],
    locations: [],
    leafLocations: [],
} as SystemState;

const findTree = (trees: Location[], searchTerm: string): Location[] => {
    return trees.reduce(function findAndCreateTree(result: Location[], location: Location): Location[] {
        let children: Location[] = [];
        if (location.name.toLowerCase().includes(searchTerm)) {
            return result.concat(location);
        }
        if (Array.isArray(location.children)) {
            children = location.children.reduce(findAndCreateTree, []);
        }
        if (children.length) {
            return result.concat({ ...location, children });
        }
        return result;
    }, []);
};

const slice = createSlice({
    name: "newLocation",
    initialState,
    reducers: {
        setLocations(state, action: PayloadAction<any>) {
            const tree = action.payload;
            state.locationsTree = tree;
            state.tree = tree;
            state.locations = flattenTree(tree);
            state.leafLocations = getLeafNodes(tree);
        },
        searchLocationsBy(state, action: PayloadAction<any>) {
            const { locationsTree } = state;
            const filterParams = action.payload;
            if (filterParams) {
                const searchTerm = filterParams.toLowerCase();
                const filteredTree = findTree(locationsTree, searchTerm);

                state.tree = filteredTree;
            } else {
                state.tree = state.locationsTree;
            }
        },
        setAllLocations(state, action: PayloadAction<any>) {
            state.locations = action.payload.data;
        },
        setOuterLocations(state, action: PayloadAction<any>) {
            state.locations = action.payload.data;
        },
        setLeafLocations(state, action: PayloadAction<any>) {
            state.leafLocations = action.payload.data;
        },
        clearLocations(state) {
            state.locations = [];
            state.locationsTree = [];
            state.sideBarTree = [];
            state.tree = [];
        },
    },
});

export const { setLocations, searchLocationsBy, setAllLocations, clearLocations, setLeafLocations } = slice.actions;
export default slice.reducer;
