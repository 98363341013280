import { Grid, LinearProgress, linearProgressClasses, styled, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import getPercentage from "utils/get-percentage";

interface StyledLinearProgressBarProps {
    progressColor: string;
    backgroundColor: string;
}

export interface LinearProgressBarProps extends StyledLinearProgressBarProps {
    value: number;
    maxValue: number;
    minValue: number;
    renderValue: (value: number, maxValue?: number, minValue?: number) => ReactNode;
    title: string;
    valueSuffix?: string;
}

const BorderLinearProgress = styled(LinearProgress)<StyledLinearProgressBarProps>(
    ({ backgroundColor, progressColor }) => ({
        height: 4,
        borderRadius: 2,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor,
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: progressColor,
        },
    }),
);

const LinearProgressBar: React.FC<LinearProgressBarProps> = ({
    value,
    maxValue = 100,
    title,
    renderValue = (val: number | string) => val,
    progressColor,
    backgroundColor,
    minValue = 0,
    valueSuffix,
}) => {
    return (
        <div style={{ height: "22px" }}>
            <Grid container item xs={12}>
                <Grid item xs={6}>
                    <Typography fontSize={12} fontWeight={400} color="#A3A3A3">
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography
                        sx={{ position: "relative" }}
                        fontSize={12}
                        fontWeight={600}
                        color="#001B4F"
                        textAlign="right"
                    >
                        {renderValue(value, maxValue, minValue)}
                        {valueSuffix}
                    </Typography>
                </Grid>
            </Grid>
            <BorderLinearProgress
                variant="determinate"
                value={getPercentage(value, minValue, maxValue)}
                backgroundColor={backgroundColor}
                progressColor={progressColor}
            />
        </div>
    );
};

export default LinearProgressBar;
