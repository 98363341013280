import React, { useEffect, useMemo, useRef, useState } from "react";
import { Grid, styled } from "@mui/material";
import IncidentReportTable from "components/tables/incident-report-table";
import IotVisionToolTips from "components/common/IotVisionToolTips";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import AverageLineChart, { generateDatesToGraph, IData } from "components/charts/average-line-chart";
import { TimeRange } from "features/common/date-header/DateRangePicker";
import SectionHeading from "components/headings/SectionHeading";
import { CommonObjectLiteral, DropDownTypes, IncidentTrendsReport } from "types/reports/interfaces";
import { executeGetReportAndReportData } from "api/app-taxi-ranking/taxi-reports.api";
import { useSelector } from "react-redux";
import { DayTypes, AllDaysMapper } from "enums/days.enum";
import { LocationType, ReportPageType } from "enums/location.enums";
import StatusCodes from "enums/status-code.enums";
import { toggleReportLoading } from "reducers/report.reducer";
import { useAppDispatch } from "store";
import { ReportMetaFieldTypes } from "types/reports/enums";
import {
    StatDetails,
    ReportRequestParameters,
    GraphDetails,
    GraphObject,
} from "types/reports/interfaces/incident-trends-report";
import { getRandomColor, humanize } from "utils/common";
import { Location } from "reducers/newLocation.reducer";
import IncidentTrendsFilterPanel from "./incident-trends-filter-panel";
import { ReportDataTypes, ReportPeriod } from "./lga-overview";

const OPTION_ALL = "all";

const incidentTableColumns: GridColDef[] = [
    {
        field: "locationName",
        headerName: "Locations",
        renderCell: (params: GridRenderCellParams) => (
            <IotVisionToolTips title={params.row.locationName || ""} arrow>
                <span>{params.row.locationName}</span>
            </IotVisionToolTips>
        ),
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
    },
    {
        field: "total",
        headerName: "Total Potential Incidents",
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => (
            <IotVisionToolTips title={params.row.total || ""} arrow>
                <span>{params.row.total}</span>
            </IotVisionToolTips>
        ),
    },
    {
        field: "avg",
        headerName: "Average per Day",
        renderCell: (params: GridRenderCellParams) => (
            <IotVisionToolTips title={Math.round(Number(params.row.avg)) || ""} arrow>
                <span>{Math.round(Number(params.row.avg))}</span>
            </IotVisionToolTips>
        ),
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
    },
];

const taxiTableColumns: GridColDef[] = [
    {
        field: "locationName",
        headerName: "Locations",
        renderCell: (params: GridRenderCellParams) => (
            <IotVisionToolTips title={params.row.locationName || ""} arrow>
                <span>{params.row.locationName}</span>
            </IotVisionToolTips>
        ),
        flex: 2,
        sortable: false,
        disableColumnMenu: true,
    },
    {
        field: "total",
        headerName: "Total Taxis",
        flex: 1.5,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => (
            <IotVisionToolTips title={params.row.total || ""} arrow>
                <span>{params.row.total}</span>
            </IotVisionToolTips>
        ),
    },
    {
        field: "avg",
        headerName: "Average no of Taxis per Day",
        renderCell: (params: GridRenderCellParams) => (
            <IotVisionToolTips title={Math.round(Number(params.row.avg)) || ""} arrow>
                <span>{Math.round(Number(params.row.avg))}</span>
            </IotVisionToolTips>
        ),
        flex: 2,
        sortable: false,
        disableColumnMenu: true,
    },
];

interface LGAList {
    label: string;
    id: string;
}

const CustomizedSectionHeading = styled(SectionHeading)(() => ({
    background: "transparent !important",
    "& .icon-div": {
        background: "transparent !important",
    },
}));

const LocationOverview = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const reportAbortControllerRef = useRef<AbortController | null>(null);
    const [reportPeriod, setReportPeriod] = useState<ReportPeriod>({from: "", to: ""});
    const [modifiedReportData, setModifiedReportData] = useState<ReportDataTypes>({
        incidentTypes: [],
        incidentStat: [],
        incidentGraph: [],
        taxisStat: [],
        taxisGraph: [],
    });
    const { reportId } = useParams<{ reportId: string }>();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { appCode } = useSelector((state) => state.auth);
    const { locations } = useSelector((state) => state.newLocation);
    const capturedSearchParams: Record<string, string> = useMemo(
        () => Object.fromEntries<string>([...searchParams]) || {},
        [searchParams],
    );

    useEffect(() => {
        if (reportId) {
            dispatch(toggleReportLoading(true));
            getReportDataFromAPI(reportId);
        }
        return () => {
            reportAbortControllerRef.current?.abort();
        }
    }, [reportId, capturedSearchParams]);

    const getReportDataFromAPI = async (reportId: string) => {
        try {
            reportAbortControllerRef.current = new AbortController();
            const params = generateRequestQueryParams(capturedSearchParams);
            const { signal } = reportAbortControllerRef.current;
            const data = await executeGetReportAndReportData({ reportId, ...params }, { signal });
            if (data) {
                setModifiedReportData({...mapReportData(data)});
                setReportPeriod({from: data.reportFrom, to: data.reportTo});
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: Error | any) {
            if (error?.response?.status === StatusCodes.FORBIDDEN) {
                navigate(`/${appCode}/reports/forbidden`, { replace: true });
                return;
            }
            if (error?.response?.status === StatusCodes.NOT_FOUND) {
                navigate(`/${appCode}/reports/report-not-found`, { replace: true });
                return;
            }
            console.log(error);
        }
        finally {
            dispatch(toggleReportLoading(false));
        }
    };

    const getColorByLocation = () => {
        const colorBindings: Record<string, string> = {};
        locations?.forEach(({ id }: Location) => {
            if (colorBindings[id]) return;
            const color = getRandomColor();
            colorBindings[id] = color;
        });
        return colorBindings;
    };

    const generateRequestQueryParams = (params: Record<string, string>) => {
        const { lga, dayType, dayOfWeek, incidentType } = params;
        const requestParams: ReportRequestParameters = {
            locationId: OPTION_ALL,
            metaField2Type: ReportMetaFieldTypes.VALUE,
            metaField2: OPTION_ALL,
            metaField1Type: ReportMetaFieldTypes.VALUE,
            metaField1: OPTION_ALL,
            type: ReportPageType.LOCATION,
        };
        if (lga) requestParams.locationId = lga;
        if (incidentType) requestParams.metaField2 = incidentType;
        if (dayType)
            requestParams.metaField1 =
                dayType === DayTypes.WEEKDAY ? AllDaysMapper.ALL_WEEKDAYS : AllDaysMapper.ALL_WEEKEND_DAYS;
        if (dayOfWeek) {
            if (dayOfWeek !== OPTION_ALL) requestParams.metaField1 = dayOfWeek;
        }
        return requestParams;
    };

    const mapReportData = (reportsData: IncidentTrendsReport) => {
        const { storage } = reportsData;
        const { incidentTypes: reportIncidentTypes, report } = storage || {};
        const { data } = report || {};
        const [dataObject] = data || [];
        const { metaData } = dataObject || {};
        const { stat, graph } = metaData || {};
        const colorMappings = getColorByLocation();
        const graphConfigs = {
            reportFrom: reportsData?.reportFrom,
            reportTo: reportsData?.reportTo,
            colorMappings,
        };

        const finalizedData = {
            incidentTypes: [] as DropDownTypes[],
            incidentStat: [] as StatDetails[],
            incidentGraph: [] as IData[],
            taxisStat: [] as StatDetails[],
            taxisGraph: [] as IData[],
        };

        finalizedData.incidentGraph = modifyGraphData(graph?.incident || [], graphConfigs);
        finalizedData.taxisGraph = modifyGraphData(graph?.event || [], graphConfigs);
        finalizedData.incidentTypes = modifyIncidentTypes(reportIncidentTypes || []);
        finalizedData.incidentStat = modifyStatData(stat?.incident || []);
        finalizedData.taxisStat = modifyStatData(stat?.event || []);
        return finalizedData;
    };

    const handleSearchParams = (params: CommonObjectLiteral) => {
        setSearchParams(params);
    };

    const modifyIncidentTypes = (types: string[]) => {
        return types?.map((type: string) => ({ id: type, label: humanize(type) }));
    };

    const modifyStatData = (statData: StatDetails[] = []) => {
        return statData?.map((item: StatDetails, index: number) => {
            const location = locations?.find((loc) => loc.id === item.locationId);
            return { id: index, ...item, locationName: location?.name };
        });
    };

    const generateGraphXPoints = (fromDate: string, toDate: string) => {
        const startPoint = generateDatesToGraph(fromDate, toDate);
        const endPoint = generateDatesToGraph(fromDate, toDate, 1);
        const xAxisDataset = [];
        let currentHour = new Date(startPoint);
        while (currentHour.getTime() <= endPoint.getTime()) {
            xAxisDataset.push(currentHour.toISOString());
            currentHour = new Date(currentHour.setHours(currentHour.getHours() + 1));
        }
        return xAxisDataset;
    };

    const modifyGraphData = (
        graphItems: GraphDetails[],
        graphConfigs: { reportFrom: string; reportTo: string; colorMappings: Record<string, string> },
    ) => {
        const { reportFrom, reportTo, colorMappings } = graphConfigs;
        const xAxisDataset = generateGraphXPoints(reportFrom, reportTo);
        return graphItems?.map((item: GraphDetails) => {
            const location = locations?.find((loc: Location) => loc.id === item.locationId);
            item?.graphData?.sort((a: GraphObject, b: GraphObject) => a.time - b.time);
            const modifiedGraphData = item?.graphData?.map((graphItem: GraphObject) => {
                const matchedTimeSlot = xAxisDataset[graphItem.time];
                return {
                    x: new Date(matchedTimeSlot),
                    y: Number(graphItem?.value?.toFixed(2)),
                };
            });
            modifiedGraphData?.sort((a, b) => a.x.getTime() - b.x.getTime());
            return {
                label: location?.name,
                data: modifiedGraphData,
                borderColor: colorMappings[item.locationId],
                backgroundColor: colorMappings[item.locationId],
                fillStyle: colorMappings[item.locationId],
                strokeStyle: colorMappings[item.locationId],
                type: "line",
                tension: 0.01,
            } as IData;
        });
    };

    const generateLGAListFromLocations = () => {
        const extractedLgaList: LGAList[] = [];
        locations.forEach((location) => {
            if (location.type === LocationType.ZONE)
                extractedLgaList.push({
                    id: location.id,
                    label: location.name,
                });
        });
        return extractedLgaList;
    };

    return (
        <>
            <Grid item xs={12} sm={7} md={7} lg={9} xl={9}>
                <CustomizedSectionHeading heading="Location Overview" />
            </Grid>
            <IncidentTrendsFilterPanel
                searchParams={capturedSearchParams}
                setSearchParams={handleSearchParams}
                IncidentTypes={modifiedReportData.incidentTypes}
                lgaList={generateLGAListFromLocations()}
            />
            <Grid container display="flex" justifyContent="space-between" spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <IncidentReportTable
                        tableHeader="Top 5 Locations by Incidents"
                        columnHeaders={incidentTableColumns}
                        rowData={modifiedReportData.incidentStat}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <IncidentReportTable
                        tableHeader="Top 5 Locations by Number of Taxis"
                        columnHeaders={taxiTableColumns}
                        rowData={modifiedReportData.taxisStat}
                    />
                </Grid>
            </Grid>
            <Grid container display="flex" justifyContent="space-between" spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <AverageLineChart
                        data={modifiedReportData.incidentGraph}
                        fromTime={reportPeriod.from}
                        toTime={reportPeriod.to}
                        graphHeader="Average no of Potential Incidents"
                        useLegend
                        timeUnit={TimeRange.Hour}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <AverageLineChart
                        data={modifiedReportData.taxisGraph}
                        fromTime={reportPeriod.from}
                        toTime={reportPeriod.to}
                        graphHeader="Average no of Taxis"
                        useLegend
                        timeUnit={TimeRange.Hour}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default LocationOverview;
