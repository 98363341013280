/**
 * Load an image from a URL
 */
export const loadImage = (imageSrc: string) => {
    const INVALID_IMAGE_URL_MESSAGE = "Invalid image URL";

    return new Promise<HTMLImageElement>((resolve, reject) => {
        const img = new Image();
        img.onerror = () => reject(new Error(INVALID_IMAGE_URL_MESSAGE));
        img.onload = () => resolve(img);
        img.src = imageSrc;
    });
};
