export enum LocationLevel {
    REGION = 1,
    ZONE = 2,
    LOCATION = 3,
}

export enum LocationType {
    REGION = "region",
    ZONE = "zone",
    LOCATION = "location",
}

export enum ReportPageType {
    LGA = "LGA",
    LOCATION = "LOCATION",
}