/* eslint-disable class-methods-use-this */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import videojs from "video.js";

import Player from "video.js/dist/types/player";
import { CommonObjectLiteral } from "types";
import { ZoomModal } from "./zoom-modal";
import { ZoomGesture } from "./zoom-gesture";
import { ZoomButton } from "./zoom-button";
import { Observer } from "../observer";

interface ListenerType { click: () => void; change: () => void; }

export interface ZoomPluginOptions {
    zoom: number;
    moveX: number;
    moveY: number;
    showZoom: boolean;
    showMove: boolean;
    gestureHandler: boolean;
}

const Plugin = videojs.getPlugin("plugin");

const DEFAULT_OPTIONS = {
    zoom: 1,
    moveX: 0,
    moveY: 0,
    flip: "+",
    showZoom: true,
    showMove: true,
    gestureHandler: false,
};

class ZoomPlugin extends Plugin {
    playerEl: HTMLElement;

    listeners: ListenerType;

    state: CommonObjectLiteral;

    observer: Observer;

    static defaultState: CommonObjectLiteral;

    constructor(player: Player, options: CommonObjectLiteral = {}) {
        super(player);
        this.playerEl = player.el() as HTMLElement;
        this.listeners = {
            click: () => {},
            change: () => {},
        };
        this.playerEl.style.overflow = "hidden";
        this.state = Object.assign(DEFAULT_OPTIONS, options);
        this.state.flip = "+";
        if (this.state.showZoom || this.state.showMove) {
            const zoomOptionButton = player.getChild("ControlBar")?.addChild("ZoomButton");
            zoomOptionButton.controlText("Zoom Options");
            player.addChild("ZoomModal", { plugin: this, state: this.state });
        }
        if (this.state.gestureHandler) {
            player.addChild("ZoomGesture", { plugin: this, state: this.state });
        }
        this.observer = Observer.getInstance();
        this.setTransform();
    }

    zoom(value: number) {
        if (value <= 0) {
            throw new Error("Zoom value invalid");
        }
        this.state.zoom = value;
        this.setTransform();
    }

    move(x: number, y: number) {
        this.state.moveX = x;
        this.state.moveY = y;
        this.setTransform();
    }

	flip(signal: string) {
		this.state.flip = signal;
		this.setTransform();
	}

    toggle() {
        const [modal] = this.playerEl.getElementsByClassName("vjs-zoom-duck__container");
        modal.classList.toggle("open");
    }

    listen(listener: string, callback: () => void) {
        this.listeners[listener as  keyof ListenerType] = callback;
    }

    notify() {
        this.observer.notify("change", this.state);
    }

    setTransform() {
        const [video] = this.playerEl.getElementsByTagName("video");
        video.style.transform = `
             translate(${this.state.moveX}px, ${this.state.moveY}px)
             scale(${this.state.flip}${this.state.zoom}, ${this.state.zoom})
         `;
        this.notify();
    }
}

ZoomPlugin.defaultState = {};

videojs.registerComponent("ZoomModal", ZoomModal);
videojs.registerComponent("ZoomGesture", ZoomGesture);
videojs.registerComponent("ZoomButton", ZoomButton);
videojs.registerPlugin("zoomPlugin", ZoomPlugin);

export default ZoomPlugin;
