/* eslint-disable array-callback-return */
import { TreeItemContentProps, useTreeItem } from "@mui/lab";
import { Typography } from "@mui/material";
import React from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { reportSideMenuDisplayValues } from "configs/reports/report-sidemenu-display-values";

const ReportTreeContent = React.forwardRef(function CustomContent(props: TreeItemContentProps, ref) {
    const { classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon } = props;
    const { disabled, expanded, selected, handleExpansion, handleSelection } = useTreeItem(nodeId);
    const icon = iconProp || expansionIcon || displayIcon;
    const { rootPath } = useSelector((state) => state.auth);
    const navigate = useNavigate();

    const handleSelectionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        handleSelection(event);
        handleExpansion(event);
        navigateOnNodeSelect();
    };

    const navigateOnNodeSelect = () => {
        if (
            nodeId !== "root" &&
            ![nodeId, nodeId.split("/")[0]].some(type => Object.values(reportSideMenuDisplayValues).includes(type))
        ) {
            navigate(`${rootPath}/${nodeId}`);
        }
    };
    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
        <div
            className={clsx(className, classes.root, {
                [classes.expanded]: expanded,
                [classes.selected]: selected,
                [classes.focused]: false,
                [classes.disabled]: disabled,
            })}
            onClick={handleSelectionClick}
            ref={ref as React.Ref<HTMLDivElement>}
        >
            <div className={classes.iconContainer}>{icon}</div>
            <Typography component="div" className={classes.label}>
                {label}
            </Typography>
        </div>
    );
});

export default ReportTreeContent;
