import { store } from "store";

/**
 * Checks if the provided location id corresponds to a valid location on the locations list.
 * @param locationId location Id to check
 * @returns if the location id provided is a valid location
 */
export const isValidLocation = (locationId: string | undefined): boolean => {
    const state = store.getState();
    const { locations } = state.newLocation;
    const foundLocation = locations.find((l: { id: string | undefined }) => l.id === locationId);
    if (foundLocation) {
        return true;
    }
    return false;
};

export default isValidLocation;
