/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Forbidden = (): JSX.Element => {
    const navigate = useNavigate();

    return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="70vh">
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Typography fontSize="80px" fontWeight="600px" color="#7A7A7A">
                    403
                </Typography>
                <Typography fontSize="14px" fontWeight="600px" color="#7A7A7A" marginTop="-20px" paddingLeft="20px">
                    Forbidden
                </Typography>
                <Typography fontSize="10px" fontWeight="600px" color="#7A7A7A" marginTop="40px">
                    You do not have permissions to view the page.
                </Typography>
                <Typography fontSize="10px" fontWeight="600px" color="#7A7A7A">
                    <Link sx={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                        Go back
                    </Link>{" "}
                    or choose a new direction.
                </Typography>
            </Box>
        </Box>
    );
};

export default Forbidden;
