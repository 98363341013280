/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import PaperWrapper from "components/wrappers/PaperWrapper";
import { CgArrowsExpandRight } from "react-icons/cg";
import FullScreenDialog from "components/dialogs/FullScreenDialog";
import { useSelector } from "react-redux";
import LineChart from "components/charts/LineChart";
import { VehiclesOvertimeResponse } from "pages/app-ungated-parking/location/LocationChartLayout";
import { processChartData } from "./SynchronizedLineChart";

type ParkingMeanChartProps = {
    data: VehiclesOvertimeResponse[];
    title: string;
    icon?: any;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function ParkingMeanChart({ data, title, icon }: ParkingMeanChartProps) {
    const [processedData, setProcessedData] = useState<processChartData[]>([]);
    const [modalShow, setModalShow] = React.useState(false);
    const { startTime, endTime, timeType } = useSelector((state) => state.time);

    useEffect(() => {
        processData(data);
    }, [data]);

    function processData(data: VehiclesOvertimeResponse[]): void {
        const uniques = Array.from(new Set(data.map((item: VehiclesOvertimeResponse) => item.field4)));
        const dataset = uniques.map((i) => {
            const subset = data.filter((j: VehiclesOvertimeResponse) => j.field4 === i);
            const result = subset.reduce((acc = [] as VehiclesOvertimeResponse[], itm: VehiclesOvertimeResponse) => {
                const matchIndex = acc.findIndex((el: any) => el.inTime === itm.inTime);
                if (matchIndex === -1) {
                    acc.push(itm);
                    return acc;
                }
                acc[matchIndex].count = String(Number(itm.count) + Number(acc[matchIndex].count));
                return acc;
            }, [] as VehiclesOvertimeResponse[]);
            return {
                label: i,
                data: result.map((l: VehiclesOvertimeResponse) => {
                    return {
                        x: new Date(l.inTime),
                        y: Number(l.count),
                    };
                }),
                // eslint-disable-next-line no-bitwise
                borderColor: `#${(((1 << 24) * Math.random()) | 0).toString(16)}`,
                tension: 0.1,
            };
        });
        setProcessedData(dataset);
    }

    const handleClick = (status: boolean): void => {
        setModalShow(status);
    };
    return (
        <>
            <PaperWrapper>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={1} gap={1} marginBottom={1}>
                        <Grid item className="chart-padding-0">
                            {icon}
                        </Grid>
                        <Grid item className="chart-padding-0">
                            <span className="chartHearder">{title}</span>
                        </Grid>
                        <Grid item className="chart-padding-0">
                            <IconButton className="expand-btn" onClick={() => handleClick(true)} disableRipple>
                                <CgArrowsExpandRight />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <LineChart
                        isFillEmpty
                        data={processedData}
                        fromTime={startTime}
                        toTime={endTime}
                        timeUnit={timeType}
                    />
                </Grid>
            </PaperWrapper>
            <FullScreenDialog
                modalShow={modalShow}
                onClose={handleClick}
                childComponent={
                    <div>
                        <LineChart
                            isFillEmpty
                            data={processedData}
                            fromTime={startTime}
                            toTime={endTime}
                            timeUnit={timeType}
                        />
                    </div>
                }
            />
        </>
    );
}
