import React, { useEffect, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import PaperWrapper from "components/wrappers/PaperWrapper";
import { CgArrowsExpandRight } from "react-icons/cg";
import FullScreenDialog from "components/dialogs/FullScreenDialog";
import { useSelector } from "react-redux";
import TimeSeriesChart from "components/charts/TimeSeriesChart";

type DetectedCyclistsChartProps = {
    data: IData[];
    title: string;
    config: IConfig;
}

interface IData {
    inTime: Date;
    count: number;
    classification: string;
}

interface IConfig {
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
    label: string;
    maintainAspectRatio: boolean;
}

interface IChartData {
    x: Date;
    y: number;
}

export default function DetectedCyclistsChart({ data, title, config }: DetectedCyclistsChartProps) {
    const [processedData, setProcessedData] = useState<IChartData[]>([]);

    const [modalShow, setModalShow] = React.useState(false);
    const { startTime, endTime, timeType } = useSelector((state) => state.time);

    const options = {
        maintainAspectRatio: config?.maintainAspectRatio,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                type: "time",
                min: new Date(startTime).valueOf(),
                max: new Date(endTime).valueOf(),
                time: {
                    unit: timeType,
                    round: timeType,
                },
            },
        },
    };

    useEffect(() => {
        const valueList = data.map((l: IData): IChartData => {
            return {
                x: l.inTime,
                y: l.count,
            };
        });
        setProcessedData(valueList);
    }, [data]);

    const handleClick = (status: boolean): void => {
        setModalShow(status);
    };

    return (
        <>
            <PaperWrapper>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <span className="chartHearder">{title}</span>
                    <IconButton className="expand-btn" onClick={() => handleClick(true)} disableRipple>
                        <CgArrowsExpandRight />
                    </IconButton>
                    <div>
                        <TimeSeriesChart data={processedData} {...config} options={options} />
                    </div>
                </Grid>
            </PaperWrapper>
            <FullScreenDialog
                modalShow={modalShow}
                onClose={handleClick}
                childComponent={
                    <div>
                        <TimeSeriesChart data={processedData} {...config} options={options} />
                    </div>
                }
            />
        </>
    );
}
