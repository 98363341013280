import { useState, useEffect } from "react";
import { executeGetDetectionsBehaviourRequest } from "api/common/detections.api";
import { useDispatch, useSelector } from "react-redux";
import IotVisionTable from "components/common/IotVisionTable";
import { checkLocationIdChanged, FetchFunctionParams, makeApiCallWithUpdateTime } from "utils/common";
import { DetectionsBehaviourResponse } from "types/detections/detections-behaviour";
import { GridColumns } from "@mui/x-data-grid";

const columns: GridColumns = [
    {
        field: "site",
        headerName: "Site",
        flex: 1,
    },
    { field: "count", headerName: "Count", flex: 1, sortComparator: (a, b) => a - b },
];

const rowsPerPageOptions = [30, 50];

interface SiteCounts {
    id: string;
    site: string;
    count: number;
}
const LocationDirectionMap = (props: { locationId?: string }) => {
    const { locationId } = props;
    const { startTime, endTime, selectedRange } = useSelector((state) => state.time);
    const [siteCounts, setSiteCounts] = useState<SiteCounts[]>([]);
    const { locations } = useSelector((state) => state.newLocation);
    const dispatch = useDispatch();

    const fetchData = async ({ startTime, endTime }: FetchFunctionParams): Promise<void> => {
        try {
            if (locationId) {
                const response: DetectionsBehaviourResponse[] = await executeGetDetectionsBehaviourRequest(
                    locationId,
                    {
                        fromTime: startTime,
                        toTime: endTime,
                        classification: "bicycle",
                    },
                    { disableNotification: checkLocationIdChanged(locationId, locations) },
                );
                const tempSiteCounts: SiteCounts[] = [];
                if (Array.isArray(response)) {
                    response.forEach((v) => {
                        if (v.siteId && v.direction) {
                            tempSiteCounts.push({
                                id: `${v.siteId}${v.direction}`,
                                site: `${v.siteId}${v.direction}`,
                                count: v.count,
                            });
                        }
                    });
                }
                setSiteCounts(tempSiteCounts);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        makeApiCallWithUpdateTime(selectedRange, { startTime, endTime }, fetchData, dispatch);
    }, [locationId, startTime, endTime]);

    return (
        <IotVisionTable
            columns={columns}
            rows={siteCounts}
            pageSize={100}
            rowsPerPageOptions={rowsPerPageOptions}
            hideFooterPagination
            hideFooter
            sortingMode="client"
        />

        //  ! Temp disabled to display table instead
        // <div className="location-direction-chart">
        //     <img
        //         alt="location map"
        //         src={chartImg}
        //         height="365"
        //         className="location-map-img"
        //     />
        //     <div className="location-indicator-div loc-1">
        //         <p className="location-name">Liverpool E - College N</p>
        //         <p className="count">{siteCounts.Site5NE || 0}</p>
        //     </div>
        //     <div className="location-indicator-div loc-2">
        //         <p className="location-name">Wentworth N - College N</p>
        //         <p className="count">{siteCounts.Site1N || 0}</p>
        //     </div>
        //     <div className="location-indicator-div loc-3">
        //         <p className="location-name">College S - Wentworth S</p>
        //         <p className="count">{siteCounts.Site1S || 0}</p>
        //     </div>
        //     <div className="location-indicator-div loc-4">
        //         <p className="location-name">College S - Oxford E</p>
        //         <p className="count">{siteCounts.Site3SE || 0}</p>
        //     </div>
        //     <div className="location-indicator-div loc-5">
        //         <p className="location-name">Liverpool E - Oxford E</p>
        //         <p className="count">{siteCounts.Site2E || 0}</p>
        //     </div>
        //     <div className="location-indicator-div loc-6">
        //         <p className="location-name">Wentworth N - Oxford E</p>
        //         <p className="count">{siteCounts.Site4NE || 0}</p>
        //     </div>
        //     <div className="location-indicator-div loc-7">
        //         <p className="location-name">Liverpool E - Wentworth S</p>
        //         <p className="count">{siteCounts.Site6SE || 0}</p>
        //     </div>
        //     <div className="location-indicator-div loc-8">
        //         <p className="location-name">Oxford W - Liverpool W</p>
        //         <p className="count">{siteCounts.Site2W || 0}</p>
        //     </div>
        //     <div className="loc-9 location-indicator-div">
        //         <p className="location-name">Oxford W - Wentworth S</p>
        //         <p className="count">{siteCounts.Site4SW || 0}</p>
        //     </div>
        //     <div className="loc-10 location-indicator-div">
        //         <p className="location-name">Oxford W - College N</p>
        //         <p className="count">{siteCounts.Site3NW || 0}</p>
        //     </div>
        //     <div className="loc-11 location-indicator-div">
        //         <p className="location-name">College S - Liverpool W</p>
        //         <p className="count">{siteCounts.Site5SW || 0}</p>
        //     </div>
        //     <div className="loc-12 location-indicator-div">
        //         <p className="location-name">Wentworth N - Liverpool W</p>
        //         <p className="count">{siteCounts.Site6NW || 0}</p>
        //     </div>
        // </div>
    );
};

export default LocationDirectionMap;
