import { Box, Chip, CircularProgress, Grid, Typography, styled } from "@mui/material";
import { FiCamera, FiVideo, FiPlay } from "react-icons/fi";
import { BsDownload } from "react-icons/bs";
import { AiOutlineStop } from "react-icons/ai";
import { IncidentFileStatus } from "types/incident/file-status";
import React, { useEffect, useState } from "react";
import ProtectedView from "components/common/ProtectedView";
import { SCOPES } from "enums/scopes.enum";
import IotVisionToolTips from "components/common/IotVisionToolTips";
import { RETENTION_PERIOD_HAS_PASSED, VIDEO_IS_NO_LONGER_AVAILABLE } from "constants/taxirank-incidents.constants";
import { useSelector } from "react-redux";
import { Location } from "reducers/newLocation.reducer";

interface IncidentModalHeaderProps {
    incidentId?: number;
    location?: string;
    lga?: string;
    incidentFileStatus?: IncidentFileStatus;
    videoLinkLoading: boolean;
    requestVideoLoading: boolean;
    playVideoLoading: boolean;
    downloadVideoLoading: boolean;
    fileStatusFetching?: boolean;
    isRetentionPeriodExpired?: boolean;
    locationId: string;
    onViewFootageClick: () => void;
    onRequestVideo: ()=> Promise<void>;
    onDownloadVideo: ()=> Promise<void>;
    onPlayVideo: ()=> Promise<void>;
    handlePlayer: (status: boolean)=> void;
}

const chipsCommonStyles: React.CSSProperties = {
    width: "191px",
    outline: "none",
    marginTop: "10px",
    fontWeight: "600",
    fontSize: "14px",
    float: "right",
};

const CustomizedPlayVideoChip = styled(Chip)(({ theme }) => ({
    ...chipsCommonStyles,
    backgroundColor: "#75C1E126",
    color: "#3D9FC9",
    [theme.breakpoints.only("md")]: {
        marginTop: "25px",
    },
    [theme.breakpoints.only("sm")]: {
        marginTop: "25px",
    },
}));

const CustomizedRequestVideoChip = styled(Chip)(({ theme }) => ({
    ...chipsCommonStyles,
    backgroundColor: "#75C1E126",
    color: "#3D9FC9",
    [theme.breakpoints.only("md")]: {
        marginTop: "25px",
    },
    [theme.breakpoints.only("sm")]: {
        marginTop: "25px",
    },
}));

const CustomizedDownloadVideoChip = styled(Chip)(({ theme }) => ({
    ...chipsCommonStyles,
    backgroundColor: "#7EE17526",
    color: "#1AAE17",
    [theme.breakpoints.only("md")]: {
        marginTop: "25px",
    },
    [theme.breakpoints.only("sm")]: {
        marginTop: "25px",
    },
}));

const CustomizedProcessingVideoChip = styled(Chip)(({ theme }) => ({
    ...chipsCommonStyles,
    backgroundColor: "#FBEA5359",
    color: "#EEBA00",
    [theme.breakpoints.only("md")]: {
        marginTop: "25px",
    },
    [theme.breakpoints.only("sm")]: {
        marginTop: "25px",
    },
}));

const CustomizedFileUnavailableButton = styled(Chip)(({ theme }) => ({
    ...chipsCommonStyles,
    backgroundColor: "#FA807230",
    color: "#8B0000",
    [theme.breakpoints.only("md")]: {
        marginTop: "25px",
    },
    [theme.breakpoints.only("sm")]: {
        marginTop: "25px",
    },
}));

const CustomizedStatusLoadingButton = styled(Chip)(({ theme }) => ({
    ...chipsCommonStyles,
    backgroundColor: "transparent",
    color: "#7A7A7A",
    [theme.breakpoints.only("md")]: {
        marginTop: "25px",
    },
    [theme.breakpoints.only("sm")]: {
        marginTop: "25px",
    },
}));

const IncidentModalHeader = ({
    incidentId,
    location,
    lga,
    videoLinkLoading,
    playVideoLoading,
    downloadVideoLoading,
    requestVideoLoading,
    incidentFileStatus,
    fileStatusFetching,
    isRetentionPeriodExpired,
    locationId,
    onViewFootageClick,
    onRequestVideo,
    onDownloadVideo,
    onPlayVideo,
    handlePlayer,
}: IncidentModalHeaderProps) => {
    const [shouldRenderFootage, setShouldRenderFootage] = useState<boolean>(false);
    const [shouldRenderVideoRequest, setShouldRenderVideoRequest] = useState<boolean>(false);
    const { locations } = useSelector((state) => state.newLocation);
    
    useEffect(() => {
        const filteredLocation = locations.find((loc: Location) => loc.id === locationId);
        const { metadata } = filteredLocation || {};
        setShouldRenderFootage(!!metadata?.canViewMerakiFootage);
        setShouldRenderVideoRequest(!!metadata?.canRequestEdgeVideo);
    }, [locationId]);

    const handlePlayModalOpen = async () => {
        await onPlayVideo();
        handlePlayer(true);
    };

    const renderChips = () => {
        switch (incidentFileStatus) {
            case IncidentFileStatus.AVAILABLE:
            case IncidentFileStatus.EXPIRED: {
                const playButton = (
                    <CustomizedPlayVideoChip
                        label={playVideoLoading ? "Loading..." : "Play Video"}
                        icon={<FiPlay color="#3D9FC9" size="18px" fontWeight="600" />}
                        onClick={handlePlayModalOpen}
                        disabled={
                            playVideoLoading || fileStatusFetching || incidentFileStatus === IncidentFileStatus.EXPIRED
                        }
                    />
                );
                const downloadButton = (
                    <CustomizedDownloadVideoChip
                        label={downloadVideoLoading ? "Loading..." : "Download Video"}
                        icon={<BsDownload color="#1AAE17" size="18px" fontWeight="600" />}
                        onClick={onDownloadVideo}
                        disabled={
                            downloadVideoLoading ||
                            fileStatusFetching ||
                            incidentFileStatus === IncidentFileStatus.EXPIRED
                        }
                    />
                );
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <ProtectedView filterScopes={[SCOPES.VIEW_FILES_INCIDENTS]} shouldHide>
                                {incidentFileStatus === IncidentFileStatus.EXPIRED ? (
                                    <IotVisionToolTips
                                        title={VIDEO_IS_NO_LONGER_AVAILABLE}
                                        arrow
                                        PopperProps={{ style: { whiteSpace: "nowrap" } }}
                                    >
                                        <div>{playButton}</div>
                                    </IotVisionToolTips>
                                ) : (
                                    playButton
                                )}
                            </ProtectedView>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <ProtectedView filterScopes={[SCOPES.DOWNLOAD_FILES_INCIDENTS]} shouldHide>
                                {incidentFileStatus === IncidentFileStatus.EXPIRED ? (
                                    <IotVisionToolTips
                                        title={VIDEO_IS_NO_LONGER_AVAILABLE}
                                        arrow
                                        PopperProps={{ style: { whiteSpace: "nowrap" } }}
                                    >
                                        <div>{downloadButton}</div>
                                    </IotVisionToolTips>
                                ) : (
                                    downloadButton
                                )}
                            </ProtectedView>
                        </Grid>
                    </Grid>
                );
            }
            case IncidentFileStatus.PENDING:
                return (
                    <CustomizedProcessingVideoChip
                        label="Processing Video"
                        icon={
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress sx={{ color: "#EEBA00" }} size={18} />
                            </Box>
                        }
                    />
                );

            case IncidentFileStatus.NOT_AVAILABLE:
                return (
                    <CustomizedFileUnavailableButton
                        label="Video Unavailable"
                        icon={<AiOutlineStop color="#8B0000" size="18px" fontWeight="600" />}
                    />
                );

            case IncidentFileStatus.LOADING:
                return (
                    <CustomizedStatusLoadingButton
                        label="Loading..."
                        icon={
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress sx={{ color: "#7A7A7A" }} size={18} />
                            </Box>
                        }
                    />
                );

            default: {
                const videoRequestButton = (
                    <CustomizedRequestVideoChip
                        label={requestVideoLoading ? "Loading..." : "Request Video"}
                        icon={<FiVideo color="#3D9FC9" size="18px" fontWeight="600" />}
                        disabled={requestVideoLoading || fileStatusFetching || isRetentionPeriodExpired}
                        onClick={onRequestVideo}
                    />
                );
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <ProtectedView filterScopes={[SCOPES.CREATE_INCIDENTS_VIDEO]} shouldHide>
                                {isRetentionPeriodExpired ? (
                                    <IotVisionToolTips
                                        title={RETENTION_PERIOD_HAS_PASSED}
                                        arrow
                                        PopperProps={{ style: { whiteSpace: "nowrap" } }}
                                    >
                                        <div>{videoRequestButton}</div>
                                    </IotVisionToolTips>
                                ) : (
                                    videoRequestButton
                                )}
                            </ProtectedView>
                        </Grid>
                    </Grid>
                );
            }
        }
    };

    return (
        <>
            <Grid container item xs={12} sm={12} md={12} lg={12} width="814px" spacing={1}>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Typography color="#21315B" fontSize="26px" fontWeight={600}>
                        Incident {incidentId}
                    </Typography>
                    <Typography color="#7A7A7A" fontSize="14px" fontWeight={400}>
                        {location}, {lga}
                    </Typography>
                </Grid>
                {shouldRenderFootage && (
                    <Grid item xs={2.5} sm={2.5} md={2.5} lg={2.5}>
                        <Chip
                            label={videoLinkLoading ? "Loading..." : "View Footage"}
                            icon={<FiCamera color="#3D9FC9" size="18px" fontWeight="600" />}
                            sx={(theme) => ({
                                width: "191px",
                                outline: "none",
                                backgroundColor: "#75C1E126",
                                color: "#3D9FC9",
                                marginTop: "10px",
                                fontWeight: "600",
                                fontSize: "14px",
                                float: "right",
                                [theme.breakpoints.only("md")]: {
                                    marginTop: "25px",
                                },
                                [theme.breakpoints.only("sm")]: {
                                    marginTop: "25px",
                                },
                            })}
                            disabled={videoLinkLoading}
                            onClick={() => onViewFootageClick()}
                        />
                    </Grid>
                )}
                {shouldRenderVideoRequest && (
                    <Grid item xs={5.5} sm={5.5} md={5.5} lg={5.5} display="flex" justifyContent="flex-start">
                        {renderChips()}
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default IncidentModalHeader;
