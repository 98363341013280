/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Card, CardContent, CardHeader, Divider, Grid, Typography } from "@mui/material";
import SemiCircleMeter, { ColorObj } from "components/meters/semi-circle-meter/SemiCircleMeter";
import LinearProgressBar from "components/progress-bar/LinearProgressBar";
import { ReactComponent as VOC } from "assets/images/VOC.svg";
import { ReactComponent as Air } from "assets/images/Air.svg";
import { LoadingWrapper } from "components/wrappers/LoadingWrapper";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AQSensorData, PositionedData } from "utils/threshold-data-finder/types";
import { useEffect, useRef, useState } from "react";
import { executeGetAllRoomStatusRequest } from "api/app-dept-air-quality/air-home-layout.api";
import { Classification } from "enums/room.enums";
import { getThresholdData } from "utils/threshold-data-finder";
import { ThresHoldConfigDataSetType } from "utils/threshold-data-finder/enums";
import { ColorsSemiCircleBackground } from "constants/colours.constants";
import { appDefaultRoute } from "pages/app-dept-air-quality";

interface HomeCardProps {
    cardHeading: string;
    width: string;
    locationId?: string;
}

export interface WeatherAttribute {
    value?: number;
    color?: string;
    maxThreshold?: number;
    minThreshold?: number;
    symbol?: PositionedData;
    statusLabel?: string;
    name?: string;
    colorRange?: ColorObj;
}

interface AQRoomProperties {
    colorsSemiCircleIndicator: ColorObj;
    colorsSemiCircleBackground: ColorObj;
    roomStatus: string;
    semiCircleMeterValue: number;
    semiCircleMeterMax: number;
    innerTextSemiCircle: string;
    innerSubTextSemiCircle: string;
    pm100: WeatherAttribute;
    pm25: WeatherAttribute;
    pm10: WeatherAttribute;
    temp: WeatherAttribute;
    rh: WeatherAttribute;
    eco2: WeatherAttribute;
    voc: WeatherAttribute;
    roomAqi: WeatherAttribute;
}

const HomeCard = ({ cardHeading, width, locationId }: HomeCardProps) => {
    const navigate = useNavigate();
    const { locations } = useSelector((state) => state.newLocation);
    const [aqRoomData, setAqRoomData] = useState<AQRoomProperties | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const aqDataAbortControllerRef = useRef<AbortController | null>(null);

    useEffect(() => {
        if (locationId) fetchRoomData();
        return () => {
            aqDataAbortControllerRef.current?.abort();
        };
    }, [locationId]);

    const fetchRoomData = async () => {
        try {
            aqDataAbortControllerRef.current = new AbortController();
            const { signal } = aqDataAbortControllerRef.current;
            const response = await executeGetAllRoomStatusRequest(
                {
                    locationId,
                    // eslint-disable-next-line max-len
                    classifications: `${Classification.PM100AQI},${Classification.PM25AQI},${Classification.PM10AQI},${Classification.TEMP},${Classification.RH},${Classification.CO2},${Classification.VOCAQI}`,
                },
                { signal },
            );
            if (Array.isArray(response) && response.length > 0) {
                const roomData = response.find((data) => data.location?.id === locationId);

                const pm10ThresholdData: AQSensorData | undefined = getThresholdData(
                    ThresHoldConfigDataSetType.AQ_SENSOR_THRESHOLD_CONFIGS,
                    Classification.PM10AQI,
                    roomData?.events?.find((e) => e.classification === Classification?.PM10AQI)?.value,
                );
                const pm25ThresholdData: AQSensorData | undefined = getThresholdData(
                    ThresHoldConfigDataSetType.AQ_SENSOR_THRESHOLD_CONFIGS,
                    Classification.PM25AQI,
                    roomData?.events?.find((e) => e.classification === Classification?.PM25AQI)?.value,
                );
                const pm100ThresholdData: AQSensorData | undefined = getThresholdData(
                    ThresHoldConfigDataSetType.AQ_SENSOR_THRESHOLD_CONFIGS,
                    Classification.PM100AQI,
                    roomData?.events?.find((e) => e.classification === Classification?.PM100AQI)?.value,
                );
                const tempThresholdData: AQSensorData | undefined = getThresholdData(
                    ThresHoldConfigDataSetType.AQ_SENSOR_THRESHOLD_CONFIGS,
                    Classification.TEMP,
                    roomData?.events?.find((e) => e.classification === Classification?.TEMP)?.value,
                );
                const rhThresholdData: AQSensorData | undefined = getThresholdData(
                    ThresHoldConfigDataSetType.AQ_SENSOR_THRESHOLD_CONFIGS,
                    Classification.RH,
                    roomData?.events?.find((e) => e.classification === Classification?.RH)?.value,
                );
                const co2ThresholdData: AQSensorData | undefined = getThresholdData(
                    ThresHoldConfigDataSetType.AQ_SENSOR_THRESHOLD_CONFIGS,
                    Classification.CO2,
                    roomData?.events?.find((e) => e.classification === Classification?.CO2)?.value,
                );
                const vocThresholdData: AQSensorData | undefined = getThresholdData(
                    ThresHoldConfigDataSetType.AQ_SENSOR_THRESHOLD_CONFIGS,
                    Classification.VOCAQI,
                    roomData?.events?.find((e) => e.classification === Classification?.VOCAQI)?.value,
                );

                const pmAQIs = [pm10ThresholdData, pm25ThresholdData, pm100ThresholdData];
                const roomAQI = pmAQIs?.reduce((prevValue, currentValue) =>
                    (prevValue?.value ?? 0) < (currentValue?.value ?? 0) ? currentValue : prevValue,
                );

                const latestAQData: AQRoomProperties = {
                    colorsSemiCircleIndicator: roomAQI?.colorRange ?? ColorsSemiCircleBackground,
                    colorsSemiCircleBackground: ColorsSemiCircleBackground,
                    roomStatus: "Online",
                    semiCircleMeterValue: roomAQI?.value ?? 0,
                    semiCircleMeterMax: roomAQI?.maxThreshold ?? 0,
                    innerTextSemiCircle: (roomAQI?.value ?? 0)?.toString(),
                    innerSubTextSemiCircle: roomAQI?.name ?? "",
                    pm100: { ...pm100ThresholdData, name: "PM10" },
                    pm25: { ...pm25ThresholdData, name: "PM2.5" },
                    pm10: { ...pm10ThresholdData, name: "PM1" },
                    temp: { ...tempThresholdData, name: "Temp" },
                    rh: { ...rhThresholdData, name: "RH" },
                    eco2: { ...co2ThresholdData, name: "eCO2" },
                    voc: { ...vocThresholdData },
                    roomAqi: { ...roomAQI },
                };

                setAqRoomData(latestAQData);
                setLoading(false);
            }
        } catch (e) {}
    };

    const renderValue = (value?: number, maxValue?: number): string => {
        return value?.toFixed(0) ?? "";
    };

    const renderSemiCircleMeter = (data: AQRoomProperties) => {
        const {
            semiCircleMeterMax,
            semiCircleMeterValue,
            innerSubTextSemiCircle,
            innerTextSemiCircle,
            colorsSemiCircleBackground,
            colorsSemiCircleIndicator,
        } = data;
        return (
            <SemiCircleMeter
                value={semiCircleMeterValue > semiCircleMeterMax ? semiCircleMeterMax : semiCircleMeterValue}
                max={semiCircleMeterMax}
                innerTextValue="AQI"
                innerValueText={innerTextSemiCircle}
                innerValueText2={`(${innerSubTextSemiCircle})`}
                backgroundColor={colorsSemiCircleBackground}
                indicatorColor={colorsSemiCircleIndicator}
            />
        );
    };

    const renderLinearProgressBar = (data: WeatherAttribute) => {
        const { color, value, maxThreshold, minThreshold, name, symbol } = data;
        return (
            <LinearProgressBar
                progressColor={color ?? "#F5F5FB"}
                backgroundColor="#F5F5FB"
                value={value ?? 0}
                maxValue={maxThreshold ?? 0}
                title={name ?? ""}
                minValue={minThreshold ?? 0}
                renderValue={renderValue}
                valueSuffix={symbol?.value}
            />
        );
    };

    const renderAirQualityLevel = (data: WeatherAttribute) => {
        return (
            <Grid item xs={3.6}>
                <Typography color="#001B4F" fontSize={12} fontWeight={600} marginBottom={0}>
                    Air Quality
                </Typography>
                <Typography
                    color={data.color}
                    fontSize={12}
                    fontWeight={400}
                    marginTop={0}
                    position="relative"
                    top="-5px"
                >
                    {data.statusLabel}
                </Typography>
            </Grid>
        );
    };

    const renderVOCLevel = (data: WeatherAttribute) => {
        return (
            <Grid item xs={3.6}>
                <Typography color="#001B4F" fontSize={12} fontWeight={600} marginBottom={0}>
                    VOC Level
                </Typography>
                <Typography
                    color={data?.color}
                    fontSize={12}
                    fontWeight={400}
                    marginTop={0}
                    position="relative"
                    top="-5px"
                >
                    {data?.statusLabel}
                </Typography>
            </Grid>
        );
    };

    return (
        <Card
            sx={{
                borderRadius: "15px",
                borderTop: 1,
                borderWidth: 3,
                borderColor: !loading ? aqRoomData?.roomAqi.color : "#F5F5FB",
                minWidth: { width },
                maxWidth: "300px",
            }}
            elevation={0}
        >
            <CardHeader
                sx={{
                    height: "67.5px",
                    paddingLeft: "20px",
                    paddingTop: "12px",
                }}
                title={
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                    <h3
                        style={{
                            fontSize: 18,
                            fontWeight: 600,
                            color: "#001B4F",
                            fontFamily: "Poppins",
                            marginBottom: "0px",
                            height: "27px",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            const matchedLocation = locations.find((loc) => loc.id === locationId);
                            if (matchedLocation) {
                                const url = `${appDefaultRoute}/school/${matchedLocation.parentId}/room/${locationId}`;
                                navigate(url);
                            }
                        }}
                    >
                        {cardHeading}
                    </h3>
                }
                subheader={<div />}
            />
            <Divider sx={{ color: "#C4C4C4" }} />
            <CardContent sx={[{ paddingTop: "0px" }]}>
                <LoadingWrapper style={{ marginTop: "7.5rem", marginBottom: "7.5rem" }} isLoading={loading ?? false}>
                    {aqRoomData && (
                        <Box>
                            {renderSemiCircleMeter(aqRoomData)}

                            <Grid container item xs={12} paddingBottom={1} paddingTop="15px">
                                <Grid item xs={5}>
                                    {renderLinearProgressBar(aqRoomData.pm100)}
                                </Grid>
                                <Grid item xs={1.5} />
                                <Grid item xs={5.5} paddingLeft="3px">
                                    {renderLinearProgressBar(aqRoomData.temp)}
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} paddingBottom={1} paddingTop="8px">
                                <Grid item xs={5}>
                                    {renderLinearProgressBar(aqRoomData.pm25)}
                                </Grid>
                                <Grid item xs={1.5} />
                                <Grid item xs={5.5} paddingLeft="3px">
                                    {renderLinearProgressBar(aqRoomData.rh)}
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} paddingBottom={1} paddingTop="8px">
                                <Grid item xs={5}>
                                    {renderLinearProgressBar(aqRoomData.pm10)}
                                </Grid>
                                <Grid item xs={1.5} />
                                <Grid item xs={5.5} paddingLeft="3px">
                                    {renderLinearProgressBar(aqRoomData.eco2)}
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} paddingTop="18px">
                                <Grid item xs={1.5} marginRight="5px" paddingTop="2px">
                                    <Air height="24" width="24" color="#001B4F" />
                                </Grid>
                                {renderAirQualityLevel(aqRoomData.roomAqi)}
                                <Grid item xs={1.3} />
                                <Grid item xs={1.4} paddingTop="2px" position="relative" left="-4px">
                                    <VOC height="24" width="24" color="#001B4F" />
                                </Grid>
                                {renderVOCLevel(aqRoomData.voc)}
                            </Grid>
                        </Box>
                    )}
                </LoadingWrapper>
            </CardContent>
        </Card>
    );
};

export default HomeCard;
