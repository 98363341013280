import axios, { AxiosInstance } from "axios";
import { ApiContextState, ApiRequestConfig } from "axios-types";

import { HttpConfig } from "configs";
import { ApiRequest, WrappedResponse } from "app-api";
import { setNotifier } from "context/notification";
import {
    ERROR_YOU_DO_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION,
    SERVER_ERROR_CODES_STARTING_POINT,
    CLIENT_ERROR_CODES_STARTING_POINT,
    REDIRECTING_CODES_STARTING_POINT,
    ERROR_INTERNAL_SERVER_ERROR,
    SUCCESS_CODES_STARTING_POINT,
} from "constants/common.constants";
import StatusCodes from "enums/status-code.enums";

export function wrap<RequestType>(contextConfig: ApiContextState, _: ApiRequestConfig<RequestType>): AxiosInstance {
    const instance = axios.create(contextConfig);
    return instance;
}

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
const applyErrorToNotificationBar = (error: any) => {
    if (error?.response?.status >= SERVER_ERROR_CODES_STARTING_POINT) {
        setNotifier()({ type: "error", message: ERROR_INTERNAL_SERVER_ERROR });
        return;
    }
    if (
        error?.response?.data &&
        error?.response?.data?.message &&
        error?.response?.status &&
        error?.response?.status >= CLIENT_ERROR_CODES_STARTING_POINT
    ) {
        customErrorNotifier(error);
    }
};

const customErrorNotifier = (error: Error | any) => {
    const setNotifierFunc = setNotifier();
    let errorMessage = "";
    switch(error?.response?.status) {
        case StatusCodes.FORBIDDEN:
            errorMessage = ERROR_YOU_DO_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION;
            break;
        default:
            errorMessage = error?.response?.data?.message;
    }
    setNotifierFunc({
        type: "error",
        message: errorMessage,
    });
};

export default async function ExecuteAsync<RequestType, ResponseType>({
    baseURL,
    method,
    url,
    data,
    signal,
    disableNotification,
}: ApiRequest<RequestType>): Promise<ResponseType | undefined> {
    try {
        const requestConfig = {
            method,
            url,
            data,
            signal,
            validateStatus: (status: number) =>
                status >= SUCCESS_CODES_STARTING_POINT && status < REDIRECTING_CODES_STARTING_POINT,
        } as ApiRequestConfig<RequestType>;
        const axiosConfig = Object.assign({}, HttpConfig, requestConfig, {
            baseURL,
        });
        const instance = wrap<RequestType>(axiosConfig, requestConfig);
        const response = await instance.request(requestConfig);
        const schemedData = response.data as WrappedResponse<ResponseType>;
        return schemedData?.data;
    } catch (error: any) {
        if (error.response) {
            console.error("Response Error Status Code:-", error?.response?.status);
            console.error("Response Error Message:-", error?.response?.data?.message);
            // Call the notification if does not disable
            if (!disableNotification) applyErrorToNotificationBar(error);
        } else if (error.request) {
            console.error("Request Error:-", error?.request);
        } else if (error?.message !== "canceled") {
            console.error("Error Message:-", error?.message);
        }
        throw error;
    }
}
