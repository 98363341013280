import { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "store";
import {
    checkLocationIdChanged,
    FetchFunctionParams,
    makeApiCallWithUpdateTime,
} from "utils/common";
import { BikeRackStatus } from "types/chart-configs/enums/common";
import HistoricalOccupancyChart from "features/app-cycle-ways/chart/HistoricalOccupancyChart";
import { HistoricalQueryParams, HistoricalResponse } from "types";
import { executeGetHistoricalOccupancyRequest } from "api/common/realtime-api";
import NetOccupancyChart from "features/app-cycle-ways/chart/NetOccupancyChart";
import { Classification } from "enums/classifications.enum";
import { setGraphLoading } from "reducers/graph.reducer";
import { executeGetDetectionsOvertimeRequest } from "api/common/detections.api";
import { useSelector } from "react-redux";

export interface DetectedVehicles {
    classification?: string[];
    fromTime: string;
    toTime: string;
    scaleUnit: string | undefined;
    locationId?: string;
    field1?: string;
}

export interface VehiclesOvertime {
    classifications?: string[];
    fromTime: string;
    toTime: string;
    scaleUnit: string | undefined;
    field1?: string;
}

export interface IncidentsOvertime {
    locationId: string;
    fromTime: string;
    toTime: string;
}

export interface VehiclesOvertimeResponse {
    classification: string;
    count: string;
    inTime: string;
}

export interface ExtraParams {
    [key: string]: string | string[];
}

const LocationChartLayout = () => {
    const [detectedVehiclesInByEntrance, setDetectedVehiclesInByEntrance] = useState<VehiclesOvertimeResponse[]>([]);
    const [detectedVehiclesOutByExit, setDetectedVehiclesOutByExit] = useState<VehiclesOvertimeResponse[]>([]);
    const [historicalOccupancy, setHistoricalOccupancy] = useState<HistoricalResponse[]>([]);
    const [dataLoading, setDataLoading] = useState<Record<string, boolean>>({});
    const detectedVehiclesInByEntranceAbortControllerRef = useRef<AbortController | null>(null);
    const detectedVehiclesOutByClassificationAbortControllerRef = useRef<AbortController | null>(null);
    const detectedVehiclesOutByExitAbortControllerRef = useRef<AbortController | null>(null);
    const getHistoricalOccupancyAbortControllerRef = useRef<AbortController | null>(null);
    const { startTime, endTime, timeType, selectedRange } = useSelector((state) => state.time);
    const { locations } = useSelector((state) => state.newLocation);
    const { locationId } = useParams();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setGraphLoading(true));
        setDetectedVehiclesInByEntrance([]);
        setDetectedVehiclesOutByExit([]);
        setHistoricalOccupancy([]);
        makeApiCallWithUpdateTime(selectedRange, { startTime, endTime }, fetchData, dispatch);
        return () => {
            detectedVehiclesInByEntranceAbortControllerRef.current?.abort();
            detectedVehiclesOutByExitAbortControllerRef.current?.abort();
            getHistoricalOccupancyAbortControllerRef.current?.abort();
        };
    }, [startTime, endTime, locationId]);

    useEffect(() => {
        return () => {
            setDetectedVehiclesInByEntrance([]);
            setDetectedVehiclesOutByExit([]);
            setHistoricalOccupancy([]);
        };
    }, []);

    const fetchData = async ({ startTime, endTime }: FetchFunctionParams) => {
        const commonParams = {
            fromTime: startTime,
            toTime: endTime,
            scaleUnit: timeType,
        };
        if (locationId) {
            await Promise.all([
                getDetectedVehiclesInByEntrance(locationId, commonParams, {
                    classification: [BikeRackStatus.ARRIVAL],
                }),
                getDetectedVehiclesOutByExit(locationId, commonParams, {
                    classification: [BikeRackStatus.DEPARTURE],
                }),
                getHistoricalOccupancyData({
                    locationId,
                    classification: Classification.UNATTENDED_BICYCLE,
                    fromTime: startTime,
                    toTime: endTime,
                }),
            ]);
        }
        dispatch(setGraphLoading(false));
    };

    async function getDetectedVehiclesInByEntrance(
        locationId: string,
        data: VehiclesOvertime,
        specificParams?: ExtraParams,
    ): Promise<void> {
        try {
            setDataLoading((prev) => ({ ...prev, arrival: true }));
            detectedVehiclesInByEntranceAbortControllerRef.current = new AbortController();
            const { signal } = detectedVehiclesInByEntranceAbortControllerRef.current;
            const aggregatedParams = { ...data, ...specificParams };
            const response: VehiclesOvertimeResponse[] = await executeGetDetectionsOvertimeRequest(
                locationId,
                aggregatedParams,
                {
                    signal,
                    disableNotification: checkLocationIdChanged(locationId, locations),
                },
            );
            if (response) setDetectedVehiclesInByEntrance(response);
        } catch (e) {
            console.log(e);
        } finally {
            setDataLoading((prev) => ({ ...prev, arrival: false }));
        }
    }

    async function getDetectedVehiclesOutByExit(
        locationId: string,
        data: VehiclesOvertime,
        specificParams?: ExtraParams,
    ): Promise<void> {
        try {
            setDataLoading((prev) => ({ ...prev, departure: true }));
            detectedVehiclesOutByExitAbortControllerRef.current = new AbortController();
            const { signal } = detectedVehiclesOutByExitAbortControllerRef.current;
            const aggregatedParams = { ...data, ...specificParams };
            const response: VehiclesOvertimeResponse[] = await executeGetDetectionsOvertimeRequest(
                locationId,
                aggregatedParams,
                {
                    signal,
                    disableNotification: checkLocationIdChanged(locationId, locations),
                },
            );
            if (response) setDetectedVehiclesOutByExit(response);
        } catch (e) {
            console.log(e);
        } finally {
            setDataLoading((prev) => ({ ...prev, departure: false }));
        }
    }

    async function getHistoricalOccupancyData(queryParams: HistoricalQueryParams): Promise<void> {
        try {
            setDataLoading((prev) => ({ ...prev, historical: true }));
            detectedVehiclesOutByClassificationAbortControllerRef.current = new AbortController();
            const { signal } = detectedVehiclesOutByClassificationAbortControllerRef.current;
            const response = await executeGetHistoricalOccupancyRequest(queryParams, {
                signal,
                disableNotification: checkLocationIdChanged(locationId, locations),
            });
            if (response) setHistoricalOccupancy(response);
        } catch (e) {
            console.log(e);
        } finally {
            setDataLoading((prev) => ({ ...prev, historical: false }));
        }
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
                <HistoricalOccupancyChart
                    title="Occupancy"
                    data={historicalOccupancy}
                    loading={dataLoading.historical}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
                <NetOccupancyChart
                    data={[...detectedVehiclesInByEntrance, ...detectedVehiclesOutByExit]}
                    title="Detected bicycle count by entrance and exit"
                    loading={dataLoading.arrival || dataLoading.departure}
                />
            </Grid>
        </Grid>
    );
};

export default LocationChartLayout;
