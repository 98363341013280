// This file can be used for defining incidents related enums

export enum IncidentModalChangeablePropertyType {
    REQUEST_VIDEO = "REQUEST_VIDEO",
    ASSIGNEE = "ASSIGNEE",
    REGO = "REGO",
    SALES_FORCE_ID = "SALES_FORCE_ID",
    STATUS = "STATUS",
}

export enum IncidentGroupByColumn {
    INCIDENT = "incident",
    SITE_ID = "siteId",
}

export enum IncidentType {
    CAR_IN_TAXIRANK = "car_in_taxirank",
    DOUBLE_PARKING = "double_parking",
    STANDBY_TAXI = "standby_taxi",
}

export enum PresetFilterType {
    EXCLUDE_T_PLATE_IN_CAR_IN_TAXIRANK = "Exclude T Plate in car_in_taxirank",
}
