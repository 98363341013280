import { Box, Chip, CircularProgress, Grid, IconButton, InputAdornment, Typography } from "@mui/material";
import IotVisionTextFiled from "components/common/IotVisionTextFiled";
import React from "react";
import { FaPen } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { TiTick } from "react-icons/ti";

interface IIncidentModalTextFieldProps {
    onClose: () => void;
    onSave: () => void;
    value?: string;
    isEdited: boolean;
    isEditable: boolean;
    onClickEdit: () => void;
    onChange: (inputText: string) => void;
    label: string;
    loading: boolean;
    isSmallScreen: boolean;
}

const IncidentModalTextField = ({
    onClose,
    onSave,
    value,
    isEdited,
    isEditable,
    onClickEdit,
    onChange,
    label,
    loading,
    isSmallScreen,
}: IIncidentModalTextFieldProps) => {
    const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <>
            <Grid item xs={6} sm={6} md={5} lg={4} sx={{ display: "flex", alignItems: "center" }}>
                <Typography fontWeight="600" fontSize="14px" color="#21315B">
                    {label}
                </Typography>
            </Grid>
            {isEditable ? (
                <>
                    <Grid item xs={4} sm={4} md={5} lg={6}>
                        <IotVisionTextFiled
                            sx={{
                                width: "100%",
                                flexGrow: 1,
                                fontSize: "14px",
                            }}
                            onChange={(event) => {
                                onChange(event.target.value);
                            }}
                            value={value}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {loading ? <CircularProgress color="primary" size={20} /> : null}
                                    </InputAdornment>
                                ),
                            }}
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2} justifyContent="end">
                        <IconButton
                            size="small"
                            onClick={onSave}
                            sx={{
                                backgroundColor: "#6B439D",
                                marginRight: "10px",
                                "&:hover": {
                                    backgroundColor: "#6B439D",
                                },
                            }}
                        >
                            <TiTick size="20px" color="white" />
                        </IconButton>

                        <IconButton
                            size="medium"
                            onClick={onClose}
                            sx={{
                                border: 1,
                                borderColor: "#6B439D",
                                marginRight: "0px",
                            }}
                        >
                            <ImCross size="14px" color="#6B439D" />
                        </IconButton>
                    </Grid>
                </>
            ) : (
                <Grid item xs={6} sm={6} md={7} lg={8}>
                    <Chip
                        size="medium"
                        sx={{
                            width: isSmallScreen ? "320px" : "400px",
                            display: "flex",
                            justifyContent: "left",
                            height: "37px",
                            borderRadius: "37px",
                        }}
                        label={
                            <Box
                                width={isSmallScreen ? "290px" : "370px"}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Grid container wrap="nowrap" spacing={1} flexDirection="row" alignItems="center">
                                    <Grid
                                        item
                                        xs={10.5}
                                        lg={11}
                                        container
                                        wrap="nowrap"
                                        flexDirection="row"
                                        alignItems="center"
                                    >
                                        <Typography fontSize="14px" fontWeight="400" color="#21315B" noWrap>
                                            {value || "N/A"}{" "}
                                        </Typography>
                                        {isEdited && (
                                            <Typography
                                                fontSize="10px"
                                                fontWeight="400"
                                                color="#75BAE1"
                                                fontStyle="italic"
                                                marginLeft="5px"
                                            >
                                                {" "}
                                                Edited
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid
                                        item
                                        style={{
                                            maxWidth: "50px",
                                        }}
                                        flexDirection="row"
                                        alignItems="right"
                                        xs={1.5}
                                        lg={1}
                                    >
                                        <IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            component="span"
                                            size="small"
                                            onMouseDown={handleMouseDown}
                                            onClick={onClickEdit}
                                        >
                                            <FaPen color="#21315B" />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                        variant="outlined"
                    />
                </Grid>
            )}
        </>
    );
};

export default IncidentModalTextField;
