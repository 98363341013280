import { TextField, TextFieldProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import React from "react";

/* eslint @typescript-eslint/no-unused-vars: ["off"] */
interface IIotVisionTextFiled {
    additionalProps?: PropTypes.ReactElementLike;
}
const CustomizedTextFiled = styled(TextField)(
    ({ theme }) => `
      color: ${theme.palette.primary.main};

      & .MuiInputBase-root {
        border-radius:20px
    }
      & input {
        font-size: 14px ;
        color: ${theme.palette.secondary.main};
        margin-left: 3px ;
      }
`,
);
const IotVisionTextFiled: React.FC<IIotVisionTextFiled & JSX.IntrinsicAttributes & TextFieldProps> = ({
    additionalProps,
    ...rest
}) => {
    return <CustomizedTextFiled size="small" {...rest} />;
};

export default IotVisionTextFiled;
