/* eslint-disable no-unneeded-ternary */
import { Card, Grid, IconButton } from "@mui/material";
import { Bar } from "react-chartjs-2";
import { ChartData, ChartOptions } from "chart.js";
import {
    REPORT_FONT_FAMILY,
    REPORT_LEGEND_FONT_SIZE,
    REPORT_SCALES_FONT_SIZE,
    REPORT_TITLE_FONT_SIZE,
    REPORT_TOOLTIP_BODY_FONT_SIZE,
    REPORT_TOOLTIP_TITLE_FONT_SIZE,
} from "constants/report.constants";
import { useState } from "react";
import { CgArrowsExpandRight } from "react-icons/cg";
import FullScreenDialog from "components/dialogs/FullScreenDialog";

export interface IStackedBarChartProps {
    title: string;
    data: ChartData<"bar">;
    options?: ChartOptions<"bar">;
    chartCardHeight?: number;
    chartCardWidth?: number;
    isExpandable?: boolean;
}

const StackedBarChart = (props: IStackedBarChartProps) => {
    const { data, title, chartCardHeight, chartCardWidth, isExpandable } = props;
    const [displayExpandedView, setDisplayExpandedView] = useState<boolean>(false);
    let { options } = props;
    if (!options) {
        options = {
            maintainAspectRatio: false,
            plugins: {
                title: {
                    display: isExpandable ? false : true,
                    text: title,
                    font: {
                        family: REPORT_FONT_FAMILY,
                        size: REPORT_TITLE_FONT_SIZE,
                    },
                },
                legend: {
                    labels: {
                        font: {
                            family: REPORT_FONT_FAMILY,
                            size: REPORT_LEGEND_FONT_SIZE,
                        },
                    },
                },
                tooltip: {
                    titleFont: {
                        family: REPORT_FONT_FAMILY,
                        size: REPORT_TOOLTIP_TITLE_FONT_SIZE,
                    },
                    bodyFont: {
                        family: REPORT_FONT_FAMILY,
                        size: REPORT_TOOLTIP_BODY_FONT_SIZE,
                    },
                },
            },
            responsive: true,
            scales: {
                x: {
                    stacked: true,
                    grid: {
                        display: true,
                    },
                    title: {
                        font: {
                            family: REPORT_FONT_FAMILY,
                            size: REPORT_SCALES_FONT_SIZE,
                        },
                    },
                },
                y: {
                    stacked: true,
                    grid: {
                        display: true,
                    },
                    title: {
                        font: {
                            family: REPORT_FONT_FAMILY,
                            size: REPORT_SCALES_FONT_SIZE,
                        },
                    },
                },
            },
        };
    }

    return (
        <Card
            className="graph-card"
            elevation={0}
            style={{
                height: chartCardHeight,
                width: chartCardWidth,
                paddingBottom: "50px",
            }}
        >
            {isExpandable ? (
                <>
                    <FullScreenDialog
                        modalShow={displayExpandedView}
                        onClose={() => setDisplayExpandedView(false)}
                        childComponent={
                            <>
                                <Bar options={options} data={data} />
                            </>
                        }
                    />
                    <Grid container justifyContent="center" alignItems="center" direction="row">
                        <Grid item xs={11} sm={11} md={11} lg={11}>
                            <div className="graph-title">{title}</div>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} textAlign="right">
                            <IconButton
                                className="expand-btn-sub"
                                onClick={() => setDisplayExpandedView(true)}
                                disableRipple
                            >
                                <CgArrowsExpandRight />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Bar options={options} data={data} />
                </>
            ) : (
                <Bar options={options} data={data} />
            )}
        </Card>
    );
};

export default StackedBarChart;
