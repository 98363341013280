// Time period used as the default value for historical data
export const DEFAULT_HISTORICAL_ANALYSIS_DATA_TIME_PERIOD = 86400000;

// Time formatting related constants
export const HOUR_24_WITH_MINUTES = "24.00";
export const HOUR_00_WITH_MINUTES = "00.00";
export const MILLISECONDS_TO_HOUR = 3600000;
export const MILLISECONDS_TO_MINUTE = 1000 * 60;
export const MILLISECONDS_TO_SECONDS = 1000;
export const MINUTES_TO_HOUR = 60;
export const HOUR_24 = 24;
export const MILLISECONDS_TO_DAY = MILLISECONDS_TO_HOUR * HOUR_24;

export const DATE_FORMAT_WITH_SECONDS = "dd/MM/yyyy hh:mm:ss aaa";
export const DEFAULT_DATE_FORMAT = "dd/MM/yyyy hh:mm aaa";
export const MINUTES_15_IN_MILLISECOND = 15 * 60 * 1000;
export const ONE_DAY_IN_MILLISECOND = 24 * 60 * 60 * 1000;
export const RETENTION_PERIOD_IN_DAYS = 30; // In days
