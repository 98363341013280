import { useEffect } from "react";
import { Box, Container, CssBaseline, Grid } from "@mui/material";
import { Navigate, Outlet, useLocation, useMatch } from "react-router-dom";
import { appPrefix, appDefaultRoute } from "pages/app-cycle-ways";
import { LoadingWrapper } from "components/wrappers/LoadingWrapper";
import { useSelector } from "react-redux";
import { Applications } from "configs";
import { useAppDispatch } from "store";
import { setRootPath } from "reducers/auth.reducer";
import LoadingLayout from "layouts/common/loader/LoadingLayout.tsx";
import { useShouldRenderPageGuard } from "hooks/useShouldRenderPageGuard";
import { APPLICATION_CYCLEWAYS_BIKE_RACKS } from "constants/app.constants";
import SidebarCycleWays from "./drawer/SidebarCycleWays";
import NavbarCycleWays from "./header/NavbarCycleWays";

const { AppIdCycleways } = Applications;
const CycleWaysLayout = (): JSX.Element => {
    const match = useMatch(appPrefix);
    const location = useLocation();
    const { locationsTree } = useSelector((state) => state.newLocation);
    const { shouldRender } = useShouldRenderPageGuard(AppIdCycleways);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (location.pathname.includes(APPLICATION_CYCLEWAYS_BIKE_RACKS))
            dispatch(setRootPath(`/cycle-ways/${APPLICATION_CYCLEWAYS_BIKE_RACKS}`));
        else dispatch(setRootPath("/cycle-ways/home"));
    }, [location]);

    if (match) {
        return <Navigate to={appDefaultRoute} replace />;
    }

    return shouldRender ? (
        <Container maxWidth="xl" disableGutters>
            <CssBaseline />
            <NavbarCycleWays />
            <Grid className="custom-container">
                <SidebarCycleWays />

                <div className="content">
                    {locationsTree.length > 0 ? (
                        <Outlet />
                    ) : (
                        <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                            <LoadingWrapper isLoading />
                        </Box>
                    )}
                </div>
            </Grid>
        </Container>
    ) : (
        <LoadingLayout />
    );
};

export default CycleWaysLayout;
