import React from "react";
import { BrowserRouter } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import AppRouter from "app.router";
import AuthProvider from "context/auth";
import "./App.scss";
import CustomThemeProvider from "theme/theme-provider";
import { NotificationProvider } from "./context/notification/index";

const App = (): React.ReactElement => {
    return (
        <BrowserRouter>
            <CssBaseline />
            <CustomThemeProvider>
                <NotificationProvider>
                    <AuthProvider>
                        <AppRouter />
                    </AuthProvider>
                </NotificationProvider>
            </CustomThemeProvider>
        </BrowserRouter>
    );
};

export default App;
