/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import camLowIcon from "assets/images/indicators/cam_low.png";
import camModIcon from "assets/images/indicators/cam_moderate.png";
import camHighIcon from "assets/images/indicators/cam_high.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { InfoWindow, Marker } from "@react-google-maps/api";
import { Grid, IconButton, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { APPLICATION_CYCLEWAYS_BIKE_RACKS } from "constants/app.constants";

const InfoMarkerCycleways = (props: any) => {
    const { key, location, clusterer } = props;
    const { pathname } = useLocation();
    const { rootPath } = useSelector((state) => state.auth);
    const [showInfo, setShowInfo] = useState(false);
    let camIcon;
    if (location.count < 3) {
        camIcon = camLowIcon;
    } else if (location.count < 7) {
        camIcon = camModIcon;
    } else {
        camIcon = camHighIcon;
    }

    const checkApplication = (application: string) => {
        return pathname.includes(application);
    }

    return (
        <Marker
            onLoad={(marker: any) => {
                marker.count = location.count;
            }}
            icon={camIcon}
            key={key}
            position={location}
            clusterer={clusterer}
            onClick={() => setShowInfo(!showInfo)}
        >
            {showInfo && (
                <InfoWindow position={location}>
                    <Grid container justifyContent="center" alignItems="center" p={1}>
                        <Grid item xs={11} m={0}>
                            <Typography variant="subtitle1">{location.address}</Typography>
                            <Typography variant="caption">
                                {location.city},{location.state},{location.zip}
                            </Typography>
                            <Typography marginLeft={1} variant="caption" color="text.secondary">
                                {location.lat}, {location.lng}
                            </Typography>
                            {!checkApplication(`/${APPLICATION_CYCLEWAYS_BIKE_RACKS}`) && (
                                <Typography variant="subtitle1" style={{ fontSize: "12px" }}>
                                    Cyclists Count: {location.count}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={1} m={0}>
                            <IconButton component={Link} to={`${rootPath}/location/${location.id}`}>
                                <ArrowForwardIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </InfoWindow>
            )}
        </Marker>
    );
};

export default InfoMarkerCycleways;
