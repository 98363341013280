import { Grid } from "@mui/material";
import { AppParkAndRidePrefix } from "constants/app.constants";
import IncidentPageHeader from "features/app-park-and-ride/header/IncidentTableHeader";
import IncidentsPage from "features/app-park-and-ride/IncidentsPage";
import React from "react";
import { Helmet } from "react-helmet";
import platformityLogo from "assets/images/platformity-favicon-logo.png";

interface IncidentsProps {}

const Incidents: React.FC<IncidentsProps> = () => {
    return (
        <>
            <Helmet>
                <title>{AppParkAndRidePrefix} | Incidents</title>
                <link rel="icon" id="pnr-incident" type="image/png" href={platformityLogo} sizes="32x32" />
            </Helmet>
            <Grid container>
                <Grid item xs={12}>
                    <IncidentPageHeader lastUpdated="" />
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "24px" }}>
                    <IncidentsPage />
                </Grid>
            </Grid>
        </>
    );
};

export default Incidents;
