export const REPORT_FONT_FAMILY =  "Poppins"
export const REPORT_LEGEND_FONT_SIZE =  12
export const REPORT_TOOLTIP_BODY_FONT_SIZE =  11
export const REPORT_TOOLTIP_TITLE_FONT_SIZE =  12
export const REPORT_SCALES_FONT_SIZE =  12
export const REPORT_TITLE_FONT_SIZE = 16

// placeholders
export const PLACEHOLDER_IN_TIME = "In Time";

export const PLATE_SEARCH_REPORT_FILE_NAME = "report.csv";