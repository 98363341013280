import React, { useEffect, useRef, useState } from "react";
import { Button, Grid } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridRowsProp } from "@mui/x-data-grid";
import IotVisionTable from "components/common/IotVisionTable";
import PaperWrapper from "components/wrappers/PaperWrapper";
import { SortOrder } from "enums/sorting.enum";
import {
    executeGetIncidentsVideoRequests,
    executeObtainFileDownloadPresignedURL,
} from "api/app-taxi-ranking/taxi-incidents.api";
import { CommonObjectLiteral } from "types/reports/interfaces";
import IotVisionToolTips from "components/common/IotVisionToolTips";
import DateView from "components/dates/DateView";
import { enAU } from "date-fns/locale";
import { useNavigate, useSearchParams } from "react-router-dom";
import { camelCaseToTitleCase, dateRangeStringCreator } from "utils/common";
import { FaThList } from "react-icons/fa";
import SectionHeading from "components/headings/SectionHeading";
import IotVisionButton from "components/common/IotVisionButton";
import { styled } from "@mui/styles";
import ProtectedView from "components/common/ProtectedView";
import { SCOPES } from "enums/scopes.enum";
import { useSelector } from "react-redux";
import { useQueryParams } from "hooks/useQueryParams";
import { IDevice, LoadingStates, SelectedRowStates } from "types";
import { FileStatus } from "enums/file-status.enum";
import StatusCodes from "enums/status-code.enums";
import {
    INCIDENT_TYPE_MANUAL_VIDEO_REQUEST,
    VIDEO_IS_NO_LONGER_AVAILABLE,
} from "constants/taxirank-incidents.constants";
import { saveFile } from "utils/file";
import { incidentFileStatusMapping } from "configs/incidents";
import { Location } from "reducers/newLocation.reducer";
import VideoPlayer from "components/players/video-player";
import { executeGetAllDevicesRequest } from "api/app-taxi-ranking/taxi-video-requests.api";
import VideoRequestsFilterPanel from "./video-requests-list-page-filter-panel";

const pageSize = 10;

const CustomizedSectionHeading = styled(SectionHeading)(() => ({
    background: "transparent !important",
    "& .icon-div": {
        background: "transparent !important",
    },
}));

const CustomizedIotVisionCreateButton = styled(IotVisionButton)(() => ({
    color: "white !important",
    height: "37px",
    display: "flex",
    alignItems: "center",
    marginTop: "4px",
    marginRight: "25px",
}));

const CustomizedTypographyDiv = styled("div")(() => ({
    color: "white !important",
    whiteSpace: "nowrap",
    marginLeft: "10px",
}));

const CustomizedViewButton = styled(Button)(() => ({
    color: "#6B439D !important",
    textDecoration: "#6B439D underline !important",
    "&:hover": {
        backgroundColor: "transparent !important",
    },
}));

const CustomizedDownloadButton = styled(Button)(() => ({
    color: "#3D9FC9 !important",
    textDecoration: "#3D9FC9 underline !important",
    "&:hover": {
        backgroundColor: "transparent !important",
    },
}));

const CustomizedSpan = styled("span")(() => ({
    height: "fit-content",
}));

const tableColumns: GridColDef[] = [
    {
        field: "location",
        headerName: "Location",
        renderCell: (params: GridRenderCellParams) => (
            <IotVisionToolTips title={params.row.location || ""} arrow>
                <div>{params.row.location || ""}</div>
            </IotVisionToolTips>
        ),
        flex: 1.5,
        sortable: false,
        disableColumnMenu: true,
    },
    {
        field: "period",
        headerName: "Period",
        flex: 1.5,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => (
            <IotVisionToolTips
                title={<span>{dateRangeStringCreator(params.row?.meta?.startAt, params?.row.meta?.endAt, "Ppp")}</span>}
                arrow
            >
                <span>{dateRangeStringCreator(params.row?.meta?.startAt, params.row?.meta?.endAt, "Ppp")}</span>
            </IotVisionToolTips>
        ),
    },
    {
        field: "createdAt",
        headerName: "Requested At",
        renderCell: (params: GridRenderCellParams) => (
            <IotVisionToolTips
                title={
                    <span>
                        <DateView dateFormat="Ppp" options={{ locale: enAU }}>
                            {params.row.createdAt}
                        </DateView>
                    </span>
                }
                arrow
            >
                <span>
                    <DateView dateFormat="Ppp" options={{ locale: enAU }}>
                        {params.row.createdAt}
                    </DateView>
                </span>
            </IotVisionToolTips>
        ),
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
    },
    {
        field: "createdBy",
        headerName: "Requested By",
        renderCell: (params: GridRenderCellParams) => (
            <IotVisionToolTips title={params.row.createdBy?.username || ""} arrow>
                <div>{params.row.createdBy?.username || ""}</div>
            </IotVisionToolTips>
        ),
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
    },
    {
        field: "status",
        headerName: "Status",
        renderCell: (params: GridRenderCellParams) => {
            const status =
                incidentFileStatusMapping[params.row.file?.status]?.displayValue ||
                camelCaseToTitleCase(params.row.file?.status || "");
            return (
                <IotVisionToolTips title={status} arrow>
                    <div
                        style={
                            params.row.file?.status
                                ? {
                                      color: incidentFileStatusMapping[params.row.file?.status]?.color,
                                      textShadow: `0px 0px 0px ${
                                          incidentFileStatusMapping[params.row.file?.status]?.color
                                      }`,
                                  }
                                : {}
                        }
                    >
                        {status}
                    </div>
                </IotVisionToolTips>
            );
        },
        flex: 0.7,
        sortable: false,
        disableColumnMenu: true,
    },
];

function IncidentsVideoRequestsListTable() {
    const incidentVideoRequestsAbortControllerRef = useRef<AbortController | null>(null);
    const videoPresignedURLAbortControllerRef = useRef<AbortController | null>(null);
    const videoGpuDeviceAbortControllerRef = useRef<AbortController | null>(null);
    const viewButtonRef = useRef<HTMLButtonElement | null>(null);
    let { page } = useQueryParams();
    if (!page) page = 1;
    const [searchParams, setUrlParams] = useSearchParams();
    const navigate = useNavigate();
    const { leafLocations } = useSelector((state) => state.newLocation);
    const { appCode } = useSelector((store) => store.auth);
    const [loading, setLoading] = useState<LoadingStates>({
        tableLoading: false,
        playerLoading: false,
        downloadLoading: false,
    });
    const [isPlayerOpen, setIsPlayerOpen] = useState<boolean>(false);
    const [filePresignedURL, setFilePresignedURL] = useState<string>("");
    const [selectedRowId, setSelectedRowId] = useState<SelectedRowStates>({
        viewButton: "",
        downloadButton: "",
    });
    const [incidentsVideoRequestList, setIncidentsVideoRequestList] = useState<GridRowsProp>([]);
    const [rowsCount, setRowsCount] = useState<number>(0);
    const [incidentGpuDevice, setIncidentGpuDevice] = useState<IDevice | null>(null);

    useEffect(() => {
        getAllVideoRequests(Object.fromEntries([...searchParams]));
        return () => {
            incidentVideoRequestsAbortControllerRef.current?.abort();
        };
    }, [searchParams]);

    const dynamicTableColumns: GridColDef[] = [
        {
            field: "view",
            headerName: "",
            renderCell: (params: GridRenderCellParams) => {
                const viewButton = (
                    <CustomizedViewButton
                        variant="text"
                        ref={viewButtonRef}
                        sx={{
                            textTransform: "capitalize",
                            opacity: params.row.file?.status !== FileStatus.AVAILABLE ? 0.5 : 1,
                        }}
                        disableRipple
                        disabled={params.row.file?.status !== FileStatus.AVAILABLE || loading.playerLoading}
                        onClick={() => playIncidentVideo(params.row)}
                    >
                        {loading.playerLoading && selectedRowId.viewButton === params.row.id ? "Loading..." : "View"}
                    </CustomizedViewButton>
                );
                return (
                    <ProtectedView filterScopes={[SCOPES.VIEW_FILES_INCIDENTS]}>
                        {params.row.file?.status === FileStatus.EXPIRED ? (
                            <IotVisionToolTips title={VIDEO_IS_NO_LONGER_AVAILABLE} arrow>
                                <CustomizedSpan>
                                    {viewButton}
                                </CustomizedSpan>
                            </IotVisionToolTips>
                        ) : (
                            viewButton
                        )}
                    </ProtectedView>
                );
            },
            flex: 0.7,
            align: "center",
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "download",
            headerName: "",
            renderCell: (params: GridRenderCellParams) => {
                const downloadButton = (
                    <CustomizedDownloadButton
                        variant="text"
                        sx={{
                            textTransform: "capitalize",
                            opacity: params.row.file?.status !== FileStatus.AVAILABLE ? 0.5 : 1,
                        }}
                        disableRipple
                        disabled={params.row.file?.status !== FileStatus.AVAILABLE || loading.downloadLoading}
                        onClick={() => downloadFileUsingPresignedURL(params.row)}
                    >
                        {loading.downloadLoading && selectedRowId.downloadButton === params.row.id
                            ? "Loading..."
                            : "Download"}
                    </CustomizedDownloadButton>
                );
                return (
                    <ProtectedView filterScopes={[SCOPES.DOWNLOAD_FILES_INCIDENTS]}>
                        {params.row.file?.status === FileStatus.EXPIRED ? (
                            <IotVisionToolTips title={VIDEO_IS_NO_LONGER_AVAILABLE} arrow>
                                <CustomizedSpan>
                                    {downloadButton}
                                </CustomizedSpan>
                            </IotVisionToolTips>
                        ) : (
                            downloadButton
                        )}
                    </ProtectedView>
                );
            },
            flex: 0.7,
            align: "center",
            sortable: false,
            disableColumnMenu: true,
        },
    ];

    async function downloadFileUsingPresignedURL(selectedIncident: CommonObjectLiteral) {
        setSelectedRowId((prev: SelectedRowStates) => ({ ...prev, downloadButton: selectedIncident.id }));
        setLoading((prev: LoadingStates) => ({ ...prev, downloadLoading: true }));
        const extension = selectedIncident?.file?.fileKey?.split(".").pop();
        const fileName = `${selectedIncident?.idInc}-${selectedIncident?.incident}.${extension}`;
        if (selectedIncident && selectedIncident?.file?.fileKey) {
            await saveFile(() => getIncidentVideoPresignedURL(selectedIncident.file?.id, fileName), fileName);
        }
        setLoading((prev: LoadingStates) => ({ ...prev, downloadLoading: false }));
    }

    async function playIncidentVideo(selectedIncident: CommonObjectLiteral) {
        const promises = [];
        setSelectedRowId((prev: SelectedRowStates) => ({ ...prev, viewButton: selectedIncident.id }));
        promises.push(getIncidentGpuDevices(selectedIncident?.locationId));
        setLoading((prev: LoadingStates) => ({ ...prev, playerLoading: true }));
        if (selectedIncident?.file?.id) promises.push(getIncidentVideoPresignedURL(selectedIncident?.file?.id));
        const [ deviceList ] = await Promise.all(promises);
        const matchedDevice = Array.isArray(deviceList) ? deviceList?.find(
            (device: IDevice) => device.serialId === selectedIncident?.meta?.cameraId,
        ) : null;
        setIncidentGpuDevice(matchedDevice || null);
        setIsPlayerOpen(true);
        setLoading((prev: LoadingStates) => ({ ...prev, playerLoading: false }));
    }

    const onIncidentTablePageChange = (newPage: number) => {
        setUrlParams({
            ...Object.fromEntries([...searchParams]),
            page: (newPage + 1).toString(),
        });
    };

    const handleCreateButtonClick = () => {
        navigate("create");
    };

    const prepareLocationsForFilter = (locationList: Location[]) => {
        return locationList
            ?.filter((loc) => loc?.metadata?.canRequestEdgeVideo)
            ?.map((loc) => ({ label: loc.name, id: loc.id }));
    };

    const getAllVideoRequests = async (queryParams: CommonObjectLiteral) => {
        try {
            setLoading((prev: LoadingStates) => ({ ...prev, tableLoading: true }));
            incidentVideoRequestsAbortControllerRef.current = new AbortController();
            const { signal } = incidentVideoRequestsAbortControllerRef.current;
            const { fromTime, toTime, ...rest } = queryParams;
            const params: CommonObjectLiteral = {
                ...rest,
                type: INCIDENT_TYPE_MANUAL_VIDEO_REQUEST,
            };
            if (fromTime && toTime) params.createdAt = `${fromTime},${toTime}`;
            const response = await executeGetIncidentsVideoRequests(
                {
                    ...params,
                    limit: pageSize?.toString(),
                },
                { signal },
            );
            if (response) {
                const { total, data } = response;
                setRowsCount(total);
                data.forEach((incidentData: CommonObjectLiteral) => {
                    incidentData.location =
                        leafLocations.find((loc) => loc.id === incidentData.locationId)?.name || null;
                });
                setIncidentsVideoRequestList(data);
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: Error | any) {
            if (error?.response?.status === StatusCodes.FORBIDDEN) {
                navigate(`/${appCode}/forbidden`, { replace: true });
                return;
            }
            if (error?.response?.status === StatusCodes.NOT_FOUND) {
                navigate(`/${appCode}/video-requests/page-not-found`, { replace: true });
                return;
            }
            console.error(error);
        } finally {
            setLoading((prev: LoadingStates) => ({ ...prev, tableLoading: false }));
        }
    };

    async function getIncidentVideoPresignedURL(fileId: string, fileName?: string) {
        try {
            videoPresignedURLAbortControllerRef.current = new AbortController();
            const { signal } = videoPresignedURLAbortControllerRef.current;
            const response = await executeObtainFileDownloadPresignedURL(
                fileId,
                fileName ? { contentDisposition: `attachment; filename=${fileName}` } : {},
                { signal },
            );
            const { presignedUrl } = response;
            setFilePresignedURL(presignedUrl);
            return presignedUrl as string;
        } catch (error) {
            console.error(error);
        }
    }

    async function getIncidentGpuDevices(locationId: string) {
        try {
            videoGpuDeviceAbortControllerRef.current = new AbortController();
            const { signal } = videoGpuDeviceAbortControllerRef.current;
            const response = await executeGetAllDevicesRequest(
                { locationId },
                { signal },
            );
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    const handlePlayerClose = () => {
        viewButtonRef.current?.blur();
        setFilePresignedURL("");
        setIsPlayerOpen(false);
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={7} md={7} lg={9} xl={9} marginBottom={2}>
                    <CustomizedSectionHeading heading="Video Requests" icon={<FaThList className="icon" />} />
                </Grid>
                <ProtectedView filterScopes={[SCOPES.VIEW_CREATE_VIDEO_REQUESTS_PAGE]} shouldHide>
                    <Grid
                        item
                        xs={12}
                        sm={5}
                        md={5}
                        lg={3}
                        xl={3}
                        marginTop={0.1}
                        display="flex"
                        justifyContent="flex-end"
                    >
                        <CustomizedIotVisionCreateButton type="button" onClick={handleCreateButtonClick}>
                            <CustomizedTypographyDiv>+ New Request</CustomizedTypographyDiv>
                        </CustomizedIotVisionCreateButton>
                    </Grid>
                </ProtectedView>
            </Grid>
            <PaperWrapper>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <VideoRequestsFilterPanel
                            locationOptions={{
                                name: "Location",
                                value: prepareLocationsForFilter(leafLocations),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <IotVisionTable
                            loading={loading.tableLoading}
                            sortingMode="server"
                            sortingOrder={[SortOrder.ASC, SortOrder.DESC]}
                            columns={[...tableColumns, ...dynamicTableColumns]}
                            rows={incidentsVideoRequestList}
                            pageSize={pageSize}
                            page={page ? page - 1 : 1}
                            rowCount={rowsCount}
                            onPageChange={(newPage) => {
                                onIncidentTablePageChange(newPage);
                            }}
                            rowsPerPageOptions={[]}
                            hideFooterPagination={false}
                        />
                    </Grid>
                </Grid>
            </PaperWrapper>
            {
                isPlayerOpen && filePresignedURL && (
                    <VideoPlayer
                        filePresignedURL={filePresignedURL}
                        isOpen={isPlayerOpen}
                        onClose={handlePlayerClose}
                        options={{
                            isActiveDownload: true,
                            fileNameOptions: {
                                useCapturedTime: true,
                                gpuId: incidentGpuDevice?.name,
                            },
                        }}
                    />
                )
            }
        </>
    );
}

export default IncidentsVideoRequestsListTable;
