export enum IncidentFileStatus {
    AVAILABLE = "available",
    PENDING = "pending",
    NOT_AVAILABLE = "not-available",
    LOADING = "loading",
    NOT_REQUESTED = "not-requested",
    EXPIRED = "expired",
}

export interface IIncidentVideoItem {
    fileId: string,
    tenantId: string,
    referenceDomain: string,
    referenceId: string,
    fileKey: string,
    status: IncidentFileStatus
}

export interface IIncidentFileStatus {
    data: IIncidentVideoItem[],

}
