import { Box, Link, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

/* eslint react/jsx-curly-brace-presence: ["off"] */
/* eslint jsx-a11y/anchor-is-valid: ["off"] */
interface NotFoundProps {
    primaryErrorMessage: string;
    secondaryErrorMessage: string;
}

const NotFound: React.FC<NotFoundProps> = ({ primaryErrorMessage, secondaryErrorMessage }) => {
    const navigate = useNavigate();
    return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="70vh">
            <div>
                <Typography fontSize={"80px"} fontWeight={"600px"} color={"#7A7A7A"}>
                    404
                </Typography>
                <Typography
                    fontSize={"14px"}
                    fontWeight={"600px"}
                    color={"#7A7A7A"}
                    marginTop={"-20px"}
                    paddingLeft={"20px"}
                >
                    {primaryErrorMessage}
                </Typography>
                <Typography
                    fontSize={"10px"}
                    fontWeight={"600px"}
                    color={"#7A7A7A"}
                    marginTop={"40px"}
                    marginLeft={"-100px"}
                >
                    {secondaryErrorMessage}
                </Typography>
                <Typography fontSize={"10px"} fontWeight={"600px"} color={"#7A7A7A"}>
                    <Link sx={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                        Go back
                    </Link>{" "}
                    or choose a new direction.
                </Typography>
            </div>
        </Box>
    );
};

export default NotFound;
