import { styled } from "@mui/material/styles";
import { DataGrid, DataGridProps } from "@mui/x-data-grid";
import React from "react";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
/* eslint @typescript-eslint/no-unused-vars: ["off"] */
interface IIotVisionTable {
    additionalProps?: any;
}
const CustomizedTable = styled(DataGrid)(
    () => `
//add default customized styles
`,
);

const IotVisionTable: React.FC<IIotVisionTable & JSX.IntrinsicAttributes & DataGridProps> = ({
    additionalProps,
    ...rest
}) => {
    return <CustomizedTable paginationMode="server" autoHeight disableSelectionOnClick {...rest} />;
};

export default IotVisionTable;
