import { ApiContextState } from "axios-types";

export const isProduction = process.env.NODE_ENV === "production";

export const isMockEnabled = (process.env.REACT_APP_API_IS_MOCK_ENABLED || "false").toLowerCase() === "true";

export const AuthToken = process.env.REACT_APP_AUTH_TOKEN;

export const HttpConfig = {
    baseURL: process.env.REACT_APP_BASE_URI,
    // timeout: parseInt(process.env.REACT_APP_API_TIMEOUT || "1000"),
    // headers: {
    //     "Access-Control-Allow-Origin": "*",
    //     "Access-Control-Allow-Credentials": "true",
    //     "Access-Control-Expose-Headers": "Access-Control-*",
    //     "Access-Control-Allow-Headers":
    //         "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    //     "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS, HEAD",
    //     "Content-Type": "application/json",
    // },
    isMockEnabled,
} as ApiContextState;

export const AppDomain = process.env.REACT_APP_DOMAIN as string;

export const AppClientId = process.env.REACT_APP_CLIENT_ID as string;

export const AppAudience = process.env.REACT_APP_AUDIENCE as string;
export const GoogleKey = process.env.REACT_APP_GOOGLE_MAP_KEY as string;

export const AuthConfig = {
    ClientId: process.env.REACT_APP_CLIENT_ID as string,
    Audience: process.env.REACT_APP_AUDIENCE as string,
};

export const Applications = {
    AppIdTaxiRanking: process.env.REACT_APP_APP_ID_TAXI_RANKING as string,
    AppIdCycleways: process.env.REACT_APP_APP_ID_CYCLE_WAYS as string,
    AppIdAirQuality:
        (process.env.REACT_APP_APP_ID_AIR_QUALITY as string) || (process.env.REACT_APP_APP_ID_SENSEI as string),
    AppIdOversizedVehicles: process.env.REACT_APP_APP_ID_POSOMS as string,
    AppIdDEPTAirQuality: process.env.REACT_APP_APP_ID_DEPT_AIR_QUALITY as string,
    AppIdParkAndRide: process.env.REACT_APP_APP_ID_PARK_AND_RIDE as string,
    AppIdUngatedParking: process.env.REACT_APP_APP_ID_UNGATED_PARKING as string,
};

export const ApiEndpoints = {
    BaseUrlEndpoint: process.env.REACT_APP_API_BASE_URI as string,
};

export enum Environments {
    Dev = "dev",
    Stage = "stage",
    Prod = "prod",
}

export const ApplicationsEnvironment: Environments | undefined =
    process.env.REACT_APP_APP_ENV === Environments.Stage
        ? Environments.Stage
        : process.env.REACT_APP_APP_ENV === Environments.Dev
        ? Environments.Dev
        : undefined;
