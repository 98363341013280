import { Button, Grid, Menu, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import EventIcon from "@mui/icons-material/Event";
import { setTimeRange } from "reducers/time.reducer";
import { useAppDispatch } from "store";
import moment from "moment";
import { useSelector } from "react-redux";
import { MobileDateTimePicker } from "@mui/lab";
import IotVisionTextFiled from "components/common/IotVisionTextFiled";
import { format, isBefore, isSameMinute } from "date-fns";
import IotVisionButton from "components/common/IotVisionButton";

export enum TimeRange {
    Minutes = "minute",
    Hour = "hour",
    Day = "day",
    Week = "week",
    Month = "month",
}
export enum PickerErrors {
    shouldDisableDate = "shouldDisableDate",
    minTime = "minTime",
    maxTime = "maxTime",
}

interface DateRangePickerProps {
    isValidFunction?: (
        startDateTime: string,
        endDateTime: string,
        callBackMessage: Dispatch<SetStateAction<string>>,
    ) => void;
}
const defaultIsValidFunction = (
    startDateTime: string,
    endDateTime: string,
    setWarningMessage: Dispatch<SetStateAction<string>>,
) => {
    const isSameDates = isSameMinute(new Date(endDateTime), new Date(startDateTime));
    const isBeforeEndDate = isBefore(new Date(endDateTime), new Date(startDateTime));
    if (isSameDates) {
        setWarningMessage("Start date and end date must be different");
    } else {
        // eslint-disable-next-line no-unused-expressions
        isBeforeEndDate ? setWarningMessage("Start date must be before end date") : setWarningMessage("");
    }
};

const DateRangePicker: React.FC<DateRangePickerProps> = ({ isValidFunction = defaultIsValidFunction }) => {
    const dispatch = useAppDispatch();
    const [endDateTime, setEndDateTime] = React.useState<string>("");
    const [startDateTime, setStartDateTime] = React.useState<string>("");
    const [startDateTimePicker, setStartDateTimePicker] = React.useState<Date | null>(new Date());
    const [endDateTimePicker, setEndDateTimePicker] = React.useState<Date | null>(new Date());
    const [selectedRangeFilter, setSelectedRangeFilter] = React.useState<string>("Last 24 hours");
    const { selectedRange, startTime, endTime } = useSelector((state) => state.time);
    const [warningMessage, setWarningMessage] = useState<string>("");
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>();
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        handleBtnClick(24, "Last 24 hours");
    }, []);

    useEffect(() => {
        isValidFunction(startDateTime, endDateTime, setWarningMessage);
    }, [startDateTime, endDateTime]);

    const handleClose = (): void => {
        setAnchorEl(null);
        setStartDateTimePicker(new Date(startTime));
        setEndDateTimePicker(new Date(endTime));
        setWarningMessage("");
    };

    function handleBtnClick(hours: number, label: string): void {
        const end = new Date();
        end.setSeconds(0, 0);
        setEndDateTime(end.toISOString());
        setEndDateTimePicker(end);
        const dt = new Date();
        dt.setHours(dt.getHours() - hours);
        dt.setSeconds(0, 0);
        const start = dt.toISOString();
        setStartDateTime(start);
        setStartDateTimePicker(dt);
        setSelectedRangeFilter(label);
    }

    function setTimeUnit(start: string, end: string): string {
        const a = moment(end);
        const b = moment(start);
        const minutes = a.diff(b, "minutes");
        let unit = TimeRange?.Hour;
        if (minutes <= 20) {
            unit = TimeRange?.Minutes;
        } else if (minutes <= 120) {
            unit = TimeRange?.Minutes;
        } else if (minutes <= 1440) {
            unit = TimeRange?.Hour;
        } else if (minutes <= 30240) {
            unit = TimeRange?.Day;
        } else if (minutes <= 241920) {
            unit = TimeRange?.Week;
        } else {
            unit = TimeRange?.Month;
        }
        return unit;
    }

    function applyTimeRange(): void {
        const timeRange = setTimeUnit(startDateTime, endDateTime);
        dispatch(
            setTimeRange({
                startTime: startDateTime,
                endTime: endDateTime,
                timeType: timeRange,
                selectedRange: selectedRangeFilter,
            }),
        );
        setAnchorEl(null);
    }

    function onStartDateTimeChange(newValue: Date | null): void {
        if (newValue) {
            setStartDateTimePicker(newValue);
            setStartDateTime(new Date(new Date(newValue).setSeconds(0, 0)).toISOString());
            setSelectedRangeFilter("Custom");
        }
    }

    function onEndDateTimeChange(newValue: Date | null): void {
        if (newValue) {
            setEndDateTimePicker(newValue);
            setEndDateTime(new Date(new Date(newValue).setSeconds(0, 0)).toISOString());
            setSelectedRangeFilter("Custom");
        }
    }

    function disableStartTimePicker(date: Date): boolean {
        if (endDateTimePicker) {
            return (
                format(date, "EEEE,MMMM do, yyyy hh:mm a") === format(endDateTimePicker, "EEEE,MMMM do, yyyy hh:mm a")
            );
        }
        return false;
    }

    function disableEndTimePicker(date: Date): boolean {
        if (startDateTimePicker) {
            return (
                format(date, "EEEE,MMMM do, yyyy hh:mm a") === format(startDateTimePicker, "EEEE,MMMM do, yyyy hh:mm a")
            );
        }
        return false;
    }
    return (
        <div>
            <IotVisionTextFiled
                sx={{
                    "& .MuiInputBase-root": {
                        backgroundColor: "white",
                        height: "34px",
                    },
                }}
                fullWidth
                InputProps={{
                    readOnly: true,
                    endAdornment: <EventIcon sx={{ color: "gray" }} />,
                }}
                placeholder={selectedRange}
                onClick={(event: React.MouseEvent<HTMLElement>) => handleClick(event)}
            />
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        width: { xl: "320px" },
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "&:before": {
                            content: '""',
                            position: "absolute",
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <div className="DropDownOuter-wrapper">
                    <Grid container item>
                        <Grid container item xs={12} sm={12} md={12} lg={12}>
                            <span className="topDropDwn_head">Select time range</span>
                        </Grid>
                        <Grid container item xs={6} sm={6} md={6} lg={6} className="headerDroplineSpace">
                            <div className="DropDownBtn-wrapper">
                                <Button variant="outlined" size="small" onClick={() => handleBtnClick(1, "Last hour")}>
                                    Last hour
                                </Button>
                            </div>
                        </Grid>
                        <Grid container item xs={6} sm={6} md={6} lg={6} className="headerDroplineSpace">
                            <div className="DropDownBtn-wrapper">
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() => handleBtnClick(12, "Last 12 hours")}
                                >
                                    Last 12 hours
                                </Button>
                            </div>
                        </Grid>
                        <Grid container item xs={6} sm={6} md={6} lg={6} className="headerDroplineSpace">
                            <div className="DropDownBtn-wrapper">
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() => handleBtnClick(2, "Last 2 hours")}
                                >
                                    Last 2 hours
                                </Button>
                            </div>
                        </Grid>
                        <Grid container item xs={6} sm={6} md={6} lg={6} className="headerDroplineSpace">
                            <div className="DropDownBtn-wrapper">
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() => handleBtnClick(24, "Last 24 hours")}
                                >
                                    Last 24 hours
                                </Button>
                            </div>
                        </Grid>
                        <Grid container item xs={6} sm={6} md={6} lg={6} className="headerDroplineSpace">
                            <div className="DropDownBtn-wrapper">
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() => handleBtnClick(4, "Last 4 hours")}
                                >
                                    Last 4 hours
                                </Button>
                            </div>
                        </Grid>
                        <Grid container item xs={6} sm={6} md={6} lg={6} className="headerDroplineSpace">
                            <div className="DropDownBtn-wrapper">
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() => {
                                        setStartDateTimePicker(null);
                                        setEndDateTimePicker(null);
                                        setStartDateTime("");
                                        setEndDateTime("");
                                    }}
                                >
                                    Custom
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sm={12} md={12} lg={12} className="headerDroplineSpace">
                        <div className="DropDownInput-wrapper">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <MobileDateTimePicker
                                    maxDateTime={endDateTimePicker as Date}
                                    disableFuture
                                    shouldDisableDate={disableStartTimePicker}
                                    renderInput={(props) => (
                                        <IotVisionTextFiled
                                            sx={
                                                warningMessage
                                                    ? {
                                                          "& .MuiOutlinedInput-root": {
                                                              "& fieldset": {
                                                                  borderColor: "red",
                                                              },
                                                              "&.Mui-focused fieldset": {
                                                                  borderColor: "red",
                                                              },
                                                          },
                                                      }
                                                    : {}
                                            }
                                            placeholder="Start date and time"
                                            {...props}
                                        />
                                    )}
                                    value={startDateTimePicker}
                                    onChange={(newValue) => {
                                        onStartDateTimeChange(newValue);
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                    </Grid>

                    <Grid container item xs={12} sm={12} md={12} lg={12} className="headerDroplineSpace">
                        <div className="DropDownInput-wrapper">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <MobileDateTimePicker
                                    disableFuture
                                    minDateTime={startDateTimePicker as Date}
                                    shouldDisableDate={disableEndTimePicker}
                                    renderInput={(props) => (
                                        <IotVisionTextFiled
                                            sx={
                                                warningMessage
                                                    ? {
                                                          "& .MuiOutlinedInput-root": {
                                                              "& fieldset": {
                                                                  borderColor: "red",
                                                              },
                                                              "&.Mui-focused fieldset": {
                                                                  borderColor: "red",
                                                              },
                                                          },
                                                      }
                                                    : {}
                                            }
                                            placeholder="End date and time"
                                            {...props}
                                        />
                                    )}
                                    value={endDateTimePicker}
                                    onChange={(newValue) => {
                                        onEndDateTimeChange(newValue);
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                    </Grid>
                    {warningMessage && (
                        <Grid item xs={12}>
                            <Typography fontSize="14px" marginLeft="14px" color="red">
                                {warningMessage}
                            </Typography>
                        </Grid>
                    )}
                </div>
                <div className="dropDwnBottom-wrapper">
                    <Grid container item xs={12} sm={12} md={12} lg={12} spacing={1}>
                        <Grid container item xs={6} sm={6} md={6} lg={6}>
                            <IotVisionButton
                                onClick={applyTimeRange}
                                {...(Boolean(warningMessage) || !(startDateTimePicker && endDateTimePicker)
                                    ? {
                                          sx: {
                                              backgroundColor: "disableColor.main",
                                              color: "disableColor.lightOverRide",
                                          },
                                          disabled: true,
                                      }
                                    : {})}
                            >
                                Apply
                            </IotVisionButton>
                        </Grid>
                        <Grid container item xs={6} sm={6} md={6} lg={6}>
                            <IotVisionButton
                                sx={{
                                    backgroundColor: "white",
                                    color: "primary.main",
                                    "&:hover": {
                                        background: "white",
                                    },
                                }}
                                onClick={handleClose}
                            >
                                Cancel
                            </IotVisionButton>
                        </Grid>
                    </Grid>
                </div>
            </Menu>
        </div>
    );
};

export default DateRangePicker;
