import { RequestOptions } from "axios-types";
import { ApiEndpoints } from "configs";
import { IDetections, IDetectionsUpdate } from "features/app-ungated-parking/statistics/StatisticsCards";
import { HistoricalQueryParams, HistoricalResponse } from "types";
import ExecuteAsync from "utils/api-client";
import { createQueryParams } from "utils/create-query-params";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
export function executeGetRealTimeDetectionsRequest(data?: IDetections, options?: RequestOptions) {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/real-time/data${createQueryParams(data)}`,
        signal: options?.signal,
        disableNotification: options?.disableNotification,
    });
}

export function executePostRealTimeDetectionsRequest(data?: IDetectionsUpdate, options?: RequestOptions) {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "PUT",
        url: "/real-time/data",
        signal: options?.signal,
        data,
        disableNotification: options?.disableNotification,
    });
}

export function executeGetHistoricalOccupancyRequest(query: HistoricalQueryParams, options?: RequestOptions) {
    return ExecuteAsync<unknown, HistoricalResponse[]>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/real-time/historical${createQueryParams(query)}`,
        signal: options?.signal,
        disableNotification: options?.disableNotification,
    });
}
