import ProtectedLayout from "layouts/common/general/ProtectedLayout";
import ReportsLayout from "layouts/app-taxi-ranking/reports";
import WithScopes from "components/common/WithScopes";
import NotFound from "pages/common/errors/not-found/NotFound";
import Forbidden from "pages/common/errors/forbidden/Forbidden";
import { SCOPES } from "enums/scopes.enum";
import Reports from "./reports/Report";
import NoReports from "./reports/NoReports";
import LGAOverview from "./reports/lga-overview";
import LocationOverview from "./reports/location-overview";

const root = {
    path: "reports",
    element: (
        <ProtectedLayout>
            <WithScopes
                filterScopes={[
                    SCOPES.READ_REPORTS,
                    SCOPES.READ_SUMMARY_REPORTS,
                    SCOPES.READ_WORKFLOW_REPORTS,
                    SCOPES.READ_INCIDENT_TRENDS_REPORTS,
                ]}
            >
                <ReportsLayout />
            </WithScopes>
        </ProtectedLayout>
    ),
    children: [
        {
            path: "",
            element: <Reports />,
        },
        {
            path: ":reportId",
            children: [
                {
                    path: "",
                    element: <Reports />,
                },
                {
                    path: "lga-overview",
                    element: <LGAOverview />,
                },
                {
                    path: "location-overview",
                    element: <LocationOverview />,
                },
            ],
        },
        {
            path: "forbidden",
            element: <Forbidden />,
        },
        {
            path: "no-results",
            element: <NoReports />,
        },
        {
            path: "report-not-found",
            element: <NotFound />,
        },
    ],
};
export default root;
