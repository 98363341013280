import { useAuth0 } from "@auth0/auth0-react";
import { Auth0UserInfo } from "auth-types";
import { useEffect, useState } from "react";
import { ActionTypes } from "reducers/action-types/action-types";
import { useAppDispatch } from "store";

/**
 * this is hook is return the shouldRender based on the application id
 * else it will navigate to loginPage
 * @param appId this is application id
 * @returns shouldRender boolean variable
 */
export const useShouldRenderPageGuard = (appId: string) => {
    const dispatch = useAppDispatch();
    const [shouldRender, setShouldRender] = useState<boolean>(false);
    const { user } = useAuth0();
    /* eslint camelcase: ["off"] */
    const { org_id } = user as Auth0UserInfo;
    const { logout } = useAuth0();
    useEffect(() => {
        if (org_id !== appId) {
            dispatch({ type: ActionTypes.CLEAR_STORE });
            logout({
                returnTo: window.location.origin,
            });
        } else {
            setShouldRender(true);
        }
    }, [org_id]);

    return { shouldRender };
};
