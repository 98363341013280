export enum FilterTypes {
    DATE = "date",
    TEXT = "text"
}

export enum Directions {
    IN = "in",
    OUT = "out",
}

export enum BikeRackStatus {
    ARRIVAL = "arrival",
    DEPARTURE = "departure",
}
