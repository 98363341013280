import CommonDateRangePicker, { DatePickerValue } from "components/common/CommonDateRangePicker";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import { setTimeUnit } from "utils/common";
import { setTimeRange } from "reducers/time.reducer";
import { differenceInMinutes } from "date-fns";

const DAY_IN_MINUTES = 1440;

interface HeaderDatePickerProps {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customDateValidator = (startDateTime: string, endDateTime: string, callBackMessage: any) => {
    const days = differenceInMinutes(new Date(endDateTime), new Date(startDateTime), { roundingMethod: "trunc" });

    const isSameDates = new Date(endDateTime).getTime() === new Date(startDateTime).getTime();

    const isBeforeEndDate = new Date(endDateTime).getTime() < new Date(startDateTime).getTime();

    const isFutureTimeSelected =
        new Date(startDateTime).getTime() > new Date().getTime() ||
        new Date(endDateTime).getTime() > new Date().getTime();

    if (isSameDates) {
        callBackMessage("Start date and end date must be different");
    } else if (isBeforeEndDate) {
        callBackMessage("Start date must be before end date");
    } else if (days > 7 * DAY_IN_MINUTES) {
        callBackMessage("Maximum time range between dates must be less than 7 days");
    } else if (isFutureTimeSelected) {
        callBackMessage("Date Entered Exceeds Todays Date");
    } else {
        callBackMessage("");
    }
};
const HeaderDatePicker: React.FC<HeaderDatePickerProps> = () => {
    const dispatch = useAppDispatch();
    const { selectedRange, startTime, endTime } = useSelector((state) => state.time);
    const [pickerValue, setPickerValue] = useState<DatePickerValue>({
        fromTime: startTime,
        toTime: endTime,
        range: selectedRange,
    });

    useEffect(() => {
        setPickerValue({
            fromTime: startTime,
            toTime: endTime,
            range: selectedRange,
        });
    }, [selectedRange, startTime, endTime]);

    const onChange = (startDate: string, endDate: string, range: string) => {
        const timeRange = setTimeUnit(startDate, endDate);
        setPickerValue({ fromTime: startDate, toTime: endDate, range });
        dispatch(
            setTimeRange({
                startTime: startDate,
                endTime: endDate,
                timeType: timeRange,
                selectedRange: range,
            }),
        );
    };

    return (
        <CommonDateRangePicker
            isValidFunction={customDateValidator}
            sx={{
                "& .MuiInputBase-root": {
                    backgroundColor: "white",
                    height: "34px",
                },
            }}
            onPickerMenuClose={() => {
                setPickerValue({
                    fromTime: startTime,
                    toTime: endTime,
                    range: selectedRange,
                });
            }}
            value={pickerValue}
            onDatePickerApply={onChange}
            onDatePickerClear={() => {
                setPickerValue({
                    fromTime: startTime,
                    toTime: endTime,
                    range: selectedRange,
                });
            }}
            buttonText="Cancel"
        />
    );
};

export default memo(HeaderDatePicker);
