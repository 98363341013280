import { ThresHoldConfigDataSetType } from "./enums";
import { handleAQSensorThresholds } from "./handlers/aq-sensor.handlers";
import { AQ_SENSOR_RULES } from "./rules";
import { ThresholdData } from "./types";

/**
 * Find the matched data set according to the provided values
 * @param datasetType type of the data set
 * @param key key of the data set
 * @param value actual value to be matched with the data set
 * @returns generated threshold data
 */
export const getThresholdData = (
    datasetType: ThresHoldConfigDataSetType,
    key: string,
    value?: number,
): ThresholdData | undefined => {
    switch (datasetType) {
        // Generate AQ Sensor related data set
        case ThresHoldConfigDataSetType.AQ_SENSOR_THRESHOLD_CONFIGS:
            return handleAQSensorThresholds(
                AQ_SENSOR_RULES?.find((config) => config?.key === key),
                value,
            );
        default:
            return undefined;
    }
};

export default getThresholdData;
