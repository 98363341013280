/* eslint-disable import/prefer-default-export */
import { FileStatus } from "enums/file-status.enum";
import { CommonObjectLiteral } from "types/reports/interfaces";

export const incidentFileStatusMapping: Record<string, CommonObjectLiteral> = {
    [FileStatus.AVAILABLE]: {
        displayValue: "Available",
        color: "#1AAE17",
    },
    [FileStatus.NOT_AVAILABLE]: {
        displayValue: "Unavailable",
        color: "#8B0000",
    },
    [FileStatus.PENDING]: {
        displayValue: "Processing",
        color: "#EEBA00",
    },
    [FileStatus.EXPIRED]: {
        displayValue: "Expired",
        color: "#FF5F15",
    },
    [FileStatus.REQUESTED]: {
        displayValue: "Requested",
        color: "#21315B",
    },
};
