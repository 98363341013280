import ExecuteAsync from "utils/api-client";
import { createQueryParams } from "utils/create-query-params";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
export function executeGetHistoricalDataRequest(data?: any) {
    return ExecuteAsync({
        method: "GET",
        url: `/api/reporting/detections/values/historical${createQueryParams(data)}`,
    });
}
