import { Theme } from "@mui/material/styles";
import { aqDemoTheme } from "./sensei-theme";
import { defaultTheme } from "./default";

export function getThemeByName(theme: string): Theme {
    return themeMap[theme];
}

const themeMap: { [key: string]: Theme } = {
    aqDemoTheme,
    defaultTheme,
};
