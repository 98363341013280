/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { TreeView, TreeItem, TreeItemProps } from "@mui/lab";
import { BiLeftArrowAlt, BiRightArrowAlt, BiMinus } from "react-icons/bi";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import SearchInputField from "components/input-fields/SearchInputField";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import { toggleSideBar } from "reducers/system.reducer";
import { newExecuteRetrieveLocationsRequest } from "api/common/side-panel.api";
import Tooltip from "@mui/material/Tooltip";
import { setInitialMapFocus } from "reducers/map.reducer";
import { styled } from "@mui/system";
import CustomTreeContent from "features/common/tree-item/CustomTreeContent";
import { Typography } from "@mui/material";
import { setLocations, searchLocationsBy, clearLocations } from "reducers/newLocation.reducer";
import { findParentLocations } from "utils/data-manipulation";
import { executeGetAllDevicesRequest } from "api/app-taxi-ranking/taxi-video-requests.api";
import { clearDevices, setDeviceList } from "reducers/devices.reducer";
import useStyles from "./styles";

type SidebarTaxiRankProps = {
    rootPath: string;
};

const setFontWeightBaseGroupType = (group: { type: string; name: string }) => {
    if (group.type === "zone") {
        return <span className="fw500 textDNone">{group.name}</span>;
    }
    return <span className="fw600 textDNone">{group.name}</span>;
};

const renderNodeByType = (item: any, levelIndex = 0) => {
    const { type } = item;
    if (type === "location") {
        return RenderLocation(item, levelIndex + 1);
    }
    return RenderGroup(item, levelIndex + 1);
};

const CustomTreeItem = (props: TreeItemProps) => <TreeItem ContentComponent={CustomTreeContent} {...props} />;

const SetToolTipBaseNameSize = (location: any) => {
    if (location.name.length > 20) {
        return (
            <span className="textOverFlow">
                <Tooltip placement="bottom" title={location.name} arrow>
                    <Typography fontSize="14px">{location.name}</Typography>
                </Tooltip>
            </span>
        );
    }
    return <span className="textDNone">{location.name}</span>;
};

const RenderLocation = (loc: any, levelIndex = 0) => {
    return (
        <CustomTreeItem
            nodeId={loc.id}
            label={SetToolTipBaseNameSize(loc)}
            icon={
                loc.isFavorite === true ? (
                    <AiFillStar className="favourite-active-icon" />
                ) : (
                    <AiOutlineStar className="favourite-inactive-icon" />
                )
            }
            className={`level${levelIndex}`}
            key={loc.id}
        />
    );
};

const RenderGroup = (group: any, levelIndex = 0) => {
    const { children } = group;

    const sortedChildren = [...children].sort((a, b) => a.name.localeCompare(b.name));

    return (
        <CustomTreeItem
            nodeId={group.id}
            label={setFontWeightBaseGroupType(group)}
            className={`level${levelIndex}`}
            key={group.id}
        >
            {sortedChildren.map((item: any) => renderNodeByType(item, levelIndex))}
        </CustomTreeItem>
    );
};

const SidebarTaxiRank: React.FC<SidebarTaxiRankProps> = () => {
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const { isDrawerOpen } = useSelector((state) => state.system);
    const { locationId, groupId } = useParams();
    const [selectedNode, setSelectedNode] = useState<string>("root");
    const { tree } = useSelector((state) => state.newLocation);
    const locations = useSelector((state) => state.newLocation.locations);

    const [expanded, setExpanded] = useState<string[]>(["root"]);
    const locationsAbortControllerRef = useRef<AbortController | null>(null);
    const devicesAbortControllerRef = useRef<AbortController | null>(null);
    const locationsTreeAbortControllerRef = useRef<AbortController | null>(null);
    const [cachedExpanded, setCachedExpanded] = useState<string[]>(expanded);

    useEffect(() => {
        if (isDrawerOpen) {
            setExpanded(cachedExpanded);
        } else {
            setCachedExpanded(expanded);
            setExpanded([]);
        }
    }, [isDrawerOpen, locations]);

    function handleSidebar() {
        dispatch(toggleSideBar());
    }

    const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        setExpanded(nodeIds);
    };

    const handleNode = () => {
        if (!isDrawerOpen) {
            dispatch(toggleSideBar());
        }
    };

    useEffect(() => {
        (async () => {
            dispatch(clearLocations());
            dispatch(clearDevices());
            await Promise.all([getLocations(), getDevices()]);
            dispatch(setInitialMapFocus());
        })();

        return () => {
            locationsAbortControllerRef.current?.abort();
            locationsTreeAbortControllerRef.current?.abort();
        };
    }, []);

    useEffect(() => {
        const finalLocationId = locationId ?? groupId;
        if (finalLocationId) {
            setSelectedNode(finalLocationId);
            const arr = findParentLocations(finalLocationId, locations);
            const set = new Set([...arr, finalLocationId, "root", ...expanded]);
            setExpanded([...set]);
        } else {
            setSelectedNode("root");
        }
    }, [locationId, locations, groupId]);

    const getLocations = async () => {
        try {
            locationsAbortControllerRef.current = new AbortController();
            const locationSignal = locationsAbortControllerRef.current.signal;
            locationsTreeAbortControllerRef.current = new AbortController();

            const locationsResponse = await newExecuteRetrieveLocationsRequest({
                signal: locationSignal,
            });

            if (locationsResponse && Array.isArray(locationsResponse)) {
                dispatch(setLocations(locationsResponse));
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getDevices = async () => {
        try {
            devicesAbortControllerRef.current = new AbortController();
            const { signal } = devicesAbortControllerRef.current;

            const response = await executeGetAllDevicesRequest({}, {
                signal,
                disableNotification: true,
            });

            if (response && Array.isArray(response)) {
                dispatch(setDeviceList(response));
            }
        } catch (e) {
            console.log(e);
        }
    };

    function onSearchLocation(e: any) {
        const { value } = e.target;
        dispatch(searchLocationsBy(value));
    }
    const SideBarStyledDivIconWrapper = styled("div")({
        ...(isDrawerOpen ? { paddingLeft: "11px" } : {}),
    });

    const sortedTree = [...tree].sort((a, b) => a.name.localeCompare(b.name));

    return (
        <div className={isDrawerOpen ? "sidebar active" : "sidebar"}>
            <div className={isDrawerOpen ? "sidebar-top-header" : ""}>
                <div className="menu-btn-wrapper" onClick={handleSidebar}>
                    {isDrawerOpen ? <BiLeftArrowAlt className="icon" /> : <BiRightArrowAlt className="icon" />}
                </div>

                <SearchInputField onSearch={onSearchLocation} />
            </div>
            <div className="menu-tree">
                <TreeView
                    aria-label="controlled"
                    defaultCollapseIcon={<BiMinus className="expandedIcon" />}
                    defaultExpandIcon={<FiPlus className="expandedIcon" />}
                    expanded={expanded}
                    onNodeToggle={handleToggle}
                    onNodeSelect={handleNode}
                    className={classes.root}
                    selected={selectedNode}
                >
                    <CustomTreeItem
                        nodeId="root"
                        label={<span className="fw700 ">All Locations</span>}
                        icon={
                            <SideBarStyledDivIconWrapper>
                                <FaMapMarkerAlt className="color-active" />
                            </SideBarStyledDivIconWrapper>
                        }
                        className="level1"
                    >
                        {Array.isArray(tree) && sortedTree.map((region: any) => renderNodeByType(region))}
                    </CustomTreeItem>
                </TreeView>
            </div>
        </div>
    );
};

export default SidebarTaxiRank;
