import React from "react";
import Grid from "@mui/material/Grid";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
type PaperWrapperProps = {
    className?: string;
    cusStyle?: any;
};

const PaperWrapper: React.FC<PaperWrapperProps> = ({ className, cusStyle, children }) => {
    return (
        <Grid className={className || "paper-wrapper"} style={cusStyle}>
            <Grid item>{children}</Grid>
        </Grid>
    );
};

export default PaperWrapper;
