import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import { IoMdBicycle } from "react-icons/io";
import { RiWalkFill } from "react-icons/ri";
import { FaCarAlt } from "react-icons/fa";
import { executeGetDetectionsRequest } from "api/common/detections.api";
import { CLASS_CYCLE } from "constants/class.constants";
import { useSelector } from "react-redux";
import { CLASS_TYPE_PERSON, CLASS_TYPE_VEHICLE } from "constants/classType.constants";
import { useParams } from "react-router-dom";
import { checkLocationIdChanged, FetchFunctionParams, makeApiCallWithUpdateTime } from "utils/common";
import { useAppDispatch } from "store";
import { Location } from "reducers/newLocation.reducer";
import StatsCard from "components/cards/StatsCard";
import { LocationType } from "enums/location.enums";

/* eslint-disable no-empty */
interface StatisticsCardsProps {
    /* eslint-disable react/no-unused-prop-types */
    cycleCount?: number;
    pedestriansCount?: number;
    vehicleCount?: number;
    group?: Location;
}

export interface IDetections {
    locationId?: string;
    fromTime?: string;
    toTime?: string;
    classification?: string | string[];
    level?: number;
    field1?: string;
    includeChildLocations?: boolean;
}

const StatisticsCards: React.FC<StatisticsCardsProps> = ({ group }) => {
    const { locationId } = useParams();
    const { startTime, endTime, selectedRange } = useSelector((state) => state.time);
    const [cyclesCount, setCyclesCount] = useState<number>(0);
    const [pedestriansCount, setPedestriansCount] = useState<number>(0);
    const [vehiclesCount, setVehiclesCount] = useState<number>(0);
    const [isCyclesCountLoading, setIsCyclesCountLoading] = useState<boolean>(false);
    const [isPedestriansCountLoading, setIsPedestriansCountLoading] = useState<boolean>(false);
    const [isVehiclesCountLoading, setIsVehiclesCountLoading] = useState<boolean>(false);
    const isAllLocations = () => locationId === undefined;
    const cyclesAbortControllerRef = useRef<AbortController | null>(null);
    const pedestriansAbortControllerRef = useRef<AbortController | null>(null);
    const vehiclesAbortControllerRef = useRef<AbortController | null>(null);
    const { locations } = useSelector((state) => state.newLocation);
    const dispatch = useAppDispatch();

    const fetchData = async ({ startTime, endTime }: FetchFunctionParams) => {
        let level = 0;
        if (isAllLocations()) {
            level = -1;
        } else if (locationId !== undefined) {
            level = 3;
        } else if (group?.type === LocationType.REGION) {
            level = 1;
        } else if (group?.type === LocationType.ZONE) {
            level = 2;
        }

        await Promise.all([
            getCyclesDetections({
                locationId,
                fromTime: startTime,
                toTime: endTime,
                classification: CLASS_CYCLE,
                level,
            }),
            getPedestriansDetections({
                locationId,
                fromTime: startTime,
                toTime: endTime,
                classification: CLASS_TYPE_PERSON,
                level,
            }),
            getVehiclesDetections({
                locationId,
                fromTime: startTime,
                toTime: endTime,
                classification: CLASS_TYPE_VEHICLE,
                level,
            }),
        ]);
    };
    async function getCyclesDetections(data?: IDetections): Promise<void> {
        try {
            cyclesAbortControllerRef.current = new AbortController();
            const { signal } = cyclesAbortControllerRef.current;
            setIsCyclesCountLoading(true);
            const response = await executeGetDetectionsRequest(data, {
                signal,
                disableNotification: checkLocationIdChanged(locationId, locations),
            });
            if (response && Array.isArray(response)) {
                const [first] = response;
                setCyclesCount(first?.count || 0);
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
        } finally {
            setIsCyclesCountLoading(false);
        }
    }

    async function getPedestriansDetections(data?: IDetections): Promise<void> {
        try {
            pedestriansAbortControllerRef.current = new AbortController();
            const { signal } = pedestriansAbortControllerRef.current;
            setIsPedestriansCountLoading(true);
            const response = await executeGetDetectionsRequest(data, {
                signal,
                disableNotification: checkLocationIdChanged(locationId, locations),
            });
            if (response && Array.isArray(response)) {
                const [first] = response;
                setPedestriansCount(first?.count || 0);
            }
        } catch (error) {
        } finally {
            setIsPedestriansCountLoading(false);
        }
    }

    async function getVehiclesDetections(data?: IDetections): Promise<void> {
        try {
            vehiclesAbortControllerRef.current = new AbortController();
            const { signal } = vehiclesAbortControllerRef.current;
            setIsVehiclesCountLoading(true);
            const response = await executeGetDetectionsRequest(data, {
                signal,
                disableNotification: checkLocationIdChanged(locationId, locations),
            });
            if (response && Array.isArray(response)) {
                const [first] = response;
                setVehiclesCount(first?.count || 0);
            }
        } catch (error) {
        } finally {
            setIsVehiclesCountLoading(false);
        }
    }

    useEffect(() => {
        makeApiCallWithUpdateTime(selectedRange, { startTime, endTime }, fetchData, dispatch);

        return () => {
            cyclesAbortControllerRef.current?.abort();
            pedestriansAbortControllerRef.current?.abort();
            vehiclesAbortControllerRef.current?.abort();
        };
    }, [locationId, startTime, endTime]);

    return (
        <Grid className="stats-container m-0 p-0">
            <StatsCard
                isLoading={isCyclesCountLoading}
                title="Total number of cycles detected"
                count={cyclesCount}
                icon={<IoMdBicycle />}
            />
            <StatsCard
                isLoading={isPedestriansCountLoading}
                title="Total number of pedestrians detected"
                count={pedestriansCount}
                icon={<RiWalkFill />}
            />

            <StatsCard
                isLoading={isVehiclesCountLoading}
                title="Total number of vehicles detected "
                count={vehiclesCount}
                icon={<FaCarAlt />}
            />
        </Grid>
    );
};

export default StatisticsCards;
