/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import PaperWrapper from "components/wrappers/PaperWrapper";
import { CgArrowsExpandRight } from "react-icons/cg";
import FullScreenDialog from "components/dialogs/FullScreenDialog";
import { useSelector } from "react-redux";
import LineChart from "components/charts/LineChart";
import { HistoricalResponse } from "types";
import { MINUTES_15_IN_MILLISECOND, ONE_DAY_IN_MILLISECOND } from "constants/time.constants";
import { XYData, processChartData } from "./SynchronizedLineChart";

type ParkingMeanChartProps = {
    data: HistoricalResponse[];
    title: string;
    icon?: any;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function HistoricalOccupancyChart({ data, title, icon }: ParkingMeanChartProps) {
    const [processedData, setProcessedData] = useState<processChartData[]>([]);
    const [modalShow, setModalShow] = React.useState(false);
    const { startTime, endTime, timeType } = useSelector((state) => state.time);

    useEffect(() => {
        processData(data);
    }, [data]);

    function processData(rawData: HistoricalResponse[]): void {
        const result = rawData
            .reduce((acc: XYData[], current: HistoricalResponse) => {
                const epoch = new Date(current.timestamp).getTime();
                const start = new Date(epoch - (epoch % MINUTES_15_IN_MILLISECOND));
                const matchIndex = acc.findIndex(({ x }) => x.getTime() === start.getTime());
                if (matchIndex === -1) {
                    acc.push({
                        x: start,
                        y: current.value,
                    });
                    return acc;
                }
                if (acc[matchIndex].y < current.value) acc[matchIndex].y = current.value;
                return acc;
            }, [])
            .sort((a, b) => a.x.getTime() - b.x.getTime());
        if (result.length > 0) {
            const start = result[0];
            const end = result[result.length - 1];
            result.unshift({
                x: new Date(start.x.getTime() - ONE_DAY_IN_MILLISECOND),
                y: start.y,
            });

            result.push({
                x: new Date(end.x.getTime() + ONE_DAY_IN_MILLISECOND),
                y: end.y,
            });
        }
        setProcessedData([
            {
                label: rawData?.[0]?.classification || "",
                // eslint-disable-next-line no-bitwise
                borderColor: `#${(((1 << 24) * Math.random()) | 0).toString(16)}`,
                data: result,
                tension: 0.1,
            },
        ]);
    }

    const handleClick = (status: boolean): void => {
        setModalShow(status);
    };
    return (
        <>
            <PaperWrapper>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={1} gap={1} marginBottom={1}>
                        <Grid item className="chart-padding-0">
                            {icon}
                        </Grid>
                        <Grid item className="chart-padding-0">
                            <span className="chartHearder">{title}</span>
                        </Grid>
                        <Grid item className="chart-padding-0">
                            <IconButton className="expand-btn" onClick={() => handleClick(true)} disableRipple>
                                <CgArrowsExpandRight />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <LineChart
                        data={processedData}
                        fromTime={startTime}
                        toTime={endTime}
                        timeUnit={timeType}
                        useLegend={false}
                        roundTimeDisabled
                    />
                </Grid>
            </PaperWrapper>
            <FullScreenDialog
                modalShow={modalShow}
                onClose={handleClick}
                childComponent={
                    <div>
                        <LineChart
                            data={processedData}
                            fromTime={startTime}
                            toTime={endTime}
                            timeUnit={timeType}
                            useLegend={false}
                            roundTimeDisabled
                        />
                    </div>
                }
            />
        </>
    );
}
