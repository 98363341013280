import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import IncidentFilter from "./filter/IncidentFilter";
import IncidentTable from "./table/IncidentTable";

interface IncidentsPageProps {}

const IncidentsPage: React.FC<IncidentsPageProps> = () => {
    return (
        <Grid container>
            <Box
                sx={(theme) => ({
                    padding: "25px",
                    borderRadius: "15px",
                    width: "100%",
                    height: "750px",
                    [theme.breakpoints.up("lg")]: {
                        height: "720px",
                    },
                    background: "#FFFFFF",
                    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.03)",
                })}
            >
                <Grid item xs={12}>
                    <IncidentFilter />
                </Grid>
                <Grid item xs={12}>
                    <IncidentTable />
                </Grid>
            </Box>
        </Grid>
    );
};

export default IncidentsPage;
