import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
    isGraphLoading: boolean;
}

const initialState = {
    isGraphLoading: false,
} as InitialState;

const slice = createSlice({
    name: "graph",
    initialState,
    reducers: {
        setGraphLoading(state, action: PayloadAction<boolean>) {
            state.isGraphLoading = action.payload;
        },
    },
});

export const { setGraphLoading } = slice.actions;
export default slice.reducer;
