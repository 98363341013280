import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ALL_ENTITY_ACCESS } from "../constants";

export interface FilterableObject {
    id: string;
    disabled?: boolean;
}

const useScopedDataFilter = <T extends FilterableObject>(scope: string, data: T[]): T[] => {
    const [filteredData, setFilteredData] = useState<T[]>([]);
    const { scopes } = useSelector((state) => state.auth);
    useEffect(() => {
        const matchedScope = scopes.filter((scp) => {
            const splitScope = scp.split(":");
            splitScope.pop();
            const joinedScope = splitScope.join(":");
            return joinedScope === scope;
        });
        if (!matchedScope.length) {
            const modifiedData = data.map(item => {
                item.disabled = true;
                return item;
            });
            setFilteredData(modifiedData);
        }
        const entities = matchedScope?.map((scp) => scp.split(":").pop());
        if (entities?.some((scp) => scp === ALL_ENTITY_ACCESS)) return setFilteredData(data);
        const filter = data.map((item) => {
            if (!entities?.includes(item.id)) item.disabled = true;
            return item;
        });
        setFilteredData(filter);
    }, [data, scope, scopes]);
    return filteredData;
};

export default useScopedDataFilter;
