/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    styled,
    AutocompleteChangeReason,
    Grid,
    InputLabel,
    Dialog,
    DialogContent,
    CircularProgress,
    Typography,
    IconButton,
} from "@mui/material";
import IotVisionButton from "components/common/IotVisionButton";
import IotVisionTextFiled from "components/common/IotVisionTextFiled";
import SectionHeading from "components/headings/SectionHeading";
import PaperWrapper from "components/wrappers/PaperWrapper";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import CommonDateTimePicker from "components/common/CommonDateTimePicker";
import IotVisionAutoComplete from "components/common/IotVisonAutoComplete";
import ArticleIcon from "@mui/icons-material/Article";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { CgArrowsExpandRight } from "react-icons/cg";
import FullScreenDialog from "components/dialogs/FullScreenDialog";
import { executeCreateVideoRequest, executeGetAllDevicesRequest } from "api/app-taxi-ranking/taxi-video-requests.api";
import { IDevice } from "types/device";
import { DeviceType } from "enums/device.enums";
import { IVideoRequestBody } from "types/video-requests";
import {
    END_TIME_SHOULD_BE_GREATER_THAN_START_TIME,
    FIELD_IS_REQUIRED,
    INVALID_SELECTION,
    START_TIME_SHOULD_BE_LESS_THAN_END_TIME,
} from "constants/validation.constants";
import { DATE_FORMAT_WITH_SECONDS, RETENTION_PERIOD_IN_DAYS } from "constants/time.constants";
import { START_TIME_IS_OUT_OF_30_DAY_RANGE } from "constants/taxirank-incidents.constants";

interface AutoLabelId {
    label: string;
    id: string;
}

interface VideoRequestForm {
    location: AutoLabelId;
    device: AutoLabelId;
    camera: AutoLabelId;
    startTime: Date | null;
    endTime: Date | null;
    reason: string;
}

const CustomizedIotVisionSearchButton = styled(IotVisionButton)(() => ({
    color: "white !important",
    height: "37px",
}));

const CustomizedIotVisionClearButton = styled(IotVisionButton)(({ theme }) => ({
    height: "37px",
    color: theme.palette.primary.main,
    backgroundColor: "white",
    "&:hover": {
        background: "white",
    },
}));

const CustomizedDialogContent = styled(DialogContent)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    minHeight: "200px",
    minWidth: "400px",
}));

const CustomizedTypography = styled(Typography)(() => ({
    color: "#21315b",
}));

const CustomizedCircularProgress = styled(CircularProgress)(() => ({
    color: "#21315b",
}));

const CustomizedInputLabel = styled(InputLabel)(() => ({
    fontWeight: "bold",
    color: "#21315b",
    whiteSpace: "pre-wrap",
}));

const defaultValues: Partial<VideoRequestForm> = {
    location: {
        label: "",
        id: "",
    },
    device: {
        label: "",
        id: "",
    },
    camera: {
        label: "",
        id: "",
    },
};

const NewVideoRequest = () => {
    const deviceAbortControllerRef = useRef<AbortController | null>(null);
    const { appCode } = useSelector((state) => state.auth);
    const { leafLocations } = useSelector((state) => state.newLocation);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isFullScreenModalOpen, setIsFullScreenModalOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [allDevices, setAllDevices] = useState<IDevice[]>([]);
    const [deviceOptions, setDeviceOptions] = useState<AutoLabelId[]>([]);
    const [cameraOptions, setCameraOptions] = useState<AutoLabelId[]>([]);
    const [selectedLocationId, setSelectedLocationId] = useState<string | null>(null);
    const [isDevicesLoading, setIsDevicesLoading] = useState(false);
    const navigate = useNavigate();
    const locationOptions: AutoLabelId[] = leafLocations
        ?.filter((loc) => loc?.metadata?.canRequestEdgeVideo)
        ?.map(({ id, name }) => ({ id, label: name }));
    const {
        handleSubmit,
        setValue,
        control,
        reset,
        watch,
        clearErrors,
        formState: { errors },
    } = useForm<VideoRequestForm>({
        defaultValues,
    });

    useEffect(() => {
        return () => {
            deviceAbortControllerRef.current?.abort();
        };
    }, []);

    const getMinSelectableDate = () => {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() - RETENTION_PERIOD_IN_DAYS);
        return new Date(expirationDate);
    };

    const onSubmit: SubmitHandler<VideoRequestForm> = async (data) => {
        setIsModalOpen(true);
        setIsLoading(true);
        const body: IVideoRequestBody = {
            cameraId: data.camera.id,
            deviceId: data.device.id,
            locationId: data.location.id,
            startAt: data.startTime ? data.startTime.toISOString() : "",
            endAt: data.endTime ? new Date(new Date(data.endTime).setMilliseconds(999)).toISOString() : "",
            reason: data.reason,
        };
        try {
            await executeCreateVideoRequest(body);
        } catch (error) {
            setIsModalOpen(false);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchDevices = async (locationId: string) => {
        let fetchedDevices = allDevices;
        setDeviceOptions([]);
        setCameraOptions([]);
        try {
            if (selectedLocationId !== locationId) {
                setValue("device", { label: "", id: "" });
                setValue("camera", { label: "", id: "" });
                clearErrors("device");
                clearErrors("camera");
                setIsDevicesLoading(true);
                setAllDevices([]);
                fetchedDevices = await executeGetAllDevicesRequest(
                    { locationId, isActive: true },
                    { signal: deviceAbortControllerRef.current?.signal },
                );
                setAllDevices(fetchedDevices);
            }
            const gpus = fetchedDevices.filter(({ type }) => type === DeviceType.GPU);
            setDeviceOptions(gpus.map(({ id, name }) => ({ id, label: name })));
            if (gpus.length === 1) {
                setValue("device", { id: gpus[0].id, label: gpus[0].name });
                clearErrors("device");
                const cameras = fetchedDevices.filter(
                    ({ type, parentDeviceId }) => type === DeviceType.CAMERA && parentDeviceId === gpus[0].id,
                );
                setCameraOptions(cameras.map(({ id, name }) => ({ id, label: name })));
                if (cameras.length === 1) {
                    setValue("camera", { id: cameras[0].id, label: cameras[0].name });
                    clearErrors("camera");
                }
            }
        } catch (error) {
            setValue("location", { label: "", id: "" });
            setValue("device", { label: "", id: "" });
            setValue("camera", { label: "", id: "" });
        } finally {
            setIsDevicesLoading(false);
        }
    };

    const handleCancel = () => {
        reset(defaultValues);
        navigate(`/${appCode}/video-requests`);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        handleCancel();
    };

    const startTime = watch("startTime");
    const endTime = watch("endTime");
    const location = watch("location");
    const device = watch("device");

    const Form = (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container item xs={12} sm={12} md={12} lg={12} marginBottom={2}>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <CustomizedInputLabel htmlFor="location">Location</CustomizedInputLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                        name="location"
                        rules={{
                            validate: (value) => !!value?.id || FIELD_IS_REQUIRED,
                        }}
                        control={control}
                        render={({ field: { value } }) => (
                            <IotVisionAutoComplete
                                options={locationOptions}
                                value={value}
                                onChange={(
                                    _event: React.SyntheticEvent,
                                    value: AutoLabelId,
                                    reason: AutocompleteChangeReason,
                                ) => {
                                    if (reason === "clear") {
                                        setValue("location", { label: "", id: "" });
                                        setValue("device", { label: "", id: "" });
                                        setValue("camera", { label: "", id: "" });
                                        setDeviceOptions([]);
                                        setCameraOptions([]);
                                        clearErrors("device");
                                        clearErrors("camera");
                                    } else {
                                        fetchDevices(value.id);
                                        setValue("location", { label: value?.label, id: value?.id });
                                        setSelectedLocationId(value.id);
                                    }
                                    clearErrors("location");
                                }}
                                renderInput={(params: any) => (
                                    <IotVisionTextFiled
                                        fullWidth
                                        {...params}
                                        error={!!errors.location}
                                        helperText={errors.location && errors.location.message}
                                    />
                                )}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} marginBottom={2}>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <CustomizedInputLabel htmlFor="device">Device ID</CustomizedInputLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                        name="device"
                        rules={{
                            validate: (value) => {
                                if (!value.id) return FIELD_IS_REQUIRED;
                                const matchedDevice = allDevices.find(({ id }) => id === value.id);
                                const matchedLocation = leafLocations.find(
                                    ({ id }) => id === matchedDevice?.locationId,
                                );
                                return matchedLocation?.id === location.id || INVALID_SELECTION;
                            },
                        }}
                        control={control}
                        render={({ field: { value } }) => (
                            <IotVisionAutoComplete
                                options={deviceOptions}
                                value={value}
                                onChange={(
                                    _event: React.SyntheticEvent,
                                    value: AutoLabelId,
                                    reason: AutocompleteChangeReason,
                                ) => {
                                    if (reason === "clear") {
                                        setValue("device", { label: "", id: "" });
                                        setValue("camera", { label: "", id: "" });
                                        setCameraOptions([]);
                                    } else {
                                        setValue("device", { label: value?.label, id: value?.id });
                                        setValue("camera", { label: "", id: "" });
                                        const cameras = allDevices.filter(
                                            ({ parentDeviceId, type }) =>
                                                parentDeviceId === value.id && type === DeviceType.CAMERA,
                                        );
                                        setCameraOptions(cameras.map(({ id, name }) => ({ id, label: name })));
                                        if (cameras.length === 1)
                                            setValue("camera", { id: cameras[0].id, label: cameras[0].name });
                                    }
                                    clearErrors("camera");
                                    clearErrors("device");
                                }}
                                renderInput={(params: any) => (
                                    <IotVisionTextFiled
                                        fullWidth
                                        {...params}
                                        error={!!errors.device}
                                        helperText={
                                            (errors.device && errors.device.message) ||
                                            (isDevicesLoading && "Loading...")
                                        }
                                    />
                                )}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} marginBottom={2}>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <CustomizedInputLabel htmlFor="camera">Camera ID</CustomizedInputLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                        name="camera"
                        rules={{
                            validate: (value) => {
                                if (!value.id) return FIELD_IS_REQUIRED;
                                const matchedCamera = allDevices.find(({ id }) => id === value.id);
                                const parentDevice = allDevices.find(({ id }) => id === matchedCamera?.parentDeviceId);
                                return parentDevice?.id === device.id || INVALID_SELECTION;
                            },
                        }}
                        control={control}
                        render={({ field: { value } }) => (
                            <IotVisionAutoComplete
                                options={cameraOptions}
                                value={value}
                                onChange={(
                                    _event: React.SyntheticEvent,
                                    value: AutoLabelId,
                                    reason: AutocompleteChangeReason,
                                ) => {
                                    if (reason === "clear") {
                                        setValue("camera", { label: "", id: "" });
                                    } else {
                                        setValue("camera", { label: value?.label, id: value?.id });
                                    }
                                    clearErrors("camera");
                                }}
                                renderInput={(params: any) => (
                                    <IotVisionTextFiled
                                        fullWidth
                                        {...params}
                                        error={!!errors.camera}
                                        helperText={errors.camera && errors.camera.message}
                                    />
                                )}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} marginBottom={2}>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <CustomizedInputLabel htmlFor="startTime">Start Time</CustomizedInputLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                        name="startTime"
                        rules={{
                            validate: (value) => {
                                if (!value) return FIELD_IS_REQUIRED;
                                if (getMinSelectableDate() > value) return START_TIME_IS_OUT_OF_30_DAY_RANGE;
                                return endTime ? endTime > value || START_TIME_SHOULD_BE_LESS_THAN_END_TIME : true;
                            },
                        }}
                        control={control}
                        render={({ field: { value } }) => (
                            <CommonDateTimePicker
                                views={["day", "hours", "minutes", "seconds"]}
                                dateFormat={DATE_FORMAT_WITH_SECONDS}
                                maxDate={endTime || new Date()}
                                minDate={getMinSelectableDate()}
                                onChange={(date: Date | null): void => {
                                    setValue("startTime", date);
                                    clearErrors("startTime");
                                    if (errors.endTime?.message === END_TIME_SHOULD_BE_GREATER_THAN_START_TIME)
                                        clearErrors("endTime");
                                }}
                                errorMessage={errors.startTime && errors.startTime.message}
                                value={value || null}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} marginBottom={2}>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <CustomizedInputLabel htmlFor="endTime">End Time</CustomizedInputLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                        name="endTime"
                        rules={{
                            validate: (value) => {
                                if (!value) return FIELD_IS_REQUIRED;
                                return startTime
                                    ? startTime < value || END_TIME_SHOULD_BE_GREATER_THAN_START_TIME
                                    : true;
                            },
                        }}
                        control={control}
                        render={({ field: { value } }) => (
                            <CommonDateTimePicker
                                views={["day", "hours", "minutes", "seconds"]}
                                dateFormat={DATE_FORMAT_WITH_SECONDS}
                                minDate={startTime || undefined}
                                maxDate={new Date()}
                                onChange={(date: Date | null): void => {
                                    setValue("endTime", date);
                                    clearErrors("endTime");
                                    if (errors.startTime?.message === START_TIME_SHOULD_BE_LESS_THAN_END_TIME)
                                        clearErrors("startTime");
                                }}
                                errorMessage={errors.endTime && errors.endTime.message}
                                value={value || null}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} marginBottom={2}>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <CustomizedInputLabel htmlFor="reason">Reason</CustomizedInputLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                        name="reason"
                        rules={{ required: { value: true, message: FIELD_IS_REQUIRED } }}
                        control={control}
                        render={({ field: { value } }) => (
                            <IotVisionTextFiled
                                fullWidth
                                value={value || ""}
                                onChange={(event) => {
                                    setValue("reason", event?.target?.value);
                                    clearErrors("reason");
                                }}
                                error={!!errors.reason}
                                helperText={errors.reason && errors.reason.message}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} marginTop={5}>
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={6} md={2} lg={2} xl={2} marginRight={1}>
                        <CustomizedIotVisionSearchButton type="submit" fullWidth>
                            Create
                        </CustomizedIotVisionSearchButton>
                    </Grid>
                    <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                        <CustomizedIotVisionClearButton fullWidth onClick={handleCancel}>
                            Cancel
                        </CustomizedIotVisionClearButton>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );

    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={10} md={10} lg={10} xl={10} marginBottom={2}>
                    <SectionHeading heading="Create Video Request" icon={<ArticleIcon className="icon" />} />
                </Grid>
            </Grid>
            <PaperWrapper>
                <Grid container>
                    <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                        <IconButton className="expand-btn" onClick={() => setIsFullScreenModalOpen(true)} disableRipple>
                            <CgArrowsExpandRight />
                        </IconButton>
                    </Grid>
                </Grid>
                {Form}
            </PaperWrapper>
            <FullScreenDialog
                modalShow={isFullScreenModalOpen}
                onClose={() => setIsFullScreenModalOpen(false)}
                childComponent={
                    <PaperWrapper>
                        <Grid container>
                            <Grid item xs={12} sm={10} md={10} lg={10} xl={10} marginBottom={5}>
                                <SectionHeading
                                    heading="Create Video Request"
                                    icon={<ArticleIcon className="icon" />}
                                />
                            </Grid>
                        </Grid>
                        {Form}
                    </PaperWrapper>
                }
            />
            <Dialog open={isModalOpen}>
                <CustomizedDialogContent>
                    {isLoading && <CustomizedCircularProgress />}
                    <CustomizedTypography variant="body">
                        {isLoading ? "Sending video request..." : "Video request successful"}
                    </CustomizedTypography>
                    {!isLoading && (
                        <CustomizedIotVisionSearchButton onClick={handleModalClose}>
                            Close
                        </CustomizedIotVisionSearchButton>
                    )}
                </CustomizedDialogContent>
            </Dialog>
        </>
    );
};

export default NewVideoRequest;
