import { Alert } from "@mui/material";
import React, { createContext, useContext, useMemo } from "react";
import { useSnackbar, SnackbarProvider, SnackbarKey, OptionsObject } from "notistack";
import { makeStyles } from "@mui/styles";

export type SetNotificationType = (notificationProps: NotificationProps) => SnackbarKey;

export type NotificationContextType = {
    setNotification: SetNotificationType;
};
export const NotificationContext = createContext<NotificationContextType | null>(null);

type MessageType = "error" | "warning" | "info" | "success";

export interface NotificationProps extends OptionsObject {
    type?: MessageType;
    message?: string;
    customIcon?: React.ReactNode;
    isAutoClose?: boolean;
}
export interface InnerSnackbarUtilsConfigurationProps {
    setNotificationBarRef: (setNotificationType: SetNotificationType) => void;
}
let setNotificationRef: SetNotificationType;
const useStyles = makeStyles({
    containerRoot: {
        marginTop: "53px",
    },
});

const setNotificationBarRefFunc = (setNotificationBarRefArg: SetNotificationType) => {
    setNotificationRef = setNotificationBarRefArg;
};
export const setNotifier = () => {
    return setNotificationRef;
};

const InnerNotificationUtilsConfiguration: React.FC<InnerSnackbarUtilsConfigurationProps> = ({
    setNotificationBarRef,
}) => {
    const { setNotification } = useContext(NotificationContext) as NotificationContextType;
    setNotificationBarRef(setNotification);
    return null;
};
export const NotificationProvider: React.FC<React.ReactNode> = ({ children }) => {
    const classes = useStyles();
    return (
        <SnackbarProvider classes={{ containerRoot: classes.containerRoot }} maxSnack={3}>
            <NotificationLogic>
                {children}
                <InnerNotificationUtilsConfiguration setNotificationBarRef={setNotificationBarRefFunc} />
            </NotificationLogic>
        </SnackbarProvider>
    );
};

export const NotificationLogic: React.FC<React.ReactNode> = ({ children }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const setNotification = (notificationProps: NotificationProps): SnackbarKey => {
        const { type, message, isAutoClose, customIcon } = notificationProps || {};
        return enqueueSnackbar("", {
            ...(isAutoClose ? { autoHideDuration: 3000 } : { persist: true }),
            anchorOrigin: {
                vertical: "top",
                horizontal: "right",
            },
            content: (key) => (
                <Alert
                    {...(customIcon ? { icon: customIcon } : {})}
                    {...(isAutoClose
                        ? {}
                        : {
                              onClose: () => {
                                  closeSnackbar(key);
                              },
                          })}
                    {...(type ? { severity: type } : {})}
                    sx={{ width: "100%" }}
                >
                    {message || ""}
                </Alert>
            ),
        });
    };

    const setNotificationProp = useMemo(() => ({ setNotification }), []);
    return <NotificationContext.Provider value={setNotificationProp}>{children}</NotificationContext.Provider>;
};
