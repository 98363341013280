/* eslint-disable @typescript-eslint/no-explicit-any */
import ExecuteAsync from "utils/api-client";
import { ApiEndpoints } from "configs";
import { RequestOptions } from "axios-types";
import createQueryParams from "utils/create-query-params";
import { CommonObjectLiteral } from "types/reports/interfaces";

export const executeGetIncidentVideoFootageRequest = (incidentId: string, options?: RequestOptions) => {
    return ExecuteAsync<any, string>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/incidents/incident/${incidentId}/video-link`,
        signal: options?.signal,
    });
};

export const executeGetIncidentData = (data?: CommonObjectLiteral, options?: RequestOptions) => {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/incidents/incident/filter${createQueryParams(data)}`,
        signal: options?.signal,
    });
};
