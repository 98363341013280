import { ConditionType } from "../enums";
import { Threshold } from "../types";

/**
 * Compare the given value with the threshold value
 * @param threshold Condition to be checked
 * @param value Actual amount, that need to checked with the threshold
 * @returns boolean value whether given value is in the range of the expected condition or not.
 */
export const isMatched = (threshold: Threshold, value: number): boolean => {
    switch (threshold.condition) {
        // check less than or equals condition
        case ConditionType.LT_EQ:
            return value <= threshold.value;
        // check greater than or equals condition
        case ConditionType.GT_EQ:
            return value >= threshold.value;
        // check greater than condition
        case ConditionType.GT:
            return value > threshold.value;
        // check less than condition
        case ConditionType.LT:
            return value < threshold.value;
        // check equals condition
        case ConditionType.EQ:
            return value === threshold.value;
        // If none of above conditions are not matched returns false
        default:
            return false;
    }
};

export default isMatched;
