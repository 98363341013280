import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { IconButton, Menu, Icon } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import vizialLogo from "assets/images/vizialLogo.png";
import { appDefaultRoute, pagePath } from "pages/app-dept-air-quality";
import { useLocation, useNavigate } from "react-router-dom";
import { UserMenu } from "./UserMenu";

const Navbar = () => {
    const { logout } = useAuth0();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const isActivePage = (path: pagePath) => pathname.includes(path);

    return (
        <AppBar position="fixed" sx={{ bgcolor: "white", height: "53px" }} elevation={1}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <IconButton
                        sx={{
                            height: "45px",
                            "&:hover": {
                                borderRadius: "15px",
                            },
                            marginBottom: "10px",
                            marginLeft: "-15px",
                        }}
                        onClick={() => navigate(appDefaultRoute)}
                    >
                        <Icon
                            sx={{
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            <img alt="platformityLogo" src={vizialLogo} />
                        </Icon>
                    </IconButton>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "flex", md: "none" },
                        }}
                    >
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" },
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "none", md: "flex" },
                            justifyContent: "flex-end",
                            paddingRight: "10px",
                            position: "relative",
                            top: "-5px",
                        }}
                    >
                        <IconButton
                            sx={{
                                marginTop: "5px",
                                height: "30px",
                                marginRight: "-9px",
                                "&:hover": {
                                    borderRadius: "10px",
                                },
                                ...(isActivePage(pagePath.Incident) && {
                                    borderRadius: "10px",
                                    backgroundColor: "#F5F8FD",
                                }),
                            }}
                            onClick={() => {
                                navigate(`${appDefaultRoute}/${pagePath.Incident}`);
                            }}
                        >
                            <Typography
                                {...(isActivePage(pagePath.Incident)
                                    ? {
                                          variant: "activeHeader",
                                      }
                                    : { variant: "header3" })}
                            >
                                Incidents
                            </Typography>
                        </IconButton>
                    </Box>
                    <UserMenu navigate={navigate} logout={logout} />
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Navbar;
