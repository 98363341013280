import React from "react";
import { BiCalendarStar } from "react-icons/bi";

const EventItem = () => {
    return (
        <div className="event-item-wrapper">
            <div className="icon">
                <BiCalendarStar className="event-icon" />
            </div>
            <div className="event-content">
                <p>Event Alert: T9015</p>
                <p>Busy time - Many cyclists</p>
            </div>
        </div>
    );
};

export default EventItem;
