import { Chip, Typography } from "@mui/material";
import React from "react";
import { AQSensorData } from "utils/threshold-data-finder/types";

export interface RoomHeaderProps {
    roomName?: string;
    serialNo: string;
    roomStatus: AQSensorData | null;
    lastUpdated: string | null;
}
const RoomHeader: React.FC<RoomHeaderProps> = ({ roomName, serialNo, roomStatus, lastUpdated }) => {
    return (
        <div>
            <Typography fontSize={40} color="#001B4F" fontWeight={600}>
                {roomName}
                {roomStatus && (
                    <Chip
                        label={<span style={{ color: "white" }}>{roomStatus.statusLabel}</span>}
                        size="small"
                        sx={{
                            fontWeight: "400",
                            fontSize: "12px",
                            marginLeft: "10px",
                        }}
                        style={{ backgroundColor: roomStatus.color }}
                    />
                )}
            </Typography>
            <Typography marginTop="-6px" position="relative">
                {serialNo && (
                    <span
                        style={{
                            fontSize: "14px",
                            color: "#001B4F",
                            fontWeight: "600",
                        }}
                    >
                        {`Serial #: ${serialNo}`}
                    </span>
                )}
                {lastUpdated && (
                    <span
                        style={{
                            fontSize: "14px",
                            color: "#A3A3A3",
                            fontWeight: "400",
                            paddingRight: "7px",
                            paddingLeft: "7px",
                        }}
                    >
                        {" | "}
                    </span>
                )}
                {lastUpdated && (
                    <span
                        style={{
                            fontSize: "14px",
                            color: "#A3A3A3",
                            fontWeight: "400",
                        }}
                    >
                        {` Last Updated ${lastUpdated}`}
                    </span>
                )}
            </Typography>
        </div>
    );
};

export default RoomHeader;
