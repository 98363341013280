/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { CLASS_CAR, CLASS_SCOOTER, CLASS_VEHICLE } from "constants/class.constants";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DetectedVehiclesChart from "features/app-park-and-ride/chart/DetectedVehiclesChart";
import ParkingMeanChart from "features/app-park-and-ride/chart/ParkingMeanChart";
import { useAppDispatch } from "store";
import { checkLocationIdChanged, FetchFunctionParams, makeApiCallWithUpdateTime } from "utils/common";
import { BsBoxArrowInRight, BsBoxArrowRight } from "react-icons/bs";
import { executeGetDetectionsOvertimeByDirectionRequest } from "api/app-park-and-ride/pr-detection-api";
import { Directions } from "types/chart-configs/enums/common";
import HistoricalOccupancyChart from "features/app-park-and-ride/chart/HistoricalOccupancyChart";
import { HistoricalQueryParams, HistoricalResponse, IncidentOvertimeData } from "types";
import { executeGetHistoricalOccupancyRequest } from "api/app-park-and-ride/pr-realtime-api";
import NetOccupancyChart from "features/app-park-and-ride/chart/NetOccupancyChart";
import IncidentsOverTimeLineChart from "features/app-park-and-ride/chart/IncidentsOverTimeLineChart";
import { ScaleUnit } from "enums/scale-unit.enum";
import { executeGetIncidentOvertime } from "api/app-park-and-ride/pnr-incidents-api";
import { IncidentGroupByColumn } from "enums/incident.enum";

const config = {
    backgroundColor: "rgba(107, 67, 157, 1)",
    borderColor: "rgba(107, 67, 157, 1)",
    borderWidth: 1,
    maintainAspectRatio: true,
    label: "Vehicle Count",
};

enum IncidentType {
    COUNTER_FLOW = "counter_flow",
}

export interface DetectedVehicles {
    classification?: string[];
    fromTime: string;
    toTime: string;
    scaleUnit: string | undefined;
    locationId?: string;
    field1?: string;
}

export interface VehiclesOvertime {
    classifications?: string[];
    fromTime: string;
    toTime: string;
    scaleUnit: string | undefined;
    field1?: string;
}

export interface IncidentsOvertime {
    locationId: string;
    fromTime: string;
    toTime: string;
    scaleUnit: ScaleUnit;
    scaleValue?: number;
    incident?: IncidentType;
    groupBy?: string[];
}

export interface VehiclesOvertimeResponse {
    classification: string;
    count: string;
    inTime: string;
    field1?: string;
    field4: string;
}

export interface ExtraParams {
    [key: string]: string | string[];
}

const LocationChartLayout = () => {
    const [detectedVehiclesInByClassification, setDetectedVehiclesInByClassification] = useState<
        VehiclesOvertimeResponse[]
    >([]);
    const [detectedVehiclesInByEntrance, setDetectedVehiclesInByEntrance] = useState<VehiclesOvertimeResponse[]>([]);
    const [detectedVehiclesOutByClassification, setDetectedVehiclesOutByClassification] = useState<
        VehiclesOvertimeResponse[]
    >([]);
    const [incidentOvertimeData, setIncidentOvertimeData] = useState<IncidentOvertimeData[]>([]);
    const [detectedVehiclesOutByExit, setDetectedVehiclesOutByExit] = useState<VehiclesOvertimeResponse[]>([]);
    const [historicalOccupancy, setHistoricalOccupancy] = useState<HistoricalResponse[]>([]);
    const { startTime, endTime, timeType, selectedRange } = useSelector((state) => state.time);
    const { locationId } = useParams();
    const detectedVehiclesInCountByTypeAbortControllerRef = useRef<AbortController | null>(null);
    const detectedVehiclesOutCountByTypeAbortControllerRef = useRef<AbortController | null>(null);
    const detectedVehiclesInByEntranceAbortControllerRef = useRef<AbortController | null>(null);
    const detectedVehiclesOutByClassificationAbortControllerRef = useRef<AbortController | null>(null);
    const detectedVehiclesOutByExitAbortControllerRef = useRef<AbortController | null>(null);
    const detectedVehiclesInByClassificationAbortControllerRef = useRef<AbortController | null>(null);
    const getHistoricalOccupancyAbortControllerRef = useRef<AbortController | null>(null);
    const getIncidentOvertimeAbortControllerRef = useRef<AbortController | null>(null);
    const { locations } = useSelector((state) => state.newLocation);
    const dispatch = useAppDispatch();

    const fetchData = async ({ startTime, endTime }: FetchFunctionParams) => {
        const commonParams = {
            fromTime: startTime,
            toTime: endTime,
            scaleUnit: timeType,
        };
        if (locationId) {
            await Promise.all([
                getDetectedVehiclesInByEntrance(locationId, commonParams, {
                    field1: Directions.IN,
                    classifications: [CLASS_VEHICLE, CLASS_CAR, CLASS_SCOOTER],
                }),
                getDetectedVehiclesOutByExit(locationId, commonParams, {
                    field1: Directions.OUT,
                    classifications: [CLASS_VEHICLE, CLASS_CAR, CLASS_SCOOTER],
                }),
                getDetectedVehiclesInByClassification(locationId, commonParams, {
                    field1: Directions.IN,
                    classifications: [CLASS_VEHICLE, CLASS_CAR, CLASS_SCOOTER],
                }),
                getDetectedVehiclesOutByClassification(locationId, commonParams, {
                    field1: Directions.OUT,
                    classifications: [CLASS_VEHICLE, CLASS_CAR, CLASS_SCOOTER],
                }),
                getHistoricalOccupancyData({
                    locationId,
                    classification: CLASS_VEHICLE,
                    fromTime: startTime,
                    toTime: endTime,
                }),
                getIncidentOvertime({
                    locationId,
                    fromTime: startTime,
                    toTime: endTime,
                    scaleUnit: ScaleUnit.MINUTE,
                    incident: IncidentType.COUNTER_FLOW,
                    scaleValue: 15,
                    groupBy: [IncidentGroupByColumn.SITE_ID],
                }),
            ]);
        }
    };

    async function getDetectedVehiclesInByEntrance(
        locationId: string,
        data: VehiclesOvertime,
        specificParams?: ExtraParams,
    ): Promise<void> {
        try {
            detectedVehiclesInByEntranceAbortControllerRef.current = new AbortController();
            const { signal } = detectedVehiclesInByEntranceAbortControllerRef.current;
            const aggregatedParams = { ...data, ...specificParams };
            const response: VehiclesOvertimeResponse[] = await executeGetDetectionsOvertimeByDirectionRequest(
                locationId,
                aggregatedParams,
                {
                    signal,
                    disableNotification: checkLocationIdChanged(locationId, locations),
                },
            );
            if (response) setDetectedVehiclesInByEntrance(response);
        } catch (e) {
            console.log(e);
        }
    }

    async function getDetectedVehiclesInByClassification(
        locationId: string,
        data: VehiclesOvertime,
        specificParams?: ExtraParams,
    ): Promise<void> {
        try {
            detectedVehiclesInByClassificationAbortControllerRef.current = new AbortController();
            const { signal } = detectedVehiclesInByClassificationAbortControllerRef.current;
            const aggregatedParams = { ...data, ...specificParams };
            const response: VehiclesOvertimeResponse[] = await executeGetDetectionsOvertimeByDirectionRequest(
                locationId,
                aggregatedParams,
                {
                    signal,
                    disableNotification: checkLocationIdChanged(locationId, locations),
                },
            );
            if (response) setDetectedVehiclesInByClassification(response);
        } catch (e) {
            console.log(e);
        }
    }

    async function getDetectedVehiclesOutByExit(
        locationId: string,
        data: VehiclesOvertime,
        specificParams?: ExtraParams,
    ): Promise<void> {
        try {
            detectedVehiclesOutByExitAbortControllerRef.current = new AbortController();
            const { signal } = detectedVehiclesOutByExitAbortControllerRef.current;
            const aggregatedParams = { ...data, ...specificParams };
            const response: VehiclesOvertimeResponse[] = await executeGetDetectionsOvertimeByDirectionRequest(
                locationId,
                aggregatedParams,
                {
                    signal,
                    disableNotification: checkLocationIdChanged(locationId, locations),
                },
            );
            if (response) setDetectedVehiclesOutByExit(response);
        } catch (e) {
            console.log(e);
        }
    }

    async function getDetectedVehiclesOutByClassification(
        locationId: string,
        data: VehiclesOvertime,
        specificParams?: ExtraParams,
    ): Promise<void> {
        try {
            detectedVehiclesOutByClassificationAbortControllerRef.current = new AbortController();
            const { signal } = detectedVehiclesOutByClassificationAbortControllerRef.current;
            const aggregatedParams = { ...data, ...specificParams };
            const response: VehiclesOvertimeResponse[] = await executeGetDetectionsOvertimeByDirectionRequest(
                locationId,
                aggregatedParams,
                {
                    signal,
                    disableNotification: checkLocationIdChanged(locationId, locations),
                },
            );
            if (response) setDetectedVehiclesOutByClassification(response);
        } catch (e) {
            console.log(e);
        }
    }

    async function getHistoricalOccupancyData(queryParams: HistoricalQueryParams): Promise<void> {
        try {
            detectedVehiclesOutByClassificationAbortControllerRef.current = new AbortController();
            const { signal } = detectedVehiclesOutByClassificationAbortControllerRef.current;
            const response = await executeGetHistoricalOccupancyRequest(queryParams, {
                signal,
                disableNotification: checkLocationIdChanged(locationId, locations),
            });
            if (response) setHistoricalOccupancy(response);
        } catch {}
    }

    async function getIncidentOvertime(params: IncidentsOvertime): Promise<void> {
        try {
            getIncidentOvertimeAbortControllerRef.current = new AbortController();
            const { signal } = getIncidentOvertimeAbortControllerRef.current;
            const data = await executeGetIncidentOvertime(params, {
                signal,
                disableNotification: checkLocationIdChanged(locationId, locations),
            });
            if (data) setIncidentOvertimeData(data);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        setDetectedVehiclesInByClassification([]);
        setDetectedVehiclesInByEntrance([]);
        setDetectedVehiclesOutByClassification([]);
        setDetectedVehiclesOutByExit([]);
        setHistoricalOccupancy([]);
        setIncidentOvertimeData([]);
        makeApiCallWithUpdateTime(selectedRange, { startTime, endTime }, fetchData, dispatch);
        if (locationId) {
            return () => {
                detectedVehiclesInCountByTypeAbortControllerRef.current?.abort();
                detectedVehiclesOutCountByTypeAbortControllerRef.current?.abort();
                detectedVehiclesInByEntranceAbortControllerRef.current?.abort();
                detectedVehiclesInByClassificationAbortControllerRef.current?.abort();
                detectedVehiclesOutByExitAbortControllerRef.current?.abort();
                detectedVehiclesOutByClassificationAbortControllerRef.current?.abort();
                getHistoricalOccupancyAbortControllerRef.current?.abort();
                getIncidentOvertimeAbortControllerRef.current?.abort();
            };
        }
    }, [startTime, endTime, locationId]);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                    <DetectedVehiclesChart
                        title="Detected Vehicle Count entrance"
                        data={detectedVehiclesInByClassification}
                        config={config}
                        icon={
                            <BsBoxArrowInRight
                                className="header-prefix-icon-height"
                                fontSize="15px"
                                strokeWidth="0.5px"
                            />
                        }
                    />
                    <ParkingMeanChart
                        title="Detected Vehicle Count entrance"
                        data={detectedVehiclesInByEntrance}
                        icon={
                            <BsBoxArrowInRight
                                className="header-prefix-icon-height"
                                fontSize="15px"
                                strokeWidth="0.5px"
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                    <DetectedVehiclesChart
                        title="Detected vehicle count exit"
                        data={detectedVehiclesOutByClassification}
                        config={config}
                        icon={
                            <BsBoxArrowRight
                                className="header-prefix-icon-height"
                                fontSize="15px"
                                strokeWidth="0.5px"
                            />
                        }
                    />
                    <ParkingMeanChart
                        title="Detection by site exit"
                        data={detectedVehiclesOutByExit}
                        icon={
                            <BsBoxArrowRight
                                className="header-prefix-icon-height"
                                fontSize="15px"
                                strokeWidth="0.5px"
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                    <NetOccupancyChart
                        data={[...detectedVehiclesInByEntrance, ...detectedVehiclesOutByExit]}
                        title="Detected vehicle count by entrance and exit"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                    <HistoricalOccupancyChart title="Historical Occupancy" data={historicalOccupancy} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                    <IncidentsOverTimeLineChart title="Incidents over time" data={incidentOvertimeData} />
                </Grid>
            </Grid>
        </>
    );
};

export default LocationChartLayout;
