import React from "react";
import ParkAndRideLayout from "layouts/app-park-and-ride";
import Dashboard from "pages/app-park-and-ride/dashboard";
import Location from "pages/app-park-and-ride/location";
import { ProtectedLayout } from "layouts/common/general";

const root = {
    path: "home",
    element: (
        <ProtectedLayout>
            <ParkAndRideLayout />
        </ProtectedLayout>
    ),
    children: [
        { path: "", element: <Dashboard /> },
        { path: ":locationId", element: <Dashboard /> },
        { path: "location/:locationId", element: <Location /> },
    ],
    replace: true,
};

export default root;
