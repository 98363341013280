import { Link } from "react-router-dom";
import { BiRightArrowAlt } from "react-icons/bi";
import EventItem from "./EventItem";

const DrawerEventsMain = () => {
    return (
        <>
            <Link to="/" className="go-to-summery-link">
                Go to events summary page
                <BiRightArrowAlt className="icon" />
            </Link>
            <EventItem />
            <EventItem />
            <EventItem />
        </>
    );
};

export default DrawerEventsMain;
