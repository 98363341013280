/* eslint-disable import/prefer-default-export */
import videojs from "video.js";
import Player from "video.js/dist/types/player";
import Plugin from "video.js/dist/types/plugin";

interface DownloadButtonState {
    isActiveDownload: boolean;
}

interface DownloadButtonOptions{
    children?: unknown[];
    className?: string;
    plugin: PluginOptions;
    state: DownloadButtonState;
}

type PluginOptions = {
    listeners: {
        click: () => void;
    };
    download: () => void;
} & Plugin;

type CustomVideoJS = {VERSION: string} & typeof videojs;

const Button = videojs.getComponent("Button");

export class DownloadButton extends Button {

    state: DownloadButtonState;

    plugin: PluginOptions;

    constructor(player: Player, options: DownloadButtonOptions) {
        super(player, options);
        this.plugin = options.plugin;
        this.state = options.state;

        player.on("loadeddata", () => {
            this.state.isActiveDownload = true;
        });

        player.on("loadstart", () => {
            this.state.isActiveDownload = false;
        });
    }

    buildCSSClass() {
        const [major] = (videojs as CustomVideoJS).VERSION.split(".");
        return `vjs-zoom-duck vjs-download-duck-${major} ${super.buildCSSClass()}`;
    }

    handleClick() {
        if (this.state.isActiveDownload) this.plugin.download();
    }
}
