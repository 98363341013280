import { TreeItem, treeItemClasses, TreeItemProps } from "@mui/lab";
import { Box, styled, SvgIconProps, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React from "react";

declare module "react" {
    interface CSSProperties {
        "--tree-view-color"?: string;
        "--tree-view-bg-color"?: string;
    }
}

type StyledTreeItemProps = TreeItemProps & {
    bgColor?: string;
    color?: string;
    labelIcon?: React.ElementType<SvgIconProps>;
    labelInfo?: string;
    labelText: string;
};

const StyledTreeItemRoot = styled(TreeItem)(() => ({
    [`& .${treeItemClasses.content}`]: {
        color: "#001B4F",
        background: "#FFFFFF",
        fontFamily: "Poppins",
        flexDirection: "row-reverse",
        "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
            backgroundColor: "#F5F8FD",
        },

        "&:hover": {
            backgroundColor: "#F5F8FD",
            backGround: "#F5F8FD",
            borderRadius: "3px",
            color: "#001B4F !important",
            borderRight: "3px solid #001B4F ",
        },
        [`& .${treeItemClasses.iconContainer}`]: {},
        [`& .${treeItemClasses.label}`]: {
            height: "34px",
        },
    },

    [`& .${treeItemClasses.group}`]: {
        [`& .${treeItemClasses.content}`]: {
            paddingLeft: "37px",
        },
    },
}));

const SideBarTreeItem = (props: StyledTreeItemProps) => {
    const { bgColor, color, labelIcon: LabelIcon, labelText, ...other } = props;

    return (
        <StyledTreeItemRoot
            label={
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        p: 0.5,
                        pr: 0,
                    }}
                >
                    <Box component={LabelIcon} sx={{ mr: 1 }} />

                    {labelText.length > 20 ? (
                        <Tooltip placement="bottom" title={labelText} arrow>
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    ...(props?.children ? { fontWeight: 700 } : { fontWeight: 400 }),
                                }}
                            >
                                {labelText.substring(0, 19)}
                            </Typography>
                        </Tooltip>
                    ) : (
                        <Typography
                            sx={{
                                fontSize: "14px",
                                ...(props?.children ? { fontWeight: 700 } : { fontWeight: 400 }),
                            }}
                        >
                            {labelText}
                        </Typography>
                    )}
                </Box>
            }
            style={{
                "--tree-view-color": color,
                "--tree-view-bg-color": bgColor,
            }}
            {...other}
        />
    );
};

export default SideBarTreeItem;
