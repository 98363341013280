import { Navigate, useRoutes } from "react-router-dom";
import App1RouteRoot from "pages/app-taxi-ranking";
import App2RouteRoot from "pages/app-cycle-ways";
import App3RouteRoot from "pages/app-air-quality";
import App4RouteRoot from "pages/app-oversized-vehicle";
import App5RouteRoot from "pages/app-dept-air-quality";
import App6RouteRoot from "pages/app-park-and-ride";
import App7RouteRoot from "pages/app-ungated-parking";
import { NavigateForbidden, NavigateNotFound } from "constants/paths.constants";
import NotFound from "pages/common/errors/not-found/NotFound";
import ScrollTopWrapper from "components/wrappers/ScrollTopWrapper";
import ImagePreview from "pages/common/image/ImagePreview";
import { SCOPES } from "enums/scopes.enum";
import React from "react";
import Forbidden from "pages/common/errors/forbidden/Forbidden";
import WithScopes from "components/common/WithScopes";
import { IndexLayout } from "./layouts/common/general";

const Router = (): React.ReactElement => {
    const routes = [
        { path: "redirect-callback", element: <IndexLayout /> },
        ...App1RouteRoot,
        ...App2RouteRoot,
        ...App3RouteRoot,
        ...App4RouteRoot,
        ...App5RouteRoot,
        ...App6RouteRoot,
        ...App7RouteRoot,
        {
            path: "image/snapshot",
            element: (
                <WithScopes filterScopes={[SCOPES.VIEW_INCIDENTS_PAGE]} isFullScreen>
                    <ImagePreview />
                </WithScopes>
            ),
        },
        { path: NavigateNotFound, element: <NotFound /> },
        { path: NavigateForbidden, element: <Forbidden /> },
        { path: "*", element: <Navigate to={NavigateNotFound} replace /> },
    ];
    const routing = useRoutes(routes);

    return <ScrollTopWrapper>{routing}</ScrollTopWrapper>;
};

export default Router;
