import { MILLISECONDS_TO_DAY, MILLISECONDS_TO_HOUR, MILLISECONDS_TO_MINUTE } from "constants/time.constants";
import { isAfter, isBefore } from "date-fns";
import { ScaleUnit } from "enums/scale-unit.enum";
import { TimeRange } from "features/common/date-header/DateRangePicker";
import { XYData } from "types/chart-configs/interfaces/common";

/**
 * generate time intervals for provided time range based on provided scale unit
 * @param start pickerRanges
 * @param end pickerRanges
 * @param scaleUnit TimeRange.Values can be the minute | hour | day | month
 * @returns string date array
 */
export const generateTimeIntervals = (start: string, end: string, scaleUnit: string) => {
    const intervals: string[] = [];
    const current = new Date(start);
    const endTime = new Date(end);

    switch (scaleUnit) {
        case TimeRange?.Minutes:
            current.setUTCSeconds(0, 0);
            while (current < endTime) {
                intervals.push(current.toISOString());
                current.setMinutes(current.getMinutes() + 1);
            }
            break;
        case TimeRange?.Day:
            current.setUTCHours(0, 0, 0, 0);
            while (current < endTime) {
                intervals.push(current.toISOString());
                current.setDate(current.getDate() + 1);
            }
            break;
        case TimeRange?.Month:
            current.setUTCDate(1);
            current.setUTCHours(0, 0, 0, 0);
            while (current < endTime) {
                intervals.push(current.toISOString());
                current.setMonth(current.getMonth() + 1);
            }
            break;
        default:
            current.setUTCMinutes(0, 0, 0);
            while (current < endTime) {
                intervals.push(current.toISOString());
                current.setHours(current.getHours() + 1);
            }
    }
    return intervals;
};

/**
 * Calculate the data missing gaps from both start and the end of the
 * provided time range based on the provided scale unit and generate missing data points
 * @param data XYData array generated for graphs
 * @param fromTime start of the range as ISO string
 * @param toTime end of the range as ISO string
 * @param scaleUnit TimeRange.Values can be the minute | hour | day | month
 * @returns XYData array returns as inputs for graphs
 */
export const fillEmptyPointsWithZeros = (data: XYData[], fromTime: string, toTime: string, scaleUnit: string) => {
    let modifiedData: XYData[] = data;
    if (Array.isArray(data) && data?.length) {
        const fromTimePoint = new Date(fromTime);
        const actualDataStartTimePoint = new Date(data[0]?.x);
        const toTimePoint = new Date(toTime);
        const actualDataEndTimePoint = new Date(data[data.length - 1]?.x);
        if (isBefore(fromTimePoint, actualDataStartTimePoint)) {
            const startRangeDateArray = generateTimeIntervals(
                fromTimePoint.toISOString(),
                actualDataStartTimePoint.toISOString(),
                scaleUnit,
            );
            if (Array.isArray(startRangeDateArray) && startRangeDateArray?.length) {
                const sub: { x: Date; y: number }[] = [];
                startRangeDateArray.forEach((date) => sub.push({ x: new Date(date), y: 0 }));
                modifiedData = sub.concat(data);
            }
        }
        if (isAfter(toTimePoint, actualDataEndTimePoint)) {
            const endRangeDateArray = generateTimeIntervals(
                actualDataEndTimePoint.toISOString(),
                toTimePoint.toISOString(),
                scaleUnit,
            );
            if (Array.isArray(endRangeDateArray) && endRangeDateArray?.length) {
                endRangeDateArray.forEach(
                    (date, index) => index !== 0 && modifiedData.push({ x: new Date(date), y: 0 }),
                );
            }
        }
    }
    return modifiedData;
};

/**
 * reduce the time that matched to the provided scale unit from the provided time
 * @param scaleUnit TimeRange.Values can be the minute | hour | day | month
 * @param timeObject Date object
 * @returns XYData array returns as inputs for graphs
 */
export const reduceScaleUnitFromTime = (scaleUnit: string, timeObject: Date) => {
    switch (scaleUnit) {
        case TimeRange?.Minutes:
            return new Date(timeObject.setMinutes(timeObject.getMinutes() - 1)).toISOString();
        case TimeRange?.Day:
            return new Date(timeObject.setDate(timeObject.getDate() - 1)).toISOString();
        case TimeRange?.Month:
            return new Date(timeObject.setMonth(timeObject.getMonth() - 1)).toISOString();
        default:
            return new Date(timeObject.setHours(timeObject.getHours() - 1)).toISOString();
    }
};

/**
 * Generates an array of dates, grouped by a specified scale unit and value, between a start and end date.
 * @param {string | Date} startDate - The start date for the range.
 * @param {string | Date} endDate - The end date for the range.
 * @param {ScaleUnit} scaleUnit - The unit of time for scaling (e.g., minute, hour, day).
 * @param {number} scaleValue - The value to scale the unit by (e.g., 5 minutes, 2 hours).
 * @returns {Date[]} An array of dates, grouped by the specified scale unit and value.
 */
export const findDateGroups = (
    startDate: string | Date,
    endDate: string | Date,
    scaleUnit: ScaleUnit,
    scaleValue: number,
): Date[] => {
    const dateGroups: Date[] = [];
    let scaleValueInMS: number;
    let rangeStart = new Date(startDate);

    switch (scaleUnit) {
        case ScaleUnit.MINUTE:
            scaleValueInMS = scaleValue * MILLISECONDS_TO_MINUTE;
            break;
        case ScaleUnit.HOUR:
            scaleValueInMS = scaleValue * MILLISECONDS_TO_HOUR;
            break;
        case ScaleUnit.DAY:
            scaleValueInMS = scaleValue * MILLISECONDS_TO_DAY;
            break;
        default:
            scaleValueInMS = scaleValue * MILLISECONDS_TO_HOUR;
    }

    do {
        const epoch = new Date(rangeStart).getTime();
        rangeStart = new Date(epoch - (epoch % scaleValueInMS));
        dateGroups.push(rangeStart);
        rangeStart = new Date(rangeStart.getTime() + scaleValueInMS);
    } while (rangeStart.getTime() < new Date(endDate).getTime());

    return dateGroups;
};

/**
 * Fills gaps in the provided data with zero values at specified intervals between a start and end date.
 * @param {XYData[]} data - The original data points to be modified.
 * @param {Object} options - The options for the date range and scale.
 * @param {string | Date} options.startDate - The start date for the range.
 * @param {string | Date} options.endDate - The end date for the range.
 * @param {ScaleUnit} options.scaleUnit - The unit of time for scaling (e.g., minute, hour, day).
 * @param {number} options.scaleValue - The value to scale the unit by (e.g., 5 minutes, 2 hours).
 * @returns {XYData[]} The modified data with gaps filled by zeros.
 */
export const fillEmptyPointsInGraphWithZeros = (
    data: XYData[],
    options: { startDate: string | Date; endDate: string | Date; scaleUnit: ScaleUnit; scaleValue: number },
): XYData[] => {
    const { startDate, endDate, scaleUnit, scaleValue } = options;
    const modifiedData: XYData[] = [];
    const dateGroups = findDateGroups(startDate, endDate, scaleUnit, scaleValue);
    dateGroups.forEach((dateGroup) => {
        const match = data.findIndex(({ x }) => x.getTime() === dateGroup.getTime());
        if (match === -1) {
            modifiedData.push({ x: dateGroup, y: 0 });
        } else {
            modifiedData.push(data[match]);
        }
    });
    return modifiedData;
};
