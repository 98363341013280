/* eslint-disable import/prefer-default-export */

import { CommonObjectLiteral } from "types";

interface ListenerType {
    event: string, callback: (data: CommonObjectLiteral) => void
}
export class Observer {
    static instance: Observer | null = null;

    listeners: ListenerType[];

    constructor() {
        this.listeners = [];
    }

    static getInstance(): Observer {
        if (!Observer.instance) {
            Observer.instance = new Observer();
        }
        return Observer.instance;
    }

    subscribe(event: string, callback: (data: CommonObjectLiteral) => void): void {
        this.listeners.push({ event, callback });
    }

    notify(event: string, data: CommonObjectLiteral) {
        this.listeners.forEach((listener) => {
            if (listener.event === event) {
                return listener.callback(data);
            }
        });
    }
}
