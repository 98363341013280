import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { IconButton, Menu, MenuItem, ListItemIcon, Icon } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { IoPersonCircleOutline } from "react-icons/io5";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Logout } from "@mui/icons-material";
import platformityLogo from "assets/images/platformityLogo.png";
import { appOVDefaultRoute, pagePath } from "pages/app-oversized-vehicle";
import { useLocation, useNavigate } from "react-router-dom";

const Header = () => {
    const { logout } = useAuth0();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const isActivePage = (path: pagePath) => pathname.includes(path);

    return (
        <AppBar position="fixed" sx={{ bgcolor: "white", height: "53px" }} elevation={1}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <IconButton
                        sx={{
                            height: "45px",
                            "&:hover": {
                                borderRadius: "15px",
                            },
                            marginBottom: "10px",
                            marginLeft: "-15px",
                        }}
                        onClick={() => navigate(`${appOVDefaultRoute}`)}
                    >
                        <Icon
                            sx={{
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            <img alt="platformityLogo" src={platformityLogo} />
                        </Icon>
                    </IconButton>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "flex", md: "none" },
                        }}
                    >
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" },
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "none", md: "flex" },
                            justifyContent: "flex-end",
                            paddingRight: "10px",
                            position: "relative",
                            top: "-5px",
                        }}
                    >
                        <IconButton
                            sx={{
                                marginTop: "5px",
                                height: "30px",
                                marginRight: "-9px",
                                "&:hover": {
                                    borderRadius: "10px",
                                },
                                ...(isActivePage(pagePath.Detection) && {
                                    borderRadius: "10px",
                                    backgroundColor: "#F5F8FD",
                                }),
                            }}
                            onClick={() => {
                                navigate(`${appOVDefaultRoute}/${pagePath.Detection}`);
                            }}
                        >
                            <Typography
                                style={
                                    isActivePage(pagePath.Detection)
                                        ? {
                                              margin: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: "600",
                                              fontSize: "14px",
                                              lineHeight: "21px",
                                              color: "#001B4F",
                                          }
                                        : {
                                              margin: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: "400",
                                              fontSize: "14px",
                                              lineHeight: "21px",
                                              color: "#001B4F",
                                          }
                                }
                            >
                                Detections
                            </Typography>
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 0,
                            paddingLeft: "3px",
                            marginRight: "-5px",
                            position: "relative",
                            top: "-5px",
                        }}
                    >
                        <IconButton color="primary" onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <IoPersonCircleOutline fontSize="24" />
                        </IconButton>
                        <Menu
                            sx={{ mt: "45px" }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem
                                onClick={() => {
                                    logout({
                                        returnTo: window.location.origin,
                                    });
                                }}
                            >
                                <ListItemIcon>
                                    <Logout fontSize="small" />
                                </ListItemIcon>
                                <Typography textAlign="center">Logout</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Header;
