import { InputBase, Select, SelectProps } from "@mui/material";
import { styled } from "@mui/material/styles";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
/* eslint @typescript-eslint/no-unused-vars: ["off"] */
interface IIotVisionSelectFiled {
    additionalProps?: any;
}
const CustomizedInputBase = styled(InputBase)(({ theme }) => ({
    "& .MuiSelect-icon": {
        right: "8px",
    },
    "& .MuiInputBase-input": {
        borderRadius: "20px",
        border: "1px solid ",
        fontSize: "14px",
        paddingLeft: "10px",
        borderColor: "rgb(204,204,204)",
        "&:focus": {
            borderRadius: "20px",
            border: "2px solid ",
            borderColor: `${theme.palette.primary.main}`,
        },
    },
}));
type Props<T> = IIotVisionSelectFiled & JSX.IntrinsicAttributes & SelectProps<T>;
const IotVisionSelectFiled = <T,>({ additionalProps, ...rest }: Props<T>) => {
    return (
        <Select
            MenuProps={{
                sx: {
                    "& .MuiMenuItem-root": {
                        fontSize: "14px",
                    },
                },
            }}
            {...rest}
            input={<CustomizedInputBase />}
        />
    );
};

export default IotVisionSelectFiled;
