import React, { FC } from "react";
import { BiSearch } from "react-icons/bi";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
interface SearchInputFieldProps {
    onSearch: any;
}

const SearchInputField: FC<SearchInputFieldProps> = ({ onSearch }) => {
    return (
        <div className="search-input-wrapper">
            <BiSearch className="search-icon" />
            <input
                type="text"
                placeholder="Search"
                className="search-input"
                onChange={(e) => {
                    onSearch(e);
                }}
            />
        </div>
    );
};

export default SearchInputField;
