import { RequestOptions } from "axios-types";
import { ApiEndpoints } from "configs";
import {
    IDevice,
    IGetAllDeviceQuery,
    IGetAllDeviceResponse,
    IGetVideoLinkParams,
} from "types/device";
import { IVideoRequestBody } from "types/video-requests";
import ExecuteAsync from "utils/api-client";
import createQueryParams from "utils/create-query-params";

export const executeCreateVideoRequest = (data: IVideoRequestBody): Promise<unknown> =>
    ExecuteAsync<IVideoRequestBody, unknown>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "POST",
        url: "/incidents/v2/incident/create-manual-incident",
        data,
    });

export const executeGetAllDevicesRequest = async (
    query: IGetAllDeviceQuery,
    options?: RequestOptions,
): Promise<IDevice[]> => {
    const response = await ExecuteAsync<undefined, IGetAllDeviceResponse>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/location/device${createQueryParams({ ...query })}`,
        signal: options?.signal,
    });
    if (!response || !response.data || !Array.isArray(response.data)) return [];
    return response.data;
};

export const executeGetVideoLinkRequest = async (
    params: IGetVideoLinkParams,
    options?: RequestOptions,
): Promise<string | null> => {
    const { serialId, queryParams = {} } = params;
    const response = await ExecuteAsync<undefined, string>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `location/v2/device/${serialId}/video${createQueryParams({ ...queryParams })}`,
        signal: options?.signal,
    });
    return response || null;
};
