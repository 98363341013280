/* eslint-disable import/prefer-default-export */
import videojs from "video.js";
import Component from "video.js/dist/types/component";
import Player from "video.js/dist/types/player";

interface CustomComponent extends Component {
    open: () => void;
    close: () => void;
    toggle: () => void;
}

type CustomVideoJS = {VERSION: string} & typeof videojs;

const Button = videojs.getComponent("Button");

export class ZoomButton extends Button {
    isOpen: boolean;

    constructor(player: Player, options: {
        children?: unknown[];
        className?: string;
    }) {
        super(player, options);
        this.isOpen = false;
        player.on("useractive", () => {
            if (!this.isOpen) return;
            const modal = this.player().getChild("ZoomModal") as CustomComponent;
            modal?.open();
        });
        player.on("userinactive", () => {
            if (!this.isOpen) return;
            const modal = this.player().getChild("ZoomModal") as CustomComponent;
            modal.close();
        });
    }

    buildCSSClass() {
        const [major] = (videojs as CustomVideoJS).VERSION.split(".");
        return `vjs-zoom-duck vjs-zoom-duck-${major} ${super.buildCSSClass()}`;
    }

    handleClick() {
        const modal = this.player().getChild("ZoomModal") as CustomComponent;
        this.isOpen = !this.isOpen;
        modal.toggle();
    }
}
