import { CSSObject, Drawer, styled, Theme } from "@mui/material";

const openedMixin = (theme: Theme): CSSObject => ({
    width: "317px",
    marginTop: "56px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "37px",
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: "47px",
    marginTop: "56px",
    paddingLeft: "10px",
    paddingRight: "10px",
    background: "#FFFFFF",
    [theme.breakpoints.up("sm")]: {
        width: "47px",
    },
});

const AppDrawer = styled(Drawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: "317px",
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

export default AppDrawer;
