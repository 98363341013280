import React, { useEffect, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import PaperWrapper from "components/wrappers/PaperWrapper";
import { CgArrowsExpandRight } from "react-icons/cg";
import FullScreenDialog from "components/dialogs/FullScreenDialog";
import { useSelector } from "react-redux";
import LineChart from "components/charts/LineChart";
import { MINUTES_15_IN_MILLISECOND } from "constants/time.constants";
import { capitalCase } from "capital-case";
import { IncidentOvertimeData } from "types";
import { fillEmptyPointsInGraphWithZeros } from "utils/chart";
import { ScaleUnit } from "enums/scale-unit.enum";
import { XYData, processChartData } from "./SynchronizedLineChart";

type IncidentsOverTimeLineChartProps = {
    data: IncidentOvertimeData[];
    title: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon?: any;
};

const INCIDENT_OVERTIME_SCALE_VALUE = 15;

export default function IncidentsOverTimeLineChart({ data, title, icon }: IncidentsOverTimeLineChartProps) {
    const [processedData, setProcessedData] = useState<processChartData[]>([]);
    const [modalShow, setModalShow] = React.useState(false);
    const [roundedStartTime, setRoundedStartTime] = React.useState("");
    const { startTime, endTime, timeType } = useSelector((state) => state.time);

    useEffect(() => {
        const epoch = new Date(startTime).getTime();
        const start = new Date(epoch - (epoch % MINUTES_15_IN_MILLISECOND));
        setRoundedStartTime(start.toISOString());
    }, [startTime]);

    useEffect(() => {
        processData(data);
    }, [data]);

    function processData(data: IncidentOvertimeData[]): void {
        const uniqueSiteIds = [...Array.from(new Set(data.map((item: IncidentOvertimeData) => item.siteId)))];
        const dataset = uniqueSiteIds.map((siteId) => {
            const siteData = data.filter((item) => item.siteId === siteId);
            const graphData: XYData[] = siteData.map(({ timestamp, value }) => ({ x: new Date(timestamp), y: value }));
            const filledData = fillEmptyPointsInGraphWithZeros(graphData, {
                startDate: startTime,
                endDate: endTime,
                scaleUnit: ScaleUnit.MINUTE,
                scaleValue: INCIDENT_OVERTIME_SCALE_VALUE,
            });
            return {
                label: capitalCase(siteId as string),
                data: filledData,
                // eslint-disable-next-line no-bitwise
                borderColor: `#${(((1 << 24) * Math.random()) | 0).toString(16)}`,
                tension: 0.1,
            };
        });
        setProcessedData(dataset);
    }

    const handleClick = (status: boolean): void => {
        setModalShow(status);
    };

    return (
        <>
            <PaperWrapper>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={1} gap={1} marginBottom={1}>
                        <Grid item className="chart-padding-0">
                            {icon}
                        </Grid>
                        <Grid item className="chart-padding-0">
                            <span className="chartHearder">{title}</span>
                        </Grid>
                        <Grid item className="chart-padding-0">
                            <IconButton className="expand-btn" onClick={() => handleClick(true)} disableRipple>
                                <CgArrowsExpandRight />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <LineChart
                        data={processedData}
                        fromTime={roundedStartTime}
                        toTime={endTime}
                        timeUnit={timeType}
                        roundTimeDisabled
                    />
                </Grid>
            </PaperWrapper>
            <FullScreenDialog
                modalShow={modalShow}
                onClose={handleClick}
                childComponent={
                    <div>
                        <LineChart
                            data={processedData}
                            fromTime={roundedStartTime}
                            toTime={endTime}
                            timeUnit={timeType}
                            roundTimeDisabled
                        />
                    </div>
                }
            />
        </>
    );
}
