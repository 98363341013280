import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { AQDemoAppCode, AQDemoThemeName, DefaultThemeName, DEPTAQDemoAppCode } from "constants/app.constants";
import { getThemeByName } from "./base";

const CustomThemeProvider: React.FC = (props) => {
    const { appCode } = useSelector((state) => state.auth);
    const [selectedTheme, setSelectedTheme] = useState(DefaultThemeName);
    useEffect(() => {
        if (appCode === AQDemoAppCode) {
            setSelectedTheme(AQDemoThemeName);
        }
        if (appCode === DEPTAQDemoAppCode) {
            setSelectedTheme(AQDemoThemeName);
        }
    }, [appCode]);
    const { children } = props;
    return <ThemeProvider theme={getThemeByName(selectedTheme)}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
