import { Location } from "reducers/newLocation.reducer";

export const searchTree = (element: Location, id: string): Location | undefined => {
    if (element.id === id) {
        return element;
    }
    if (element.children && element.children.length > 0) {
        let i;
        let result;
        for (i = 0; !result && i < element.children.length; i++) {
            result = searchTree(element.children[i], id);
        }
        return result;
    }
};

export const findNoOfChildrenInTree = (location?: Location, locations?: Location[]): number => {
    let count = 0;
    locations?.forEach((element) => {
        if (element.parentId === location?.id) {
            count = 1 + findNoOfChildrenInTree(element, locations);
        }
    });
    return count;
};

export const findNoOfChildren = (location?: Location, locations?: Location[]): number => {
    return locations?.filter((element) => location?.id === element.parentId)?.length ?? 0;
};

export const findLocation = (tree: Location[], id: string): Location | undefined => {
    let foundLocation;
    tree.forEach((loc) => {
        const location = searchTree(loc, id);
        if (location) foundLocation = location;
    });

    return foundLocation;
};
