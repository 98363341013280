import { IconButton } from "@mui/material";
import { styled } from "@mui/styles";
import { executeGetSnapshotRequest } from "api/common/detections.api";
import StatusCodes from "enums/status-code.enums";
import React, { useEffect, useRef, useState } from "react";
import { GrClose } from "react-icons/gr";
import { useNavigate, useSearchParams } from "react-router-dom";

const Img = styled("img")(() => ({
    height: "100%",
    width: "100%",
}));

const ImagePreview = () => {
    const [searchParams] = useSearchParams();
    const [src, setSrc] = useState<string>();
    const snapshotAbortControllerRef = useRef<AbortController | null>(null);
    const { objectId, incidentId } = Object.fromEntries([...searchParams]) || {};
    const navigate = useNavigate();

    async function getSnapshot() {
        try {
            snapshotAbortControllerRef.current = new AbortController();
            const { signal } = snapshotAbortControllerRef.current;
            const response = await executeGetSnapshotRequest(
                {
                    incidentId,
                },
                { signal, disableNotification: true },
            );

            if (response !== undefined) {
                setSrc(response);
            }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: Error | any) {
            if (err?.response?.status === StatusCodes.FORBIDDEN) {
                navigate("/forbidden", { replace: true });
                return;
            }
            navigate("/page-not-found", { replace: true });
            console.error(err);
        }
    }
    const closeTab = () => {
        window.close();
    };

    useEffect(() => {
        getSnapshot();
    }, [objectId]);

    return (
        <div>
            <IconButton
                edge="start"
                color="inherit"
                onClick={closeTab}
                sx={{
                    position: "absolute",
                    width: "38px",
                    height: "38px",
                    right: "15px",
                    top: "10px",
                    backgroundColor: "rgb(150, 149, 149)",
                }}
            >
                <GrClose fontSize={40} fontWeight={600} />
            </IconButton>
            <Img src={src} />
        </div>
    );
};

export default ImagePreview;
