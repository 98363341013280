import React from "react";
import { Box, Container, CssBaseline } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useAppDispatch } from "store";
import { toggleSideBar } from "reducers/system.reducer";
import { useSelector } from "react-redux";
import { useShouldRenderPageGuard } from "hooks/useShouldRenderPageGuard";
import { Applications } from "configs";
import LoadingLayout from "layouts/common/loader/LoadingLayout.tsx";
import SideBar from "./side-bar/SideBar";
import Navbar from "./header/Navbar";

const { AppIdAirQuality } = Applications;
export default function Layout() {
    const dispatch = useAppDispatch();
    const { isDrawerOpen } = useSelector((state) => state.system);
    const { shouldRender } = useShouldRenderPageGuard(AppIdAirQuality);

    return shouldRender ? (
        <Container maxWidth="xl" disableGutters>
            <CssBaseline />
            <Navbar />
            <SideBar
                isSideBarOpen={isDrawerOpen}
                onCollapse={() => {
                    dispatch(toggleSideBar());
                }}
                onExpand={() => {
                    dispatch(toggleSideBar());
                }}
            />
            <Box
                sx={{
                    bgcolor: "#F5F5FB",
                    paddingLeft: "19px",
                    paddingRight: "19px",
                    paddingTop: "82px",
                    marginLeft: isDrawerOpen ? "317px" : "47px",
                }}
            >
                <Outlet />
            </Box>
        </Container>
    ) : (
        <LoadingLayout />
    );
}

Layout.propTypes = {};
