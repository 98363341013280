import { Grid, MenuItem, SelectChangeEvent } from "@mui/material";
import PaperWrapper from "components/wrappers/PaperWrapper";
import React, { useState } from "react";
import { useParams } from "react-router";
import IotVisionSelectFiled from "components/common/IotVisionSelectField";
import IotVisionButton from "components/common/IotVisionButton";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import MultiDatePicker from "../date-picker/MultiDatePicker";
import HistoricalTrendLineChart from "./HistoricalTrendLineChart";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
enum ChartScaleUnit {
    Hour = "hour",
    Day = "day",
    Week = "week",
}
enum TypeOptionsValues {
    Days = "Days",
    Weeks = "Weeks",
    Months = "Months",
}
interface IHistoricalTrendFilterProps {}
const typeOptions = [TypeOptionsValues.Days, TypeOptionsValues.Weeks, TypeOptionsValues.Months];
export interface IStartEndDate {
    from: string;
    to: string;
    title: string;
}

const scaleUnitObject = {
    [TypeOptionsValues?.Days]: ChartScaleUnit.Hour,
    [TypeOptionsValues?.Weeks]: ChartScaleUnit.Day,
    [TypeOptionsValues.Months]: ChartScaleUnit.Day,
};

type ScaleUnitObject = keyof typeof scaleUnitObject;

const getScaleUnit = (type: ScaleUnitObject) => {
    return scaleUnitObject[type] || ChartScaleUnit.Hour;
};

const HistoricalTrendFilter: React.FC<IHistoricalTrendFilterProps> = () => {
    const [meanOptions, setMeanOptions] = useState<string[]>(["All"]);
    const [appliedDateArray, setAppliedDateArray] = useState<IStartEndDate[]>([]);

    interface HistoricalFormFiled {
        mean: string;
        type: string;
        dayValueArray: IStartEndDate[];
    }
    const { locationId } = useParams();

    const {
        handleSubmit,
        setValue,
        control,
        getValues,
        reset,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        formState: { isValid },
    } = useForm<HistoricalFormFiled>({
        defaultValues: {
            mean: "",
            type: "Days",
            dayValueArray: [],
        },
    });

    const handleCancel = () => {
        reset({
            mean: "",
            type: "Days",
            dayValueArray: [],
        });
        setAppliedDateArray([]);
    };

    const onOptionsUpdate = (options: string[]): void => {
        setMeanOptions([...options]);
    };

    const onSubmit: SubmitHandler<HistoricalFormFiled> = (data) => {
        const submitFormData: HistoricalFormFiled = {
            mean: data?.mean ? data?.mean : "",
            type: data?.type ? data?.type : "",
            dayValueArray: data?.dayValueArray ? data.dayValueArray : [],
        };
        setAppliedDateArray(submitFormData?.dayValueArray);
    };
    return (
        <PaperWrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <span className="chartHearder">Historical Trends</span>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                <Controller
                                    name="mean"
                                    control={control}
                                    render={({ field: { value } }) => (
                                        <IotVisionSelectFiled
                                            fullWidth
                                            displayEmpty
                                            renderValue={(selected: any) => {
                                                if (selected.length === 0) {
                                                    return "Mean";
                                                }
                                                return selected;
                                            }}
                                            value={value}
                                            onChange={(event: SelectChangeEvent<string>) => {
                                                setValue("mean", event?.target?.value);
                                            }}
                                            sx={{
                                                "& .MuiInputBase-input": {
                                                    color: "rgb(204,204,204)",
                                                },
                                            }}
                                        >
                                            {meanOptions?.map((option: any, key: any) => {
                                                return (
                                                    <MenuItem key={key} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                );
                                            })}
                                        </IotVisionSelectFiled>
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                <Controller
                                    name="type"
                                    control={control}
                                    render={({ field: { value } }) => (
                                        <IotVisionSelectFiled
                                            fullWidth
                                            displayEmpty
                                            renderValue={(selected: any) => {
                                                if (selected.length === 0) {
                                                    return "Type";
                                                }
                                                return selected;
                                            }}
                                            value={value}
                                            onChange={(event: SelectChangeEvent<string>) => {
                                                setValue("type", event?.target?.value);
                                                setValue("dayValueArray", []);
                                            }}
                                            sx={{
                                                "& .MuiInputBase-input": {
                                                    color: "rgb(204,204,204)",
                                                },
                                            }}
                                        >
                                            {typeOptions?.map((option: any) => {
                                                return (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                );
                                            })}
                                        </IotVisionSelectFiled>
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                <Controller
                                    name="dayValueArray"
                                    control={control}
                                    render={({ field: { value } }) => (
                                        <MultiDatePicker
                                            dayValueArray={value}
                                            onChangeDay={(dayArr: IStartEndDate[]) => {
                                                setValue("dayValueArray", dayArr);
                                            }}
                                            typeValue={getValues("type")}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <IotVisionButton
                                            type="submit"
                                            sx={{
                                                height: "34px",
                                            }}
                                            fullWidth
                                        >
                                            Apply
                                        </IotVisionButton>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <IotVisionButton
                                            fullWidth
                                            sx={{
                                                height: "34px",
                                                backgroundColor: "white",
                                                color: "primary.main",
                                                "&:hover": {
                                                    background: "white",
                                                },
                                            }}
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </IotVisionButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {appliedDateArray &&
                        appliedDateArray?.map((d: IStartEndDate, i: number) => {
                            return (
                                <Grid item xs={12}>
                                    <HistoricalTrendLineChart
                                        key={i}
                                        locationId={locationId}
                                        fromTime={d.from}
                                        toTime={d.to}
                                        scaleUnit={getScaleUnit(getValues("type") as ScaleUnitObject)}
                                        classification={getValues("mean")}
                                        {...(getValues("type") === TypeOptionsValues.Months ? { period: "month" } : {})}
                                        onOptionsUpdate={onOptionsUpdate}
                                        title={d.title}
                                    />
                                </Grid>
                            );
                        })}
                </Grid>
            </form>
        </PaperWrapper>
    );
};

export default HistoricalTrendFilter;
