/**
 * Conditions to be checked
 * EQ - Equals
 * GT - Greater than or Equals
 * LT - Less than or Equals
 * GT_EQ - Greater than or Equals
 * LT_EQ - Less than or Equals
 */
export enum ConditionType {
    EQ,
    GT,
    LT,
    GT_EQ,
    LT_EQ,
}

/**
 * The place where value should be placed
 */
export enum Position {
    LEFT,
    RIGHT,
    TOP,
    BOTTOM,
}

/**
 * Threshold data set type for find the data
 * Tenant can have multiple threshold config data sets according to the requirement
 *
 * AQ_SENSOR_THRESHOLD_CONFIGS - Dataset for generating data for AQ sensor related activities
 */
export enum ThresHoldConfigDataSetType {
    AQ_SENSOR_THRESHOLD_CONFIGS,
}
