import { RequestOptions } from "axios-types";
import ExecuteAsync from "utils/api-client";
import { ApiEndpoints } from "configs";
import { createQueryParams } from "utils/create-query-params";
import { ExtraParams, VehiclesOvertime } from "pages/app-ungated-parking/location/LocationChartLayout";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
export function executeGetDetectionsOvertimeByDirectionRequest(
    locationId: string,
    data?: VehiclesOvertime | ExtraParams,
    options?: RequestOptions,
) {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/reporting/detections/overtime/by_direction/${locationId}${createQueryParams(data)}`,
        signal: options?.signal,
        disableNotification: options?.disableNotification,
    });
}
