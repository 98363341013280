import { styled, TextField, TextFieldProps } from "@mui/material";

const StyledTextField = styled((props: TextFieldProps) => <TextField {...props} />)(() => ({
    "& .MuiOutlinedInput-root": {
        borderRadius: "20px",
        height: "25px",
        width: "224px",
        fontSize: "12px",
        fontWeight: 400,
        color: "#A3A3A3",
    },
}));

export default StyledTextField;
