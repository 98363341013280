/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import { FaParking } from "react-icons/fa";
import {
    executeGetRealTimeDetectionsRequest,
    executePostRealTimeDetectionsRequest,
} from "api/app-park-and-ride/pr-realtime-api";
import { useSelector } from "react-redux";
import { CLASS_TYPE_VEHICLE } from "constants/classType.constants";
import { useParams } from "react-router-dom";
import { checkLocationIdChanged, FetchFunctionParams, makeApiCallWithUpdateTime } from "utils/common";
import { useAppDispatch } from "store";
import { Location } from "reducers/newLocation.reducer";
import { BsBoxArrowInRight, BsBoxArrowRight } from "react-icons/bs";
import { executeGetDetectionsRequest } from "api/common/detections.api";
import { Directions } from "types/chart-configs/enums/common";
import { CLASS_CAR, CLASS_SCOOTER, CLASS_VEHICLE } from "constants/class.constants";
import { styled } from "@mui/styles";
import { RealTimeCountUpdate } from "types/realtime/common";
import CountUpdaterDialog from "../count-updater/CountUpdaterDialog";
import CountUpdatingCard from "../count-updater/CountUpdatingCard";
import StatsCard from "../../../components/cards/StatsCard";

const StatsCardWrapper = styled(StatsCard)(() => ({}));
interface StatisticsCardsProps {
    group?: Location;
}

const CustomizedGrid = styled(Grid)(() => ({
    gridGap: "0% !important",
    gap: "0% !important",
}));

export interface IDetections {
    locationId?: string;
    fromTime?: string;
    toTime?: string;
    classification?: string | string[];
    level?: number;
    field1?: string;
    includeChildLocations?: boolean;
}

export interface IDetectionsUpdate {
    locationId?: string;
    type?: string;
    classification?: string;
    value?: number;
}

const StatisticsCards: React.FC<StatisticsCardsProps> = ({ group }) => {
    const { locationId } = useParams();
    const { startTime, endTime, selectedRange } = useSelector((state) => state.time);
    const [enteredVehicleCount, setEnteredVehicleCount] = useState<number>(0);
    const [realtimeVehiclesCount, setRealtimeVehiclesCount] = useState<number>(0);
    const [exitVehiclesCount, setExitVehiclesCount] = useState<number>(0);
    const [isEnteredVehicleCountLoading, setIsEnteredVehicleCountLoading] = useState<boolean>(false);
    const [isRealtimeVehiclesCountLoading, setIsRealtimeVehiclesCountLoading] = useState<boolean>(false);
    const [isExitVehiclesCountLoading, setIsExitVehiclesCountLoading] = useState<boolean>(false);
    const [isRealtimeCountUpdate, setIsRealtimeCountUpdate] = useState<boolean>(false);
    const isAllLocations = () => locationId === undefined;
    const enteredVehiclesAbortControllerRef = useRef<AbortController | null>(null);
    const realtimeVehiclesAbortControllerRef = useRef<AbortController | null>(null);
    const exitVehiclesAbortControllerRef = useRef<AbortController | null>(null);
    const { locations } = useSelector((state) => state.newLocation);
    const dispatch = useAppDispatch();

    const fetchData = async ({ startTime, endTime }: FetchFunctionParams) => {
        let level = 0;
        if (isAllLocations()) {
            level = -1;
        } else if (locationId !== undefined) {
            level = 3;
        } else if (group?.type === "region") {
            level = 1;
        } else if (group?.type === "zone") {
            level = 2;
        }

        await Promise.all([
            getRealtimeVehiclesDetections({
                locationId,
                classification: CLASS_TYPE_VEHICLE,
                includeChildLocations: !isLeafLocation(),
            }),
            getEntranceVehiclesDetections({
                locationId,
                fromTime: startTime,
                toTime: endTime,
                classification: [CLASS_VEHICLE, CLASS_CAR, CLASS_SCOOTER],
                field1: Directions.IN,
                level,
            }),
            getExitVehiclesDetections({
                locationId,
                fromTime: startTime,
                toTime: endTime,
                classification: [CLASS_VEHICLE, CLASS_CAR, CLASS_SCOOTER],
                field1: Directions.OUT,
                level,
            }),
        ]);
    };
    async function updateRealtimeVehiclesDetections(data?: IDetectionsUpdate): Promise<void> {
        try {
            enteredVehiclesAbortControllerRef.current = new AbortController();
            const { signal } = enteredVehiclesAbortControllerRef.current;
            setIsRealtimeVehiclesCountLoading(true);
            const response = await executePostRealTimeDetectionsRequest(data, {
                signal,
                disableNotification: checkLocationIdChanged(locationId, locations),
            });
            if (response) {
                await getRealtimeVehiclesDetections({
                    locationId,
                    fromTime: startTime,
                    toTime: endTime,
                    classification: CLASS_TYPE_VEHICLE,
                });
                setIsRealtimeCountUpdate(false);
            }
        } catch (error: any) {
            console.error(error);
        } finally {
            setIsRealtimeVehiclesCountLoading(false);
        }
    }

    async function getRealtimeVehiclesDetections(data?: IDetections): Promise<void> {
        try {
            realtimeVehiclesAbortControllerRef.current = new AbortController();
            const { signal } = realtimeVehiclesAbortControllerRef.current;
            setIsRealtimeVehiclesCountLoading(true);
            const response = await executeGetRealTimeDetectionsRequest(data, {
                signal,
                disableNotification: checkLocationIdChanged(locationId, locations),
            });
            if (response) {
                setRealtimeVehiclesCount(response.value);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsRealtimeVehiclesCountLoading(false);
        }
    }

    async function getEntranceVehiclesDetections(data?: IDetections): Promise<void> {
        try {
            enteredVehiclesAbortControllerRef.current = new AbortController();
            const { signal } = enteredVehiclesAbortControllerRef.current;
            setIsEnteredVehicleCountLoading(true);
            const response = await executeGetDetectionsRequest(data, {
                signal,
                disableNotification: checkLocationIdChanged(locationId, locations),
            });
            if (response && Array.isArray(response)) {
                let count = 0;
                // eslint-disable-next-line no-return-assign
                response.forEach((itm) => (count = +itm.count + count));
                setEnteredVehicleCount(count);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsEnteredVehicleCountLoading(false);
        }
    }

    async function getExitVehiclesDetections(data?: IDetections): Promise<void> {
        try {
            exitVehiclesAbortControllerRef.current = new AbortController();
            const { signal } = exitVehiclesAbortControllerRef.current;
            setIsExitVehiclesCountLoading(true);
            const response = await executeGetDetectionsRequest(data, {
                signal,
                disableNotification: checkLocationIdChanged(locationId, locations),
            });
            if (response && Array.isArray(response)) {
                let count = 0;
                // eslint-disable-next-line no-return-assign
                response.forEach((itm) => (count = +itm.count + count));
                setExitVehiclesCount(count);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsExitVehiclesCountLoading(false);
        }
    }

    useEffect(() => {
        makeApiCallWithUpdateTime(selectedRange, { startTime, endTime }, fetchData, dispatch);

        return () => {
            enteredVehiclesAbortControllerRef.current?.abort();
            realtimeVehiclesAbortControllerRef.current?.abort();
            exitVehiclesAbortControllerRef.current?.abort();
        };
    }, [locationId, startTime, endTime]);

    const handleEditModalShow = (status: boolean) => {
        setIsRealtimeCountUpdate(status);
    };

    const handleUpdateCount = (value: number) => {
        updateRealtimeVehiclesDetections({
            locationId,
            value,
            type: RealTimeCountUpdate.REPLACE,
            classification: CLASS_TYPE_VEHICLE,
        });
    };

    const isLeafLocation = () => {
        const loc = locations.find((loc) => loc.id === locationId);
        if (loc) {
            return loc.type === "location";
        }
        return false;
    };

    return (
        <CustomizedGrid className="stats-container m-0 p-0">
            <div className="stat-card-wrapper-width">
                <StatsCardWrapper
                    isLoading={isEnteredVehicleCountLoading}
                    title={
                        isLeafLocation()
                            ? "Total number of vehicles entered the site"
                            : "Total number of vehicles entered"
                    }
                    count={enteredVehicleCount}
                    icon={<BsBoxArrowInRight fontSize="85px" strokeWidth="0.5px" />}
                    wrapperStyles={`stat-card stat-card-flex-col ${
                        isLeafLocation() ? "stat-card-width-40" : "stat-card-width-50"
                    }`}
                    iconStyles="Icon-wrapper icon-background-none"
                />
                <StatsCardWrapper
                    isLoading={isExitVehiclesCountLoading}
                    title="Total number of vehicles left the site"
                    count={exitVehiclesCount}
                    icon={<BsBoxArrowRight fontSize="80px" strokeWidth="0.5px" />}
                    wrapperStyles={`stat-card stat-card-flex-col ${
                        isLeafLocation() ? "stat-card-width-40" : "stat-card-width-50"
                    }`}
                    iconStyles="Icon-wrapper icon-background-none"
                />
            </div>
            <CountUpdatingCard
                isLoading={isRealtimeVehiclesCountLoading}
                title={isLeafLocation() ? "Car park occupancy" : "Number of vehicles in car parks"}
                count={realtimeVehiclesCount}
                icon={<FaParking fontSize="80px" fontWeight={700} />}
                onUpdate={() => handleEditModalShow(true)}
                isCountEditingActive={isLeafLocation()}
            />
            <CountUpdaterDialog
                modalShow={isRealtimeCountUpdate}
                count={realtimeVehiclesCount}
                onClose={() => handleEditModalShow(false)}
                onUpdate={handleUpdateCount}
                isLoading={isRealtimeVehiclesCountLoading}
            />
        </CustomizedGrid>
    );
};

export default StatisticsCards;
