import {
    HOUR_00_WITH_MINUTES,
    HOUR_24,
    HOUR_24_WITH_MINUTES,
    MILLISECONDS_TO_HOUR,
    MILLISECONDS_TO_MINUTE,
    MINUTES_TO_HOUR,
} from "constants/time.constants";

const PAD_FILLING_STRING = "0";

export const formatHours = (time: number, isConsiderFormat = true): string => {
    if (time === HOUR_24) {
        return isConsiderFormat ? HOUR_24_WITH_MINUTES : HOUR_00_WITH_MINUTES;
    }
    const durationInMilliseconds = time * MILLISECONDS_TO_HOUR;
    const durationInMinutes = durationInMilliseconds / MILLISECONDS_TO_MINUTE;
    const durationInHours = durationInMinutes / MINUTES_TO_HOUR;
    const result = `${Math.floor(durationInHours).toString().padStart(2, PAD_FILLING_STRING)}.${Math.round(
        durationInMinutes % MINUTES_TO_HOUR,
    )
        .toString()
        .padStart(2, PAD_FILLING_STRING)}`;
    return result;
};

export default formatHours;
