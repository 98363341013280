import { Card, CardContent, Grid } from "@mui/material";
import { LoadingWrapper } from "components/wrappers/LoadingWrapper";
import React from "react";
import * as FontAwesome from "react-icons/fa";
import { ReportDataTypes } from "types/reports/enums";


interface IReportCardProps {
    icon: string;
    value: number;
    text: string;
    dataType?: ReportDataTypes;
    relatedValues?: ObjectLiteral[];
}

/* eslint-disable @typescript-eslint/no-explicit-any */
interface ObjectLiteral {
    [key: string]: any;
}


/* eslint-disable @typescript-eslint/no-loop-func */
const formatDataValue = (value: number, dataType?: ReportDataTypes, relatedValues? : ObjectLiteral[]) => {
    if (!dataType) {
        return value;
    }
    if (dataType === ReportDataTypes.PERCENTAGE) {
        if(relatedValues && relatedValues.length > 0){
            let containsZerosFlag = true
            for(let index = 0; index  < relatedValues.length; index++) {
                const val = relatedValues[index]
                const keysArray = Object.keys(val)
                for(let i = 0; i < keysArray.length; i++) {
                    const key = keysArray[i]
                    if(val[key] !== 0){
                        containsZerosFlag = false
                    }
                }
            }
            if(containsZerosFlag){
                return "N/A";
            }     
        } 

        if (value % 1 === 0) {
            return `${value}%`;
        }

        return `${value.toFixed(2)}%`;
    }
};

export const ReportCard = (reportcardProps: IReportCardProps) => {
    const { value, icon, text, dataType, relatedValues } = reportcardProps;
    return (
        <>
            <Grid item xs={6} sm={6} md={3} lg={3}>
                <Card
                    sx={{
                        borderRadius: "15px",
                        height: {
                            lg: "200px",
                            md: "230px",
                            sm: "230px",
                            xs: "230px",
                        },
                        backgroundColor: !Number.isNaN(value) && value !== undefined ? "#FFFFFF" : "#F5F5F5",
                    }}
                    elevation={0}
                >
                    <CardContent>
                        <LoadingWrapper style={{ marginTop: "4rem" }} isLoading={false}>
                            <div className="card-icon">
                                {React.createElement((FontAwesome as ObjectLiteral)[icon], {
                                    size: "35",
                                })}
                            </div>
                            {!Number.isNaN(value) && value !== undefined ? (
                                <>
                                    <div className="card-value">{formatDataValue(value, dataType, relatedValues)}</div>
                                    <div className="card-text">{text}</div>
                                </>
                            ) : (
                                <>
                                    <div className="card-text">{text}</div>
                                    <div className="card-data-unavailable">Data Unavailable</div>
                                </>
                            )}
                        </LoadingWrapper>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
};
