export enum Classification {
    DID = "did",
    PCO1 = "pc01",
    PC03 = "pc03",
    PC05 = "pc05",
    PC10 = "pc10",
    PC25 = "pc25",
    PC50 = "pc50",
    PC100 = "pc100",
    PM01 = "pm01",
    PM03 = "pm03",
    PM05 = "pm05",
    PM10 = "pm10",
    PM25 = "pm25",
    PM50 = "pm50",
    PM100 = "pm100",
    TEMP = "temp",
    RH = "rh",
    ATM = "atm",
    VOCAQI = "vocaqi",
    VOCPPM = "vocppm",
    GASEST = "gasest",
    CO2 = "co2",
    AQI = "aqi",
    PM10AQI = "pm10aqi",
    PM25AQI = "pm25aqi",
    PM100AQI = "pm100aqi",
}

export enum AirQualityStatus {
    GOOD = "Good",
    MODERATE = "Satisfactory",
    UNHEALTHY_FOR_SENSITIVE_GROUPS = "Moderate",
    UNHEALTHY = "Poor",
    VERY_UNHEALTHY = "Very Poor",
    HARZARDOUS = "Severe",
}
