import React from "react";

interface TableHeadingProps {
    heading: string;
}

const TableHeading: React.FC<TableHeadingProps> = ({ heading }) => {
    return (
        <div className="section-heading">
            <span className="label">{heading}</span>
        </div>
    );
};

export default TableHeading;
