import { useEffect, useState } from "react";
import SectionHeading from "components/headings/SectionHeading";
import { AiFillAppstore } from "react-icons/ai";
import StatisticsZoneLocationCards from "features/app-taxi-ranking/statistics/StatisticsZoneLocationCards";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import { AppTitlePrefix } from "constants/app.constants";
import LocationDetailCard from "components/cards/LocationDetailCard";
import { useNavigate } from "react-router";
import HeaderDatePicker from "features/common/date-header/HeaderDatePicker";
import { findLocation } from "utils/search-tree";
import TaxiChartLayout from "./ChartLayout";
import { Location } from "../../../reducers/newLocation.reducer";
import NswLogo from "../../../assets/images/nsw-logo.png";

const HomeLocationPage = (): JSX.Element => {
    const { locationId } = useParams();
    const { locationsTree } = useSelector((state) => state.newLocation);
    const [selectedLocation, setSelectedLocation] = useState<Location>();
    const [selectedZone, setSelectedZone] = useState<Location>();
    const navigate = useNavigate();
    const { appCode } = useSelector((state) => state.auth);

    useEffect(() => {
        if (locationId) {
            const location = findLocation(locationsTree, locationId);
            if (!location) navigate(`/${appCode}/page-not-found`, { replace: true });
            if (location) setSelectedLocation(location);
        }
    }, [locationId, locationsTree]);

    useEffect(() => {
        let parent!: Location | undefined;
        if (selectedLocation) {
            const { parentId } = selectedLocation;
            parent = findLocation(locationsTree, parentId);
            if (parent) {
                setSelectedZone(parent);
            } else {
                navigate(`/${appCode}/page-not-found`, { replace: true });
            }
        }
    }, [selectedLocation]);

    return (
        <Grid container spacing={1}>
            <Helmet>
                <title>{AppTitlePrefix} | Location Summary</title>
                <link rel="icon" id="tr-location" type="image/png" href={NswLogo} sizes="32x32" />
            </Helmet>
            <Grid item xs={12}>
                <LocationDetailCard
                    location={selectedLocation?.name || ""}
                    zone={selectedZone?.name}
                    longitude={parseFloat(selectedLocation?.longitude || "0").toFixed(5)}
                    latitude={parseFloat(selectedLocation?.latitude || "0").toFixed(5)}
                    operatingHours={selectedLocation?.openHours}
                    enableLiveFootageView={selectedLocation?.metadata?.canViewMerakiFootage}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={9}>
                <SectionHeading heading="Location Summary" icon={<AiFillAppstore className="icon" />} />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                <div style={{ maxWidth: "300px", marginLeft: "auto" }}>
                    <HeaderDatePicker />
                </div>
            </Grid>
            <Grid item xs={12}>
                <StatisticsZoneLocationCards />
            </Grid>
            <Grid item xs={12}>
                <TaxiChartLayout regionType="" region="" />
            </Grid>
        </Grid>
    );
};

export default HomeLocationPage;
