import { GridColumnHeaderParams, GridColumns, GridRenderCellParams } from "@mui/x-data-grid";
import { TableMultiLinerCell } from "../features/app-air-quality/common";
import { DITTO_MARK, NOT_APPLICABLE_SHORT } from "./common.constants";

// Aq Rules columns
export const RULE_TABLE_COLUMNS: GridColumns = [
    {
        field: "name",
        headerName: "Name",
        align: "left",
        hideSortIcons: true,
        sortable: false,
        minWidth: 175,
        flex: 2,
        disableColumnMenu: true,
    },
    {
        field: "green",
        headerName: "Green (Good)",
        align: "left",
        hideSortIcons: true,
        sortable: false,
        minWidth: 150,
        flex: 2,
        disableColumnMenu: true,
    },
    {
        field: "yellow",
        align: "left",
        hideSortIcons: true,
        sortable: false,
        minWidth: 150,
        flex: 2,
        disableColumnMenu: true,
        renderHeader: (_params: GridColumnHeaderParams) => (
            <TableMultiLinerCell
                itemStyle={{
                    color: "#001B4F",
                    fontSize: "14px",
                    fontWeight: "bold",
                }}
                value={["Yellow", "(Moderate)"]}
            />
        ),
        renderCell: (params: GridRenderCellParams) => <TableMultiLinerCell value={params.value} />,
    },
    {
        field: "orange",
        align: "left",
        hideSortIcons: true,
        sortable: false,
        minWidth: 150,
        flex: 2,
        disableColumnMenu: true,
        renderHeader: (_params: GridColumnHeaderParams) => (
            <TableMultiLinerCell
                itemStyle={{
                    color: "#001B4F",
                    fontSize: "14px",
                    fontWeight: "bold",
                }}
                value={["Orange", "(Unhealthy for", "Sensitive Groups)"]}
            />
        ),
        renderCell: (params: GridRenderCellParams) => <TableMultiLinerCell value={params.value} />,
    },
    {
        field: "red",
        align: "left",
        hideSortIcons: true,
        sortable: false,
        minWidth: 150,
        flex: 2,
        disableColumnMenu: true,
        renderHeader: (_params: GridColumnHeaderParams) => (
            <TableMultiLinerCell
                itemStyle={{
                    color: "#001B4F",
                    fontSize: "14px",
                    fontWeight: "bold",
                }}
                value={["Red", "(Unhealthy)"]}
            />
        ),
        renderCell: (params: GridRenderCellParams) => <TableMultiLinerCell value={params.value} />,
    },
    {
        field: "maroon",
        align: "left",
        hideSortIcons: true,
        sortable: false,
        minWidth: 150,
        flex: 2,
        disableColumnMenu: true,
        renderHeader: (_params: GridColumnHeaderParams) => (
            <TableMultiLinerCell
                itemStyle={{
                    color: "#001B4F",
                    fontSize: "14px",
                    fontWeight: "bold",
                }}
                value={["Maroon", "(Very Unhealthy)"]}
            />
        ),
        renderCell: (params: GridRenderCellParams) => <TableMultiLinerCell value={params.value} />,
    },
    {
        field: "hazardous",
        headerName: "Hazardous",
        align: "left",
        hideSortIcons: true,
        sortable: false,
        minWidth: 150,
        flex: 2.5,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => <TableMultiLinerCell value={params.value} />,
    },
];

// Aq Rules data
export const RULE_TABLE_ROWS = [
    {
        id: "1",
        name: "AQI",
        green: "0 - 50",
        yellow: ["51 - 100"],
        orange: ["101 - 150"],
        red: ["151 - 200"],
        maroon: ["201 - 300"],
        hazardous: ["301 - 500"],
    },
    {
        id: "2",
        name: "PM10",
        // Add ditto mark for the redundant data
        green: [DITTO_MARK],
        yellow: [DITTO_MARK],
        orange: [DITTO_MARK],
        red: [DITTO_MARK],
        maroon: [DITTO_MARK],
        hazardous: [DITTO_MARK],
    },
    {
        id: "3",
        name: "PM2.5",
        green: [DITTO_MARK],
        yellow: [DITTO_MARK],
        orange: [DITTO_MARK],
        red: [DITTO_MARK],
        maroon: [DITTO_MARK],
        hazardous: [DITTO_MARK],
    },
    {
        id: "4",
        name: "PM1",
        green: [DITTO_MARK],
        yellow: [DITTO_MARK],
        orange: [DITTO_MARK],
        red: [DITTO_MARK],
        maroon: [DITTO_MARK],
        hazardous: [DITTO_MARK],
    },
    {
        id: "5",
        name: "VOC",
        green: [DITTO_MARK],
        yellow: [DITTO_MARK],
        orange: [DITTO_MARK],
        red: [DITTO_MARK],
        maroon: [DITTO_MARK],
        hazardous: [DITTO_MARK],
    },
    {
        id: "6",
        name: "Temperature (C)",
        green: "21.5 - 24.5 ",
        yellow: ["19.5 - 21.5 /", "24.5 - 26.5"],
        orange: ["17.5 - 19.5 /", "26.5 - 28.5"],
        red: ["15.5 - 17.5 /", "28.5 - 30.5"],
        maroon: ["13.5 - 15.5 /", "30.5 - 35.5"],
        hazardous: ["0 - 14.5 /", "35.5 - Max Integer"],
    },
    {
        id: "7",
        name: "Humidity (%)",
        green: "39.5 - 60.5",
        yellow: ["34.5 - 39.5 /", "60.5 - 65.5"],
        orange: ["29.5 - 34.5 /", "65.5 - 70.5"],
        red: ["24.5 - 29.5 /", "70.5 - 75.5"],
        maroon: ["19.5 - 24.5 /", "76.5 - 80.5"],
        hazardous: ["0 - 19.5 /", "80.5 - 100"],
    },
    {
        id: "8",
        name: "Air Pressure (hPA)",
        green: "999.5 - 1030.5",
        yellow: ["979.5 - 999.5 /", "1030.5 - 1040.5"],
        orange: ["959.5 - 979.5 /", "1040.5 - 1050.5"],
        red: ["939.5 - 959.5 /", "1050.5 - 1060.5"],
        maroon: ["889.5 - 939.5 /", "1060.5 - 1110.5"],
        hazardous: [NOT_APPLICABLE_SHORT],
    },
    {
        id: "9",
        name: "CO2-Estimate (ppm)",
        green: "0 - 400.5",
        yellow: ["400.5 - 600.5"],
        orange: ["600.5 - 800.5"],
        red: ["800.5 - 1500.5"],
        maroon: ["1500.5 - 5000.5"],
        hazardous: ["5000.5 - 10000.5"],
    },
];

//  Aq Triggers columns
export const TRIGGER_TABLE_COLUMNS: GridColumns = [
    {
        field: "name",
        headerName: "Trigger Name",
        align: "left",
        hideSortIcons: true,
        sortable: false,
        flex: 2,
        minWidth: 150,
        disableColumnMenu: true,
    },
    {
        field: "measure",
        headerName: "Measure",
        align: "left",
        hideSortIcons: true,
        sortable: false,
        flex: 2,
        minWidth: 150,
        disableColumnMenu: true,
    },
    {
        field: "recommended",
        headerName: "Recommended",
        align: "left",
        hideSortIcons: true,
        sortable: false,
        flex: 2,
        minWidth: 150,
        disableColumnMenu: true,
    },
    {
        field: "value",
        headerName: "Trigger Value",
        align: "left",
        hideSortIcons: true,
        sortable: false,
        flex: 2,
        minWidth: 150,
        disableColumnMenu: true,
    },
    {
        field: "time",
        headerName: "Trigger Time",
        align: "left",
        hideSortIcons: true,
        sortable: false,
        flex: 2,
        minWidth: 150,
        disableColumnMenu: true,
    },
    {
        field: "field",
        headerName: "Mapped Field",
        align: "left",
        hideSortIcons: true,
        sortable: false,
        flex: 2,
        minWidth: 150,
        disableColumnMenu: true,
    },
];

// Aq Triggers data
export const TRIGGER_TABLE_ROWS = [
    {
        id: "1",
        name: "Co2 Alert Amber",
        measure: "CO2",
        recommended: "< 800 ppm",
        value: "> 800 ppm",
        time: "15 min",
        field: NOT_APPLICABLE_SHORT,
    },
    {
        id: "2",
        name: "Co2 Alert Red",
        measure: "CO2",
        recommended: "800 ppm",
        value: "> 1500 ppm",
        time: "5 min",
        field: NOT_APPLICABLE_SHORT,
    },
    {
        id: "3",
        name: "rH Amber",
        measure: "Humidity",
        recommended: "40-60%",
        value: "65%",
        time: "30 min",
        field: NOT_APPLICABLE_SHORT,
    },
    {
        id: "4",
        name: "rH Red",
        measure: "Humidity",
        recommended: "40-60%",
        value: "75%",
        time: "30 min",
        field: NOT_APPLICABLE_SHORT,
    },
    {
        id: "5",
        name: "Temp Amber",
        measure: "Temperature",
        recommended: "22-24°C",
        value: "26°C",
        time: "1 hour",
        field: NOT_APPLICABLE_SHORT,
    },
    {
        id: "6",
        name: "Temp Red",
        measure: "Temperature",
        recommended: "22-24°C",
        value: "30°C",
        time: "15 min",
        field: NOT_APPLICABLE_SHORT,
    },
    {
        id: "7",
        name: "PM1.0 Alert",
        measure: "PM1.0",
        recommended: "< 25ug/m³",
        value: "26ug/m³",
        time: "1 hour",
        field: "pm10",
    },
    {
        id: "8",
        name: "PM2.5 Alert",
        measure: "PM2.5",
        recommended: "< 25ug/m³",
        value: "26ug/m³",
        time: "1 hour",
        field: "pm25",
    },
    {
        id: "9",
        name: "PM10 Alert",
        measure: "PM10",
        recommended: "< 5ug/m³",
        value: "6ug/m³",
        time: "1 hour",
        field: "pm100",
    },
];
