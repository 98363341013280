import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import React from "react";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
/* eslint @typescript-eslint/no-unused-vars: ["off"] */
interface IIotVisionButton {
    additionalProps?: any;
}
const CustomizedButton = styled(Button)(({ theme }) => ({
    backgroundColor: `${theme.palette.primary.main}`,
    textTransform: "none",
    fontSize: "14px",
    lineHeight: "21px",
    borderRadius: "20px",
    borderColor: `${theme.palette.secondary.main}`,
    height: "33px",
    color: "white",
    padding: "0px 43px",
    border: "1px solid",
    "&:hover": {
        background: `${theme.palette.primary.main}`,
        cursor: "pointer",
    },
}));
const IotVisionButton: React.FC<IIotVisionButton & JSX.IntrinsicAttributes & ButtonProps> = ({
    additionalProps,
    ...rest
}) => {
    return <CustomizedButton {...rest} />;
};

export default IotVisionButton;
