import ExecuteAsync from "utils/api-client";
import { ApiEndpoints } from "configs";
import { createQueryParams } from "utils/create-query-params";
import { RequestOptions } from "axios-types";
import { IDoughnutChartCardData } from "components/cards/DoughnutChartCard";
import { AssigneeObj, IByLocation } from "types";
import { DetectionParams } from "features/app-taxi-ranking/statistics/HomeAllLocationStatisticsCards";
import { IIncidentVideoItem } from "types/incident/file-status";
import {
    IIncidentVideoStatusParams,
} from "features/app-taxi-ranking/dialogs/IncidentModal/IncidentModal";
import { CommonObjectLiteral } from "types/reports/interfaces";
import { GetUsersQuery } from "types/user/user";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
export const executeGetIncidentData = (data?: any, options?: RequestOptions) => {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/incidents/incident/filter${createQueryParams(data)}`,
        signal: options?.signal,
        disableNotification: options?.disableNotification,
    });
};

export const executeGetIncidentsVideoRequests = (data?: any, options?: RequestOptions) => {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/incidents/v2/incident${createQueryParams(data)}`,
        signal: options?.signal,
        disableNotification: options?.disableNotification,
    });
};

export const executeGetUsersRequest = (query: GetUsersQuery,options?: RequestOptions) => {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/users${createQueryParams(query)}`,
        signal: options?.signal,
    });
};

export const getIncidentFilterDropDownValues = (options?: RequestOptions) => {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: "/incidents/incident/filtertypes",
        signal: options?.signal,
    });
};

export function executeGetIncidentByTypeRequest(data?: any, options?: RequestOptions) {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/incidents/incident/by_type${createQueryParams(data)}`,
        signal: options?.signal,
    });
}

export function executeGetIncidentByTaxiTypeRequest(data?: any, options?: RequestOptions) {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/incidents/incident/by_taxi_type${createQueryParams(data)}`,
        signal: options?.signal,
    });
}

export function executeGetIncidentByLgaRequest(data?: any, options?: RequestOptions) {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/incidents/incident/by_lga${createQueryParams(data)}`,
        signal: options?.signal,
    });
}

export function executeGetIncidentByIdRequest(incidentId: string, options?: RequestOptions) {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/incidents/incident/${incidentId}`,
        signal: options?.signal,
    });
}

export function executeGetIncidentVideoFootageRequest(incidentId: string, options?: RequestOptions) {
    return ExecuteAsync<any, string>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/incidents/incident/${incidentId}/video-link`,
        signal: options?.signal,
    });
}

export function executeUpdateIncidentStatusRequest(incidentId: string, data?: any, options?: RequestOptions) {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "PUT",
        url: `/incidents/incident/${incidentId}/status${createQueryParams(data)}`,
        signal: options?.signal,
    });
}

export function executeCreateIncidentCommentRequest(incidentId: string, data: any, options?: RequestOptions) {
    return ExecuteAsync({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "POST",
        url: `/incidents/incident/${incidentId}/comment`,
        data,
        signal: options?.signal,
    });
}

export function executeUpdateIncidentRegoRequest(incidentId: string, data?: any, options?: RequestOptions) {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "PUT",
        url: `/incidents/incident/${incidentId}`,
        data,
        signal: options?.signal,
    });
}

export function executeAssignUserToIncidentRequest(data?: AssigneeObj, options?: RequestOptions) {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "PUT",
        url: "/incidents/incident/assign_user",
        data,
        signal: options?.signal,
    });
}

export function executeGetCommentsByIncidentIdRequest(incidentId: string, options?: RequestOptions) {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/incidents/incident/${incidentId}/incidentComments`,
        signal: options?.signal,
    });
}

export async function executeGetIncidentsByLocationsTaxiRequest(
    data?: any,
    options?: RequestOptions,
): Promise<IByLocation[]> {
    const response = await ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/incidents/incident/by_location${createQueryParams(data)}`,
        signal: options?.signal,
    });
    return response.map((item: any) => {
        return {
            count: item.count,
            locationGroupIds: item.locationgroupids,
            locationId: item.locationid,
        };
    });
}

export async function executeGetIncidentDetectionsTaxiRequest(
    data?: DetectionParams,
    options?: RequestOptions,
): Promise<IDoughnutChartCardData[]> {
    const response = await ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/incidents/incident/total${createQueryParams(data)}`,
        signal: options?.signal,
    });
    return response.map((item: any) => {
        return {
            count: item.count,
            incident: item?.incident_incident,
        };
    });
}

export function executeGetIncidentVideoStatusRequest(
    data?: IIncidentVideoStatusParams,
    options?: RequestOptions,
): Promise<IIncidentVideoItem[]> {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/file${createQueryParams(data)}`,
        signal: options?.signal,
        disableNotification: false,
    });
}

export function executeQueueIncidentVideoRequest(
    incidentId: string,
    options?: RequestOptions,
) {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "POST",
        url: `/incidents/v2/incident/${incidentId}/video-request`,
        signal: options?.signal,
        disableNotification: false,
    });
}

export function executeObtainFileDownloadPresignedURL(
    fileId: string,
    params: CommonObjectLiteral,
    options?: RequestOptions,
) {
    return ExecuteAsync<any, any>({
        baseURL: ApiEndpoints.BaseUrlEndpoint,
        method: "GET",
        url: `/file/${fileId}/signed-link${createQueryParams(params)}`,
        signal: options?.signal,
        disableNotification: false,
    });
}

