import { AnnotationOptions, EventContext, PartialEventContext } from "chartjs-plugin-annotation";
import { ChartData } from "features/app-air-quality/historical-analysis/chart/HistoricalAnalysisChart";
import { XYData } from "features/app-cycle-ways/chart/SynchronizedLineChart";

export function average(data: XYData[]): number {
    if (data.length) {
        return data.reduce((a: number, b: XYData) => a + b.y, 0) / data.length;
    }
    return 0;
}

export const generateAverageLines = (dataSets: ChartData[]): AnnotationOptions[] => {
    if (dataSets.length) {
        return dataSets.map((d: ChartData, index: number) => {
            const avg = average(d.data);
            return {
                id: Math.random(),
                type: "line",
                borderColor: d.borderColor,
                borderDash: [6, 6],
                borderDashOffset: 0,
                borderWidth: 2,
                value: avg.toFixed(2),
                scaleID: "y",
                display: (ctx: PartialEventContext) => ctx.chart.isDatasetVisible(index),
                label: {
                    content: `Average of ${d.label} is ${avg.toFixed(2)}`,
                    display: false,
                    backgroundColor: d.borderColor,
                    font: {
                        size: 11,
                    },
                    z: 100,
                },
                enter({ element }: EventContext) {
                    if (element?.label) {
                        element.label.options.display = true;
                        return true;
                    }
                },
                leave({ element }: EventContext) {
                    if (element?.label) {
                        element.label.options.display = false;
                        return true;
                    }
                },
            } as unknown as AnnotationOptions;
        });
    }
    return [] as AnnotationOptions[];
};
