import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Button, IconButton, TextField } from "@mui/material";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { GrClose } from "react-icons/gr";
import { Box } from "@mui/system";
import { styled } from "@mui/styles";
import { LoadingWrapper } from "components/wrappers/LoadingWrapper";

const StyledUpIcon = styled(IoMdArrowDropup)(() => ({
    width: "50px",
    "& path": {
        strokeWidth: "300px",
        stroke: "#c8c8c8",
    },
}));

const StyledDownIcon = styled(IoMdArrowDropdown)(() => ({
    width: "50px",
    "& path": {
        strokeWidth: "300px",
        stroke: "#c8c8c8",
    },
}));
interface CountUpdaterDialogProps {
    count: number;
    modalShow: boolean;
    onClose: () => void;
    onUpdate: (value: number) => void;
    isLoading: boolean;
}
const CountUpdaterDialog: React.FC<CountUpdaterDialogProps> = ({ count, modalShow, onClose, onUpdate, isLoading }) => {
    const [newCount, setNewCount] = React.useState<number>(count ?? 0);
    const handleValueAdjustFromButton = (value: number) => {
        const newValue = +newCount + value;
        if (newValue >= 0) {
            setNewCount(newValue);
        }
    };
    const handleInputFieldChange = (value: string) => {
        const convertedValue = Number(value);
        if (!Number.isNaN(convertedValue) && convertedValue >= 0) {
            setNewCount(convertedValue);
        }
    };
    const handleClose = () => {
        setNewCount(count);
        onClose();
    };
    useEffect(() => {
        setNewCount(count);
    }, [count]);

    return (
        <div>
            <Dialog
                open={modalShow}
                onClose={handleClose}
                sx={{
                    "& .MuiPaper-root": {
                        width: "25%",
                    },
                }}
            >
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        width: "28px",
                        height: "28px",
                        right: "5px",
                        top: "5px",
                    }}
                >
                    <GrClose fontSize={20} fontWeight={100} />
                </IconButton>
                <DialogContent
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "8%",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <IconButton onClick={() => handleValueAdjustFromButton(1)} disableRipple>
                            <StyledUpIcon />
                        </IconButton>
                        <TextField
                            value={newCount}
                            className="count-border text-field-width"
                            sx={{
                                "& .MuiInputBase-root": {
                                    borderRadius: "0px !important",
                                    padding: "6% 0%",
                                },
                            }}
                            inputProps={{
                                style: {
                                    color: "#21315b",
                                    fontSize: "30px",
                                    lineHeight: "40px",
                                    textAlign: "center",
                                    fontWeight: 600,
                                    padding: 0,
                                    margin: 0,
                                },
                            }}
                            onChange={(e) => handleInputFieldChange(e.target.value)}
                        />
                        <IconButton onClick={() => handleValueAdjustFromButton(-1)} disableRipple>
                            <StyledDownIcon />
                        </IconButton>
                    </Box>
                    <Box>
                        <LoadingWrapper isLoading={isLoading} className="loading-wrapper">
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "#f4b921",
                                    borderRadius: "10px",
                                    fontWeight: 600,
                                    textTransform: "capitalize",
                                    "&:hover": {
                                        backgroundColor: "#f4b921",
                                    },
                                }}
                                onClick={() => onUpdate(newCount)}
                                color="warning"
                            >
                                Adjust
                            </Button>
                        </LoadingWrapper>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
};
export default CountUpdaterDialog;
