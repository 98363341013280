import React from "react";
import Grid from "@mui/material/Grid";
import { FaMapMarkerAlt } from "react-icons/fa";
import { GiCctvCamera } from "react-icons/gi";
import { AiFillStar } from "react-icons/ai";
import { OperatingHours } from "components/times/OperatingHours";
import { ILocationOpenHours } from "../../../reducers/newLocation.reducer";

interface LocationSummaryCardProps {
    name?: string;
    city?: string;
    latitude?: string;
    longitude?: string;
    component: number;
    openHours?: ILocationOpenHours;
}

const LocationSummaryCard: React.FC<LocationSummaryCardProps> = ({
    name,
    city: zone,
    latitude,
    longitude,
    component,
    openHours: operatingHours,
}) => {
    return (
        <Grid container className="region-statlocation-card-wrapper m-0">
            <Grid item xs={12} className="region-statlocation-card-Innerwrapper">
                <Grid container item xs={12}>
                    <div className="region-statlocation-row">
                        <div className="LocationIcon-wrapper">
                            <FaMapMarkerAlt />
                        </div>
                        <span className="region-statlocation-header">{name}</span>
                        <span className="StarIcon-wrapper">
                            <AiFillStar className="color-yellow" />
                        </span>
                    </div>
                </Grid>

                <Grid item xs={12} className="region-statlocation-innaer-wrapper_bottom">
                    <Grid item xs={12} className="region-statlocation-row-left">
                        <Grid item xs={12} className="region-statlocation-row-subrow">
                            LGA: {zone}
                        </Grid>
                        <Grid item xs={12} className="region-statlocation-row-subrow lgaData">
                            {latitude}, {longitude}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} display="flex" flexDirection="column" className="region-statlocation-row-right">
                        <Grid item xs={12} className="region-statlocation-row-subrow">
                            <GiCctvCamera />
                            Number of cameras:{" "}
                            <div className="region-statlocation-row-subrow-data">{/* {devices?.length} */}1</div>
                        </Grid>
                        {component === 1 ? <OperatingHours operatingHours={operatingHours} /> : null}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LocationSummaryCard;
