import { Dispatch } from "@reduxjs/toolkit";
import { pickerRanges } from "components/common/CommonDateRangePicker";
import { TimeRange } from "features/common/date-header/DateRangePicker";
import moment from "moment";
import { setTimeRange } from "reducers/time.reducer";
import { Location } from "reducers/newLocation.reducer";
import { format } from "date-fns";
import { enAU } from "date-fns/locale";
import { MILLISECONDS_TO_HOUR, MILLISECONDS_TO_MINUTE, MILLISECONDS_TO_SECONDS } from "constants/time.constants";

export function setTimeUnit(start: string, end: string): string {
    const a = moment(end);
    const b = moment(start);
    const minutes = a.diff(b, "minutes");
    let unit = TimeRange?.Hour;
    if (minutes <= 20) {
        unit = TimeRange?.Minutes;
    } else if (minutes <= 120) {
        unit = TimeRange?.Minutes;
    } else if (minutes <= 1440) {
        unit = TimeRange?.Hour;
    } else if (minutes <= 30240) {
        unit = TimeRange?.Day;
    } else if (minutes <= 241920) {
        unit = TimeRange?.Week;
    } else {
        unit = TimeRange?.Month;
    }
    return unit;
}

export const getRangeIntoNumber = (range: pickerRanges): number => {
    switch (range) {
        case pickerRanges.LastHour:
            return 1;
        case pickerRanges.Last2Hours:
            return 2;
        case pickerRanges.Last4hours:
            return 4;
        case pickerRanges.Last12Hours:
            return 12;
        default:
            return 24;
    }
};

export const getUpdatedStartEndTimeBaseRange = (
    range: pickerRanges,
): {
    fromTime: string;
    toTime: string;
    timeType: string;
    range: pickerRanges;
} => {
    const end = new Date();
    end.setSeconds(0, 0);
    const dt = new Date();
    dt.setHours(dt.getHours() - getRangeIntoNumber(range));
    dt.setSeconds(0, 0);
    const timeType = setTimeUnit(dt.toISOString(), end.toISOString());
    return {
        fromTime: dt.toISOString(),
        toTime: end.toISOString(),
        timeType,
        range,
    };
};
export interface FetchFunctionParams<T = string> {
    startTime: T;
    endTime: T;
}

export const getRandomColor = () =>
    `#${Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, "0")
        .toUpperCase()}`;

export const getDoughnutBackGroundColorBaseLabels = (data: number[]): string[] => {
    let color: string[] = [];
    if (Array.isArray(data) && data.length > 0) {
        if (data?.length > 6) {
            // Add random colors if more than figma colors
            for (let i = 0; i < data?.length; i++) {
                color.push(getRandomColor());
            }
        } else {
            // add default figma design colors
            color = ["#6B439D", "#C62034", "#F4C621", "#21315B", "#D6DDEF", "#32D1DB"];
        }
    }
    return color;
};
export const makeApiCallWithUpdateTime = <T extends FetchFunctionParams = FetchFunctionParams>(
    selectedRange: pickerRanges,
    fetchDataParams: T,
    fetchData: (params: T) => void,
    /* eslint @typescript-eslint/no-explicit-any: ["off"] */
    dispatch: Dispatch<any>,
): void => {
    const { startTime, endTime, ...otherParams } = fetchDataParams || {};
    if (selectedRange === pickerRanges.Custom) {
        fetchData({ startTime, endTime, ...otherParams } as T);
    } else {
        const { fromTime, toTime, range } = getUpdatedStartEndTimeBaseRange(selectedRange);

        if (startTime === fromTime) {
            fetchData({ startTime, endTime, ...otherParams } as T);
        } else {
            dispatch(
                setTimeRange({
                    startTime: fromTime,
                    endTime: toTime,
                    selectedRange: range,
                }),
            );
        }
    }
};

export function cleanUrl(url: string): string {
    const cleanedUrl = url.replace(/\/\//g, "/");
    return cleanedUrl;
}

export const checkIfDelayed = (seconds: number, elapsedDate: Date): boolean => {
    const TIME_IN_MILLISECONDS = seconds * 1000;
    const date = new Date();
    if (date.valueOf() - new Date(elapsedDate).valueOf() > TIME_IN_MILLISECONDS) {
        return true;
    }
    return false;
};

/**
 * Returns the time x amount of milliseconds ago from the current time
 * @param difference time difference in milliseconds
 * @param fromtTime optional parameter from which the time would be reduced from.
 * If not provided defaults to current date time.
 * @returns a new Date object x milliseconds ago.
 */
export const getRelativeTime = (difference: number, fromtTime: Date = new Date()): Date => {
    return new Date(fromtTime.getTime() - difference);
};

/**
 * this is to check whether the location id in the url has change before the api is called
 * @param locationId id received from the parameters
 * @param locationList location list that we store
 * @returns
 */
export function checkLocationIdChanged(locationId: string | undefined, locationList: Location[]): boolean {
    if (typeof locationId === "string" && locationList.some((item) => item.id === locationId)) {
        return false;
    }
    return true;
}

export const capitalizeFirstLetter = (text: string): string => {
    return text.charAt(0).toUpperCase() + text.slice(1);
};
/**
 * this is to mapping string to another string
 * @param text string that want to map
 * @param mapping mapping configuration object of array
 * @returns mapped string or input string
 */
export const stringMapper = (text: string, mapping: { [key: string]: string }[]): string => {
    let mappedString;
    mapping.forEach((i) => {
        if (text === i.from) {
            mappedString = i.to;
        }
    });
    return mappedString ?? text;
};

/**
 * this is to capitalize the string
 * @param text string that want to capitalize
 * @param separator string that want to used as separator or words
 * @returns capitalized string
 */
export const stringCapitalizer = (text?: string, separator?: string): string => {
    if (!text) {
        return "";
    }
    const words = text.split(separator ?? " ");
    const result = words.map((sub) => sub.charAt(0).toUpperCase() + sub.slice(1).toLowerCase());
    return result.join(separator ?? " ");
};

/**
 * Removes underscores and capitalizes the first letter of a given string
 * @param str string that want to humanize
 * @returns
 */
export const humanize = (str: string) => {
    let i
    const frags = str.split("_");
    for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].toLowerCase();
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(" ");
};

/**
 * Converts the provided string to Title Case
 * @param string String to be converted to Title Case
 * @returns
 */
export const camelCaseToTitleCase = (string: string) => {
    const result =  string.replace(/([A-Z])/g, " $1")
    return result.charAt(0).toUpperCase() + result.slice(1);

}


/**
 * Converts the provided string to Title Case
 * @param fromTime Date string
 * @param toTime Date string
 * @param dateFormat Date format. Values can be "P", "Pp"
 * @returns Date range string separated by hyphen
 */
export const dateRangeStringCreator = (fromTime: string, toTime: string, dateFormat: string) => {
    const from = new Date(fromTime);
    const to = new Date(toTime);
    if (dateFormat) {
        return `${format(from, dateFormat, { locale: enAU })} - ${format(to, dateFormat, { locale: enAU })}`;
    }
    return `${format(from, "Pp", { locale: enAU })} - ${format(to, "Pp", { locale: enAU })}`;

};

/**
 * check whether startTime and endTime in redux store is up to date or not
 * @param selectedRange pickerRanges
 * @param startTime Date string
 * @returns boolean value
 */
export const isStoreTimeUpToDate = (selectedRange: pickerRanges, startTime: string): boolean => {
    if (selectedRange === pickerRanges.Custom) return true;
    const { fromTime } = getUpdatedStartEndTimeBaseRange(selectedRange);
    if (startTime === fromTime) return true;
    return false;
};

/**
 * filter dataset based on provided classification list
 * @param dataset dataset that needs to filter
 * @param classificationList list of classifications
 * @param shouldRemove shouldRemove boolean indicating whether to remove or include provided classification list
 * @returns filtered dataset
 */
export const filterDataByClassifications = <T extends {classification: string}>(
   dataset: T[],
   classificationList: string[],
   shouldRemove = false,
): T[] => {
    return dataset.filter((item: T) =>
        shouldRemove
            ? !classificationList.includes(item.classification)
            : classificationList.includes(item.classification),
    );
};

/**
* Generate hours, minutes, seconds and rest milliseconds portions of provided no of milliseconds
* @param {number} ms no of milliseconds
* @returns {{hours: number; minutes: number; seconds: number; milliseconds: number}} 
* return object with each units with related values according to the provided parameter value
*/
export const convertMilliseconds = (ms: number) => {
    // Calculate hours
    const hours = Math.floor(ms / MILLISECONDS_TO_HOUR);
    ms %= MILLISECONDS_TO_HOUR;

    // Calculate minutes
    const minutes = Math.floor(ms / MILLISECONDS_TO_MINUTE);
    ms %= MILLISECONDS_TO_MINUTE;

    // Calculate seconds
    const seconds = Math.floor(ms / MILLISECONDS_TO_SECONDS);
    ms %= MILLISECONDS_TO_SECONDS;

    // Remaining milliseconds
    const milliseconds = ms;

    return {
        hours,
        minutes,
        seconds,
        milliseconds,
    };
}
