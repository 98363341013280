import { LogoutOptions } from "@auth0/auth0-react";
import { Logout, ArticleOutlined } from "@mui/icons-material";
import { Box, IconButton, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import { IoPersonCircleOutline } from "react-icons/io5";
import { NavigateFunction } from "react-router-dom";
import { appDefaultRoute, pagePath } from "../../../pages/app-dept-air-quality";

interface Props {
    logout: (options?: LogoutOptions) => void;
    navigate: NavigateFunction;
}
export const UserMenu: React.FC<Props> = ({ logout, navigate }) => {
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <Box
            sx={{
                flexGrow: 0,
                paddingLeft: "3px",
                marginRight: "-5px",
                position: "relative",
                top: "-5px",
            }}
        >
            <IconButton color="primary" onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <IoPersonCircleOutline fontSize="24" />
            </IconButton>

            <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <MenuItem
                    onClick={() => {
                        logout({
                            returnTo: window.location.origin,
                        });
                    }}
                >
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    <Typography textAlign="center">Logout</Typography>
                </MenuItem>
                <MenuItem>
                    <ListItemIcon>
                        <ArticleOutlined fontSize="small" />
                    </ListItemIcon>
                    <Typography
                        textAlign="center"
                        onClick={() => {
                            navigate(`${appDefaultRoute}/${pagePath.RulesAndAlerts}`);
                            handleCloseUserMenu();
                        }}
                    >
                        Rules and Alerts
                    </Typography>
                </MenuItem>
            </Menu>
        </Box>
    );
};
