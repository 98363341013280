/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useRef } from "react";
import { TreeView, TreeItem, TreeItemProps } from "@mui/lab";
import { BiLeftArrowAlt, BiRightArrowAlt, BiMinus } from "react-icons/bi";
import { FaChartLine } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import SearchInputField from "components/input-fields/SearchInputField";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import { toggleReportsSideBar } from "reducers/system.reducer";
import { executeReportRetrievalRequest, newExecuteRetrieveLocationsRequest } from "api/common/side-panel.api";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import {
    searchReportsBy,
    setReportSideMenu,
    setReports,
    toggleReportsLoading,
} from "reducers/report.reducer";
import { IReportSideMenuItem } from "types/reports/interfaces";
import ReportTreeContent from "features/app-taxi-ranking/tree-item/report-tree-item";
import { IStructuredReportData } from "types/reports/interfaces/side-menu";
import { useParams, useLocation } from "react-router";
import {
    generateIncidentTrendsReportSubTreeItems,
    reportSideMenuDisplayValues,
} from "configs/reports/report-sidemenu-display-values";
import { IReport, ISidebarReport } from "types/reports/interfaces/report";
import { ReportTypes } from "types/reports/enums";
import { formatDateByReportType } from "utils/data-manipulation";
import { clearLocations, setLocations } from "reducers/newLocation.reducer";
import { setInitialMapFocus } from "reducers/map.reducer";
import useStyles from "./styles";

type SidebarTaxiRankProps = {
    rootPath: string;
};

interface ConcatenatedReportData {
    data: IReport[];
}

const SIDEBAR_REPORT_PAGESIZE = 90;

const CustomTreeItem = (props: TreeItemProps) => <TreeItem ContentComponent={ReportTreeContent} {...props} />;

const SetToolTipBaseNameSize = (reportName: string) => {
    if (reportName?.length > 20) {
        return (
            <span className="textOverFlow">
                <Tooltip placement="bottom" title={reportName} arrow>
                    <Typography fontSize="14px">{reportName}</Typography>
                </Tooltip>
            </span>
        );
    }
    return <span className="textDNone">{reportName}</span>;
};

const setBoldTextForReportTypes = (reportType: string) => {
    return <span className="fw600 textDNone">{reportType}</span>;
}

const renderReportSubTree = (report: {id: string; displayName: string}) => {
    return (
        <CustomTreeItem
            nodeId={report.id}
            label={report && SetToolTipBaseNameSize(report.displayName)}
            className="level4"
            key={report.id}
        />
    );
};

const renderReport = (report: IReportSideMenuItem, reportType: string) => {
    return (
        <CustomTreeItem
            nodeId={setNodeIdByType(reportType, report)}
            label={report && SetToolTipBaseNameSize(report.reportTo)}
            className="level3"
            key={report.id}
        >
            {renderSubTreeByReportType(reportType, report)}
        </CustomTreeItem>
    );
};

const setNodeIdByType = (reportType: string, report: IReportSideMenuItem) => {
    switch (reportType) {
        case reportSideMenuDisplayValues.INCIDENT_TRENDS_REPORT:
            return `${reportType}/${report.reportTo}`;
        default:
            return report.id;
    }
};

const renderSubTreeByReportType = (reportType: string, report: IReportSideMenuItem) => {
    switch (reportType) {
        case reportSideMenuDisplayValues.INCIDENT_TRENDS_REPORT: {
            const reportSummaryTreeItems = generateIncidentTrendsReportSubTreeItems(report?.id);
            return reportSummaryTreeItems.map((item) => renderReportSubTree(item))
        }
        default:
            return null;
    }
};

const renderReportTypes = (children: IReportSideMenuItem[], reportType: string) => {
    return (
        <CustomTreeItem
            nodeId={reportType}
            label={setBoldTextForReportTypes(reportType)}
            className="level2"
            key={reportType}
        >
            {children.map((item: IReportSideMenuItem) => renderReport(item, reportType))}
        </CustomTreeItem>
    );
};

const SidebarReports: React.FC<SidebarTaxiRankProps> = () => {
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const { reportId } = useParams();
    const { isReportsDrawerOpen } = useSelector((state) => state.system);
    const [mergedReports, setMergedReports] = useState<ConcatenatedReportData>()
    const [selectedNode, setSelectedNode] = useState<string>("root");
    const { sideMenuData, fetchedReports } = useSelector((state) => state.report);
    const [expanded, setExpanded] = useState<string[]>(["root"]);
    const reportAbortControllerRef = useRef<AbortController | null>(null);
    const locationsAbortControllerRef = useRef<AbortController | null>(null);
    const locationsTreeAbortControllerRef = useRef<AbortController | null>(null);
    const { rootPath, appCode } = useSelector((state) => state.auth);
    const location = useLocation();
    const navigate = useNavigate();
    const [cachedExpanded, setCachedExpanded] = useState<string[]>(expanded);

    useEffect(() => {
        (async () => {
            dispatch(clearLocations());
            await getLocations();
            dispatch(setInitialMapFocus());
        })();

        return () => {
            locationsAbortControllerRef.current?.abort();
            locationsTreeAbortControllerRef.current?.abort();
        };
    }, []);

    useEffect(() => {
        // Redirecting to first report in the list on initial load
        loadingInitialReport();
    }, [sideMenuData]);

    useEffect(() => {
        (async () => {
            await getAllReports();
        })();

        return () => {
            reportAbortControllerRef.current?.abort();
        };
    }, []);

    useEffect(() => {
        if(isReportsDrawerOpen && reportId){
            const found: ISidebarReport | undefined = fetchedReports.find((report: ISidebarReport) => {
                return report.id === reportId;
            });
            if (found) {
                const set = setExpandedTreeByReportType(found, cachedExpanded);
                setExpanded([...set]);
            }
        }
        else if (isReportsDrawerOpen) {
            setExpanded(cachedExpanded);
        } else {
            setCachedExpanded(expanded);
            setExpanded([]);
        }
    }, [isReportsDrawerOpen, sideMenuData]);

    useEffect(() => {
        if(appCode && mergedReports) {
            if (mergedReports?.data?.length === 0) {
                navigate(`/${appCode}/reports/no-results`, { replace: true });
            }
        }
    }, [appCode, mergedReports])

    useEffect(() => {
        if (reportId) {
            setSelectedNodeByURL(reportId);
            const found: ISidebarReport | undefined = fetchedReports.find((report: ISidebarReport) => {
                return report.id === reportId;
            });

            if (found) {
                const set = setExpandedTreeByReportType(found, expanded);
                setExpanded([...set]);
            }
        } else {
            setSelectedNode("root");
        }
    }, [reportId, location?.pathname]);

    const setSelectedNodeByURL = (selectedReportId: string) => {
        const { pathname } = location;
        const subURLSegment = pathname?.split(selectedReportId)?.pop();
        if (subURLSegment) setSelectedNode(`${selectedReportId}${subURLSegment}`);
        else setSelectedNode(selectedReportId);
    }

    const setExpandedTreeByReportType = (report: ISidebarReport, currentExpanded: string[]) => {
        switch (report.reportType) {
            case ReportTypes.INCIDENT_TRENDS_REPORT:
                return new Set([
                    ...currentExpanded,
                    reportSideMenuDisplayValues[report.reportType],
                    `${reportSideMenuDisplayValues[report.reportType]}/${formatDateByReportType(
                        report.reportType,
                        new Date(report.reportTo),
                    )}`,
                ]);
            default:
                return new Set([...currentExpanded, reportSideMenuDisplayValues[report.reportType]]);
        }
    }

    const loadingInitialReport = () => {
        if (sideMenuData.length > 0 && sideMenuData[0]?.data.length > 0) {
            if (location.pathname === rootPath) {
                switch (sideMenuData[0]?.displayName) {
                    case reportSideMenuDisplayValues.INCIDENT_TRENDS_REPORT:
                        setSelectedNode(sideMenuData[0]?.data[0]?.id);
                        setExpanded([
                            ...setExpandedTreeByReportType(sideMenuData[0]?.data[0] as ISidebarReport, [
                                "root",
                            ]),
                        ]);
                        navigate(`${rootPath}/${sideMenuData[0]?.data[0]?.id}/lga-overview`);
                        break;
                    default:
                        setSelectedNode(sideMenuData[0]?.data[0]?.id);
                        setExpanded(["root", sideMenuData[0]?.displayName]);
                        navigate(`${rootPath}/${sideMenuData[0]?.data[0]?.id}`);
                }
            }
        }
    };

    const handleSidebar = () => {
        dispatch(toggleReportsSideBar());
    }

    const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        setExpanded(nodeIds);
    };

    const handleNode = () => {
        if (!isReportsDrawerOpen) {
            dispatch(toggleReportsSideBar());
        }
    };

    const getAllReports = async () => {
        try {
            dispatch(toggleReportsLoading(true));
            reportAbortControllerRef.current = new AbortController();

            // The following snippet makes 2 separate API calls to fetch the reports required to
            // be displayed on the sidemenu
            const reportTypeArr = [
                ReportTypes.SUMMARY_REPORT,
                ReportTypes.WORK_FLOW_MANAGEMENT_SUMMARY_REPORT,
                ReportTypes.INCIDENT_TRENDS_REPORT,
            ];
            const allReportsResponse = await Promise.all(
                reportTypeArr.map(async (type) => {
                    const results = await executeReportRetrievalRequest({
                        pageSize: SIDEBAR_REPORT_PAGESIZE,
                        reportType: type,
                    });
                    return results;
                }),
            );

            let summaryReportResponse: IReport[] = [];
            let workflowReportResponse: IReport[] = [];
            let incidentTrendsReportResponse: IReport[] = [];

            if(allReportsResponse[0])
                summaryReportResponse = allReportsResponse[0]?.data as IReport[]

            if (allReportsResponse[1])
                workflowReportResponse = allReportsResponse[1]?.data as IReport[];

            if (allReportsResponse[2])
                incidentTrendsReportResponse = allReportsResponse[2]?.data as IReport[];

            // Fetched reports are aggregated into a single array and set to the application state
            const mergedReports = {
                data: [...summaryReportResponse, ...workflowReportResponse, ...incidentTrendsReportResponse],
            };

            setMergedReports(mergedReports)
            dispatch(toggleReportsLoading(false));
            dispatch(setReports(mergedReports));
            dispatch(setReportSideMenu(mergedReports));
        } catch (e) {
            console.log(e);
        }
    };

    const getLocations = async () => {
        try {
            locationsAbortControllerRef.current = new AbortController();
            const locationSignal = locationsAbortControllerRef.current.signal;
            locationsTreeAbortControllerRef.current = new AbortController();

            const locationsResponse = await newExecuteRetrieveLocationsRequest({
                signal: locationSignal,
            });

            if (locationsResponse && Array.isArray(locationsResponse)) {
                dispatch(setLocations(locationsResponse));
            }
        } catch (e) {
            console.log(e);
        }
    };

    function onSearchLocation(e: any) {
        const { value } = e.target;
        dispatch(searchReportsBy(value));
    }
    const SideBarStyledDivIconWrapper = styled("div")({
        ...(isReportsDrawerOpen ? { paddingLeft: "11px" } : {}),
    });

    return (
        <div className={isReportsDrawerOpen ? "sidebar active" : "sidebar"}>
            <div className={isReportsDrawerOpen ? "sidebar-top-header" : ""}>
                <div className="menu-btn-wrapper" onClick={handleSidebar}>
                    {isReportsDrawerOpen ? <BiLeftArrowAlt className="icon" /> : <BiRightArrowAlt className="icon" />}
                </div>

                <SearchInputField onSearch={onSearchLocation} />
            </div>
            <div className="menu-tree">
                <TreeView
                    aria-label="controlled"
                    defaultCollapseIcon={<BiMinus className="expandedIcon" />}
                    defaultExpandIcon={<FiPlus className="expandedIcon" />}
                    expanded={expanded}
                    onNodeToggle={handleToggle}
                    onNodeSelect={handleNode}
                    className={classes.root}
                    selected={selectedNode}
                >
                    <CustomTreeItem
                        nodeId="root"
                        label={<span className="fw700 ">All Reports</span>}
                        icon={
                            <SideBarStyledDivIconWrapper>
                                <FaChartLine className="color-active" />
                            </SideBarStyledDivIconWrapper>
                        }
                        className="level1"
                    >
                        {Array.isArray(sideMenuData) &&
                            sideMenuData.map((element: IStructuredReportData) => {
                                return renderReportTypes(
                                    element.data,
                                    element.displayName ? element.displayName : "",
                                );
                            })}
                    </CustomTreeItem>
                </TreeView>
            </div>
        </div>
    );
};

export default SidebarReports;
