export const formatDate = (d: Date | string, options: Intl.DateTimeFormatOptions, locale?: string): string => {
    let date;
    if (!locale) {
        locale = "en-AU";
    }
    if (typeof d === "string") {
        date = new Date(d);
    }
    return new Intl.DateTimeFormat(locale, options).format(date);
};

export default formatDate;
