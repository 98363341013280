import { Grid } from "@mui/material";
import React from "react";
import { Line, Chart } from "react-chartjs-2";
import { getHtmlLegendPlugin } from "utils/chart-legend";
import annotationPlugin, { AnnotationOptions } from "chartjs-plugin-annotation";

Chart.register(annotationPlugin);

interface XYData {
    x: Date;
    y: number;
}

export interface ChartData {
    label: string;
    data: XYData[];
    borderColor: string;
}

interface Time {
    fromTime: string;
    toTime: string;
}
interface HistoricalAnalysisChartProps {
    chartDataSet: ChartData[];
    time: Time;
    isChartLegendDisplay?: boolean;
    legendComponentId?: string;
    windowGap?: number;
    averageLines?: AnnotationOptions[] | Record<string, AnnotationOptions>;
}

const HistoricalAnalysisChart: React.FC<HistoricalAnalysisChartProps> = ({
    time,
    chartDataSet,
    isChartLegendDisplay = true,
    legendComponentId = "legendComponentId",
    windowGap,
    averageLines,
}) => {
    return (
        <Grid container spacing={1} sx={{ height: "83.5%" }}>
            <Grid item {...(isChartLegendDisplay ? { xs: 10 } : { xs: 12 })}>
                <Line
                    width={1317}
                    data={{
                        datasets: chartDataSet,
                    }}
                    plugins={[getHtmlLegendPlugin()]}
                    options={{
                        datasets: {
                            line: {
                                borderWidth: 2,
                                pointRadius: 3,
                            },
                        },
                        maintainAspectRatio: false,
                        plugins: {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            htmlLegend: {
                                containerID: legendComponentId,
                            },

                            legend: {
                                display: false,
                            },
                            annotation: {
                                annotations: averageLines,
                            },
                        },
                        responsive: true,
                        scales: {
                            y: {
                                grid: {
                                    borderWidth: 1.5,
                                },
                            },
                            x: {
                                grid: {
                                    borderWidth: 1.5,
                                    display: false,
                                },
                                ticks: {
                                    minRotation: 89,
                                    maxRotation: 89,
                                },
                                title: {
                                    display: true,
                                    text: windowGap === 60 ? "Hour" : "Minute",
                                    align: "end",
                                    color: "#21315B",
                                },
                                type: "time",
                                min: new Date(time?.fromTime).valueOf(), // from time
                                max: new Date(time?.toTime).valueOf(), // to time
                                time: {
                                    unit: windowGap === 60 ? "hour" : "minute",
                                    round: windowGap === 60 ? "hour" : "minute", // scale unit
                                    stepSize: windowGap === 60 ? 1 : windowGap,
                                },
                            },
                        },
                        elements: {
                            point: {
                                pointStyle: "circle",
                                hitRadius: 5,
                            },
                        },
                    }}
                />
            </Grid>
            {isChartLegendDisplay && <Grid id={legendComponentId} item xs={2} />}
        </Grid>
    );
};

export default HistoricalAnalysisChart;
