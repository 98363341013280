/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useMemo, useRef, useState } from "react";
import PaperWrapper from "components/wrappers/PaperWrapper";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import DateView from "components/dates/DateView";
import IotVisionTable from "components/common/IotVisionTable";
import { useNavigate, useParams, useSearchParams, Link } from "react-router-dom";
import { enAU } from "date-fns/locale";
import { CircularProgress, Dialog, DialogContent, Grid, IconButton, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { FaThList } from "react-icons/fa";
import { MdOutlineFileDownload } from "react-icons/md";
import SectionHeading from "components/headings/SectionHeading";
import {
    executeGenerateReportFile,
    executeGetReportAndReportData,
    executeGetReportFile,
} from "api/app-taxi-ranking/taxi-reports.api";
import { styled } from "@mui/material/styles";
import StatusCodes from "enums/status-code.enums";
import { CommonObjectLiteral, PlateSearchReportDetails } from "types/reports/interfaces";
import FullScreenDialog from "components/dialogs/FullScreenDialog";
import { CgArrowsExpandRight } from "react-icons/cg";
import IotVisionButton from "components/common/IotVisionButton";
import IotVisionToolTips from "components/common/IotVisionToolTips";
import { SCOPES } from "enums/scopes.enum";
import ProtectedView from "components/common/ProtectedView";
import { FileStatus, ReportFileFormats, ReportMetaFieldTypes } from "types/reports/enums";
import { GrClose } from "react-icons/gr";
import { setNotifier } from "context/notification";
import { saveFile, getFileName } from "utils/file";
import { PLATE_SEARCH_REPORT_FILE_NAME } from "constants/report.constants";
import { ReportsDataQueryParams } from "types/reports/interfaces/plate-search-report";
import ReportDataFilterPanel from "./reports-data-filter-panel";
import { sortOrder } from "../incidents/IncidentsTable";

const TIMEOUT = 5 * 60 * 1000;

// eslint-disable-next-line no-promise-executor-return, promise/avoid-new
const delay = (time: number) => new Promise((resolve) => setTimeout(resolve, time));

const CustomizedDialogContent = styled(DialogContent)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    minHeight: "200px",
    width: "400px",
}));

const CustomizedCircularProgress = styled(CircularProgress)(() => ({
    color: "#21315b",
}));

const CustomizedTypography = styled(Typography)(() => ({
    color: "#21315b",
}));

const tableColumns: GridColDef[] = [
    {
        field: "metaField1",
        headerName: "License Plate",
        renderCell: (params: GridRenderCellParams) => (
            <IotVisionToolTips title={params.row.metaField1 || ""} arrow>
                <span>{params.row.metaField1}</span>
            </IotVisionToolTips>
        ),
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
    },
    {
        field: "locationName",
        headerName: "Location",
        renderCell: (params: GridRenderCellParams) => (
            <IotVisionToolTips title={params.row.locationName || ""} arrow>
                <span>{params.row.locationName}</span>
            </IotVisionToolTips>
        ),
        flex: 2,
        sortable: false,
        disableColumnMenu: true,
    },
    {
        field: "inTime",
        headerName: "In Time",
        renderCell: (params: GridRenderCellParams) => (
            <IotVisionToolTips
                title={
                    <DateView dateFormat="Pp" options={{ locale: enAU }}>
                        {params.row.metaField2}
                    </DateView>
                }
                arrow
            >
                <span>
                    <DateView dateFormat="Pp" options={{ locale: enAU }}>
                        {params.row.metaField2}
                    </DateView>
                </span>
            </IotVisionToolTips>
        ),
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
    },
    {
        field: "outTime",
        headerName: "Out Time",
        renderCell: (params: GridRenderCellParams) => (
            <IotVisionToolTips 
                title={
                    <DateView dateFormat="Pp" options={{ locale: enAU }}>
                        {params.row.metaData.outTime}
                    </DateView>
                }
                arrow
            >
                <span>
                    <DateView dateFormat="Pp" options={{ locale: enAU }}>
                        {params.row.metaData.outTime}
                    </DateView>
                </span>
            </IotVisionToolTips>
        ),
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
    },
];

const CustomizedSectionHeading = styled(SectionHeading)(() => ({
    background: "transparent !important",
    "& .icon-div": {
        background: "transparent !important",
    },
}));

const CustomizedIotVisionCreateButton = styled(IotVisionButton)(() => ({
    color: "white !important",
    height: "37px",
    width: "92%",
    display: "flex",
    alignItems: "center",
    marginTop: "4px",
}));

const CustomizedTypographyDiv = styled("div")(() => ({
    color: "white !important",
    whiteSpace: "nowrap",
    marginLeft: "10px",
}));


const CustomizedIconWrapper = styled("div")(() => ({
    color: "white !important",
    whiteSpace: "nowrap",
}));

function ReportsDataListTable() {
    const [page, setPage] = useState<number>(0);
    const [rowsCount, setRowsCount] = useState<number>(0);
    const [reportData, setReportData] = React.useState<PlateSearchReportDetails[]>([]);
    const [nextStartKey, setNextStartKey] = useState<string | null>(null);
    const [pageKeyArray, setPageKeyArray] = useState<string[]>([]);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [previousPageIndex, setPreviousPageIndex] = useState<number>(0);
    const reportsDataFilterAbortControllerRef = useRef<AbortController | null>(null);
    const reportDownloadAbortControllerRef = useRef<AbortController | null>(null);
    const reportFileGenerateAbortControllerRef = useRef<AbortController | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { reportId } = useParams<{ reportId: string }>();
    const { pageSize } = useSelector((store) => store.report);
    const { leafLocations } = useSelector((store) => store.newLocation);
    const { appCode } = useSelector((store) => store.auth);
    const capturedSearchParams = useMemo(() => Object.fromEntries([...searchParams]) || {}, [searchParams]);
    const [modalShow, setModalShow] = React.useState(false);
    const [isDownloadModalOpen, setIsDownloadModalOpen] = useState<boolean>(false);
    const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false);
    const [presignedUrl, setPresignedUrl] = useState<string | null>(null);
    const [isReportFetched, setIsReportFetched] = useState<boolean>(false);

    useEffect(() => {
        getReportDataWithPaginationDetails(1, capturedSearchParams);
        setPage(0);
        return () => {
            setReportData([]);
            setRowsCount(0);
            setNextStartKey(null);
            setPresignedUrl(null);
            reportsDataFilterAbortControllerRef.current?.abort();
            reportDownloadAbortControllerRef.current?.abort();
            reportFileGenerateAbortControllerRef.current?.abort();
        };
    }, [capturedSearchParams]);

    useEffect(() => {
        setReportData(mapLocations(reportData));
    }, [leafLocations]);

    const clearPagination = () => {
        setNextStartKey(null);
        setPageKeyArray([]);
    };

    const handleModalShow = (status: boolean) => {
        setModalShow(status);
    };

    const removeArrayDuplicate = (arr: string[]): string[] => {
        return Array.from(new Set(arr));
    };

    const mapLocations = (dataset: PlateSearchReportDetails[]) => {
        return dataset.map((record) => {
            record.locationName = leafLocations.find((leafLocation) => leafLocation.id === record.locationId)?.name;
            return record;
        });
    };

    const handleSearchParams = (params: CommonObjectLiteral) => {
        setSearchParams(params);
    };

    const getReportDataWithPaginationDetails = (pageIndex: number, params: CommonObjectLiteral) => {
        if (pageIndex === 1) {
            executeGetReportDataEndPoint(params);
            setPreviousPageIndex(0);
            nextStartKey && setPageKeyArray(removeArrayDuplicate([...pageKeyArray, nextStartKey]));
        } else {
            if (previousPageIndex === pageIndex) {
                executeGetReportDataEndPoint(params, pageKeyArray[pageIndex - 2]);
            } else {
                executeGetReportDataEndPoint(params, nextStartKey);
                nextStartKey && setPageKeyArray(removeArrayDuplicate([...pageKeyArray, nextStartKey]));
            }
            setPreviousPageIndex(pageIndex - 1);
        }
    };

    async function executeGetReportDataEndPoint(params: CommonObjectLiteral, pageKey?: string | null): Promise<void> {
        try {
            setIsReportFetched(true);
            reportsDataFilterAbortControllerRef.current = new AbortController();
            const { signal } = reportsDataFilterAbortControllerRef.current;
            setTableLoading(true);
            let response = null;
            const { metaField1, fromTime, toTime, ...restParams} = params;
            const modifiedParams = {} as ReportsDataQueryParams;
            if(metaField1) {
                modifiedParams.metaField1Type = ReportMetaFieldTypes.LIST;
                modifiedParams.metaField1 = metaField1;
            }
            if(fromTime && toTime) {
                modifiedParams.metaField2Type = ReportMetaFieldTypes.RANGE;
                modifiedParams.metaField2 = [fromTime, toTime];
            }
            response = await executeGetReportAndReportData(
                {
                    ...modifiedParams,
                    ...restParams,
                    reportId,
                    pageSize,
                    startKey: pageKey,
                },
                { signal },
            );
            if (Array.isArray(response.storage.report.data)) {
                const {
                    storage: {
                        report: { data, total, nextStartKey: startKey },
                    },
                } = response;
                setReportData(mapLocations(data));
                setRowsCount(total);
                setNextStartKey(startKey);
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: Error | any) {
            if (err?.response?.status === StatusCodes.FORBIDDEN) {
                navigate(`/${appCode}/forbidden`, {replace: true});
                return;
            }
            if (err?.response?.status === StatusCodes.NOT_FOUND) {
                navigate(`/${appCode}/plate-search-reports/page-not-found`, {replace: true});
                return;
            }
            console.error(err);
        } finally {
            setIsReportFetched(false);
            setTableLoading(false);
        }
    }

    const handleDownload = async () => {
        const fileName = PLATE_SEARCH_REPORT_FILE_NAME;
        await saveFile(generatePresignedUrl, fileName);
    };

    const handleDownloadWithURL = async () => {
        if (presignedUrl) {
            const fileName = getFileName(presignedUrl);
            await saveFile(presignedUrl, fileName);
        }
    };

    const generatePresignedUrl = async () => {
        setIsDownloadModalOpen(true);
        setIsLoadingDownload(true);
        setPresignedUrl(null);
        try {
            if (reportId) {
                reportFileGenerateAbortControllerRef.current = new AbortController();
                const { signal } = reportFileGenerateAbortControllerRef.current;
                let response = await executeGenerateReportFile({ reportId, format: ReportFileFormats.CSV }, { signal });
                const timer = new Date().getTime();
                while (response?.status !== FileStatus.CREATED && new Date().getTime() - timer < TIMEOUT) {
                    await delay(5000);
                    response = await executeGenerateReportFile({ reportId, format: ReportFileFormats.CSV }, { signal });
                }
                if (response?.status === FileStatus.CREATED) {
                    reportDownloadAbortControllerRef.current = new AbortController();
                    const { signal: downloadSignal } = reportDownloadAbortControllerRef.current;
                    const presignedUrlResponse = await executeGetReportFile(
                        { reportId, format: ReportFileFormats.CSV },
                        { signal: downloadSignal },
                    );
                    if (
                        presignedUrlResponse &&
                        presignedUrlResponse?.fileStatus === FileStatus.CREATED &&
                        presignedUrlResponse?.presignedUrl
                    ) {
                        setPresignedUrl(presignedUrlResponse.presignedUrl);
                        return presignedUrlResponse?.presignedUrl;
                    }
                } else {
                    setNotifier()({
                        type: "error",
                        message: "Error while downloading file",
                        isAutoClose: true,
                    });
                    setIsDownloadModalOpen(false);
                }
            }
        } catch (_err) {
            setIsDownloadModalOpen(false);
        } finally {
            setIsLoadingDownload(false);
        }
    };

    const handleModalClose = () => {
        setPresignedUrl(null);
        setIsDownloadModalOpen(false);
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={7.2} md={8.5} lg={9} xl={9} marginBottom={2}>
                    <CustomizedSectionHeading heading="Details of Report" icon={<FaThList className="icon" />} />
                </Grid>
                <ProtectedView
                    filterScopes={[
                        SCOPES.DOWNLOAD_METADATA_SEARCH_REPORTS,
                    ]}
                    shouldHide
                >
                    <Grid item xs={12} sm={4.8} md={3.5} lg={3} xl={3} marginTop={0.1}>
                        <CustomizedIotVisionCreateButton type="button" onClick={handleDownload}>
                            <CustomizedIconWrapper>
                                <MdOutlineFileDownload className="icon" size={25} />
                            </CustomizedIconWrapper>
                            <CustomizedTypographyDiv>Download CSV</CustomizedTypographyDiv>
                        </CustomizedIotVisionCreateButton>
                    </Grid>
                </ProtectedView>
            </Grid>
            <PaperWrapper>
                <Grid container>
                    <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                        <IconButton className="expand-btn" onClick={() => handleModalShow(true)} disableRipple>
                            <CgArrowsExpandRight />
                        </IconButton>
                    </Grid>
                </Grid>
                <ReportDataFilterPanel
                    searchParams={capturedSearchParams}
                    setSearchParams={handleSearchParams}
                    onClear={() => {
                        clearPagination();
                    }}
                />
                <IotVisionTable
                    sortingOrder={[sortOrder.DESC, sortOrder.ASC]}
                    loading={tableLoading}
                    columns={tableColumns}
                    rows={reportData}
                    sortingMode="server"
                    pageSize={pageSize}
                    page={page}
                    rowCount={rowsCount}
                    onPageChange={(newPage) => {
                        getReportDataWithPaginationDetails(newPage + 1, capturedSearchParams);
                        setPage(newPage);
                    }}
                    rowsPerPageOptions={[pageSize]}
                    hideFooterPagination={false}
                    componentsProps={{
                        pagination: isReportFetched && {
                            sx: {
                                "& button": {
                                    backgroundColor: "transparent",
                                    color: "rgba(0, 0, 0, 0.26)",
                                    pointerEvents: "none",
                                    cursor: "default",
                                },
                            },
                          },
                    }}
                />
            </PaperWrapper>
            <FullScreenDialog
                modalShow={modalShow}
                onClose={handleModalShow}
                childComponent={
                    <PaperWrapper>
                        <Grid container>
                            <Grid item xs={12} sm={7.2} md={8.5} lg={9} xl={9} marginBottom={2}>
                                <CustomizedSectionHeading
                                    heading="Details of Report"
                                    icon={<FaThList className="icon" />} 
                                />
                            </Grid>
                            <ProtectedView
                                filterScopes={[
                                    SCOPES.DOWNLOAD_METADATA_SEARCH_REPORTS,
                                ]}
                                shouldHide
                            >
                                <Grid
                                    item 
                                    xs={12}
                                    sm={4.8} 
                                    md={3.5} 
                                    lg={3} 
                                    xl={3} 
                                    marginTop={0.1}
                                    display="flex"
                                    justifyContent="flex-end"
                                >
                                    <CustomizedIotVisionCreateButton type="button" onClick={handleDownload}>
                                        <CustomizedIconWrapper>
                                            <MdOutlineFileDownload className="icon" size={25} />
                                        </CustomizedIconWrapper>
                                        <CustomizedTypographyDiv>Download CSV</CustomizedTypographyDiv>
                                    </CustomizedIotVisionCreateButton>
                                </Grid>
                            </ProtectedView>
                        </Grid>
                        <ReportDataFilterPanel
                            searchParams={capturedSearchParams}
                            setSearchParams={handleSearchParams}
                            onClear={() => {
                                clearPagination();
                            }}
                        />
                        <IotVisionTable
                            sortingOrder={[sortOrder.DESC, sortOrder.ASC]}
                            loading={tableLoading}
                            columns={tableColumns}
                            rows={reportData}
                            sortingMode="server"
                            pageSize={pageSize}
                            page={page}
                            rowCount={rowsCount}
                            onPageChange={(newPage) => {
                                getReportDataWithPaginationDetails(newPage + 1, capturedSearchParams);
                                setPage(newPage);
                            }}
                            rowsPerPageOptions={[pageSize]}
                            hideFooterPagination={false}
                            componentsProps={{
                                pagination: isReportFetched && {
                                    sx: {
                                        "& button": {
                                            backgroundColor: "transparent",
                                            color: "rgba(0, 0, 0, 0.26)",
                                            pointerEvents: "none",
                                            cursor: "default",
                                        },
                                    },
                                  },
                            }}
                        />
                    </PaperWrapper>
                }
            />
            <Dialog open={isDownloadModalOpen}>
                {!isLoadingDownload && (
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleModalClose}
                        sx={(theme) => ({
                            position: "absolute",
                            width: "30px",
                            height: "30px",
                            right: "10px",
                            [theme.breakpoints.up("md")]: {
                                top: "10px",
                            },
                            [theme.breakpoints.only("md")]: {
                                top: "5px",
                            },
                        })}
                    >
                        <GrClose fontSize={30} fontWeight={800} />
                    </IconButton>
                )}
                <CustomizedDialogContent>
                    {isLoadingDownload && <CustomizedCircularProgress />}
                    {isLoadingDownload && (
                        <CustomizedTypography variant="body">Your report is preparing...</CustomizedTypography>
                    )}
                    {!isLoadingDownload && presignedUrl && (
                        <CustomizedTypography variant="body" textAlign="center">
                            {"If your download doesn't start immediately, "}
                            <Link to="" onClick={handleDownloadWithURL}>
                                click here
                            </Link>{" "}
                            to download.
                        </CustomizedTypography>
                    )}
                </CustomizedDialogContent>
            </Dialog>
        </>
    );
}

export default ReportsDataListTable;
