import { AirQualityStatus, Classification } from "enums/room.enums";
import { ConditionType, Position } from "../enums";
import { AQSensorConfig } from "../types";

const green = "#009E11";
const yellow = "#FDB600";
const orange = "#FD6600";
const red = "#FD0409";
const maroon = "#7C0102";
const harzardous = "#7A2031";
const greenRange = { start: "#009E11", end: "#009E11", mid: "#009E11" };
const yellowRange = { start: "#009E11", end: "#FDB600", mid: "#FDB600" };
const orangeRange = { start: "#009E11", end: "#FDB600", mid: "#FD6600" };
const redRange = { start: "#009E11", end: "#FD0409", mid: "#FD0409" };
const maroonRange = { start: "#009E11", end: "#7C0102", mid: "#7C0102" };
const harzardousRange = { start: "#009E11", end: "#7A2031", mid: "#7A2031" };
const greenRoomRange = { start: "#009E11", end: "#009E11", mid: "#009E11" };
const yellowRoomRange = { start: "#009E11", end: "#FDB600", mid: "#FDB600" };
const orangeRoomRange = { start: "#009E11", end: "#FDB600", mid: "#FD6600" };
const redRoomRange = { start: "#009E11", end: "#FD0409", mid: "#FD0409" };
const maroonRoomRange = { start: "#009E11", end: "#7C0102", mid: "#7C0102" };
const harzardousRoomRange = { start: "#009E11", end: "#7A2031", mid: "#7A2031" };

// rules for aq sensor related thresholds
export const AQ_SENSOR_RULES: AQSensorConfig[] = [
    {
        key: Classification.AQI,
        ruleSets: [
            {
                color: green,
                statusLabel: AirQualityStatus.GOOD,
                rules: [
                    {
                        thresholds: [
                            { value: 0, condition: ConditionType.GT_EQ },
                            { value: 50, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: greenRange,
                roomColorRange: greenRoomRange,
            },
            {
                color: yellow,
                statusLabel: AirQualityStatus.MODERATE,
                rules: [
                    {
                        thresholds: [
                            { value: 51, condition: ConditionType.GT_EQ },
                            { value: 100, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: yellowRange,
                roomColorRange: yellowRoomRange,
            },
            {
                color: orange,
                statusLabel: AirQualityStatus.UNHEALTHY_FOR_SENSITIVE_GROUPS,
                rules: [
                    {
                        thresholds: [
                            { value: 101, condition: ConditionType.GT_EQ },
                            { value: 150, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: orangeRange,
                roomColorRange: orangeRoomRange,
            },
            {
                color: red,
                statusLabel: AirQualityStatus.UNHEALTHY,
                rules: [
                    {
                        thresholds: [
                            { value: 151, condition: ConditionType.GT_EQ },
                            { value: 200, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: redRange,
                roomColorRange: redRoomRange,
            },
            {
                color: maroon,
                statusLabel: AirQualityStatus.VERY_UNHEALTHY,
                rules: [
                    {
                        thresholds: [
                            { value: 201, condition: ConditionType.GT_EQ },
                            { value: 300, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: maroonRange,
                roomColorRange: maroonRoomRange,
            },
            {
                color: harzardous,
                statusLabel: AirQualityStatus.HARZARDOUS,
                rules: [
                    {
                        thresholds: [
                            { value: 301, condition: ConditionType.GT_EQ },
                            { value: 500, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: harzardousRange,
                roomColorRange: harzardousRoomRange,
            },
        ],
        minThreshold: 0,
        maxThreshold: 500,
        measurementType: "AQI",
        subMeasurementType: "(ug/m³)",
    },
    {
        key: Classification.PM100AQI,
        name: "PM10",
        ruleSets: [
            {
                color: green,
                statusLabel: AirQualityStatus.GOOD,
                rules: [
                    {
                        thresholds: [
                            { value: 0, condition: ConditionType.GT_EQ },
                            { value: 50, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: greenRange,
                roomColorRange: greenRoomRange,
            },
            {
                color: yellow,
                statusLabel: AirQualityStatus.MODERATE,
                rules: [
                    {
                        thresholds: [
                            { value: 51, condition: ConditionType.GT_EQ },
                            { value: 100, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: yellowRange,
                roomColorRange: yellowRoomRange,
            },
            {
                color: orange,
                statusLabel: AirQualityStatus.UNHEALTHY_FOR_SENSITIVE_GROUPS,
                rules: [
                    {
                        thresholds: [
                            { value: 101, condition: ConditionType.GT_EQ },
                            { value: 150, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: orangeRange,
                roomColorRange: orangeRoomRange,
            },
            {
                color: red,
                statusLabel: AirQualityStatus.UNHEALTHY,
                rules: [
                    {
                        thresholds: [
                            { value: 151, condition: ConditionType.GT_EQ },
                            { value: 200, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: redRange,
                roomColorRange: redRoomRange,
            },
            {
                color: maroon,
                statusLabel: AirQualityStatus.VERY_UNHEALTHY,
                rules: [
                    {
                        thresholds: [
                            { value: 201, condition: ConditionType.GT_EQ },
                            { value: 300, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: maroonRange,
                roomColorRange: maroonRoomRange,
            },
            {
                color: harzardous,
                statusLabel: AirQualityStatus.HARZARDOUS,
                rules: [
                    {
                        thresholds: [
                            { value: 301, condition: ConditionType.GT_EQ },
                            { value: 500, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: harzardousRange,
                roomColorRange: harzardousRoomRange,
            },
        ],
        minThreshold: 0,
        maxThreshold: 500,
        measurementType: "AQI",
        subMeasurementType: "(ug/m³)",
    },
    {
        key: Classification.PM10AQI,
        name: "PM1",
        ruleSets: [
            {
                color: green,
                statusLabel: AirQualityStatus.GOOD,
                rules: [
                    {
                        thresholds: [
                            { value: 0, condition: ConditionType.GT_EQ },
                            { value: 50, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: greenRange,
                roomColorRange: greenRoomRange,
            },
            {
                color: yellow,
                statusLabel: AirQualityStatus.MODERATE,
                rules: [
                    {
                        thresholds: [
                            { value: 51, condition: ConditionType.GT_EQ },
                            { value: 100, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: yellowRange,
                roomColorRange: yellowRoomRange,
            },
            {
                color: orange,
                statusLabel: AirQualityStatus.UNHEALTHY_FOR_SENSITIVE_GROUPS,
                rules: [
                    {
                        thresholds: [
                            { value: 101, condition: ConditionType.GT_EQ },
                            { value: 150, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: orangeRange,
                roomColorRange: orangeRoomRange,
            },
            {
                color: red,
                statusLabel: AirQualityStatus.UNHEALTHY,
                rules: [
                    {
                        thresholds: [
                            { value: 151, condition: ConditionType.GT_EQ },
                            { value: 200, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: redRange,
                roomColorRange: redRoomRange,
            },
            {
                color: maroon,
                statusLabel: AirQualityStatus.VERY_UNHEALTHY,
                rules: [
                    {
                        thresholds: [
                            { value: 201, condition: ConditionType.GT_EQ },
                            { value: 300, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: maroonRange,
                roomColorRange: maroonRoomRange,
            },
            {
                color: harzardous,
                statusLabel: AirQualityStatus.HARZARDOUS,
                rules: [
                    {
                        thresholds: [
                            { value: 301, condition: ConditionType.GT_EQ },
                            { value: 500, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: harzardousRange,
                roomColorRange: harzardousRoomRange,
            },
        ],
        minThreshold: 0,
        maxThreshold: 500,
        measurementType: "AQI",
        subMeasurementType: "(ug/m³)",
    },
    {
        key: Classification.PM25AQI,
        name: "PM2.5",
        ruleSets: [
            {
                color: green,
                statusLabel: AirQualityStatus.GOOD,
                rules: [
                    {
                        thresholds: [
                            { value: 0, condition: ConditionType.GT_EQ },
                            { value: 50, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: greenRange,
                roomColorRange: greenRoomRange,
            },
            {
                color: yellow,
                statusLabel: AirQualityStatus.MODERATE,
                rules: [
                    {
                        thresholds: [
                            { value: 51, condition: ConditionType.GT_EQ },
                            { value: 100, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: yellowRange,
                roomColorRange: yellowRoomRange,
            },
            {
                color: orange,
                statusLabel: AirQualityStatus.UNHEALTHY_FOR_SENSITIVE_GROUPS,
                rules: [
                    {
                        thresholds: [
                            { value: 101, condition: ConditionType.GT_EQ },
                            { value: 150, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: orangeRange,
                roomColorRange: orangeRoomRange,
            },
            {
                color: red,
                statusLabel: AirQualityStatus.UNHEALTHY,
                rules: [
                    {
                        thresholds: [
                            { value: 151, condition: ConditionType.GT_EQ },
                            { value: 200, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: redRange,
                roomColorRange: redRoomRange,
            },
            {
                color: maroon,
                statusLabel: AirQualityStatus.VERY_UNHEALTHY,
                rules: [
                    {
                        thresholds: [
                            { value: 201, condition: ConditionType.GT_EQ },
                            { value: 300, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: maroonRange,
                roomColorRange: maroonRoomRange,
            },
            {
                color: harzardous,
                statusLabel: AirQualityStatus.HARZARDOUS,
                rules: [
                    {
                        thresholds: [
                            { value: 301, condition: ConditionType.GT_EQ },
                            { value: 500, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: harzardousRange,
                roomColorRange: harzardousRoomRange,
            },
        ],
        minThreshold: 0,
        maxThreshold: 500,
        measurementType: "AQI",
        subMeasurementType: "(ug/m³)",
    },
    {
        key: Classification.TEMP,
        name: "Temp",
        ruleSets: [
            {
                color: green,
                rules: [
                    {
                        thresholds: [
                            { value: 21.5, condition: ConditionType.GT_EQ },
                            { value: 24.5, condition: ConditionType.LT },
                        ],
                    },
                ],
                colorRange: greenRange,
                roomColorRange: greenRoomRange,
            },
            {
                color: yellow,
                rules: [
                    {
                        thresholds: [
                            { value: 19.5, condition: ConditionType.GT_EQ },
                            { value: 21.5, condition: ConditionType.LT },
                        ],
                    },
                    {
                        thresholds: [
                            { value: 24.5, condition: ConditionType.GT_EQ },
                            { value: 26.5, condition: ConditionType.LT },
                        ],
                    },
                ],
                colorRange: yellowRange,
                roomColorRange: yellowRoomRange,
            },
            {
                color: orange,
                rules: [
                    {
                        thresholds: [
                            { value: 17.5, condition: ConditionType.GT_EQ },
                            { value: 19.5, condition: ConditionType.LT },
                        ],
                    },
                    {
                        thresholds: [
                            { value: 26.5, condition: ConditionType.GT_EQ },
                            { value: 28.5, condition: ConditionType.LT },
                        ],
                    },
                ],
                colorRange: orangeRange,
                roomColorRange: orangeRoomRange,
            },
            {
                color: red,
                rules: [
                    {
                        thresholds: [
                            { value: 15.5, condition: ConditionType.GT_EQ },
                            { value: 17.5, condition: ConditionType.LT },
                        ],
                    },
                    {
                        thresholds: [
                            { value: 28.5, condition: ConditionType.GT_EQ },
                            { value: 30.5, condition: ConditionType.LT },
                        ],
                    },
                ],
                colorRange: redRange,
                roomColorRange: redRoomRange,
            },
            {
                color: maroon,
                rules: [
                    {
                        thresholds: [
                            { value: 13.5, condition: ConditionType.GT_EQ },
                            { value: 15.5, condition: ConditionType.LT },
                        ],
                    },
                    {
                        thresholds: [
                            { value: 30.5, condition: ConditionType.GT_EQ },
                            { value: 35.5, condition: ConditionType.LT },
                        ],
                    },
                ],
                colorRange: maroonRange,
                roomColorRange: maroonRoomRange,
            },
            {
                color: harzardous,
                rules: [
                    {
                        thresholds: [
                            { value: 0, condition: ConditionType.GT_EQ },
                            { value: 13.5, condition: ConditionType.LT },
                        ],
                    },
                    {
                        thresholds: [
                            { value: 35.5, condition: ConditionType.GT_EQ },
                            { value: Number.MAX_VALUE, condition: ConditionType.LT },
                        ],
                    },
                ],
                colorRange: harzardousRange,
                roomColorRange: harzardousRoomRange,
            },
        ],
        minThreshold: 0,
        maxThreshold: 50,
        symbol: { value: "°C", position: Position.RIGHT },
        measurementType: "°C",
        roomPageName: "Temperature",
    },
    {
        key: Classification.RH,
        name: "RH",
        ruleSets: [
            {
                color: green,
                rules: [
                    {
                        thresholds: [
                            { value: 39.5, condition: ConditionType.GT_EQ },
                            { value: 60.5, condition: ConditionType.LT },
                        ],
                    },
                ],
                colorRange: greenRange,
                roomColorRange: greenRoomRange,
            },
            {
                color: yellow,
                rules: [
                    {
                        thresholds: [
                            { value: 34.5, condition: ConditionType.GT_EQ },
                            { value: 39.5, condition: ConditionType.LT },
                        ],
                    },
                    {
                        thresholds: [
                            { value: 60.5, condition: ConditionType.GT_EQ },
                            { value: 65.5, condition: ConditionType.LT },
                        ],
                    },
                ],
                colorRange: yellowRange,
                roomColorRange: yellowRoomRange,
            },
            {
                color: orange,
                rules: [
                    {
                        thresholds: [
                            { value: 29.5, condition: ConditionType.GT_EQ },
                            { value: 34.5, condition: ConditionType.LT },
                        ],
                    },
                    {
                        thresholds: [
                            { value: 65.5, condition: ConditionType.GT_EQ },
                            { value: 70.5, condition: ConditionType.LT },
                        ],
                    },
                ],
                colorRange: orangeRange,
            },
            {
                color: red,
                rules: [
                    {
                        thresholds: [
                            { value: 24.5, condition: ConditionType.GT_EQ },
                            { value: 29.5, condition: ConditionType.LT },
                        ],
                    },
                    {
                        thresholds: [
                            { value: 70.5, condition: ConditionType.GT_EQ },
                            { value: 75.5, condition: ConditionType.LT },
                        ],
                    },
                ],
                colorRange: redRange,
                roomColorRange: redRoomRange,
            },
            {
                color: maroon,
                rules: [
                    {
                        thresholds: [
                            { value: 19.5, condition: ConditionType.GT_EQ },
                            { value: 24.5, condition: ConditionType.LT },
                        ],
                    },
                    {
                        thresholds: [
                            { value: 76.5, condition: ConditionType.GT_EQ },
                            { value: 80.5, condition: ConditionType.LT },
                        ],
                    },
                ],
                colorRange: maroonRange,
                roomColorRange: maroonRoomRange,
            },
            {
                color: harzardous,
                rules: [
                    {
                        thresholds: [
                            { value: 0, condition: ConditionType.GT_EQ },
                            { value: 19.5, condition: ConditionType.LT },
                        ],
                    },
                    {
                        thresholds: [
                            { value: 80.5, condition: ConditionType.GT_EQ },
                            { value: 100, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: harzardousRange,
                roomColorRange: harzardousRoomRange,
            },
        ],
        minThreshold: 0,
        maxThreshold: 100,
        symbol: { value: "%", position: Position.RIGHT },
        measurementType: "%",
        roomPageName: "Humidity",
    },
    {
        key: Classification.CO2,
        name: "eCO2",
        ruleSets: [
            {
                color: green,
                rules: [
                    {
                        thresholds: [
                            { value: 0, condition: ConditionType.GT_EQ },
                            { value: 400.5, condition: ConditionType.LT },
                        ],
                    },
                ],
                colorRange: greenRange,
                roomColorRange: greenRoomRange,
            },
            {
                color: yellow,
                rules: [
                    {
                        thresholds: [
                            { value: 400.5, condition: ConditionType.GT_EQ },
                            { value: 600.5, condition: ConditionType.LT },
                        ],
                    },
                ],
                colorRange: yellowRange,
                roomColorRange: yellowRoomRange,
            },
            {
                color: orange,
                rules: [
                    {
                        thresholds: [
                            { value: 600.5, condition: ConditionType.GT_EQ },
                            { value: 800.5, condition: ConditionType.LT },
                        ],
                    },
                ],
                colorRange: orangeRange,
                roomColorRange: orangeRoomRange,
            },
            {
                color: red,
                rules: [
                    {
                        thresholds: [
                            { value: 800.5, condition: ConditionType.GT_EQ },
                            { value: 1500.5, condition: ConditionType.LT },
                        ],
                    },
                ],
                colorRange: redRange,
                roomColorRange: redRoomRange,
            },
            {
                color: maroon,
                rules: [
                    {
                        thresholds: [
                            { value: 1500.5, condition: ConditionType.GT_EQ },
                            { value: 5000.5, condition: ConditionType.LT },
                        ],
                    },
                ],
                colorRange: maroonRange,
                roomColorRange: maroonRoomRange,
            },
            {
                color: harzardous,
                rules: [
                    {
                        thresholds: [
                            { value: 5000.5, condition: ConditionType.GT_EQ },
                            { value: 10000.5, condition: ConditionType.LT },
                        ],
                    },
                ],
                colorRange: harzardousRange,
                roomColorRange: harzardousRoomRange,
            },
        ],
        minThreshold: 0,
        maxThreshold: 10000,
        measurementType: "ppm",
        roomPageName: "CO2 Estimate",
    },
    {
        key: Classification.VOCAQI,
        ruleSets: [
            {
                color: green,
                statusLabel: AirQualityStatus.GOOD,
                rules: [
                    {
                        thresholds: [
                            { value: 0, condition: ConditionType.GT_EQ },
                            { value: 50, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: greenRange,
                roomColorRange: greenRoomRange,
            },
            {
                color: yellow,
                statusLabel: AirQualityStatus.MODERATE,
                rules: [
                    {
                        thresholds: [
                            { value: 51, condition: ConditionType.GT_EQ },
                            { value: 100, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: yellowRange,
                roomColorRange: yellowRoomRange,
            },
            {
                color: orange,
                statusLabel: AirQualityStatus.UNHEALTHY_FOR_SENSITIVE_GROUPS,
                rules: [
                    {
                        thresholds: [
                            { value: 101, condition: ConditionType.GT_EQ },
                            { value: 150, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: orangeRange,
                roomColorRange: orangeRoomRange,
            },
            {
                color: red,
                statusLabel: AirQualityStatus.UNHEALTHY,
                rules: [
                    {
                        thresholds: [
                            { value: 151, condition: ConditionType.GT_EQ },
                            { value: 200, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: redRange,
                roomColorRange: redRoomRange,
            },
            {
                color: maroon,
                statusLabel: AirQualityStatus.VERY_UNHEALTHY,
                rules: [
                    {
                        thresholds: [
                            { value: 201, condition: ConditionType.GT_EQ },
                            { value: 300, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: maroonRange,
                roomColorRange: maroonRoomRange,
            },
            {
                color: harzardous,
                statusLabel: AirQualityStatus.HARZARDOUS,
                rules: [
                    {
                        thresholds: [
                            { value: 301, condition: ConditionType.GT_EQ },
                            { value: 500, condition: ConditionType.LT_EQ },
                        ],
                    },
                ],
                colorRange: harzardousRange,
                roomColorRange: harzardousRoomRange,
            },
        ],
        minThreshold: 0,
        maxThreshold: 500,
        measurementType: "AQI",
        subMeasurementType: "(ug/m³)",
        roomPageName: "VOC",
    },
    {
        key: Classification.ATM,
        name: "Air Pressure",
        ruleSets: [
            {
                color: green,
                rules: [
                    {
                        thresholds: [
                            { value: 999.5, condition: ConditionType.GT_EQ },
                            { value: 1030.5, condition: ConditionType.LT },
                        ],
                    },
                ],
                colorRange: greenRange,
                roomColorRange: greenRoomRange,
            },
            {
                color: yellow,
                rules: [
                    {
                        thresholds: [
                            { value: 979.5, condition: ConditionType.GT_EQ },
                            { value: 999.5, condition: ConditionType.LT },
                        ],
                    },
                    {
                        thresholds: [
                            { value: 1030.5, condition: ConditionType.GT_EQ },
                            { value: 1040.5, condition: ConditionType.LT },
                        ],
                    },
                ],
                colorRange: yellowRange,
                roomColorRange: yellowRoomRange,
            },
            {
                color: orange,
                rules: [
                    {
                        thresholds: [
                            { value: 959.5, condition: ConditionType.GT_EQ },
                            { value: 979.5, condition: ConditionType.LT },
                        ],
                    },
                    {
                        thresholds: [
                            { value: 1040.5, condition: ConditionType.GT_EQ },
                            { value: 1050.5, condition: ConditionType.LT },
                        ],
                    },
                ],
                colorRange: orangeRange,
            },
            {
                color: red,
                rules: [
                    {
                        thresholds: [
                            { value: 939.5, condition: ConditionType.GT_EQ },
                            { value: 959.5, condition: ConditionType.LT },
                        ],
                    },
                    {
                        thresholds: [
                            { value: 1050.5, condition: ConditionType.GT_EQ },
                            { value: 1060.5, condition: ConditionType.LT },
                        ],
                    },
                ],
                colorRange: redRange,
                roomColorRange: redRoomRange,
            },
            {
                color: maroon,
                rules: [
                    {
                        thresholds: [
                            { value: 899.5, condition: ConditionType.GT_EQ },
                            { value: 939.5, condition: ConditionType.LT },
                        ],
                    },
                    {
                        thresholds: [
                            { value: 1060.5, condition: ConditionType.GT_EQ },
                            { value: 1110.5, condition: ConditionType.LT },
                        ],
                    },
                ],
                colorRange: maroonRange,
                roomColorRange: maroonRoomRange,
            },
        ],
        minThreshold: 900,
        maxThreshold: 1110,
        measurementType: "hPa",
    },
];

export default AQ_SENSOR_RULES;
