import React from "react";
import Grid from "@mui/material/Grid";
import { LoadingWrapper } from "components/wrappers/LoadingWrapper";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { styled } from "@mui/styles";

const CountWrapper = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    "@media (max-width: 1280px)": {
        flexDirection: "column",
        gap: "10px",
        height: "100%",
        justifyContent: "center",
    },
}));

const FigureWrapper = styled(Box)(() => ({
    gap: "10px",
}));

const OuterWrapper = styled(Grid)(() => ({
    display: "flex",
}));

const Counter = styled(Box)(() => ({
    padding: "0% 2%",
    lineHeight: "55px",
    fontWeight: 600,
    fontSize: "45px",
    color: "#21315b",
    "@media (max-width: 1440px)": {
        fontSize: "35px",
        lineHeight: "45px",
    },
}));

interface CountUpdatingCardProps {
    title: string;
    count: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon: any;
    isLoading?: boolean;
    onUpdate: () => void;
    isCountEditingActive?: boolean;
}
const CountUpdatingCard: React.FC<CountUpdatingCardProps> = ({
    title,
    count,
    icon,
    isLoading = false,
    onUpdate,
    isCountEditingActive = false,
}) => {
    return (
        <OuterWrapper item className="stat-card" sx={{ flexDirection: "column !important" }}>
            <Box className="title-wrapper">
                <h4>{title}</h4>
            </Box>
            <LoadingWrapper isLoading={isLoading} className="loading-wrapper">
                <CountWrapper>
                    <FigureWrapper
                        sx={{ width: isCountEditingActive ? "fit-content" : "100%" }}
                        className="figure-wrapper"
                    >
                        <Box sx={{ marginRight: "5px !important" }} className="Icon-wrapper">
                            {icon}
                        </Box>
                        <Counter
                            sx={{
                                border: isCountEditingActive ? "2px solid" : "",
                            }}
                        >
                            {count}
                        </Counter>
                    </FigureWrapper>
                    {isCountEditingActive && (
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "#f4b921",
                                borderRadius: "10px",
                                fontWeight: 600,
                                textTransform: "capitalize",
                                "&:hover": {
                                    backgroundColor: "#f4b921",
                                },
                            }}
                            onClick={onUpdate}
                            color="warning"
                        >
                            Adjust
                        </Button>
                    )}
                </CountWrapper>
            </LoadingWrapper>
        </OuterWrapper>
    );
};
export default CountUpdatingCard;
