/* eslint-disable @typescript-eslint/no-explicit-any */
import "video.js/dist/video-js.css";
import { Dialog, IconButton, styled } from "@mui/material";
import { GrClose } from "react-icons/gr";
import React, { useCallback, useRef } from "react";
import videojs from "video.js";
import { CommonObjectLiteral } from "types";
import "./plugins/plugins.scss";
import { EXPORTING_IMAGE_TYPE } from "constants/taxirank-incidents.constants";
import { MILLISECONDS_TO_SECONDS } from "constants/time.constants";
import { convertMilliseconds } from "utils/common";
import ZoomPlugin, { ZoomPluginOptions } from "./plugins/zoom-video/zoom-plugin";
import SnapshotDownloadPlugin from "./plugins/download-plugin/download-snapshot";

const SUPPORT_MEDIA_TYPE = "video/mp4";

videojs.registerPlugin("zoomPlugin", ZoomPlugin);
videojs.registerPlugin("snapshotDownloadPlugin", SnapshotDownloadPlugin);

export interface FileNameOptions {
    gpuId?: string;
    incidentId?: number;
    useCapturedTime: boolean;
}

export interface VideoPlayerOptions {
    isActiveDownload: boolean;
    fileNameOptions?: FileNameOptions;
}

export interface VideoPlayerProps {
    isOpen: boolean;
    filePresignedURL: string;
    options?: VideoPlayerOptions;
    onClose: () => void;
}

const CustomizedIconButton = styled(IconButton)(({ theme }) => ({
    position: "absolute",
    zIndex: 999,
    width: "26px",
    height: "26px",
    right: "8px",
    top: "5px",
    backgroundColor: "rgba(169,169,169, 0.5)",
    "&:hover": {
        backgroundColor: "grey",
    },
    "& > svg > path": {
        stroke: "white !important",
        strokeWidth: 3,
    },
    [theme.breakpoints.up("md")]: {
        top: "10px",
    },
    [theme.breakpoints.only("md")]: {
        top: "5px",
        width: "22px",
        height: "22px",
    },
}));

const initialOptions: CommonObjectLiteral = {
    controls: true,
    height: "100%",
    width: "100%",
    preload: "auto",
    userActions: {
        click: false,
        hotkeys: true,
    },
    enableSmoothSeeking: true,
    disablePictureInPicture: true,
    enableDocumentPictureInPicture: false,
    fluid: true,
    plugins: {},
    responsive: true,
    controlBar: {
        volumePanel: {
            inline: true,
        },
    },
};

const initialZoomPluginOptions: ZoomPluginOptions = {
    zoom: 1,
    moveX: 0,
    moveY: 0,
    showZoom: true,
    showMove: true,
    gestureHandler: true,
};

const VideoPlayer = ({ isOpen, filePresignedURL, onClose, options }: VideoPlayerProps) => {
    const player = useRef<any>(null);

    const refCallback = useCallback((node) => {
        videojs.log.error = (error: CommonObjectLiteral) => {
            videojs.log.warn(error);
        };
        if (node !== null) {
            if (!player.current) {
                node.setAttribute("crossOrigin", "anonymous");
                player.current = videojs(node, {
                    ...initialOptions,
                    ...options,
                    sources: [{ src: filePresignedURL, type: SUPPORT_MEDIA_TYPE }],
                });
            } else {
                player.current.sources = [{ src: filePresignedURL, type: SUPPORT_MEDIA_TYPE }];
            }
            if (!player.current?.hasPlugin("ZoomPlugin")) player.current?.zoomPlugin(initialZoomPluginOptions);
            if (!player.current?.hasPlugin("SnapshotDownloadPlugin"))
                player.current?.snapshotDownloadPlugin({ ...options, callback: downloadSnapshot });
            handleAutoplay();
            removePiPButton();
        }
    }, []);

    const handleAutoplay = () => {
        if (player.current) {
            player.current?.ready(() => {
                player.current?.play()?.catch((error: CommonObjectLiteral) => {
                    videojs.log.warn(error);
                });
            });
        }
    };

    const removePiPButton = () => {
        if (player.current) {
            const controlBar = player.current.getChild("controlBar");
            const pipButton = controlBar?.getChild("pictureInPictureToggle");
            if (pipButton) controlBar?.removeChild(pipButton);
        }
    };

    const handleClose = () => {
        if (player.current) {
            player.current.reset();
            player.current.dispose();
            player.current = null;
        }
        onClose();
    };

    const downloadSnapshot = () => {
        const videoElement = player.current.el().querySelector("video");
        const canvas = document.createElement("canvas");
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;
        const context = canvas.getContext("2d");
        if (context) {
            context.drawImage(videoElement, 0, 0, videoElement.videoWidth, videoElement.videoHeight);
        }
        canvas.toBlob((blob: Blob | null) => {
            if (blob) {
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = generateFileName((player.current?.currentTime() || 0) * MILLISECONDS_TO_SECONDS);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
            }
        }, EXPORTING_IMAGE_TYPE);
    };

    const generateFileName = (time: number) => {
        const { hours, minutes, seconds } = convertMilliseconds(time);
        const { gpuId, incidentId, useCapturedTime } = options?.fileNameOptions || {};
        let fileName = "";
        if (gpuId) fileName += `${gpuId}-`;
        if (incidentId) fileName += `${incidentId}`;
        if (useCapturedTime)
            fileName += `${hours ? `${hours}h_` : ""}${minutes || hours ? `${minutes}m_` : ""}${seconds}s.png`;
        return fileName;
    };

    return (
        <Dialog
            open={isOpen && !!filePresignedURL}
            componentsProps={{
                backdrop: {
                    style: {
                        backgroundColor: "rgb(0 0 0 / 75%)",
                    },
                },
            }}
            PaperProps={{
                sx: {
                    maxWidth: "72% !important",
                    minWidth: "72% !important",
                    backgroundColor: "black",
                    overflowY: "inherit",
                },
            }}
        >
            <CustomizedIconButton edge="start" color="inherit" onClick={handleClose}>
                <GrClose fontSize={30} fontWeight={600} color="white" />
            </CustomizedIconButton>
            <div
                style={{
                    width: "100%",
                    height: "0px",
                    position: "relative",
                    overflow: "hidden",
                }}
                onContextMenu={(e) => e.preventDefault()}
                data-vjs-player
            >
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video
                    ref={refCallback}
                    autoPlay
                    onLoadedData={handleAutoplay}
                    id="video-player"
                    className="video-js vjs-big-play-button"
                />
            </div>
        </Dialog>
    );
};

export default React.memo(VideoPlayer);
