import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { NavigateNotFound } from "constants/paths.constants";
import home from "./home";
import notFound from "./not-found";
import incidents from "./incident";

export const appPrefix = "park-and-ride";
export const directUrl = "/pnr";
export const appDefaultRoute = `/${appPrefix}/home`;
export enum pagePath {
    INCIDENTS = "incidents",
}

const root = [
    {
        path: appPrefix,
        element: <Outlet />,
        children: [
            { path: "", element: <Navigate to="home" replace /> },
            { path: "*", element: <Navigate to={NavigateNotFound} replace /> },
            { ...home },
            { ...incidents },
            { ...notFound },
        ],
    },
    {
        path: directUrl,
        element: <Navigate to={appDefaultRoute} replace />,
    },
];

export default root;
