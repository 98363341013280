import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import React from "react";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
/* eslint @typescript-eslint/no-unused-vars: ["off"] */
interface IIotVisionLink {
    linkProps?: any;
    linkText?: any;
}
const CustomizedLink = styled(Link)(
    ({ theme }) => `
      color: ${theme.palette.primary.main};
      font-size: 14px;
`,
);

const IotVisionLink: React.FC<IIotVisionLink> = ({ linkProps, linkText }) => {
    return <CustomizedLink {...linkProps}>{linkText}</CustomizedLink>;
};

export default IotVisionLink;
