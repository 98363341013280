import { Navigate } from "react-router-dom";
import Layout from "layouts/app-dept-air-quality";
import { ProtectedLayout } from "layouts/common/general";
import Dashboard from "./dashboard/Dashboard";
import Room from "./room/Room";
import Alert from "./alert/Alerts";
import notFound from "./not-found";
import RuleAlert from "./rule-alert/RuleAlert";

export const appPrefix = "doe-aq-demo";
export const directUrl = "/doe";
export const appDefaultRoute = `/${appPrefix}`;
export enum pagePath {
    Home = ":regionId",
    School = "school/:schoolId",
    Room = "school/:schoolId/room/:roomId",
    Incident = "incident",
    RulesAndAlerts = "rules-and-alerts",
}

const root = [
    {
        path: appDefaultRoute,
        element: (
            <ProtectedLayout>
                <Layout />
            </ProtectedLayout>
        ),
        children: [
            { path: "", element: <Dashboard /> },
            { path: pagePath.Home, element: <Dashboard /> },
            { path: pagePath.School, element: <Dashboard /> },
            { path: pagePath.Room, element: <Room /> },
            { path: pagePath.Incident, element: <Alert /> },
            { path: pagePath.RulesAndAlerts, element: <RuleAlert /> },
            { ...notFound },
        ],
    },
    {
        path: directUrl,
        element: <Navigate to={appDefaultRoute} replace />,
    },
];
export default root;
