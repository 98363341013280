/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import DrawerCommon from "components/drawers/DrawerCommon";
import DrawerEventsMain from "components/events/DrawerEventsMain";
import vizialLogo from "assets/images/vizialLogo.png";
import { useAuth0 } from "@auth0/auth0-react";
import { IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { Logout } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { IoPersonCircleOutline } from "react-icons/io5";
import { useSelector } from "react-redux";

const NavbarParkAndRide = () => {
    const { appCode } = useSelector((state) => state.auth);
    const [show, setShow] = useState(false);
    const location = useLocation();
    const { logout } = useAuth0();

    const handleClose = () => setShow(false);

    const handleProfileClose = () => {
        setAnchorEl(null);
    };

    const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLAnchorElement) | null>(null);
    const profileOpen = Boolean(anchorEl);
    const handleProfileClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };
    function isActive(paths: string[]) {
        const { pathname } = location;
        const hasPath = paths.some((v) => pathname.includes(v));
        return hasPath ? " active " : "";
    }

    return (
        <>
            <div className="main-navbar">
                <Link className="navbar-brand" to="/demo-park-and-ride/home">
                    <img alt="" src={vizialLogo} height="25" className="d-inline-block align-top" />
                </Link>
                <div className="navbar-collapse">
                    <div className="navbar-nav">
                        <div className="non-mobile">
                            <Link to={`/${appCode}/home`} className={`link ${isActive(["home"])}`}>
                                Home
                            </Link>
                        </div>
                        <div className="non-mobile">
                            <Link to={`/${appCode}/incidents`} className={`link ${isActive(["incidents"])}`}>
                                Incidents
                            </Link>
                        </div>

                        <Link
                            to="#"
                            className="event-link"
                            style={{
                                borderLeft: "1px solid #E0E0E0",
                                paddingRight: "20px",
                            }}
                            onClick={(e) => handleProfileClick(e)}
                        >
                            <IoPersonCircleOutline />
                        </Link>

                        <IconButton size="large" edge="start" color="inherit" aria-label="menu" className="menu-btn">
                            <MenuIcon />
                        </IconButton>
                    </div>
                </div>
            </div>
            <Menu
                anchorEl={anchorEl}
                open={profileOpen}
                onClose={handleProfileClose}
                onClick={handleProfileClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <MenuItem
                    onClick={() => {
                        logout({
                            returnTo: window.location.origin,
                        });
                    }}
                >
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
            <DrawerCommon show={show} handleClose={handleClose} title="Events">
                <DrawerEventsMain />
            </DrawerCommon>
        </>
    );
};

export default NavbarParkAndRide;
