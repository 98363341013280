import IncidentLayout from "layouts/app-taxi-ranking/incidents";
import Incidents from "pages/app-taxi-ranking/incidents/incidents";
import IncidentsLocation from "pages/app-taxi-ranking/incidents-location";
import { ProtectedLayout } from "layouts/common/general";
import WithScopes from "components/common/WithScopes";
import { SCOPES } from "enums/scopes.enum";

const root = {
    path: "incidents",
    element: (
        <ProtectedLayout>
            <IncidentLayout />
        </ProtectedLayout>
    ),
    children: [
        {
            path: "",
            element: (
                <WithScopes filterScopes={[SCOPES.VIEW_INCIDENTS_PAGE]}>
                    <Incidents />
                </WithScopes>
            ),
        },
        {
            path: ":groupId",
            element: (
                <WithScopes filterScopes={[SCOPES.VIEW_INCIDENTS_PAGE]}>
                    <Incidents />
                </WithScopes>
            ),
        },
        {
            path: "location/:locationId",
            element: (
                <WithScopes filterScopes={[SCOPES.VIEW_INCIDENTS_PAGE]}>
                    <IncidentsLocation />
                </WithScopes>
            ),
        },
    ],
};
export default root;
