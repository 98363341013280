import { memo } from "react";
import { useSelector } from "react-redux";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { GoogleKey } from "configs";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
const MainMap = (props: any) => {
    const { containerStyle, children } = props;
    const { mapFocusCenter, mapFocusZoom } = useSelector((state) => state.map);
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: GoogleKey,
    });

    const renderMap = () => {
        return (
            <div className="map-container">
                <GoogleMap mapContainerStyle={containerStyle} center={mapFocusCenter} zoom={mapFocusZoom}>
                    {children}
                </GoogleMap>
            </div>
        );
    };

    if (loadError) {
        return <h3>Map cannot be loaded right now, Please refresh the page.</h3>;
    }

    return isLoaded ? renderMap() : <h3>Map is loading...</h3>;
};

export default memo(MainMap);
