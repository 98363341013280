import React, { useMemo, useState } from "react";
import { Chip, ClickAwayListener, TextFieldProps, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import IotVisionTextFiled from "./IotVisionTextFiled";

const chipAddingKeyList = ["Enter", ",", ";", " "];

const CustomizedIconButton = styled(IconButton)(() => ({
    visibility: "hidden",
}));

const CustomizedChip = styled(Chip)(() => ({
    height: "100%",
    marginRight: 2,
}));

const EndAdornmentClose = styled("div")`
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    position: absolute;
`;

interface IotVisionChipInputProps {
    chips: string[];
    onChipAdd: (chip: string[]) => void;
    onChipDelete: (chip: string) => void;
    onClearAll: () => void;
    shouldModifyInputUpperCase?: boolean;
}

const IotVisionChipInput: React.FC<IotVisionChipInputProps & TextFieldProps> = ({
    chips,
    onChipAdd,
    onChipDelete,
    onClearAll,
    shouldModifyInputUpperCase = false,
    ...rest
}) => {
    const CustomizedIotVisionTextField = useMemo(
        () =>
            styled(IotVisionTextFiled)(
                () => `
    max-width: 100%;
    
    .MuiInputBase-root {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      row-gap: 5px;
      padding-top: 5px;
      padding-right: 30px;
      padding-bottom: 5px;
      padding-left: 10px;
    
      input {
        min-width: 30px;
        width: auto;
        flex-grow: 1;
        text-overflow: ellipsis;
        padding: 3.5px 4px;
        align-self: center;
        ${shouldModifyInputUpperCase ? "text-transform: uppercase;" : ""}
      }
    }
    
    &:hover .Chip-input-clear-button {
        visibility: visible;
    }
    
    & .Mui-focused .Chip-input-clear-button {
        visibility: visible;
    }
    `,
            ),
        [shouldModifyInputUpperCase],
    );

    const [inputValue, setInputValue] = useState<string>("");

    const handleInputChange = (event: { target: { value: string } }) => {
        setInputValue(event.target.value);
    };

    const handleChipAdd = () => {
        if (inputValue.trim() === "") return;
        const finalizedInputList: string[] = [];
        const separatedInputList = inputValue.split(/,| |;/);
        let inputList: string[] = separatedInputList.filter(
            (input: string, index: number) => input && separatedInputList.indexOf(input) === index,
        );
        if (shouldModifyInputUpperCase) {
            inputList = inputList.map((input: string) => input.toUpperCase());
        }
        inputList.forEach((input: string) => {
            if (!chips.includes(input)) {
                finalizedInputList.push(input);
            }
        });
        onChipAdd(finalizedInputList);
        setInputValue("");
    };

    const handleClickAway = () => {
        handleChipAdd();
    };

    const handleClearAll = () => {
        setInputValue("");
        onClearAll();
    };

    const handleKeyDownEvents = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (chipAddingKeyList.includes(event.key)) {
            event.preventDefault();
            handleChipAdd();
        }
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                <CustomizedIotVisionTextField
                    variant="outlined"
                    value={inputValue}
                    fullWidth
                    onChange={handleInputChange}
                    onKeyDown={(event) => handleKeyDownEvents(event)}
                    InputProps={{
                        startAdornment:
                            chips.length > 0
                                ? chips.map((chip, index) => {
                                      const key = `chip-${index}`;
                                      return (
                                          <CustomizedChip
                                              key={key}
                                              label={chip}
                                              onDelete={() => onChipDelete(chip)}
                                              deleteIcon={
                                                  <IconButton size="small">
                                                      <CloseIcon fontSize="small" />
                                                  </IconButton>
                                              }
                                          />
                                      );
                                  })
                                : null,
                        endAdornment:
                            chips.length > 0 ? (
                                <EndAdornmentClose>
                                    <CustomizedIconButton
                                        className="Chip-input-clear-button"
                                        size="small"
                                        onClick={handleClearAll}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </CustomizedIconButton>
                                </EndAdornmentClose>
                            ) : null,
                    }}
                    {...rest}
                />
            </div>
        </ClickAwayListener>
    );
};

export default IotVisionChipInput;
