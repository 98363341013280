import { configureStore, Action } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import { ThunkAction } from "redux-thunk";
import { useDispatch } from "react-redux";
import logger from "redux-logger";

import rootReducer, { ApplicationState } from "reducers";
import persistedReducer from "reducers/persist.reducer";
import { isProduction } from "configs";

const store = configureStore({
    reducer: persistedReducer(rootReducer),
    middleware: (getDefaultMiddleware) => {
        const defaultMiddleWares = getDefaultMiddleware({
            thunk: true,
            serializableCheck: false,
        });
        if (isProduction) {
            defaultMiddleWares.concat(logger);
        }
        return defaultMiddleWares;
    },
    devTools: isProduction,
});

const persistor = persistStore(store);

export const useAppDispatch = () => useDispatch();

export { store, persistor };

export type AppThunk = ThunkAction<void, ApplicationState, unknown, Action>;
export type AppDispatch = typeof store.dispatch;
