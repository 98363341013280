import { Box, Grid } from "@mui/material";
import React from "react";
import AlertsFilter from "./filter/AlertsFilter";
import AlertHeader from "./header/AlertHeader";
import AlertsTable from "./table/AlertsTable";

interface RoomAlertsProps {
    locationId?: string;
}

const RoomAlerts: React.FC<RoomAlertsProps> = ({ locationId }) => {
    return (
        <Grid container>
            <Box
                sx={(theme) => ({
                    padding: "20px",
                    borderRadius: "20px",
                    width: "100%",
                    height: "420px",
                    [theme.breakpoints.up("lg")]: {
                        height: "407px",
                    },
                    background: "#FFFFFF",
                    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.03)",
                })}
            >
                <Grid item xs={3} marginBottom={1}>
                    <AlertHeader />
                </Grid>
                <Grid item xs={12}>
                    <AlertsFilter />
                </Grid>
                <Grid item xs={12}>
                    <AlertsTable locationId={locationId} />
                </Grid>
            </Box>
        </Grid>
    );
};

export default RoomAlerts;
