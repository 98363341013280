import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import * as dotenv from "dotenv";

import App from "App";
import { persistor, store } from "store";

const root = document.getElementById("root");

dotenv.config();

render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>,
    root,
);
