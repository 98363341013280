import { Navigate } from "react-router-dom";
import Layout from "layouts/app-air-quality";
import { ProtectedLayout } from "layouts/common/general";
import Dashboard from "./dashboard/Dashboard";
import Room from "./room/Room";
import Alert from "./alert/Alerts";
import notFound from "./not-found";
import RuleAlert from "./rule-alert/RuleAlert";

export const appAQPrefix = "aq-demo";
export const directUrl = "/waverley-airquality";
export const appAQDefaultRoute = `/${appAQPrefix}`;
export enum pagePath {
    Home = "home",
    Region = ":regionId",
    School = "school/:schoolId",
    Room = "school/:schoolId/room/:roomId",
    RulesAndAlerts = "rules-and-alerts",
    Incident = "incidents"
}

const root = [
    {
        path: appAQDefaultRoute,
        element: (
            <ProtectedLayout>
                <Layout />
            </ProtectedLayout>
        ),
        children: [
            { path: "", element: <Dashboard /> },
            { path: pagePath.Home, element: <Dashboard /> },
            { path: pagePath.Region, element: <Dashboard /> },
            { path: pagePath.School, element: <Dashboard /> },
            { path: pagePath.Room, element: <Room /> },
            { path: pagePath.Incident, element: <Alert /> },
            { path: pagePath.RulesAndAlerts, element: <RuleAlert /> },
            { ...notFound },
        ],
    },
    {
        path: directUrl,
        element: <Navigate to={appAQDefaultRoute} replace />,
    },
];
export default root;
