import React, { useEffect, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import PaperWrapper from "components/wrappers/PaperWrapper";
import { CgArrowsExpandRight } from "react-icons/cg";
import FullScreenDialog from "components/dialogs/FullScreenDialog";
import { useSelector } from "react-redux";
import { DetectionOvertimeResponse } from "types";
import LineChart from "components/charts/LineChart";
import { Classification } from "enums/classifications.enum";
import { filterDataByClassifications } from "utils/common";
import { processChartData } from "./SynchronizedLineChart";

type TransportationMeanChartProps = {
    data: DetectionOvertimeResponse[];
    title: string;
};

// eslint-disable-next-line react/function-component-definition
export default function TransportationMeanChart({ data, title }: TransportationMeanChartProps) {
    const [processedData, setProcessedData] = useState<processChartData[]>([]);
    const [modalShow2, setModalShow2] = React.useState(false);
    const { startTime, endTime, timeType } = useSelector((state) => state.time);

    useEffect(() => {
        processData(data);
    }, [data]);

    function processData(data: DetectionOvertimeResponse[]): void {
        const removableClassifications = [
            Classification.ARRIVAL,
            Classification.DEPARTURE,
            Classification.UNATTENDED_BICYCLE,
            Classification.VEHICLE,
            Classification.PERSON,
        ];
        const acceptableDataset = filterDataByClassifications(data, removableClassifications, true);
        const uniques = Array.from(
            new Set(acceptableDataset.map((item: DetectionOvertimeResponse) => item.classification)),
        );
        const dataset = uniques.map((i) => {
            const subset = acceptableDataset.filter((j: DetectionOvertimeResponse) => j.classification === i);
            return {
                label: i,
                data: subset.map((l: DetectionOvertimeResponse) => {
                    return {
                        x: l.inTime,
                        y: l.count,
                    };
                }),
                // eslint-disable-next-line no-bitwise
                borderColor: `#${(((1 << 24) * Math.random()) | 0).toString(16)}`,
                tension: 0.1,
            };
        });
        setProcessedData(dataset);
    }

    const handleClick2 = (status: boolean): void => {
        setModalShow2(status);
    };
    return (
        <>
            <PaperWrapper>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <span className="chartHearder">{title}</span>
                    <IconButton className="expand-btn" onClick={() => handleClick2(true)} disableRipple>
                        <CgArrowsExpandRight />
                    </IconButton>
                    <LineChart
                        isFillEmpty
                        data={processedData}
                        fromTime={startTime}
                        toTime={endTime}
                        timeUnit={timeType}
                    />
                </Grid>
            </PaperWrapper>
            <FullScreenDialog
                modalShow={modalShow2}
                onClose={handleClick2}
                childComponent={
                    <div>
                        <LineChart
                            isFillEmpty
                            data={processedData}
                            fromTime={startTime}
                            toTime={endTime}
                            timeUnit={timeType}
                        />
                    </div>
                }
            />
        </>
    );
}
