/* eslint max-len: ["warn"] */
import { Classification } from "enums/room.enums";
import { HistoricalAnalysisFilterClass } from "features/app-air-quality/historical-analysis/filter/HistoricalAnalysisFilter";

export const AQDemoIncidentNames: Record<string, string> = {
    temperature_above_amber_threshold: "Temperature above 26 \u2103 for 60 minutes",
    temperature_above_red_threshold: "Temperature above 30 \u2103 for 15 minutes",
    humidity_above_amber_threshold: "Humidity above 65 for 30 minutes",
    humidity_above_red_threshold: "Humidity above 45 for 30 minutes",
    co2_above_amber_threshold: "CO2 above 800 for 15 minutes",
    co2_above_red_threshold: "CO2 above 1500 for 5 minutes",
    "pm1.0_above_threshold": "PM1.0 above 26 for 60 minutes",
    "pm2.5_above_threshold": "PM2.5 above 26 for 60 minutes",
    pm10_above_threshold: "PM10 above 6 for 60 minutes",
};

export const AQDemoIncidentTypes: Record<string, string> = {
    co2: "CO2",
    pm10: "PM1.0",
    pm25: "PM2.5",
    pm100: "PM10",
    rh: "RH",
};

/**
 * Display text and the classifications used in the dropdown of the aq historical analysis filter
 */
export const AQIncidentClassificationMetadata: HistoricalAnalysisFilterClass[] = [
    { displayText: "Temperature", classification: Classification.TEMP },
    { displayText: "CO2", classification: Classification.CO2 },
    { displayText: "PM1", classification: Classification.PM10 },
    { displayText: "PM1_AQI", classification: Classification.PM10AQI },
    { displayText: "PM2.5", classification: Classification.PM25 },
    { displayText: "PM2.5_AQI", classification: Classification.PM25AQI },
    { displayText: "PM10", classification: Classification.PM100 },
    { displayText: "PM10_AQI", classification: Classification.PM100AQI },
    { displayText: "VOC", classification: Classification.VOCPPM },
    { displayText: "VOC_AQI", classification: Classification.VOCAQI },
    { displayText: "Atmospheric Pressure", classification: Classification.ATM },
    { displayText: "Humidity", classification: Classification.RH },
];
