import React from "react";
import { Bar } from "react-chartjs-2";
import "chartjs-adapter-moment";

interface TimeSeriesBarChartProps {
    data: IDetectedChartData[];
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
    maintainAspectRatio: boolean;
    label: string;
    options: Record<string, unknown>;
}

export interface IDetectedChartData {
    x: Date;
    y: number;
}

/* eslint @typescript-eslint/no-unused-vars: ["off"] */
const TimeSeriesBarChart: React.FC<TimeSeriesBarChartProps> = ({
    data,
    backgroundColor,
    borderColor,
    borderWidth,
    maintainAspectRatio,
    label,
    options,
}) => {
    return (
        <div>
            <Bar
                data={{
                    datasets: [
                        {
                            label,
                            data,
                            backgroundColor,
                            borderColor,
                            borderWidth,
                        },
                    ],
                }}
                options={options}
            />
        </div>
    );
};

export default TimeSeriesBarChart;
