import React from "react";
import { Drawer } from "@mui/material";
import { IoMdClose } from "react-icons/io";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
interface DrawerCommonProps {
    title: string;
    show: boolean;
    handleClose: any;
    children?: any;
}

const DrawerCommon: React.FC<DrawerCommonProps> = ({ title, show, handleClose, children }) => {
    return (
        <Drawer anchor="right" open={show} onClose={handleClose}>
            <div className="common-drawer">
                <h5 className="header-title">{title}</h5>
                <IoMdClose className="btn-close" onClick={handleClose} />
                <div>{children}</div>
            </div>
        </Drawer>
    );
};

export default DrawerCommon;
