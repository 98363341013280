import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    root: {
        "& .MuiTreeItem-root": {
            position: "relative",
        },
        "& .MuiTreeItem-group": {
            marginLeft: 0,
        },
        "& .MuiTreeItem-label": {
            display: "flex",
            alignItems: "center",
            fontSize: "14px !important",
            lineHeight: "21px",
            height: "35px",
            whiteSpace: "nowrap",
            color: "#21315b",
            paddingLeft: "20px !important",
        },
        "& .MuiTreeItem-iconContainer": {
            width: "35px",
        },
        "& .MuiTreeItem-iconContainer svg": {
            fontSize: "16px",
        },
        "& .MuiTreeItem-content": {
            "&:hover": {
                color: "#6b439d",
                background: "#f3f0f7",
                borderRight: "3px solid #6b439d",
            },
        },
        "& .Mui-selected": {
            color: "#6b439d !important",
            background: "#f3f0f7 !important",
            borderRight: "3px solid #6b439d",
        },
        "& .MuiTreeItem-content:hover": {
            color: "$activeColor !important",
        },
    },
});

export default useStyles;
