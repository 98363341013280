import * as React from "react";
import { DataGrid, GridColumns, GridRowsProp } from "@mui/x-data-grid";

/* eslint no-unused-expressions: ["warn"] */
interface CommonTableProps {
    columns: GridColumns;
    rows: GridRowsProp;
    loading?: boolean;
    pageIndex?: number;
    pageSize: number;
    rowsCount?: number;
    setPageIndex?: (value: number) => void;
    rowsPerPageOptions: number[];
    hideFooterPagination: boolean;
    hideFooter?: boolean;
    autoHeight?: boolean;
    style?: React.CSSProperties;
}

const CommonTable: React.FC<CommonTableProps> = ({
    setPageIndex,
    loading,
    pageIndex,
    rowsCount,
    columns,
    rows,
    pageSize,
    rowsPerPageOptions,
    hideFooterPagination,
    hideFooter,
    autoHeight = true,
    style = { width: "100%" },
}) => {
    return (
        <div style={style}>
            <DataGrid
                page={pageIndex && pageIndex - 1}
                loading={loading}
                paginationMode="server"
                rows={rows}
                rowCount={rowsCount || 0}
                onPageChange={(newPage) => {
                    setPageIndex && setPageIndex(newPage + 1);
                }}
                autoHeight={autoHeight}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={rowsPerPageOptions}
                hideFooterPagination={hideFooterPagination}
                hideFooter={hideFooter}
                disableSelectionOnClick
            />
        </div>
    );
};

export default CommonTable;
