import { Location } from "reducers/newLocation.reducer";
import { ISidebarReport } from "types/reports/interfaces/report";
import { IStructuredReportData } from "types/reports/interfaces/side-menu";
import { reportSideMenuDisplayValues } from "configs/reports/report-sidemenu-display-values";
import { ReportTypes } from "types/reports/enums";
import { Assignee } from "types";
import { Environments } from "configs";
import { humanize } from "./common";
/**
 * FlattenTree
 * @param data Location Tree to be  flattened
 * @returns flattened location tree
 */
export const flattenTree = (data: Location[]): Location[] => {
    return data.reduce((r: Location[], { children, ...rest }) => {
        r.push(rest);
        if (children?.length) {
            r.push(...flattenTree(children));
        }
        return r;
    }, []);
};

/**
 * Obtains Leaf nodes from a location tree.
 * @param nodes Location Tree
 * @param result Result array containing leaf nodes of the passed tree.
 * @returns set of leaf nodes as an array.
 */
export const getLeafNodes = (nodes: Location[], result: Location[] = []): Location[] => {
    for (let i = 0, { length } = nodes; i < length; i++) {
        if (!nodes[i].children || nodes[i]?.children?.length === 0) {
            result.push(nodes[i]);
        } else {
            result = getLeafNodes(nodes[i].children as Location[], result);
        }
    }
    return result;
};

/**
 * Filters the set of parent locations of the given location
 * @param id location id to find parents of
 * @param arr array containing all the parent locations
 * @param locations locations array to filter the locations from
 * @returns an array of parent locations of the passed location
 */
export const findParentLocations = (id: string, locations: Location[], arr: string[] = []): string[] => {
    const foundLocation: Location | undefined = locations.find((location) => location.id === id);
    if (foundLocation && foundLocation.parentId) {
        arr.push(foundLocation.parentId);
        findParentLocations(foundLocation.parentId, locations, arr);
    }
    return arr;
};

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
/**
 * Orders the given report set by type.
 * @param reports Reports array
 * @returns array containing objects of reports filtered by report type.
 */
export const orderReportsByType = (reports: ISidebarReport[]) => {
    // Order reports by the report type
    const orderedReports = reports.reduce((acc: any, report: ISidebarReport) => {
        const endingDate = new Date(report.reportTo);
        if (report.reportType) {
            if (!acc[report.reportType]) {
                acc[report.reportType] = [];
            }
            acc[report.reportType].push({
                id: report.id,
                reportTo: formatDateByReportType(report.reportType, endingDate),
                reportFrom: report.reportFrom,
            });
        }
        return acc;
    }, {});

    const reportTypesArr: Array<IStructuredReportData> = [];
    Object.keys(orderedReports).forEach((reportType: string) => {
        if(reportType !== ReportTypes.METADATA_SEARCH_REPORT)
            reportTypesArr.push({
                displayName: reportSideMenuDisplayValues[reportType]
                    ? reportSideMenuDisplayValues[reportType]
                    : humanize(reportType),
                data: orderedReports[reportType],
            });
    });
    return reportTypesArr;
};

export const formatDateByReportType = (reportType: ReportTypes, date: Date) => {
    switch (reportType) {
        case ReportTypes.INCIDENT_TRENDS_REPORT:
            return date
                .toLocaleString("en-AU", { month: "long", year: "numeric" })
                .replace(",", "");
        default:
            return date
                .toLocaleString("en-AU", {  day: "2-digit", month: "long", year: "numeric" })
                .replace(",", "");
    }
};

export const filterUsers = (users: Assignee[]): Assignee[] => {
    const hiddenFilterUserConnections =
        process.env.REACT_APP_APP_ENV === Environments.Prod ? ["oauth2|platformity-aad"] : [];
    if (hiddenFilterUserConnections.length) {
        return users.filter(
            (user: Assignee) =>
                !hiddenFilterUserConnections.some((userConnection: string) => user.user_id.startsWith(userConnection)),
        );
    }
    return users;
};

/**
 * move item to top of the array.
 * @param items data array
 * @param callback callback function for filter the element
 * @returns Data array by moving the selected element to top 
 */
export const moveItemToFirst = <ItemType>(
        items: ItemType[],
        callback: (item: ItemType) => boolean,
    ): ItemType[] => {
    const userIndex = items.findIndex(callback);
    if (userIndex > 0) items.unshift(items.splice(userIndex, 1)[0]);
    return items;
}
