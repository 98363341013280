import { createTheme } from "@mui/material/styles";
import React from "react";

declare module "@mui/material/styles" {
    interface Palette {
        supportive: PaletteOptions["primary"];
        gradient?: PaletteOptions["primary"];
        hairlines: PaletteOptions["primary"];
        backgrounds: PaletteOptions["primary"];
    }
    interface PaletteOptions {
        supportive?: PaletteOptions["primary"];
        gradient?: PaletteOptions["primary"];
        hairlines?: PaletteOptions["primary"];
        backgrounds?: PaletteOptions["primary"];
    }
    interface PaletteColor {
        second: string;
    }
    interface SimplePaletteColorOptions {
        second?: string;
    }

    interface TypographyVariants {
        header1?: React.CSSProperties;
        header2?: React.CSSProperties;
        header3?: React.CSSProperties;
        cardCounts?: React.CSSProperties;
        subTitle?: React.CSSProperties;
        chart?: React.CSSProperties;
        body?: React.CSSProperties;
        bold?: React.CSSProperties;
        small?: React.CSSProperties;
        smallBold?: React.CSSProperties;
        link?: React.CSSProperties;
        buttonText?: React.CSSProperties;
    }

    interface TypographyVariantsOptions {
        header1?: React.CSSProperties;
        header2?: React.CSSProperties;
        header3?: React.CSSProperties;
        activeHeader?: React.CSSProperties;
        cardCounts?: React.CSSProperties;
        subTitle?: React.CSSProperties;
        chart?: React.CSSProperties;
        body?: React.CSSProperties;
        bold?: React.CSSProperties;
        small?: React.CSSProperties;
        smallBold?: React.CSSProperties;
        link?: React.CSSProperties;
        buttonText?: React.CSSProperties;
    }
}
declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        header1: true;
        activeHeader: true;
        header2: true;
        header3: true;
        cardCounts: true;
        subTitle: true;
        chart: true;
        body: true;
        bold: true;
        small: true;
        smallBold: true;
        link: true;
        buttonText: true;
    }
}
declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        supportive: true;
    }
}
export const aqDemoTheme = createTheme({
    palette: {
        primary: {
            main: "#001B4F",
        },
        secondary: {
            main: "#9CB7EC",
        },
        supportive: {
            main: "#0CCBF4",
            second: "#A3A3A3",
        },
        gradient: {
            main: "#C4C4C4",
        },
        hairlines: {
            main: "#C4C4C4",
        },
        backgrounds: {
            main: "F5F5FB",
        },
        success: {
            main: "#009E11",
        },
        warning: {
            main: "#FDB600",
        },
        error: {
            main: "#DF0000",
        },
        disableColor: {
            lightOverRide: "rgb(137,137,137) !important",
            main: "#d4d4d4",
            light: "rgb(137,137,137)",
        },
    },
    typography: {
        header1: {
            fontFamily: "Poppins !important",
            fontWeight: 600,
            fontSize: "40px",
            lineHeight: "60px",
            color: "#001B4F",
        },
        header2: {
            fontFamily: "Poppins !important",
            fontWeight: 600,
            fontSize: "26px",
            lineHeight: "39px",
            color: "#282F3C",
        },
        header3: {
            fontFamily: "Poppins !important",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "21px",
            color: "#001B4F",
        },
        activeHeader: {
            fontFamily: "Poppins !important",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "21px",
            color: "#001B4F",
        },
        cardCounts: {
            fontFamily: "Poppins !important",
            fontWeight: 600,
            fontSize: "30px",
            lineHeight: "45px",
            color: "#282F3C",
        },
        subTitle: {
            fontFamily: "Poppins !important",
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "27px",
            color: "#001B4F",
        },
        link: {
            fontFamily: "Poppins !important",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "21px",
            color: "#0CCBF4",
        },
        chart: {
            fontFamily: "Poppins !important",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "24px",
            color: "#0CCBF4",
        },
        body: {
            fontFamily: "Poppins !important",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "21px",
            color: "#A3A3A3",
        },
        bold: {
            fontFamily: "Poppins !important",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "21px",
            color: "#282F3C",
        },
        small: {
            fontFamily: "Poppins !important",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "18px",
            color: "#282F3C",
        },
        smallBold: {
            fontFamily: "Poppins !important",
            fontWeight: 600,
            fontSize: "12px",
            lineHeight: "18px",
            color: "#282F3C",
        },
        buttonText: {
            fontFamily: "Poppins !important",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "21px",
            color: "#0CCBF4",
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 800,
            md: 1024,
            lg: 1366,
            xl: 1920,
        },
    },
});

export default aqDemoTheme;
