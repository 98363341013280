import { styled } from "@mui/material";
import { gridClasses } from "@mui/x-data-grid";
import { RULE_TABLE_COLUMNS, RULE_TABLE_ROWS } from "../../../constants";
import { StyledDataTable } from "../common";

const CustomizedDataTable = styled(StyledDataTable)(() => ({
    [`& .${gridClasses.columnHeaders}`]: {
        position: "relative",
    },
    [`& .${gridClasses.virtualScroller}`]: {
        marginTop: "20px !important",
    },
    [`& .${gridClasses.columnHeader}`]: {
        marginTop: "1px",
    },
}));

interface Props {}

export const RuleTable: React.FC<Props> = () => {
    return (
        <CustomizedDataTable
            disableSelectionOnClick
            autoHeight
            columns={RULE_TABLE_COLUMNS}
            rows={RULE_TABLE_ROWS}
            hideFooterPagination
        />
    );
};
