export enum DayTypes {
    WEEKDAY = "weekday",
    WEEKEND = "weekend",
}

export enum WeekDays {
    MONDAY = "monday",
    TUESDAY = "tuesday",
    WEDNESDAY = "wednesday",
    THURSDAY = "thursday",
    FRIDAY = "friday",
}

export enum AllDaysMapper {
    ALL_WEEKDAYS = "weekDays",
    ALL_WEEKEND_DAYS = "weekEnds",
}

export enum WeekendDays {
    SATURDAY = "saturday",
    SUNDAY = "sunday",
}
