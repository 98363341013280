/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { TreeView, TreeItem, TreeItemProps } from "@mui/lab";
import { BiLeftArrowAlt, BiRightArrowAlt, BiMinus } from "react-icons/bi";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import SearchInputField from "components/input-fields/SearchInputField";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import { toggleSideBar } from "reducers/system.reducer";
import { newExecuteRetrieveLocationsRequest } from "api/common/side-panel.api";
import { setLocations, searchLocationsBy } from "reducers/newLocation.reducer";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import CustomTreeContent from "features/common/tree-item/CustomTreeContent";
import { Typography } from "@mui/material";
import { findParentLocations } from "utils/data-manipulation";
import useStyles from "./styles";

type SidebarParkAndRideProps = Record<string, unknown>;

const SidebarParkAndRide: React.FC<SidebarParkAndRideProps> = () => {
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const { isDrawerOpen } = useSelector((state) => state.system);
    const { tree } = useSelector((state) => state.newLocation);
    const locationsAbortControllerRef = useRef<AbortController | null>(null);
    const priorityLocationsAbortControllerRef = useRef<AbortController | null>(null);
    const favoritesAbortControllerRef = useRef<AbortController | null>(null);
    const [selectedNode, setSelectedNode] = useState<string>("root");
    const { locationId } = useParams();
    const locations = useSelector((state) => state.newLocation.locations);

    const [expanded, setExpanded] = useState<string[]>(["root", ...locations.map((loc) => loc.id)]);

    function handleSidebar() {
        dispatch(toggleSideBar());
        if (!isDrawerOpen) {
            setExpanded(["root"]);
        } else {
            setExpanded([]);
        }
    }

    const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        setExpanded(nodeIds);
    };

    const handleNode = () => {
        if (!isDrawerOpen) {
            dispatch(toggleSideBar());
        }
    };
    const fetchData = async () => {
        await getLocationTree();
    };
    useEffect(() => {
        fetchData();
        return () => {
            locationsAbortControllerRef.current?.abort();
            favoritesAbortControllerRef.current?.abort();
            priorityLocationsAbortControllerRef.current?.abort();
        };
    }, []);

    useEffect(() => {
        if (locationId) {
            setSelectedNode(locationId);
            const arr = findParentLocations(locationId, locations);
            setExpanded([...arr, locationId, "root", ...expanded]);
        } else {
            setSelectedNode("root");
        }
    }, [locationId, locations]);

    const getLocationTree = async () => {
        try {
            locationsAbortControllerRef.current = new AbortController();
            const locationSignal = locationsAbortControllerRef.current.signal;

            const locationsResponse = await newExecuteRetrieveLocationsRequest({
                signal: locationSignal,
            });

            if (Array.isArray(locationsResponse)) {
                dispatch(setLocations(locationsResponse));
            }
        } catch (e) {
            console.log(e);
        }
    };

    function onSearchLocation(e: any) {
        const { value } = e.target;
        dispatch(searchLocationsBy(value));
    }
    const SideBarStyledDivIconWrapper = styled("div")({
        ...(isDrawerOpen ? { paddingLeft: "11px" } : {}),
    });
    return (
        <div className={isDrawerOpen ? "sidebar active" : "sidebar"}>
            <div className={isDrawerOpen ? "sidebar-top-header" : ""}>
                <div className="menu-btn-wrapper" onClick={handleSidebar}>
                    {isDrawerOpen ? <BiLeftArrowAlt className="icon" /> : <BiRightArrowAlt className="icon" />}
                </div>
                <SearchInputField onSearch={onSearchLocation} />
            </div>
            <div className="menu-tree">
                {/* Todo :- need customize this tree view component as styled */}
                <TreeView
                    aria-label="controlled"
                    defaultCollapseIcon={<BiMinus className="expandedIcon" />}
                    defaultExpandIcon={<FiPlus className="expandedIcon" />}
                    expanded={expanded}
                    onNodeToggle={handleToggle}
                    onNodeSelect={handleNode}
                    className={classes.root}
                    selected={selectedNode}
                >
                    <CustomTreeItem
                        nodeId="root"
                        label={<span className="fw700 ">All Locations</span>}
                        icon={
                            <SideBarStyledDivIconWrapper>
                                <FaMapMarkerAlt className="color-active" />
                            </SideBarStyledDivIconWrapper>
                        }
                        className="level1"
                    >
                        {Array.isArray(tree) && tree.map((region: any) => renderNodeByType(region))}
                    </CustomTreeItem>
                </TreeView>
            </div>
        </div>
    );

    function renderNodeByType(item: any, levelIndex = 0) {
        const { type } = item;
        if (type === "location") {
            return renderLocation(item, levelIndex + 1);
        }
        return renderGroup(item, levelIndex + 1);
    }

    function setFontWeightBaseGroupType(group: any) {
        if (group.type === "zone") {
            return <span className="fw500 textDNone">{group.name}</span>;
        }
        return <span className="fw600 textDNone">{group.name}</span>;
    }

    function renderGroup(group: any, levelIndex = 0) {
        const { children } = group;
        return (
            <CustomTreeItem
                nodeId={group.id}
                label={setFontWeightBaseGroupType(group)}
                className={`level${levelIndex}`}
                key={group.id}
            >
                {children?.map((item: any) => renderNodeByType(item, levelIndex))}
            </CustomTreeItem>
        );
    }

    function setToolTipBaseNameSize(location: any) {
        if (location.name.length > 20) {
            return (
                <span className="textOverFlow">
                    <Tooltip placement="bottom" title={location.name} arrow>
                        <Typography fontSize="14px">{location.name}</Typography>
                    </Tooltip>
                </span>
            );
        }
        return <span className="textDNone">{location.name}</span>;
    }

    function renderLocation(loc: any, levelIndex = 0) {
        return (
            <CustomTreeItem
                nodeId={loc.id}
                label={setToolTipBaseNameSize(loc)}
                icon={
                    loc.isFavorite === true ? (
                        <AiFillStar className="favourite-active-icon" />
                    ) : (
                        <AiOutlineStar className="favourite-inactive-icon" />
                    )
                }
                className={`level${levelIndex}`}
                key={loc.id}
            />
        );
    }
};

const CustomTreeItem = (props: TreeItemProps) => <TreeItem ContentComponent={CustomTreeContent} {...props} />;

export default SidebarParkAndRide;
