import React from "react";
import Grid from "@mui/material/Grid";
import { FaMapMarkerAlt } from "react-icons/fa";
import { AiFillStar } from "react-icons/ai";
import { styled } from "@mui/styles";

const CustomizedGrid = styled(Grid)(() => ({
    margin: "0 0 0 0 !important",
}));

interface LocationSummaryCardProps {
    name?: string;
    city?: string;
    latitude?: string;
    longitude?: string;
}

const LocationSummaryCard: React.FC<LocationSummaryCardProps> = ({
    name,
    city: zone,
    latitude,
    longitude,
}) => {
    return (
        <CustomizedGrid container className="region-statlocation-card-wrapper m-0">
            <Grid item xs={12} className="region-statlocation-card-Innerwrapper">
                <Grid container item xs={12}>
                    <div className="region-statlocation-row">
                        <div className="LocationIcon-wrapper">
                            <FaMapMarkerAlt />
                        </div>
                        <span className="region-statlocation-header">{name}</span>
                        <span className="StarIcon-wrapper">
                            <AiFillStar className="color-yellow" />
                        </span>
                    </div>
                </Grid>

                <Grid item xs={12} className="region-statlocation-innaer-wrapper_bottom">
                    <Grid item xs={12} className="region-statlocation-row-left" sx={{borderColor: "transparent"}}>
                        <Grid item xs={12} className="region-statlocation-row-subrow">
                            LGA: {zone}
                        </Grid>
                        <Grid item xs={12} className="region-statlocation-row-subrow lgaData">
                            {latitude}, {longitude}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CustomizedGrid>
    );
};

export default LocationSummaryCard;
