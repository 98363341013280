import * as React from "react";
import useResizeObserver from "@react-hook/resize-observer";

interface IType {
    bottom: number;
    height: number;
    left: number;
    right: number;
    top: number;
    width: number;
    x: number;
    y: number;
}

const useSize = (target: any) => {
    const [size, setSize] = React.useState<IType | null>();

    React.useLayoutEffect(() => {
        setSize(target.current.getBoundingClientRect());
    }, [target]);

    /* eslint @typescript-eslint/no-explicit-any: ["off"] */
    useResizeObserver(target, (entry: any) => setSize(entry.contentRect));
    return size;
};

export default useSize;
