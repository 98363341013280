import { Container, CssBaseline, Grid } from "@mui/material";
import { Applications } from "configs";
import { useShouldRenderPageGuard } from "hooks/useShouldRenderPageGuard";
import { appDefaultRoute, appPrefix } from "pages/app-taxi-ranking";
import { useEffect } from "react";
import { Navigate, Outlet, useMatch } from "react-router-dom";
import { setRootPath } from "reducers/auth.reducer";
import { useAppDispatch } from "store";
import LoadingLayout from "layouts/common/loader/LoadingLayout.tsx";
import NavbarTaxiRank from "./header/NavbarTaxiRank";
import SidebarTaxiRank from "./drawer/SidebarTaxiRank";

const { AppIdTaxiRanking } = Applications;

export default function VideoRequestsLayout() {
    const match = useMatch(appPrefix);
    const { shouldRender } = useShouldRenderPageGuard(AppIdTaxiRanking);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setRootPath("/taxi-ranking/home"));
    }, []);

    if (match) return <Navigate to={appDefaultRoute} replace />;

    return shouldRender ? (
        <Container maxWidth="xl" disableGutters>
            <CssBaseline />
            <NavbarTaxiRank />
            <Grid className="custom-container">
                <SidebarTaxiRank rootPath="/taxi-ranking/home" />

                <div className="content">
                    <Outlet />
                </div>
            </Grid>
        </Container>
    ) : (
        <LoadingLayout />
    );
}