import WithScopes from "components/common/WithScopes";
import { SCOPES } from "enums/scopes.enum";
import ProtectedLayout from "layouts/common/general/ProtectedLayout";
import VideoRequestsLayout from "layouts/app-taxi-ranking/video-requests";
import NotFound from "pages/common/errors/not-found/NotFound";
import IncidentsVideoRequestsListTable from "./video-requests/video-requests-list-page";
import NewVideoRequest from "./video-requests/new-video-request";

const root = {
    path: "video-requests",
    element: (
        <ProtectedLayout>
            <VideoRequestsLayout />
        </ProtectedLayout>
    ),
    children: [
        {
            path: "",
            element: (
                <WithScopes
                    filterScopes={[
                        SCOPES.VIEW_VIDEO_REQUESTS_PAGE,
                    ]}
                >
                    <IncidentsVideoRequestsListTable />
                </WithScopes>
            ),
        },
        {
            path: "create",
            element: (
                <WithScopes
                    filterScopes={[
                        SCOPES.VIEW_CREATE_VIDEO_REQUESTS_PAGE,
                    ]}
                >
                    <NewVideoRequest />
                </WithScopes>
            ),
        },
        {
            path: "page-not-found",
            element: <NotFound />,
        },
    ],
}

export default root;