import React, { memo, useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import { FaCarAlt, FaTaxi } from "react-icons/fa";
import { AiFillWarning } from "react-icons/ai";
import { MdAirlineSeatReclineNormal } from "react-icons/md";
import { useSelector } from "react-redux";
import { executeGetDetectionsByLgaTaxiRequest } from "api/common/detections.api";
import { CLASS_INCIDENTS, CLASS_TAXIES, CLASS_EMBARK_PASSENGERS, CLASS_NONTAXIES } from "constants/class.constants";
import { executeGetIncidentDetectionsTaxiRequest } from "api/app-taxi-ranking/taxi-incidents.api";
import DoughnutChartCard, { IDoughnutChartCardData } from "components/cards/DoughnutChartCard";
import { FetchFunctionParams, makeApiCallWithUpdateTime } from "utils/common";
import { useAppDispatch } from "store";
import { LocationType } from "enums/location.enums";
import { Location } from "reducers/newLocation.reducer";
import ProtectedView from "components/common/ProtectedView";
import { SCOPES } from "enums/scopes.enum";
import useHasScope from "hooks/useHasScope";

interface HomeAllLocationStatisticsCardsProps {}

export interface DetectionParams {
    fromTime: string;
    toTime: string;
    classification?: string;
    level?: number;
    type?: string;
}

export interface ITaxiData {
    count: number;
    locationName: string;
}

const HomeAllLocationStatisticsCards: React.FC<HomeAllLocationStatisticsCardsProps> = () => {
    const hasReadIncidentsScope = useHasScope(SCOPES.READ_INCIDENTS);
    const dispatch = useAppDispatch();
    const { locations } = useSelector((state) => state.newLocation);
    const taxiAbortControllerRef = useRef<AbortController | null>(null);
    const nonTaxiAbortControllerRef = useRef<AbortController | null>(null);
    const passengerControllerRef = useRef<AbortController | null>(null);
    const incidentControllerRef = useRef<AbortController | null>(null);
    const { startTime, endTime, selectedRange } = useSelector((state) => state.time);
    const [taxiData, setTaxiData] = useState<IDoughnutChartCardData[]>([]);
    const [nonTaxiData, setNonTaxiData] = useState<IDoughnutChartCardData[]>([]);
    const [passengerData, setPassengerData] = useState<IDoughnutChartCardData[]>([]);
    const [incidentData, setIncidentData] = useState<IDoughnutChartCardData[]>([]);
    const [isLoadingTaxiLga, setIsLoadingTaxiLga] = useState(false);
    const [isLoadingNonTaxiLga, setIsLoadingNonTaxiLga] = useState(false);
    const [isLoadingPassengerData, setIsLoadingPassengerData] = useState(false);
    const [isLoadingIncidentData, setIsLoadingIncidentData] = useState(false);

    const findLocationById = (id: string): Location | undefined => locations.find((location) => location.id === id);

    const fetchData = async ({ startTime, endTime }: FetchFunctionParams) => {
        await Promise.all([
            getTaxiesDetections({
                fromTime: startTime,
                toTime: endTime,
                classification: CLASS_TAXIES,
                type: LocationType.ZONE,
            }),
            getNonTaxiesDetections({
                fromTime: startTime,
                toTime: endTime,
                classification: CLASS_NONTAXIES,
                type: LocationType.ZONE,
            }),
            getPassengersDetections({
                fromTime: startTime,
                toTime: endTime,
                classification: CLASS_EMBARK_PASSENGERS,
                type: LocationType.ZONE,
            }),
            hasReadIncidentsScope && getIncidentsDetections({
                fromTime: startTime,
                toTime: endTime,
                type: CLASS_INCIDENTS,
                level: -1,
            }),
        ]);
    };
    async function getTaxiesDetections(data?: DetectionParams) {
        try {
            taxiAbortControllerRef.current = new AbortController();
            const { signal } = taxiAbortControllerRef.current;
            setIsLoadingTaxiLga(true);
            const responseTaxi = await executeGetDetectionsByLgaTaxiRequest(data, { signal });
            if (responseTaxi && Array.isArray(responseTaxi)) {
                const taxies = responseTaxi.map((taxi) => {
                    const location = findLocationById(taxi.locationId);

                    return {
                        count: taxi.count,
                        locationName: location ? location.name : "",
                    };
                });
                setTaxiData(taxies);
            }
            setIsLoadingTaxiLga(false);
            // eslint-disable-next-line no-empty
        } catch (error) {
        } finally {
            setIsLoadingTaxiLga(false);
        }
    }
    async function getNonTaxiesDetections(data?: DetectionParams) {
        try {
            nonTaxiAbortControllerRef.current = new AbortController();
            const { signal } = nonTaxiAbortControllerRef.current;
            setIsLoadingNonTaxiLga(true);
            const response = await executeGetDetectionsByLgaTaxiRequest(data, {
                signal,
            });
            if (response && Array.isArray(response)) {
                const taxies = response.map((taxi) => {
                    const location = findLocationById(taxi.locationId);

                    return {
                        ...taxi,
                        locationName: location ? location.name : "",
                    };
                });
                setNonTaxiData(taxies);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoadingNonTaxiLga(false);
        }
    }

    async function getPassengersDetections(data?: DetectionParams) {
        try {
            passengerControllerRef.current = new AbortController();
            const { signal } = passengerControllerRef.current;
            setIsLoadingPassengerData(true);
            const response = await executeGetDetectionsByLgaTaxiRequest(data, {
                signal,
            });
            if (response && Array.isArray(response)) {
                const passengers = response.map((taxi) => {
                    const location = findLocationById(taxi.locationId);

                    return {
                        ...taxi,
                        locationName: location ? location.name : "",
                    };
                });
                setPassengerData(passengers);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoadingPassengerData(false);
        }
    }

    async function getIncidentsDetections(data?: DetectionParams) {
        try {
            incidentControllerRef.current = new AbortController();
            const { signal } = incidentControllerRef.current;
            setIsLoadingIncidentData(true);
            const response = await executeGetIncidentDetectionsTaxiRequest(data, { signal });
            if (response && Array.isArray(response)) {
                setIncidentData(response);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoadingIncidentData(false);
        }
    }

    useEffect(() => {
        makeApiCallWithUpdateTime(selectedRange, { startTime, endTime }, fetchData, dispatch);
        return () => {
            taxiAbortControllerRef.current?.abort();
            nonTaxiAbortControllerRef.current?.abort();
            passengerControllerRef.current?.abort();
            incidentControllerRef.current?.abort();
        };
    }, [startTime, endTime, hasReadIncidentsScope]);

    return (
        <Grid container className="stats-container">
            <DoughnutChartCard
                isLoading={isLoadingTaxiLga}
                title="Total number of taxis by LGA"
                count="0"
                icon={<FaTaxi />}
                isChart
                className="donut-Icon-wrapper"
                data={taxiData}
                labelName="locationName"
            />

            <DoughnutChartCard
                isLoading={isLoadingNonTaxiLga}
                title="Total number of non-taxis by LGA"
                count="0"
                icon={<FaCarAlt />}
                isChart
                className="donut-Icon-wrapper"
                data={nonTaxiData}
                labelName="locationName"
            />

            <DoughnutChartCard
                isLoading={isLoadingPassengerData}
                title="Total number of passengers by LGA"
                count="0"
                icon={<MdAirlineSeatReclineNormal />}
                isChart
                className="donut-Icon-wrapper"
                data={passengerData}
                labelName="locationName"
            />

            <ProtectedView filterScopes={[SCOPES.READ_INCIDENTS]} shouldHide>
                <DoughnutChartCard
                    isLoading={isLoadingIncidentData}
                    title="Total number of incidents"
                    count="0"
                    icon={<AiFillWarning />}
                    isChart
                    className="donut-Icon-wrapper donut-red-Icon-wrapper"
                    data={incidentData}
                    labelName="incident"
                />
            </ProtectedView>
        </Grid>
    );
};

export default memo(HomeAllLocationStatisticsCards);
