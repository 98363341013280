/* eslint-disable @typescript-eslint/no-unused-vars */
import { CircularProgress, InputAdornment, InputBase, Select, SelectProps } from "@mui/material";
import { styled } from "@mui/material/styles";

interface IIncidentModalStatusSelectProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    additionalProps?: any;
    loading: boolean;
}
const CustomizedInputBase = styled(InputBase)(() => ({
    "& .MuiInputBase-input": {
        borderRadius: "20px",
        fontSize: "14px",
        borderColor: "rgb(204,204,204)",
        display: "flex",
        justifyContent: "center",
        minWidth: "calc(100%)",
    },
    "& .MuiSvgIcon-root": {
        fontSize: "22px",
    },
    "& .MuiPaper-root .MuiPaper-elevation .MuiPaper-rounded": {
        width: "300px",
        marginLeft: "-20px",
    },
}));
type Props<T> = IIncidentModalStatusSelectProps & JSX.IntrinsicAttributes & SelectProps<T>;
const IncidentModalStatusSelect = <T,>({ additionalProps, loading, ...rest }: Props<T>) => {
    return (
        <Select
            MenuProps={{
                sx: {
                    "& .MuiMenuItem-root": {
                        fontSize: "14px",
                        minWidth: "fitContent",
                    },
                    width: "auto",
                },
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: -20,
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                },
            }}
            {...rest}
            input={<CustomizedInputBase />}
            endAdornment={
                <InputAdornment position="end">
                    {loading ? <CircularProgress color="primary" size={20} /> : null}
                </InputAdornment>
            }
        />
    );
};

export default IncidentModalStatusSelect;
