/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Location {
    id: string;

    name: string;
    mapImage: string;
    longitude: string;
    latitude: string;
    openHrs: any; // TODO: Figure out the type for this and strongly type it.
    address1: string;
    address2: string;
    city: string;
    zip: number;
    state: string;
    isFavorite: boolean | null;
    group: string;
    devices: string[]; // TODO: Strongly type this
    lat: number;
    lng: number;
    address: string;
    count: number;

    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    createdBy: string;
    updatedBy: string;
    deletedBy: string;

    tenantId: string;
}

export interface LocationGroup {
    id: string;
    tenantId: string;
    createdAt: string;
    updatedAt: string | null;
    deletedAt: string | null;
    createdBy: string | null;
    updatedBy: string | null;
    deletedBy: string | null;
    name: string;
    image?: string;
    type: "region" | "zone";
    parent?: LocationGroup | null;
    parentId?: string;
    nodeType: "group";
    childrenCount: number;
    devicesCount: number;
}
interface SystemState {
    locations: Location[];
    groups: LocationGroup[];
    tree: any[];
    sideBarTree: any[];
}

const initialState = {
    locations: [],
    groups: [],
    tree: [],
    sideBarTree: [],
} as SystemState;

const buildTree = (_items: any, id = null, link = "parentId") =>
    _items
        .filter((item: any) => item[link] === id)
        .map((item: any) => {
            const children = buildTree(_items, item.id) || [];
            return {
                ...item,
                children,
            };
        });

const getChildrenCounts = (nodes: any[]) => {
    return nodes.reduce((acc: any, node: any) => {
        if (!acc[node.id]) {
            acc[node.id] = {};
        }
        if (node.children && Array.isArray(node.children) && node.children.length > 0) {
            acc[node.id] = node.children.length;
            acc = {
                ...acc,
                ...getChildrenCounts(node.children),
            };
        } else {
            acc[node.id] = 0;
        }

        return acc;
    }, {});
};

const findInTree = (items = [], { children = [], ...obj }, name: string): any => {
    let result;
    if (obj.name && obj.name.toLowerCase().includes(name)) {
        return {
            ...obj,
            children: buildTree(items, obj.id) || [],
        };
    }
    return (
        // eslint-disable-next-line no-return-assign
        children.some((o) => (result = findInTree(items, o, name))) &&
        Object.assign({}, obj, {
            children: [result] || [],
        })
    );
};

const slice = createSlice({
    name: "location",
    initialState,
    reducers: {
        setLocationsInfo(state, action: PayloadAction<any>) {
            const { locations, groups } = action.payload;

            if (Array.isArray(locations)) {
                if (Array.isArray(groups)) {
                    const preprocessedGroups = groups.map((group) => {
                        return {
                            ...group,
                            parentId: group.parent?.id,
                            nodeType: "group",
                        };
                    });

                    const preprocessedLocation = locations.map((i: any) => {
                        return {
                            ...i,
                            parentId: i.group,
                            nodeType: "location",
                            childrenCount: 0,
                            devicesCount: i.devices?.length || 0,
                        };
                    });

                    const items = [...preprocessedGroups, ...preprocessedLocation];

                    const tree = buildTree(items);

                    const aggregatedDeviceCounts: Record<string, number> = {};
                    const aggregateDeviceCounts = (nodes: any[]) => {
                        return nodes.reduce((count, node) => {
                            if (node.devices && Array.isArray(node.devices) && node.devices.length) {
                                count += node.devices.length;
                            }
                            if (node.children && Array.isArray(node.children) && node.children.length > 0) {
                                count += aggregateDeviceCounts(node.children) || 0;
                            }
                            aggregatedDeviceCounts[node.id] = count;
                            return count;
                        }, 0);
                    };
                    const directChildrenCount = getChildrenCounts(tree);
                    aggregateDeviceCounts(tree);

                    const childCountMappedGroups = preprocessedGroups.map((g) => {
                        return {
                            ...g,
                            childrenCount: directChildrenCount[g.id] || 0,
                            devicesCount: aggregatedDeviceCounts[g.id] || 0,
                        };
                    });

                    state.groups = childCountMappedGroups;
                    state.locations = preprocessedLocation;
                    state.tree = tree;
                    state.sideBarTree = tree;
                }
            }
        },

        searchLocationsBy(state, action: PayloadAction<any>) {
            const filterParams = action.payload;
            if (filterParams) {
                const value = filterParams.toLowerCase();
                const { groups, locations } = state;
                const items = [...groups, ...locations] as any;

                const tree = buildTree(items);

                const treeWithRoot = {
                    children: tree,
                };
                const { children: filteredTree } = findInTree(items, treeWithRoot, value);
                const filteredTreeSafe = filteredTree || [];

                state.sideBarTree = filteredTreeSafe;
            } else {
                state.sideBarTree = state.tree;
            }
        },
    },
});

export const { setLocationsInfo, searchLocationsBy } = slice.actions;
export default slice.reducer;
