/* eslint-disable no-bitwise */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import PaperWrapper from "components/wrappers/PaperWrapper";
import { CgArrowsExpandRight } from "react-icons/cg";
import FullScreenDialog from "components/dialogs/FullScreenDialog";
import { useSelector } from "react-redux";
import LineChart from "components/charts/LineChart";
import { VehiclesOvertimeResponse } from "pages/app-ungated-parking/location/LocationChartLayout";
import { XYData, processChartData } from "./SynchronizedLineChart";

type ParkingMeanChartProps = {
    data: VehiclesOvertimeResponse[];
    title: string;
    icon?: any;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function NetOccupancyChart({ data, title, icon }: ParkingMeanChartProps) {
    const [processedData, setProcessedData] = useState<processChartData[]>([]);
    const [modalShow, setModalShow] = React.useState(false);
    const { startTime, endTime, timeType } = useSelector((state) => state.time);

    useEffect(() => {
        processData(data);
    }, [data]);

    function processData(data: VehiclesOvertimeResponse[]): void {
        const dataset = data.reduce(
            (acc, item) => {
                let itemCount = 0;
                const itemInTime = new Date(item.inTime);
                if (item.field1 === "in") {
                    const matchIndex = acc.in.findIndex((el) => el.x.getTime() === itemInTime.getTime());
                    itemCount = Number(item.count);
                    if (matchIndex === -1) {
                        acc.in.push({
                            x: itemInTime,
                            y: itemCount,
                        });
                    } else {
                        acc.in[matchIndex].y += itemCount;
                    }
                } else if (item.field1 === "out") {
                    const matchIndex = acc.out.findIndex((el) => el.x.getTime() === itemInTime.getTime())
                    itemCount = -1 * Number(item.count);
                    if (matchIndex === -1) {
                        acc.out.push({
                            x: itemInTime,
                            y: itemCount,
                        });
                    } else {
                        acc.out[matchIndex].y += itemCount;
                    }
                }

                const netMatchIndex = acc.net.findIndex((el) => el.x.getTime() === itemInTime.getTime());
                if (netMatchIndex === -1) {
                    acc.net.push({
                        x: itemInTime,
                        y: itemCount,
                    });
                }
                else {
                    acc.net[netMatchIndex].y += itemCount;
                }

                return acc;
            },
            {
                in: [] as XYData[],
                out: [] as XYData[],
                net: [] as XYData[],
            },
        );

        setProcessedData([
            {
                label: "In",
                data: dataset.in,
                borderColor: `#${(((1 << 24) * Math.random()) | 0).toString(16)}`,
                tension: 0.1,
            },
            {
                label: "Out",
                data: dataset.out,
                borderColor: `#${(((1 << 24) * Math.random()) | 0).toString(16)}`,
                tension: 0.1,
            },
            {
                label: "Delta",
                data: dataset.net.sort((first, second) => first.x.getTime() - second.x.getTime()),
                borderColor: `#${(((1 << 24) * Math.random()) | 0).toString(16)}`,
                tension: 0.1,
            },
        ]);
    }

    const handleClick = (status: boolean): void => {
        setModalShow(status);
    };
    return (
        <>
            <PaperWrapper>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={1} gap={1} marginBottom={1}>
                        <Grid item className="chart-padding-0">
                            {icon}
                        </Grid>
                        <Grid item className="chart-padding-0">
                            <span className="chartHearder">{title}</span>
                        </Grid>
                        <Grid item className="chart-padding-0">
                            <IconButton className="expand-btn" onClick={() => handleClick(true)} disableRipple>
                                <CgArrowsExpandRight />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <LineChart
                        isFillEmpty
                        data={processedData}
                        fromTime={startTime}
                        toTime={endTime}
                        timeUnit={timeType}
                    />
                </Grid>
            </PaperWrapper>
            <FullScreenDialog
                modalShow={modalShow}
                onClose={handleClick}
                childComponent={
                    <div>
                        <LineChart
                            isFillEmpty
                            data={processedData}
                            fromTime={startTime}
                            toTime={endTime}
                            timeUnit={timeType}
                        />
                    </div>
                }
            />
        </>
    );
}
