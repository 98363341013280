import { styled } from "@mui/material/styles";
import { Chip, ChipProps } from "@mui/material";
import React from "react";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
/* eslint @typescript-eslint/no-unused-vars: ["off"] */
interface IIotVisionChip {
    additionalProps?: any;
}
const CustomizedChip = styled(Chip)(() => ({
    minWidth: "150px",
    "& .MuiChip-deleteIcon": {
        marginLeft: "30px",
    },
}));
const IotVisionChip: React.FC<IIotVisionChip & JSX.IntrinsicAttributes & ChipProps> = ({
    additionalProps,
    ...rest
}) => {
    return <CustomizedChip {...rest} />;
};

export default IotVisionChip;
