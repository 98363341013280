import { Location } from "reducers/newLocation.reducer";

export function buildLocationAddressStr(location?: Partial<Location>): string {
    let address = "";
    if (location?.address1?.length) {
        address += `${location.address1}`;
    }
    if (location?.address2?.length) {
        address += `${address.length ? ", " : ""}${location.address2}`;
    }
    if (location?.city?.length) {
        address += `${address.length ? ", " : ""}${location.city}`;
    }
    if (location?.state?.length) {
        address += `${address.length ? ", " : ""}${location.state}`;
    }
    return address;
}

export default buildLocationAddressStr;
