import { AQSensorConfig, AQSensorData, AQSensorRuleSet } from "../types";
import { isMatched } from "../utils";

/**
 * Handle AQ Sensor Related threshold data generation
 * Reads all the predefined data sets and find the respective data set which is matched
 * according to the provided values
 * @param thresholdConfig config for matching the rules
 * @param value value to be validated with the rules
 * @returns returns AQSensorData which is related to matched rules set
 */
export const handleAQSensorThresholds = (
    thresholdConfig?: AQSensorConfig,
    value?: number,
): AQSensorData | undefined => {
    if (!thresholdConfig) {
        return;
    }

    if (value === undefined && thresholdConfig) {
        const { key, minThreshold, maxThreshold, symbol, name, measurementType, subMeasurementType, roomPageName } =
            thresholdConfig;
        const data: AQSensorData = {
            key,
            minThreshold,
            maxThreshold,
            symbol,
            value,
            name,
            measurementType,
            subMeasurementType,
            roomPageName,
        };

        return data;
    }

    let selectedRuleSet: AQSensorRuleSet | undefined;
    const max: number = thresholdConfig.maxThreshold ?? 0;
    const min: number = thresholdConfig.minThreshold ?? 0;

    if (value) {
        if (value > max) {
            selectedRuleSet = obtainRuleset(thresholdConfig, max);
        } else if (value < min) {
            selectedRuleSet = obtainRuleset(thresholdConfig, min);
        } else {
            selectedRuleSet = obtainRuleset(thresholdConfig, value);
        }
    }

    const { key, minThreshold, maxThreshold, symbol, name, measurementType, subMeasurementType, roomPageName } =
        thresholdConfig;
    const data: AQSensorData = {
        key,
        minThreshold,
        maxThreshold,
        symbol,
        color: selectedRuleSet?.color,
        statusLabel: selectedRuleSet?.statusLabel,
        value,
        name,
        colorRange: selectedRuleSet?.colorRange,
        measurementType,
        subMeasurementType,
        roomPageName,
        roomColorRange: selectedRuleSet?.roomColorRange,
    };

    return data;
};

/**
 * Finds and obtains the specific ruleset that conforms to the given value.
 * @param thresholdConfig config for matching the rules
 * @param value value to check against the rules
 * @returns matched ruleset
 */

const obtainRuleset = (thresholdConfig: AQSensorConfig, value?: number): AQSensorRuleSet | undefined => {
    const selectedRuleSet: AQSensorRuleSet | undefined = thresholdConfig.ruleSets.find((ruleSet) => {
        return ruleSet.rules.some((currentValue) => {
            return currentValue.thresholds.every((threshold) => {
                return isMatched(threshold, value ?? 0);
            });
        });
    });

    return selectedRuleSet;
};

export default handleAQSensorThresholds;
