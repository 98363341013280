import { TreeView } from "@mui/lab";
import { Fade, Grid, styled, SvgIconProps } from "@mui/material";
import React, { useRef, useEffect, useMemo } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import SchoolIcon from "components/icons/SchoolIcon";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { appAQDefaultRoute } from "pages/app-air-quality";
import { newExecuteRetrieveLocationsRequest } from "api/common/side-panel.api";
import { useAppDispatch } from "store";
import { useSelector } from "react-redux";
import { setLocations } from "reducers/newLocation.reducer";
import { useLocation, useParams } from "react-router";
import AppDrawer from "./drawer/AppDrawer";
import SideBarTreeItem from "./tree-item/SideBarTreeItem";

interface SideBarProps {
    isSideBarOpen: boolean;
    onCollapse: () => void;
    onExpand: () => void;
}

interface RenderTree {
    id: string;
    name: string;
    type?: string;
    labelIcon?: React.ElementType<SvgIconProps>;
    children?: readonly RenderTree[];
    parentId?: string;
}

const DrawerHeader = styled("div")(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
}));

const FormattedGrid = styled(Grid)(() => ({
    "&::-webkit-scrollbar": {
        width: 0,
    },
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    height: "calc(90% + 1rem)",
    overflowY: "scroll",
}));

const SideBar: React.FC<SideBarProps> = ({ isSideBarOpen, onExpand, onCollapse }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const urlParams = useParams();
    const [expanded, setExpanded] = React.useState<string[]>(["root", "root2"]);
    const [selected, setSelected] = React.useState<string[]>([]);
    const { locationsTree, locations } = useSelector((state) => state.newLocation);
    const isDrawerOpen = useSelector((state) => state.system.isDrawerOpen);
    const locationsAbortControllerRef = useRef<AbortController | null>(null);
    const [cachedExpanded, setCachedExpanded] = React.useState<string[]>(expanded);

    useEffect(() => {
        (async () => {
            await getLocationsTree();
        })();

        handleTreeItemSelection();

        return () => {
            locationsAbortControllerRef.current?.abort();
        };
    }, []);

    // collapse tree when navigate to homepage
    useEffect(() => {
        handleSelectedTreeItems();
        if (location.pathname === appAQDefaultRoute) {
            setExpanded([]);
            const node = locations?.find((loc) => loc.type === "region");
            if (node) {
                handleTreeItemClick(node);
            }
        }
    }, [location.pathname, locations]);

    const handleTreeItemSelection = () => {
        const { regionId, schoolId, roomId } = urlParams;
        if (regionId) {
            handleToggle(null, [regionId]);
        }
        if (schoolId && roomId) {
            const region = locations.find((loc) => loc.id === schoolId);
            if (region) {
                handleToggle(null, [schoolId, roomId, schoolId, region?.parentId]);
            }
        }
        if (schoolId) {
            const region = locations.find((loc) => loc.id === schoolId);
            if (region) {
                handleToggle(null, [schoolId, region?.parentId]);
            }
        }
    };

    const handleToggle = (event: React.SyntheticEvent | null, nodeIds: string[]) => {
        setExpanded(nodeIds);
    };

    const handleTreeItemClick = (node?: RenderTree) => {
        let path = "";
        switch (node?.type) {
            case "region":
                path = `${appAQDefaultRoute}/${node.id}`;
                break;
            case "zone":
                path = `${appAQDefaultRoute}/school/${node.id}`;
                break;
            default:
                path = `${appAQDefaultRoute}/school/${node?.parentId}/room/${node?.id}`;
        }

        navigate(path);
        if (!isSideBarOpen && node?.type === "region") {
            onExpand();
        }
    };

    const handleSelectedTreeItems = () => {
        const { regionId, schoolId, roomId } = urlParams;
        if (regionId) {
            setSelected([regionId]);
            return;
        }
        if (schoolId && roomId) {
            setSelected([roomId]);
            return;
        }
        if (schoolId) {
            setSelected([schoolId]);
        }
    };

    const renderTreeItem = (nodes: RenderTree) => {
        let sortedChildren = null;
        if (nodes.children) {
            sortedChildren = [...nodes.children].sort((a, b) => a.name.localeCompare(b.name));
        }
        return (
            <SideBarTreeItem
                onClick={() => handleTreeItemClick(nodes)}
                key={nodes.id}
                nodeId={nodes.id}
                labelText={nodes.name}
                labelIcon={nodes.type === "region" ? nodes?.labelIcon ?? SchoolIcon : undefined}
            >
                {sortedChildren ? sortedChildren.map((node) => renderTreeItem(node)) : null}
            </SideBarTreeItem>
        );
    };

    const getLocationsTree = async () => {
        try {
            locationsAbortControllerRef.current = new AbortController();
            const locationSignal = locationsAbortControllerRef.current.signal;

            const locationsResponse = await newExecuteRetrieveLocationsRequest({
                signal: locationSignal,
            });

            if (Array.isArray(locationsResponse)) {
                dispatch(setLocations(locationsResponse));
            }
        } catch (e) {
            console.log(e);
        }
    };

    const sortedTree = [...locationsTree].sort((a, b) => a.name.localeCompare(b.name));

    return (
        <AppDrawer anchor="left" open={isSideBarOpen} variant="permanent">
            <DrawerHeader
                sx={{
                    justifyContent: isSideBarOpen ? "flex-end" : "center",
                }}
            >
                {isSideBarOpen ? (
                    <IconButton
                        sx={{
                            left: "296px",
                            top: "90px",
                            position: "fixed",
                        }}
                        onClick={() => {
                            onCollapse();
                            setCachedExpanded(expanded);
                            setExpanded([]);
                        }}
                    >
                        <BiLeftArrowAlt
                            style={{
                                background: "#FFFFFF",
                                borderRadius: "50%",
                                height: "24px",
                                width: "24px",
                                color: "#21315B",
                            }}
                        />
                    </IconButton>
                ) : (
                    <IconButton
                        onClick={() => {
                            onExpand();
                            setExpanded(cachedExpanded);
                        }}
                    >
                        <BiRightArrowAlt
                            style={{
                                background: "#FFFFFF",
                                borderRadius: "50%",
                                height: "24px",
                                width: "24px",
                                color: "#21315B",
                            }}
                        />
                    </IconButton>
                )}
            </DrawerHeader>
            <FormattedGrid container spacing={1}>
                <Grid item xs={12}>
                    <TreeView
                        sx={{
                            ...(!isSideBarOpen ? { marginLeft: "0px" } : {}),
                        }}
                        aria-disabled
                        onNodeToggle={handleToggle}
                        expanded={expanded}
                        defaultCollapseIcon={useMemo(
                            () =>
                                isDrawerOpen ? (
                                    <Fade in={isDrawerOpen} timeout={500}>
                                        <ExpandMoreIcon />
                                    </Fade>
                                ) : null,
                            [isDrawerOpen],
                        )}
                        defaultExpandIcon={useMemo(
                            () =>
                                isDrawerOpen ? (
                                    <Fade in={isDrawerOpen} timeout={500}>
                                        <ChevronRightIcon />
                                    </Fade>
                                ) : null,
                            [isDrawerOpen],
                        )}
                        selected={selected.length > 0 ? selected : ["root"]}
                        style={{ width: "96%" }}
                    >
                        {sortedTree?.map((treeData) => renderTreeItem(treeData))}
                    </TreeView>
                </Grid>
            </FormattedGrid>
        </AppDrawer>
    );
};

export default SideBar;
