import React, { useEffect, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import PaperWrapper from "components/wrappers/PaperWrapper";
import { CgArrowsExpandRight } from "react-icons/cg";
import FullScreenDialog from "components/dialogs/FullScreenDialog";
import { useSelector } from "react-redux";
import LineChart from "components/charts/LineChart";
import { MINUTES_15_IN_MILLISECOND } from "constants/time.constants";
import { capitalCase } from "capital-case";
import { Incident } from "types";
import { XYData, processChartData } from "./SynchronizedLineChart";

type IncidentsOverTimeLineChartProps = {
    data: Incident[];
    title: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon?: any;
};

export default function IncidentsOverTimeLineChart({ data, title, icon }: IncidentsOverTimeLineChartProps) {
    const [processedData, setProcessedData] = useState<processChartData[]>([]);
    const [modalShow, setModalShow] = React.useState(false);
    const [roundedStartTime, setRoundedStartTime] = React.useState("");
    const { startTime, endTime, timeType } = useSelector((state) => state.time);

    useEffect(() => {
        const epoch = new Date(startTime).getTime();
        const start = new Date(epoch - (epoch % MINUTES_15_IN_MILLISECOND));
        setRoundedStartTime(start.toISOString());
    }, [startTime]);

    useEffect(() => {
        processData(data);
    }, [data]);

    function processData(data: Incident[]): void {
        const uniqueSiteIds = Array.from(new Set(data.map((incident: Incident) => incident.siteId)));
        const dataset = uniqueSiteIds.map((siteId) => {
            const matchedDataset = data.filter((incident: Incident) => incident.siteId === siteId);
            const result = matchedDataset.reduce((acc: XYData[], current: Incident) => {
                const epoch = new Date(current.dateTime).getTime();
                const start = new Date(epoch - (epoch % MINUTES_15_IN_MILLISECOND));
                const matchedIndex = acc.findIndex(({ x }: XYData) => x.getTime() === start.getTime());
                if (matchedIndex === -1) {
                    acc.push({
                        x: start,
                        y: 1,
                    });
                    return acc;
                }
                ++acc[matchedIndex].y;
                return acc;
            }, [] as XYData[]);
            let rangeStart = new Date(startTime);
            do {
                const epoch = new Date(rangeStart).getTime();
                rangeStart = new Date(epoch - (epoch % MINUTES_15_IN_MILLISECOND));
                // eslint-disable-next-line no-loop-func
                const matchedIndex = result.findIndex(({ x }: XYData) => x.getTime() === rangeStart.getTime());
                if (matchedIndex === -1) {
                    result.push({
                        x: rangeStart,
                        y: 0,
                    })
                }
                rangeStart = new Date(rangeStart.getTime() + MINUTES_15_IN_MILLISECOND);
            } while (rangeStart.getTime() < new Date(endTime).getTime())
            return {
                label: capitalCase(siteId),
                data: result.sort((first, second) => first.x.getTime() - second.x.getTime()),
                // eslint-disable-next-line no-bitwise
                borderColor: `#${(((1 << 24) * Math.random()) | 0).toString(16)}`,
                tension: 0.1,
            };
        });
        setProcessedData(dataset);
    }

    const handleClick = (status: boolean): void => {
        setModalShow(status);
    };

    return (
        <>
            <PaperWrapper>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={1} gap={1} marginBottom={1}>
                        <Grid item className="chart-padding-0">
                            {icon}
                        </Grid>
                        <Grid item className="chart-padding-0">
                            <span className="chartHearder">{title}</span>
                        </Grid>
                        <Grid item className="chart-padding-0">
                            <IconButton className="expand-btn" onClick={() => handleClick(true)} disableRipple>
                                <CgArrowsExpandRight />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <LineChart
                        data={processedData}
                        fromTime={roundedStartTime}
                        toTime={endTime}
                        timeUnit={timeType}
                        roundTimeDisabled
                    />
                </Grid>
            </PaperWrapper>
            <FullScreenDialog
                modalShow={modalShow}
                onClose={handleClick}
                childComponent={
                    <div>
                        <LineChart
                            data={processedData}
                            fromTime={roundedStartTime}
                            toTime={endTime}
                            timeUnit={timeType}
                            roundTimeDisabled
                        />
                    </div>
                }
            />
        </>
    );
}
