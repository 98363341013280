import { useEffect } from "react";
import { Box, Container, CssBaseline, Grid } from "@mui/material";
import { Navigate, Outlet, useMatch } from "react-router-dom";
import { appPrefix, appDefaultRoute } from "pages/app-ungated-parking";
import { LoadingWrapper } from "components/wrappers/LoadingWrapper";
import { useSelector } from "react-redux";
import { Applications } from "configs";
import { useAppDispatch } from "store";
import { setRootPath } from "reducers/auth.reducer";
import LoadingLayout from "layouts/common/loader/LoadingLayout.tsx";
import { useShouldRenderPageGuard } from "hooks/useShouldRenderPageGuard";
import SidebarParkAndRide from "./drawer/SidebarParkAndRide";
import NavbarParkAndRide from "./header/NavbarParkAndRide";

const { AppIdUngatedParking } = Applications;
export default function ParkAndRideLayout() {
    const match = useMatch(appPrefix);
    const { locationsTree } = useSelector((state) => state.newLocation);
    const { shouldRender } = useShouldRenderPageGuard(AppIdUngatedParking);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setRootPath("/demo-ungated-parking/home"));
    }, []);

    if (match) {
        return <Navigate to={appDefaultRoute} replace />;
    }

    return shouldRender ? (
        <Container maxWidth="xl" disableGutters>
            <CssBaseline />
            <NavbarParkAndRide />
            <Grid className="custom-container">
                <SidebarParkAndRide />

                <div className="content">
                    {locationsTree.length > 0 ? (
                        <Outlet />
                    ) : (
                        <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                            <LoadingWrapper isLoading />
                        </Box>
                    )}
                </div>
            </Grid>
        </Container>
    ) : (
        <LoadingLayout />
    );
}
