/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
import { Auth0UserInfo } from "auth-types";
import { fetchToken } from "./thunks/auth.think";

interface AuthenticatedState {
    token: string;
    user: Auth0UserInfo;
    permissions: string[];
    appCode: string;
    scopes: string[];
    rootPath: string;
}

const initialState = {} as AuthenticatedState;

const slice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<any>) {
            state.user = action.payload as Auth0UserInfo;
        },
        setToken(state, action: PayloadAction<string>) {
            const token = action.payload;
            const { permissions, scope } = jwt_decode(token) as any;

            state.token = token;
            state.permissions = permissions;
            if (scope) {
                const splittedScopes = scope.split(" ");
                if (splittedScopes) {
                    // eslint-disable-next-line no-return-assign, no-sequences
                    const reduced = splittedScopes.reduce((acc: any, curr: string) => ((acc[curr] = true), acc), {});
                    state.scopes = reduced;
                }
            }
        },
        setAppCode(state, action: PayloadAction<string>) {
            state.appCode = action.payload;
        },
        setRootPath(state, action: PayloadAction<string>) {
            state.rootPath = action.payload;
        },
        setScopes(state, action: PayloadAction<string[]>) {
            state.scopes = action.payload;
        },
    },
    extraReducers: {
        [fetchToken.fulfilled.type]: (state: any, action: PayloadAction<string>) => {
            state.token = action.payload;
        },
    },
});

export const { setUser, setToken, setAppCode, setRootPath, setScopes } = slice.actions;
export default slice.reducer;
