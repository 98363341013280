// Report Sidemenu display values for different report types.

import { CommonObjectLiteral } from "types/reports/interfaces";

export const reportSideMenuDisplayValues: CommonObjectLiteral = {
    WORK_FLOW_MANAGEMENT_SUMMARY_REPORT: "Workflow Reports",
    SUMMARY_REPORT: "Management Reports",
    INCIDENT_TRENDS_REPORT: "Incident Reports",
}

export const generateIncidentTrendsReportSubTreeItems = (reportId: string) => [
    {
        id: `${reportId}/lga-overview`,
        displayName: "LGA Overview",
    },
    {
        id: `${reportId}/location-overview`,
        displayName: "Location Overview",
    },
];
