import React from "react";

interface ReportHeadingProps {
    heading: string;
    timerange: string;
}

const ReportHeading: React.FC<ReportHeadingProps> = ({ heading, timerange }) => {
    return (
        <div className="report-heading">
            <span className="main-header">{heading}</span>
            <span className="sub-header">{timerange}</span>
        </div>
    );
};

export default ReportHeading;
