import { ReportTypes } from "types/reports/enums";
import { SummaryReportConfig } from "./summary-report.config";
import { WorkflowManagementSummaryReportConfig } from "./workflow-management-summary-report.config";

/**
 * Provides a report config based on the type of the report
 * @param type Type of the report
 */
export const reportConfigProvider = (type: ReportTypes) => {
    switch (type) {
        case ReportTypes.SUMMARY_REPORT:
            return SummaryReportConfig;
        case ReportTypes.WORK_FLOW_MANAGEMENT_SUMMARY_REPORT:
            return WorkflowManagementSummaryReportConfig;
        default:
    }
};
