import React from "react";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
interface SectionHeadingProps {
    heading: string;
    icon?: any;
}

const SectionHeading: React.FC<SectionHeadingProps> = ({ heading, icon }) => {
    return (
        <div className="section-heading">
            { icon && <div className="icon-div">{icon}</div> }
            <span className="label">{heading}</span>
        </div>
    );
};

export default SectionHeading;
