/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import { FaMapMarkerAlt } from "react-icons/fa";
import { AiFillStar } from "react-icons/ai";
import { IoMdVideocam } from "react-icons/io";
import { OperatingHours } from "components/times/OperatingHours";
import { Box, CircularProgress, ClickAwayListener, IconButton, MenuItem, Paper, Popper, styled } from "@mui/material";
import { MdKeyboardArrowDown } from "react-icons/md";
import IotVisionToolTips from "components/common/IotVisionToolTips";
import { executeGetVideoLinkRequest } from "api/app-taxi-ranking/taxi-video-requests.api";
import { useSelector } from "react-redux";
import { IDevice } from "types";
import { useParams } from "react-router-dom";
import { SCOPES } from "enums/scopes.enum";
import ProtectedView from "components/common/ProtectedView";
import { ERROR_TOOLTIP_NO_CAMERA_DEVICE } from "../../constants";
import { ILocationOpenHours, Location } from "../../reducers/newLocation.reducer";

interface LocationDetailCardProps {
    location: string;
    zone?: string;
    latitude: string;
    longitude: string;
    operatingHours?: ILocationOpenHours;
    enableLiveFootageView?: boolean;
}

const HeaderContentWrapper = styled("div")`
    display: flex;
    align-items: center;
    padding: 0 0 0 0;

    .location-icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 43px;
        height: 43px;
        background: #e7e9f4;
        border-radius: 50%;
        font-size: 16px;
        margin-right: 17px;

        svg {
            color: #6b439d;
            font-size: 20px;
        }
    }
`;

const NameContentWrapper = styled("span")`
    display: inline-block;
`;

const GridInnerContainer = styled(Grid)`
    width: 100%;
`;

const NameWrapper = styled("span")`
    display: inline;
    color: #6b439d;
    font-size: 26px;
    font-weight: 600;
    padding-right: 10px;
`;

const IconWrapper = styled("span")`
    display: inline-flex;
    align-items: center;
    gap: 5px;

    svg {
        font-size: 14px;
    }

    .color-yellow {
        color: #f4b921 !important;
    }
`;

const VideoCameraIcon = styled(IoMdVideocam)`
    display: inline;
    vertical-align: middle;
    font-size: 30px !important;
    color: #6b439d;
`;

const InnerIconWrapper = styled("span")`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    height: 43px;
`;

const LocationDetailCard: React.FC<LocationDetailCardProps> = ({
    location,
    zone,
    latitude,
    longitude,
    operatingHours,
    enableLiveFootageView = false,
}) => {
    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const [openDeviceList, setOpenDeviceList] = useState<boolean>(false);
    const [videoLinkLoading, setVideoLinkLoading] = useState<boolean>(false);
    const [filteredCameraList, setFilteredCameraList] = useState<IDevice[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<Location | undefined>();
    const { locations } = useSelector((state) => state.newLocation);
    const { cameraList } = useSelector((state) => state.device);
    const videoLinkAbortControllerRef = useRef<AbortController | null>(null);
    const { locationId } = useParams();

    useEffect(() => {
        setFilteredCameraList(enableLiveFootageView ? filterDeviceList(cameraList) : []);
    }, [cameraList, enableLiveFootageView, selectedLocation]);

    useEffect(() => {
        const currentLocation = locations?.find((loc) => loc.id === locationId);
        setSelectedLocation(currentLocation);
    }, [locationId, locations]);

    const handleVideoLinkGeneration = async (serialId: string) => {
        try {
            setVideoLinkLoading(true);
            videoLinkAbortControllerRef.current = new AbortController();
            const { signal } = videoLinkAbortControllerRef.current;

            const url = await executeGetVideoLinkRequest(
                {
                    serialId,
                },
                {
                    signal,
                },
            );
            if (url) window.open(url, "_blank", "noopener,noreferrer");
        } catch (err) {
            console.error(err);
        } finally {
            setVideoLinkLoading(false);
        }
    };

    const generateCameraButton = () => {
        if (filteredCameraList.length === 0)
            return (
                <IotVisionToolTips
                    title={ERROR_TOOLTIP_NO_CAMERA_DEVICE}
                    arrow
                    PopperProps={{ style: { whiteSpace: "nowrap" } }}
                >
                    <IconButton
                        id="camera-icon-button"
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                        sx={{ padding: "0 0 1.5px 0", cursor: "default" }}
                    >
                        <VideoCameraIcon />
                    </IconButton>
                </IotVisionToolTips>
            );
        const button =
            filteredCameraList?.length > 1 ? (
                <IconButton
                    id="camera-icon-button"
                    aria-controls={openDeviceList ? "camera-icon-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openDeviceList ? "true" : undefined}
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    sx={{ padding: 0 }}
                    onClick={handleClick}
                    ref={anchorRef}
                >
                    <VideoCameraIcon />
                    <MdKeyboardArrowDown fontSize={25} color="#6b439d" />
                </IconButton>
            ) : (
                <IconButton
                    id="camera-icon-button"
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    sx={{ padding: 0 }}
                    disabled={!filteredCameraList.length}
                    onClick={() => getVideoLink(filteredCameraList[0].serialId)}
                >
                    <VideoCameraIcon />
                </IconButton>
            );
        return button;
    };

    const generateMenuList = () => {
        return (
            <ClickAwayListener onClickAway={handleClickAway}>
                <Popper
                    id="camera-icon-menu"
                    open={openDeviceList}
                    anchorEl={anchorRef.current}
                    placement="bottom-end"
                    disablePortal
                >
                    <Paper>
                        {filteredCameraList.length > 1 ? (
                            filteredCameraList.map((device) => (
                                <MenuItem id={device.id} onClick={() => getVideoLink(device.serialId)} disableRipple>
                                    {device.name}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem disableRipple>No Options</MenuItem>
                        )}
                    </Paper>
                </Popper>
            </ClickAwayListener>
        );
    };

    const renderVideoButton = () => {
        if (enableLiveFootageView)
            return videoLinkLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                    <CircularProgress sx={{ color: "#6b439d" }} size={18} />
                </Box>
            ) : (
                <ProtectedView filterScopes={[SCOPES.READ_DEVICE_VIDEO]} shouldHide>
                    <>
                        {generateCameraButton()}
                        {generateMenuList()}
                    </>
                </ProtectedView>
            );
    };

    const getVideoLink = (serialId: string) => {
        handleVideoLinkGeneration(serialId);
        setOpenDeviceList(false);
    };

    const handleClick = () => {
        setOpenDeviceList((prevOpen) => !prevOpen);
    };

    const handleClickAway = (event: MouseEvent | TouchEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as Node)
        ) {
            return;
        }
        setOpenDeviceList(false);
    };

    const filterDeviceList = (devices: IDevice[]) => {
        return devices.filter((device) => device.locationId === selectedLocation?.id);
    };

    return (
        <Grid container className="region-statlocation-card-wrapper m-0">
            <Grid item xs={12} className="region-statlocation-card-Innerwrapper">
                <GridInnerContainer container item xs={12}>
                    <HeaderContentWrapper>
                        <div className="location-icon-wrapper">
                            <FaMapMarkerAlt />
                        </div>
                        <NameContentWrapper>
                            <NameWrapper className="stat-location-header">{location}</NameWrapper>
                            <IconWrapper>
                                <InnerIconWrapper sx={{ width: filteredCameraList.length > 1 ? "65px" : "55px" }}>
                                    <AiFillStar className="color-yellow" />
                                    {renderVideoButton()}
                                </InnerIconWrapper>
                            </IconWrapper>
                        </NameContentWrapper>
                    </HeaderContentWrapper>
                </GridInnerContainer>
                <Grid item xs={12} className="region-statlocation-innaer-wrapper_bottom">
                    <Grid item xs={12} className="region-statlocation-row-left">
                        <Grid item xs={12}>
                            {zone ? <div className="region-statlocation-row-subrow">LGA: {zone}</div> : null}
                            <div className="region-statlocation-row-subrow lgaData">
                                {latitude}, {longitude}
                            </div>
                        </Grid>
                    </Grid>
                    <OperatingHours operatingHours={operatingHours} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LocationDetailCard;
