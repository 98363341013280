import { Autocomplete, Typography, AutocompleteProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
/* eslint @typescript-eslint/no-unused-vars: ["off"] */
interface IIotVisionAutoComplete {
    additionalProps?: any;
}
const CustomizedAutoComplete = styled(Autocomplete)(
    ({ theme }) => `

    color: ${theme.palette.primary.main};

    & .MuiOutlinedInput-input {
        color: ${theme.palette.secondary.main} !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        margin: -8px !important;
        margin-left: 3px !important;
    }
`,
);

const IotVisionAutoComplete: React.FC<
    IIotVisionAutoComplete & AutocompleteProps<any, any, any, any, any> & JSX.IntrinsicAttributes
> = ({ additionalProps, ...rest }) => {
    return (
        <CustomizedAutoComplete
            disablePortal
            renderOption={(option: any) => {
                return (
                    <Typography
                        {...option}
                        key={option.id}
                        style={{ fontSize: "14px" }}
                    >
                        {option?.key}
                    </Typography>
                );
            }}
            {...rest}
        />
    );
};

export default IotVisionAutoComplete;
