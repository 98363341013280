import Layout from "layouts/app-oversized-vehicles";
import { ProtectedLayout } from "layouts/common/general";
import { Navigate } from "react-router-dom";
import Incident from "./incident/Incident";
import LocationPage from "./location";
import notFound from "./not-found";

export const appOVPrefix = "posoms";
export const directUrl = "/posoms";
export const appOVDefaultRoute = `/${appOVPrefix}`;
export enum pagePath {
    Location = "location/:locationId",
    Detection = "detections",
}

const root = [
    {
        path: appOVDefaultRoute,
        element: (
            <ProtectedLayout>
                <Layout />
            </ProtectedLayout>
        ),
        children: [
            { path: "", element: <Navigate to={pagePath.Detection} replace /> },
            { path: pagePath.Detection, element: <Incident /> },
            { path: pagePath.Location, element: <LocationPage /> },
            { ...notFound },
        ],
    },
];
export default root;
