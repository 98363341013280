import { TimeRange } from "features/common/date-header/DateRangePicker";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import PaperWrapper from "components/wrappers/PaperWrapper";
import { Box, Grid, IconButton, Stack } from "@mui/material";
import { CgArrowsExpandRight } from "react-icons/cg";
import FullScreenDialog from "components/dialogs/FullScreenDialog";
import { useSelector } from "react-redux";
import { LoadingWrapper } from "components/wrappers/LoadingWrapper";

export interface XYData {
    x: Date;
    y: number;
}

export interface IData {
    label: string;
    data: XYData[];
    borderColor?: string;
    tension?: number;
}

interface AverageLineChartProps {
    data: IData[];
    fromTime: string;
    toTime: string;
    timeUnit?: TimeRange;
    useLegend?: boolean;
    roundTimeDisabled?: boolean;
    graphHeader?: string;
}

export const generateDatesToGraph = (fromDate: string, toDate: string, numOfDates?: number) => {
    if (!fromDate || !toDate) {
        const from = new Date();
        if (numOfDates) {
            const clonedFrom = new Date(from);
            return new Date(clonedFrom.setDate(clonedFrom.getDate() + numOfDates));
        }
        return from;
    }
    const middleDate = new Date((new Date(fromDate).getTime() + new Date(toDate).getTime()) / 2);
    if (numOfDates) {
        const modifiedDate = new Date(middleDate.setUTCHours(new Date(fromDate).getUTCHours() - 1, 0, 0, 0));
        return new Date(modifiedDate.setDate(modifiedDate.getDate() + numOfDates));
    }
    return new Date(middleDate.setUTCHours(new Date(fromDate).getUTCHours(), 0, 0, 0));
};

const AverageLineChart: React.FC<AverageLineChartProps> = ({
    data,
    fromTime,
    toTime,
    timeUnit,
    graphHeader,
    useLegend = true,
    roundTimeDisabled = false,
}) => {
    const [scaleUnit, setScaleUnit] = useState<TimeRange | undefined>(TimeRange.Hour);
    const [modalShow, setModalShow] = React.useState(false);
    const { isReportLoading } = useSelector((state) => state.report);

    useEffect(() => {
        setScaleUnit(timeUnit);
    }, [timeUnit]);

    const handleClick = (status: boolean) => {
        setModalShow(status);
    };

    return (
        <>
            <PaperWrapper cusStyle={{ paddingTop: 6, paddingBottom: 6 }}>
                {graphHeader && (
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            display="flex"
                            alignItems="flex-end"
                            paddingTop={0.5}
                        >
                            <Stack className="section-heading">
                                <span
                                    className="label"
                                    style={{ fontSize: "14px", display: "flex", alignItems: "flex-end" }}
                                >
                                    {graphHeader}
                                </span>
                            </Stack>
                        </Grid>
                    </Grid>
                )}
                <Grid container>
                    <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                        <IconButton className="expand-btn" onClick={() => handleClick(true)} disableRipple>
                            <CgArrowsExpandRight />
                        </IconButton>
                    </Grid>
                </Grid>
                {isReportLoading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="20vh">
                        <LoadingWrapper isLoading />
                    </Box>
                ) : (
                    <Line
                        data={{
                            datasets: data,
                        }}
                        options={{
                            maintainAspectRatio: true,
                            scales: {
                                x: {
                                    type: "time",
                                    min: generateDatesToGraph(fromTime, toTime).getTime(),
                                    max: generateDatesToGraph(fromTime, toTime, 1).getTime(),
                                    time: {
                                        unit: scaleUnit,
                                        round: !roundTimeDisabled && scaleUnit,
                                    },
                                    ticks: {
                                        autoSkip: false,
                                        maxRotation: 90,
                                        minRotation: 90,
                                        callback: (value) => {
                                            const [hour, period] = String(value).split(/(?=[AP]M)/);
                                            const modifiedHour = String(hour).padStart(2, "0");
                                            return `${modifiedHour}.00 ${period.toLowerCase()}`;
                                        },
                                    },
                                },
                                y: {
                                    type: "linear",
                                    min: 0,
                                },
                            },
                            plugins: {
                                legend: {
                                    display: useLegend,
                                    position: "top",
                                },
                                tooltip: {
                                    enabled: true,
                                    callbacks: {
                                        label: (context) => {
                                            const label = context.dataset.label || "";
                                            const value = context.parsed.y.toFixed(2);
                                            return `${label}: ${value}`;
                                        },
                                        title: (context) => {
                                            const title = context[0].label || "";
                                            return new Date(title).toLocaleTimeString();
                                        },
                                    },
                                },
                            },
                        }}
                    />
                )}
            </PaperWrapper>
            <FullScreenDialog
                modalShow={modalShow}
                onClose={handleClick}
                childComponent={
                    <PaperWrapper>
                        {graphHeader && (
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    display="flex"
                                    alignItems="flex-end"
                                >
                                    <div className="section-heading">
                                        <span
                                            className="label"
                                            style={{ fontSize: "20px", display: "flex", alignItems: "flex-end" }}
                                        >
                                            {graphHeader}
                                        </span>
                                    </div>
                                </Grid>
                            </Grid>
                        )}
                        {isReportLoading ? (
                            <Box display="flex" justifyContent="center" alignItems="center" minHeight="20vh">
                                <LoadingWrapper isLoading />
                            </Box>
                        ) : (
                            <Line
                                data={{
                                    datasets: data,
                                }}
                                options={{
                                    maintainAspectRatio: true,
                                    scales: {
                                        x: {
                                            type: "time",
                                            min: generateDatesToGraph(fromTime, toTime).getTime(),
                                            max: generateDatesToGraph(fromTime, toTime, 1).getTime(),
                                            time: {
                                                unit: scaleUnit,
                                                round: !roundTimeDisabled && scaleUnit,
                                            },
                                            ticks: {
                                                autoSkip: false,
                                                maxRotation: 90,
                                                minRotation: 90,
                                                callback: (value) => {
                                                    const [hour, period] = String(value).split(/(?=[AP]M)/);
                                                    const modifiedHour = String(hour).padStart(2, "0");
                                                    return `${modifiedHour}.00 ${period.toLowerCase()}`;
                                                },
                                            },
                                        },
                                        y: {
                                            type: "linear",
                                            min: 0,
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            display: useLegend,
                                            position: "top",
                                        },
                                        tooltip: {
                                            enabled: true,
                                            callbacks: {
                                                label: (context) => {
                                                    const label = context.dataset.label || "";
                                                    const value = context.parsed.y.toFixed(2);
                                                    return `${label}: ${value}`;
                                                },
                                                title: (context) => {
                                                    const title = context[0].label || "";
                                                    return new Date(title).toLocaleTimeString();
                                                },
                                            },
                                        },
                                    },
                                }}
                            />
                        )}
                    </PaperWrapper>
                }
            />
        </>
    );
};

export default AverageLineChart;
