/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { pickerRanges } from "components/common/CommonDateRangePicker";

interface TimeState {
    startTime: any;
    endTime: any;
    timeType?: string;
    selectedRange: pickerRanges;
}

const date1 = new Date();
const date2 = new Date();
date2.setHours(date2.getHours() - 24);
date1.setSeconds(0, 0);
date2.setSeconds(0, 0);
const start = date2.toISOString();
const end = date1.toISOString();

const initialState = {
    startTime: start,
    endTime: end,
    timeType: "hour",
    selectedRange: pickerRanges.Last24Hours,
} as TimeState;

const slice = createSlice({
    name: "time",
    initialState,
    reducers: {
        resetTimeRange(state) {
            const { startTime, endTime, timeType, selectedRange } = initialState;
            state.startTime = startTime;
            state.endTime = endTime;
            state.timeType = timeType;
            state.selectedRange = selectedRange;
        },
        setTimeRange(state, action: PayloadAction<any>) {
            const { startTime, endTime, timeType, selectedRange } = action.payload;
            state.startTime = startTime;
            state.endTime = endTime;
            state.timeType = timeType || state.timeType;
            state.selectedRange = selectedRange;
        },
    },
});

export const { setTimeRange, resetTimeRange } = slice.actions;
export default slice.reducer;
