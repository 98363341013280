/* eslint-disable no-underscore-dangle */
/* eslint @typescript-eslint/no-explicit-any: ["off"] */
import { ReportDataTypes, ReportGraphColors, ReportGraphTypes } from "types/reports/enums";
import { IReportConfig } from "types/reports/interfaces";
import { camelCaseToTitleCase } from "utils/common";
import formatDate from "utils/date-format";
import {
    REPORT_FONT_FAMILY,
    REPORT_TITLE_FONT_SIZE,
    REPORT_LEGEND_FONT_SIZE,
    REPORT_TOOLTIP_TITLE_FONT_SIZE,
    REPORT_TOOLTIP_BODY_FONT_SIZE,
    REPORT_SCALES_FONT_SIZE,
} from "constants/report.constants";
import { addPeriodSuffix } from "utils/report-chart-utils";
import {
    IncidentsRaisedByWeekData,
    IncidentsRaisedByTypeData,
    IncidentPerDevicePerWeekData,
    incidentMarkedAsBreachByWeekData,
} from "../../../types/reports/interfaces";


// Custom options to be passed into the chart.js graph to customize the base graph.
const potentialIncidentsRaisedByWeekCustomOptions = {
    maintainAspectRatio: false,
    plugins: {
        title: {
            display: false,
            text: "Potential Incidents Raised",
            font: {
                family: REPORT_FONT_FAMILY,
                size: REPORT_TITLE_FONT_SIZE,
            },
        },
        legend: {
            labels: {
                font: {
                    family: REPORT_FONT_FAMILY,
                    size: REPORT_LEGEND_FONT_SIZE,
                },
            },
        },
        tooltip: {
            titleFont: {
                family: REPORT_FONT_FAMILY,
                size: REPORT_TOOLTIP_TITLE_FONT_SIZE,
            },
            bodyFont: {
                family: REPORT_FONT_FAMILY,
                size: REPORT_TOOLTIP_BODY_FONT_SIZE,
            },
            callbacks: {
                afterLabel: (context: any) => {
                    let total = 0;
                    for (let i = 0; i < 3; i++) {
                        total += context.parsed._stacks?.y[i] || 0;
                    }
                    return `Total incident count: ${total}`;
                },
            },
        },
    },
    responsive: true,
    scales: {
        x: {
            stacked: true,
            grid: {
                display: true,
            },
            title: {
                font: {
                    family: REPORT_FONT_FAMILY,
                    size: REPORT_SCALES_FONT_SIZE,
                },
            },
        },
        y: {
            stacked: true,
            grid: {
                display: true,
            },
            title: {
                font: {
                    family: REPORT_FONT_FAMILY,
                    size: REPORT_SCALES_FONT_SIZE,
                },
            },
        },
    },
};

// Processor functions to be used by each graph to format the response data to the chart accepted format
const dateOptions: Intl.DateTimeFormatOptions = { day: "2-digit", month: "2-digit", year: "2-digit" };
const processIncidentsRaisedByWeek = (data: IncidentsRaisedByWeekData[]) => {
    const labels = data?.map((item) => formatDate(item.endDate, dateOptions));
    const doubleParkingData = data?.map((item) => item.data.doubleParkingIncidents);
    const outOfOperatingHoursData = data?.map((item) => item.data.outOfOperatingHours);
    const carInTaxiRankData = data?.map((item) => item.data.carInRanks);
    const datasets = [
        {
            label: "Double Parking",
            data: doubleParkingData,
            backgroundColor: ReportGraphColors.DARK_PURPLE,
        },
        {
            label: "Out of Operating Hours",
            data: outOfOperatingHoursData,
            backgroundColor: ReportGraphColors.DARK_SALMON,
        },
        {
            label: "Car In Taxi Rank",
            data: carInTaxiRankData,
            backgroundColor: ReportGraphColors.DARK_YELLOW,
        },
    ];
    return { labels, datasets };
};

const processIncidentsRaisedByTypes = (data: IncidentsRaisedByTypeData[]) => {
    const labels = data?.map((item) => camelCaseToTitleCase(item.type));
    const values = data?.map((item) => item.value);
    const zeroIndexes: number[] = [];
    const filteredValues = values?.filter((item, index) => {
        if (item !== 0) {
            return true;
        }
        zeroIndexes.push(index);
        return false;
    });

    for (let i = zeroIndexes.length - 1; i >= 0; i--) {
        labels.splice(zeroIndexes[i], 1);
    }

    const datasets = [
        {
            label: "Incidents Raised by Type",
            data: filteredValues,        
            backgroundColor: labels?.map((label) => {
                switch (label) {
                    case "Double Parking Incidents":
                        return ReportGraphColors.DARK_PURPLE;
                    case "Out Of Operating Hours":
                        return ReportGraphColors.DARK_SALMON;
                    case "Car In Ranks":
                        return ReportGraphColors.DARK_YELLOW;
                    default:
                        return ReportGraphColors.LIGHT_YELLOW;
                }
            }),
        },
    ];

    return { labels, datasets };
};

const processIncidentPerDevicePerWeek = (data: IncidentPerDevicePerWeekData[]) => {
    const labels = data?.map((item) => formatDate(item.endDate, dateOptions));
    const values = data?.map((item) => item.value);
    const datasets = [
        {
            type: "line",
            borderColor: ReportGraphColors.DARK_PURPLE,
            lineTension: 0.25,
            label: "Average Potential Incidents Per Location",
            data: values,
            backgroundColor: ReportGraphColors.DARK_PURPLE,
        },
    ];

    return { labels, datasets };
};

const processIncidentMarkedAsBreachByWeek = (data: incidentMarkedAsBreachByWeekData[], reportPeriod?: string) => {
    const labels = data?.map((item) => formatDate(item.endDate, dateOptions));
    const values = data?.map((item) => item.value);
    const datasets = [
        {
            label: addPeriodSuffix("% Incidents Marked as Breach", reportPeriod),
            data: values,
            backgroundColor: ReportGraphColors.DARK_PURPLE,
        },
    ];

    return { labels, datasets };
};

export const SummaryReportConfig: IReportConfig = {
    reportTitle: "Management Overview",
    cards: [
        {
            text: "Ranks",
            icon: "FaMapPin",
            attributeName: "ranks",
        },
        {
            text: "Taxi detected",
            icon: "FaTaxi",
            attributeName: "taxiDetected",
        },
        {
            text: "Non-taxi detected",
            icon: "FaCar",
            attributeName: "nonTaxiDetected",
        },
        {
            text: "Passengers",
            icon: "FaUser",
            attributeName: "passengers",
        },
        {
            text: "Potential Incidents Raised",
            icon: "FaExclamationTriangle",
            attributeName: "potentialIncidentsRaised",
        },
        {
            text: "Potential Incidents Processed",
            icon: "FaEye",
            attributeName: "potentialIncidentsProcessed",
        },
        {
            text: "Incidents Marked as Breach count",
            icon: "FaCheckCircle",
            attributeName: "incidentsMarkedAsBreachCount",
        },
        {
            text: "Incidents Marked as Breach",
            icon: "FaPercentage",
            attributeName: "incidentsMarkedAsBreach",
            dataType: ReportDataTypes.PERCENTAGE,
            relatedAttributes: ["potentialIncidentsProcessed", "incidentsMarkedAsBreachCount"],
        },
        {
            text: "Potential Car Parked on Taxi ranks",
            icon: "FaExclamationTriangle",
            attributeName: "carInRanks",
        },
        {
            text: "Potential Car In Rank Processed",
            icon: "FaEye",
            attributeName: "carInRanksProcessed",
        },
        {
            text: "Car In Ranks Marked as Breach count",
            icon: "FaCheckCircle",
            attributeName: "carInRanksIncidentsMarkedAsBreachCount",
        },
        {
            text: "Car In Ranks Incidents Marked as Breach",
            icon: "FaPercentage",
            attributeName: "carInRanksIncidentsMarkedAsBreach",
            dataType: ReportDataTypes.PERCENTAGE,
            relatedAttributes: ["carInRanksProcessed", "carInRanksIncidentsMarkedAsBreachCount"],
        },
        {
            text: "Potential Double Parking incidents",
            icon: "FaExclamationTriangle",
            attributeName: "doubleParkingIncidents",
        },
        {
            text: "Potential Double Parking Processed",
            icon: "FaEye",
            attributeName: "doubleParkingProcessed",
        },
        {
            text: "Double Parking Marked as Breach count",
            icon: "FaCheckCircle",
            attributeName: "doubleParkingIncidentsMarkedAsBreachCount",
        },
        {
            text: "Double Parking Incidents Marked as Breach",
            icon: "FaPercentage",
            attributeName: "doubleParkingIncidentsMarkedAsBreach",
            dataType: ReportDataTypes.PERCENTAGE,
            relatedAttributes: ["doubleParkingProcessed", "doubleParkingIncidentsMarkedAsBreachCount"],
        },
        {
            text: "Potential Out of Operating Hours",
            icon: "FaExclamationTriangle",
            attributeName: "outOfOperatingHours",
        },
        {
            text: "Potential Out of Operating Hours Processed",
            icon: "FaEye",
            attributeName: "outOfOperatingHoursProcessed",
        },
        {
            text: "Out of Operating Hours Marked as Breach count",
            icon: "FaCheckCircle",
            attributeName: "outOfOperatingHoursIncidentsMarkedAsBreachCount",
        },
        {
            text: "Out of Operating Hours Incidents Marked as Breach",
            icon: "FaPercentage",
            attributeName: "outOfOperatingHoursIncidentsMarkedAsBreach",
            dataType: ReportDataTypes.PERCENTAGE,
            relatedAttributes: ["outOfOperatingHoursProcessed", "outOfOperatingHoursIncidentsMarkedAsBreachCount"],
        },
    ],
    graphs: [
        {
            graphTitle: "Potential Incidents Raised",
            graphType: ReportGraphTypes.STACKED_BAR,
            attributeName: "incidentsRaised",
            processorFunction: processIncidentsRaisedByWeek,
            options: potentialIncidentsRaisedByWeekCustomOptions,
            shouldAddPeriodSuffix: true,
        },
        {
            graphTitle: "Incidents Raised by Type",
            graphType: ReportGraphTypes.PIE,
            attributeName: "incidentsRaisedByType",
            processorFunction: processIncidentsRaisedByTypes,
            shouldAddPeriodSuffix: false,
        },
        {
            graphTitle: "Average Potential Incidents Per Location",
            graphType: ReportGraphTypes.BAR,
            attributeName: "incidentsPerDevice",
            processorFunction: processIncidentPerDevicePerWeek,
            shouldAddPeriodSuffix: false,
        },
        {
            graphTitle: "% Incidents Marked as Breach",
            graphType: ReportGraphTypes.BAR,
            attributeName: "incidentsMarkedAsBreach",
            processorFunction: processIncidentMarkedAsBreachByWeek,
            shouldAddPeriodSuffix: true,
        },
    ],
};
