import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export function useQueryParams() {
    const { pathname, search } = useLocation();
    const [data, setData] = useState({});

    useEffect(() => {
        if (search) {
            const obj = Object.fromEntries(new URLSearchParams(search));
            setData(obj);
        } else {
            setData({});
        }
    }, [pathname, search]);

    /* eslint @typescript-eslint/no-explicit-any: ["off"] */
    return data as any;
}
