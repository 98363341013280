import { styled } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";

export const StyledDataTable = styled(DataGrid)(({ theme }) => ({
    width: "100%",
    [`& .${gridClasses.cell}`]: {
        color: "#001B4F !important",
        borderBottom: "0px",
    },
    [theme.breakpoints.up("xl")]: {
        [`& .${gridClasses.cell}`]: {
            color: "#001B4F !important",
            marginRight: "12px",
            borderBottom: "0px",
        },
        [`& .${gridClasses.columnHeader}`]: {
            marginTop: "-6px",
            marginRight: "12px",
        },
    },
    [`& .${gridClasses.columnHeaders}`]: {
        borderBottom: "0px",
    },
    [`& .${gridClasses.footerContainer}`]: {
        borderTop: "0px",
    },
    [`& .${gridClasses.columnHeaderTitle}`]: {
        color: "#001B4F !important",
    },
    "& .MuiDataGrid-row:nth-child(odd)": {
        backgroundColor: "#F5F8FD",
    },
    "& .disableRow": {
        opacity: 0.4,
    },
}));
