import { NavigateFunction } from "react-router";

export const navigateTo404Page = (navigate: NavigateFunction, appCode: string, navigateToPage = true): void => {
    if (navigateToPage) {
        navigate(`/${appCode}/page-not-found`, { replace: true });
    } else {
        navigate(`/${appCode}/home/page-not-found`, { replace: true });
    }
};

export default navigateTo404Page;
