/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box } from "@mui/material";

const NoReports = (): JSX.Element => {
    return (
        <Box className="reports-message-outer-box">
            <Box className="reports-message-inner-box">
                <div className="reports-message-header">No reports to be displayed.</div>
                <div className="report-message-upper-content">
                    There are no report results to be displayed. Please check whether the account has necessary
                    permissions
                </div>
                <div className="report-message-lower-content">and schedules are in place to generate reports.</div>
            </Box>
        </Box>
    );
};

export default NoReports;
