import React from "react";
import CycleWaysLayout from "layouts/app-cycle-ways";
import Dashboard from "pages/app-cycle-ways/bike-racks/dashboard";
import Location from "pages/app-cycle-ways/bike-racks/location";
import { ProtectedLayout } from "layouts/common/general";
import { APPLICATION_CYCLEWAYS_BIKE_RACKS } from "constants/app.constants";

const root = {
    path: APPLICATION_CYCLEWAYS_BIKE_RACKS,
    element: (
        <ProtectedLayout>
            <CycleWaysLayout />
        </ProtectedLayout>
    ),
    children: [
        { path: "", element: <Dashboard /> },
        { path: ":locationId", element: <Dashboard /> },
        { path: "location/:locationId", element: <Location /> },
    ],
    replace: true,
};

export default root;
