import React from "react";
import PaperWrapper from "components/wrappers/PaperWrapper";
import { GridColDef } from "@mui/x-data-grid";
import IotVisionTable from "components/common/IotVisionTable";
import { Grid, IconButton, Stack, styled } from "@mui/material";
import { CommonObjectLiteral } from "types/reports/interfaces";
import { CgArrowsExpandRight } from "react-icons/cg";
import FullScreenDialog from "components/dialogs/FullScreenDialog";
import { useSelector } from "react-redux";

const CustomizedIotVisionTable = styled(IotVisionTable)(() => ({
    "& .MuiDataGrid-footerContainer": { minHeight: "20px" },
}));

export interface IncidentReportTableProps {
    columnHeaders: GridColDef[];
    tableHeader: string;
    rowData: CommonObjectLiteral[];
}

function IncidentReportTable({ columnHeaders, tableHeader, rowData }: IncidentReportTableProps) {
    const [modalShow, setModalShow] = React.useState(false);
    const { isReportLoading } = useSelector((state) => state.report);

    const handleClick = (status: boolean) => {
        setModalShow(status);
    };

    return (
        <>
            <PaperWrapper cusStyle={{ paddingTop: 6, paddingBottom: 6 }}>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        paddingTop={0.5}
                        display="flex"
                        alignItems="flex-end"
                    >
                        <Stack className="section-heading">
                            <span
                                className="label"
                                style={{ fontSize: "14px", display: "flex", alignItems: "flex-end" }}
                            >
                                {tableHeader}
                            </span>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                        <IconButton className="expand-btn" onClick={() => handleClick(true)} disableRipple>
                            <CgArrowsExpandRight />
                        </IconButton>
                    </Grid>
                </Grid>
                <CustomizedIotVisionTable
                    loading={isReportLoading}
                    columns={columnHeaders}
                    rows={isReportLoading ? [] : rowData}
                    sortingMode="server"
                    rowCount={rowData.length}
                    hideFooterPagination
                />
            </PaperWrapper>
            <FullScreenDialog
                modalShow={modalShow}
                onClose={handleClick}
                childComponent={
                    <PaperWrapper cusStyle={{ paddingTop: 6, paddingBottom: 6 }}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" alignItems="flex-end">
                                <div className="section-heading">
                                    <span
                                        className="label"
                                        style={{ fontSize: "20px", display: "flex", alignItems: "flex-end" }}
                                    >
                                        {tableHeader}
                                    </span>
                                </div>
                            </Grid>
                        </Grid>
                        <CustomizedIotVisionTable
                            loading={isReportLoading}
                            columns={columnHeaders}
                            rows={isReportLoading ? [] : rowData}
                            sortingMode="server"
                            rowCount={rowData.length}
                            hideFooterPagination
                        />
                    </PaperWrapper>
                }
            />
        </>
    );
}

export default IncidentReportTable;
