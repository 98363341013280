import {
    eachDayOfInterval,
    eachHourOfInterval,
    eachMinuteOfInterval,
    eachMonthOfInterval,
    isAfter,
    isBefore,
} from "date-fns";
import { TimeRange } from "features/common/date-header/DateRangePicker";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

interface XyData {
    x: Date;
    y: number;
}
interface IData {
    label: string;
    data: XyData[];
    borderColor: string;
    tension: number;
}
interface LineChartProps {
    data: IData[];
    isFillEmpty?: boolean;
    fromTime: string;
    toTime: string;
    timeUnit?: string;
    useLegend?: boolean;
    roundTimeDisabled?: boolean;
    yAxisDecimalDisabled?: boolean;
}

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
const addZeroWithEmptyData = (dataSets: IData[], fromTime: string, toTime: string, scaleUnit: any) => {
    return dataSets.map((data) => {
        return {
            ...data,
            data: addXYDataWithZeros(data.data, fromTime, toTime, scaleUnit),
        };
    });
};

const getTimeRangeFunction = (scaleUnit: any) => {
    let timeFunc;
    switch (scaleUnit) {
        case TimeRange?.Minutes:
            timeFunc = eachMinuteOfInterval;
            break;
        case TimeRange?.Day:
            timeFunc = eachDayOfInterval;
            break;
        case TimeRange?.Month:
            timeFunc = eachMonthOfInterval;
            break;
        default:
            timeFunc = eachHourOfInterval;
    }
    return timeFunc;
};

const addXYDataWithZeros = (data: XyData[], fromTime: string, toTime: string, scaleUnit: any) => {
    let modifiedData: XyData[] = data;
    if (data && Array.isArray(data) && data.length !== 0) {
        const fromTimePoint = new Date(fromTime);
        const actualDataStartTimePoint = new Date(data[0]?.x);
        if (isBefore(fromTimePoint, actualDataStartTimePoint)) {
            const startRangeDateArray = getTimeRangeFunction(scaleUnit)({
                start: fromTimePoint,
                end: actualDataStartTimePoint,
            });
            if (startRangeDateArray && Array.isArray(startRangeDateArray) && startRangeDateArray.length !== 0) {
                const sub: { x: Date; y: number }[] = [];
                startRangeDateArray.forEach((date, index) => {
                    if (index !== startRangeDateArray.length - 1) sub.push({ x: date, y: 0 });
                });
                modifiedData = sub.concat(data);
            }
        }

        const toTimePoint = new Date(toTime);
        const actualDataEndTimePoint = new Date(data[data.length - 1]?.x);
        if (isAfter(toTimePoint, actualDataEndTimePoint)) {
            const endRangeDateArray = getTimeRangeFunction(scaleUnit)({
                start: actualDataEndTimePoint,
                end: toTimePoint,
            });
            if (endRangeDateArray && Array.isArray(endRangeDateArray) && endRangeDateArray.length !== 0) {
                endRangeDateArray.forEach((date, index) => {
                    if (index !== 0) modifiedData.push({ x: date, y: 0 });
                });
            }
        }
    }
    return modifiedData;
};

const LineChart: React.FC<LineChartProps> = ({
    isFillEmpty,
    data,
    fromTime,
    toTime,
    timeUnit,
    useLegend = true,
    roundTimeDisabled = false,
    yAxisDecimalDisabled = false,
}) => {
    const [scaleUnit, setScaleUnit] = useState<any>(TimeRange?.Hour);
    const [modifyData, setModifyData] = useState<IData[]>([]);

    useEffect(() => {
        setScaleUnit(timeUnit);
    }, [timeUnit]);

    useEffect(() => {
        const modifiedData = isFillEmpty ? addZeroWithEmptyData(data, fromTime, toTime, scaleUnit) : data;
        setModifyData(modifiedData);
    }, [data, isFillEmpty, scaleUnit]);

    return (
        <div>
            <Line
                data={{
                    datasets: modifyData,
                }}
                options={{
                    maintainAspectRatio: true,
                    scales: {
                        x: {
                            type: "time",
                            min: new Date(fromTime).valueOf(),
                            max: new Date(toTime).valueOf(),
                            time: {
                                unit: scaleUnit,
                                round: !roundTimeDisabled && scaleUnit,
                            },
                        },
                        y: {
                          afterBuildTicks: (scale) => {
                            if (yAxisDecimalDisabled)
                                scale.ticks = scale.ticks.filter((tick) => Number.isInteger(tick.value));
                          },
                        },
                    },
                    plugins: {
                        legend: {
                            display: useLegend,
                        },
                    },
                }}
            />
        </div>
    );
};

export default LineChart;
