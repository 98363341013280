import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "store";
import SectionHeading from "components/headings/SectionHeading";
import { AiFillAppstore } from "react-icons/ai";
import StatisticsCards from "features/app-park-and-ride/statistics/StatisticsCards";
import PaperWrapper from "components/wrappers/PaperWrapper";
import Map from "features/app-park-and-ride/map/Map";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { executeGetDetectionsByLocationsRequest } from "api/common/detections.api";
import { CLASS_VEHICLE } from "constants/class.constants";
import { buildLocationAddressStr } from "utils/build-location-str";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import { AppParkAndRidePrefix } from "constants/app.constants";
import { LocationDetection } from "types/location-group/location-detection";
import { Location } from "reducers/newLocation.reducer";
import { useNavigate } from "react-router";
import HeaderDatePicker from "features/common/date-header/HeaderDatePicker";
import { checkLocationIdChanged, FetchFunctionParams, makeApiCallWithUpdateTime } from "utils/common";
import platformityLogo from "assets/images/platformity-favicon-logo.png";
import { getChildrenCounts } from "utils/get-children-count-in-tree";
import { navigateTo404Page } from "utils/navigation";
import { resetTimeRange } from "reducers/time.reducer";
import GroupSummeryHeader from "./GroupSummaryCard";

export interface DetectionsData {
    groupId?: string;
    fromTime: string;
    toTime: string;
    classification: string | unknown;
    locationId?: string;
}

const ParkAndRideDashBoard = (): JSX.Element => {
    const { appCode } = useSelector((state) => state.auth);
    const { locations } = useSelector((state) => state.newLocation);
    const locationsAbortControllerRef = useRef<AbortController | null>(null);
    const [selectedGroup, setSelectedGroup] = useState<Location>();
    const [selected, setSelected] = useState<Location>();
    const [childrenCount, setChildrenCount] = useState<number>(0);
    const [isGroup, setIsGroup] = useState<boolean>(false);
    const [title, setTitle] = useState<string>("");
    const { locationId } = useParams();
    const { startTime, endTime, selectedRange } = useSelector((state) => state.time);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const fetchData = async ({ startTime, endTime }: FetchFunctionParams) => {
        if (locations.length > 0) {
            const selectedLocation: Location | undefined = locations.find((loc) => loc.id === locationId);
            const parentId: string | undefined = selectedLocation?.parentId;
            if (parentId) {
                await getDetectionsByLocations({
                    groupId: parentId,
                    fromTime: startTime,
                    toTime: endTime,
                    classification: CLASS_VEHICLE,
                    locationId,
                });
            } else {
                await getDetectionsByLocations({
                    fromTime: startTime,
                    toTime: endTime,
                    classification: CLASS_VEHICLE,
                });
            }
        }
    };

    async function getDetectionsByLocations(data?: DetectionsData): Promise<void> {
        try {
            locationsAbortControllerRef.current = new AbortController();
            const { signal } = locationsAbortControllerRef.current;
            const response = await executeGetDetectionsByLocationsRequest(data, {
                signal,
                disableNotification: checkLocationIdChanged(locationId, locations),
            });
            if (response && Array.isArray(response)) {
                const sortedLocationByDetection: LocationDetection[] = [];
                response
                    .sort((a, b) => parseInt(b.count) - parseInt(a.count))
                    .slice(0, 5)
                    .forEach((detection) => {
                        const location: Location | undefined = locations.find(
                            (location) => location.id === detection.locationId,
                        );
                        const group: Location | undefined = locations.find(
                            (loc: Location) => loc.id === location?.parentId,
                        );

                        if (location) {
                            sortedLocationByDetection.push({
                                ...location,
                                locationName: location?.name,
                                address: buildLocationAddressStr(location),
                                location: "View Location",
                                groupName: group?.name,
                            });
                        }
                    });
            }
        } catch (e) {
            console.log(e);
        }
    }

    const handleChildrenData = (location: Location) => {
        const childrenDetails = getChildrenCounts([location]);
        setChildrenCount(childrenDetails[location.id]);
    };

    useEffect(() => {
        let selected!: Location | undefined;
        if (locationId) {
            selected = locations.find((loc: Location) => loc.id === locationId);

            if (selected?.type === "region") {
                setIsGroup(true);
                setTitle("");
                handleChildrenData(selected);
            }
            if (selected?.type === "zone") {
                setIsGroup(true);
                setTitle("");
                handleChildrenData(selected);
            }
            if (selected) {
                setSelectedGroup(selected);
            } else {
                navigateTo404Page(navigate, appCode);
            }
            setSelected(selected);
        } else {
            setIsGroup(false);
            setSelected(undefined);
            setTitle("Summary of All Locations");
        }
    }, [locationId]);

    useEffect(() => {
        makeApiCallWithUpdateTime(selectedRange, { startTime, endTime }, fetchData, dispatch);
        return () => {
            locationsAbortControllerRef.current?.abort();
        };
    }, [locationId, startTime, endTime, CLASS_VEHICLE]);

    useEffect(() => {
        dispatch(resetTimeRange());
    }, []);

    return (
        <>
            <Helmet>
                <title>{AppParkAndRidePrefix}</title>
                <link rel="icon" id="cw-dashboard" type="image/png" href={platformityLogo} sizes="32x32" />
            </Helmet>
            <Grid container spacing={1}>
                {isGroup && (
                    <Grid item xs={12}>
                        <GroupSummeryHeader
                            childrenCount={childrenCount}
                            devicesCount={0}
                            name={selectedGroup?.name}
                            type={selectedGroup?.type}
                        />
                    </Grid>
                )}

                {title && (
                    <>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={9}>
                            <SectionHeading heading={title} icon={<AiFillAppstore className="icon" />} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                            <div style={{ maxWidth: "300px", marginLeft: "auto" }}>
                                <HeaderDatePicker />
                            </div>
                        </Grid>
                    </>
                )}

                <Grid item xs={12}>
                    <StatisticsCards group={selectedGroup} />
                </Grid>
                <Grid item xs={12}>
                    <PaperWrapper>
                        <Map id={selected?.id} />
                    </PaperWrapper>
                </Grid>
            </Grid>
        </>
    );
};

export default ParkAndRideDashBoard;
