/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { useSelector } from "react-redux";

import camLowIcon from "assets/images/indicators/cam_low.png";
import camModIcon from "assets/images/indicators/cam_moderate.png";
import camHighIcon from "assets/images/indicators/cam_high.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { InfoWindow, Marker } from "@react-google-maps/api";
import { Grid, IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const InfoMarkerParkAndRide = (props: any) => {
    const { key, location, clusterer } = props;
    const { appCode } = useSelector((state) => state.auth);
    const [showInfo, setShowInfo] = useState(false);
    let camIcon;
    if (location.count < 3) {
        camIcon = camLowIcon;
    } else if (location.count < 7) {
        camIcon = camModIcon;
    } else {
        camIcon = camHighIcon;
    }

    return (
        <Marker
            onLoad={(marker: any) => {
                marker.count = location.count;
            }}
            icon={camIcon}
            key={key}
            position={location}
            clusterer={clusterer}
            onClick={() => setShowInfo(!showInfo)}
        >
            {showInfo && (
                <InfoWindow position={location}>
                    <Grid container justifyContent="center" alignItems="center" p={1}>
                        <Grid item xs={11} m={0}>
                            <Typography variant="subtitle1">{location.address}</Typography>
                            <Typography variant="caption">
                                {location.city},{location.state},{location.zip}
                            </Typography>
                            <Typography marginLeft={1} variant="caption" color="text.secondary">
                                {location.lat}, {location.lng}
                            </Typography>
                            {/* This code block is commented under task: VZ-1216 */}
                            {/* <Typography variant="subtitle1" style={{ fontSize: "12px" }}>
                                Cyclists Count: {location.count}
                            </Typography> */}
                        </Grid>
                        <Grid item xs={1} m={0}>
                            <IconButton component={Link} to={`/${appCode}/home/location/${location.id}`}>
                                <ArrowForwardIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </InfoWindow>
            )}
        </Marker>
    );
};

export default InfoMarkerParkAndRide;
