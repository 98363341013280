import React from "react";
import { Bar } from "react-chartjs-2";

interface LineChartProps {
    data: number[];
    labels: string[];
    backgroundColor: string;
}

const BarChart: React.FC<LineChartProps> = ({ data, labels, backgroundColor }) => {
    return (
        <div style={{ height: "55px", paddingLeft: "15px", paddingRight: "15px" }}>
            <Bar
                data={{
                    labels,
                    datasets: [
                        {
                            data,
                            backgroundColor,
                        },
                    ],
                }}
                options={{
                    scales: {
                        x: {
                            grid: {
                                display: false,
                            },
                            ticks: {
                                display: false,
                            },
                        },
                        y: {
                            grid: {
                                display: false,
                            },
                            ticks: {
                                display: false,
                            },
                        },
                    },
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                }}
            />
        </div>
    );
};

export default BarChart;
