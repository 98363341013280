/* eslint-disable no-unused-expressions */
import Player from "video.js/dist/types/player";
import { CommonObjectLiteral } from "types";
import Plugin from "video.js/dist/types/plugin";
import Component from "video.js/dist/types/component";
import { Observer } from "../observer";

type PluginOptions = {
    listeners: {
        change: (state: CommonObjectLiteral) => void;
        click: (state: CommonObjectLiteral) => void;
    };
    zoom: (state: number) => void;
    flip: (state: string) => void;
    move: (moveX: number, moveY: number) => void;
} & Plugin;

export interface ZoomFunctionType {
    zoomIn: () => void;
    zoomOut: () => void;
    moveUp: () => void;
    moveDown: () => void;
    moveLeft: () => void;
    moveRight: () => void;
    reset: () => void;
}

export const ZOOM_SALT = 0.5;
export const MIN_ZOOM_LEVEL = 1;
export const MAX_ZOOM_LEVEL = 8;

export class ZoomFunction {
    playerEl: HTMLElement;

    plugin: PluginOptions;

    observer: Observer;

    state: CommonObjectLiteral = {};

    bigPlayButton: Component | undefined;

    constructor(player: Player, options: CommonObjectLiteral) {
        this.playerEl = player.el() as HTMLElement;
        this.plugin = options.plugin;
        this.observer = Observer.getInstance()!;

        player.on("loadeddata", () => {
            this.updateSalt();
        });

        player.on("playerresize", () => {
            this.updateSalt();
        });

        player.on("fullscreenchange", () => {
            if (this.state.zoom === MIN_ZOOM_LEVEL) {
                this.zoomIn();
                this.zoomOut();
            }
            else {
                this.zoomOut();
                this.zoomIn();
            }
        });

        player.on("error", (error: CommonObjectLiteral) => {
            error?.preventDefault();
            error?.stopPropagation();
        });

        player.on(["waiting", "loadstart", "canplay"], () => {
            this.hideBigPlayButton(player);
            if (!player.hasClass("vjs-waiting")) {
                player.addClass("vjs-waiting");
            }
        });

        player.on("playing", () => {
            this.showBigPlayButton(player);
            if (player.hasClass("vjs-waiting")) {
                player.removeClass("vjs-waiting");
            }
        });

        this.observer.subscribe("change", (state: CommonObjectLiteral) => {
            this.state = { ...state, saltMoveX: 70, saltMoveY: 70 };
            this.updateSalt();
        });
    }

    hideBigPlayButton(player: Player): void {
        this.bigPlayButton = player.getChild("bigPlayButton");
        if (this.bigPlayButton) this.bigPlayButton.hide();
    }

    showBigPlayButton(player: Player): void {
        this.bigPlayButton = player.getChild("bigPlayButton");
        if (this.bigPlayButton) this.bigPlayButton.show();
    }

    updateSalt(): void {
        this.state.saltMoveX = (this.playerEl.offsetWidth * ZOOM_SALT) / 2;
        this.state.saltMoveY = (this.playerEl.offsetHeight * ZOOM_SALT) / 2;
    }

    zoom(state: number): void {
        this.state.zoom = state;
        this.plugin.zoom(this.state.zoom);
        this.plugin.listeners.change(this.state);
    }

    zoomIn(): void {
        const zoom = Math.max(1, Math.min(MAX_ZOOM_LEVEL, this.state.zoom + ZOOM_SALT));
        this.zoom(Number(zoom.toFixed(1)));
        this.moveX(0);
        this.moveY(0);
    }

    zoomOut(): void {
        const zoom = Math.max(MIN_ZOOM_LEVEL, this.state.zoom - ZOOM_SALT);
        this.zoom(Number(zoom.toFixed(1)));
        this.moveX(0);
        this.moveY(0);
    }

    zoomHandler(salt: number): void {
        Math.sign(salt) === 1 ? this.zoomIn() : this.zoomOut();
    }

    move(): void {
        this.plugin.move(this.state.moveX, this.state.moveY);
        this.plugin.listeners.change(this.state);
    }

    moveUp(): void {
        const available = this.getMoveYAvailable();
        this.state.moveY = Math.max(-available, Math.min(available, this.state.moveY + this.state.saltMoveY));
        this.move();
    }

    moveDown(): void {
        const available = this.getMoveYAvailable();
        this.state.moveY = Math.max(-available, Math.min(available, this.state.moveY - this.state.saltMoveY));
        this.move();
    }

    moveX(salt: number): void {
        const available = this.getMoveYAvailable();
        this.state.moveY = Math.max(-available, Math.min(available, this.state.moveY + salt));
        this.move();
    }

    getMoveYAvailable(): number {
        return this.state.saltMoveY * ((this.state.zoom - 1) / ZOOM_SALT);
    }

    moveLeft(): void {
        const available = this.getMoveXAvailable();
        this.state.moveX = Math.max(-available, Math.min(available, this.state.moveX + this.state.saltMoveX));
        this.move();
    }

    moveRight(): void {
        const available = this.getMoveXAvailable();
        this.state.moveX = Math.max(-available, this.state.moveX - this.state.saltMoveX);
        this.move();
    }

    moveY(salt: number): void {
        const available = this.getMoveXAvailable();
        this.state.moveX = Math.max(-available, Math.min(available, this.state.moveX + salt));
        this.move();
    }

    getMoveXAvailable(): number {
        return this.state.saltMoveX * ((this.state.zoom - 1) / ZOOM_SALT);
    }

    flipBack(): void {
        this.plugin.flip(this.state.flip);
        this.plugin.listeners.change(this.state);
    }

    flip(): void {
        this.state.flip = this.state.flip === "+" ? "-" : "+";
        this.flipBack();
    }

    reset(): void {
        this.state.zoom = 1;
        this.state.moveX = 0;
        this.state.moveY = 0;
        this.plugin.zoom(1);
        this.plugin.flip("+");
        this.plugin.move(0, 0);
        this.plugin.listeners.change(this.state);
    }
}
