import { LocationLevel, LocationType } from "enums/location.enums";
import { Location } from "reducers/newLocation.reducer";

export const getLevel = (location: Location | undefined): number => {
    if (!location) return -1;
    switch (location?.type) {
        case LocationType.REGION:
            return LocationLevel.REGION;
        case LocationType.ZONE:
            return LocationLevel.ZONE;
        case LocationType.LOCATION:
            return LocationLevel.LOCATION;
        default:
            return 0;
    }
};

export const getGroupAndLocationIds = (
    location: Location | undefined,
): { groupId: string | undefined; locationId: string | undefined } => {
    return {
        groupId:
            location?.type === LocationType.REGION || location?.type === LocationType.ZONE ? location?.id : undefined,
        locationId: location?.type === LocationType.LOCATION ? location?.id : undefined,
    };
};
