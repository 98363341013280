import StackedBarChart from "components/charts/StackedBarChart";
import UpdatedBarChart from "components/charts/UpdatedBarChart";
import PieChart from "components/charts/PieChart";
import { ReportGraphTypes } from "types/reports/enums";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
export const generateReportChart = (chartType: ReportGraphTypes, title: string, data: any, options: any) => {
    switch (chartType) {
        case ReportGraphTypes.STACKED_BAR:
            return <StackedBarChart data={data} title={title} options={options} isExpandable />;
        case ReportGraphTypes.PIE:
            return <PieChart data={data} title={title} isExpandable options={options} />;
        case ReportGraphTypes.BAR:
            return <UpdatedBarChart data={data} title={title} isExpandable options={options} />;
        default:
            return null;
    }
};

export const addPeriodSuffix = (title: string, suffix?: string) => {
    if(!suffix) return title;
    return `${title} by ${suffix.charAt(0).toUpperCase()}${suffix.slice(1).toLowerCase()}`;
};