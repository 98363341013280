import { TRIGGER_TABLE_COLUMNS, TRIGGER_TABLE_ROWS } from "../../../constants";
import { StyledDataTable } from "../common";

interface Props {}
export const TriggerTable: React.FC<Props> = () => {
    return (
        <StyledDataTable
            disableSelectionOnClick
            autoHeight
            columns={TRIGGER_TABLE_COLUMNS}
            rows={TRIGGER_TABLE_ROWS}
            hideFooterPagination
        />
    );
};
