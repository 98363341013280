/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { IoWarning } from "react-icons/io5";
import { CLASS_INCIDENTS } from "constants/class.constants";
import {
    executeGetIncidentByLgaRequest,
    executeGetIncidentByTaxiTypeRequest,
    executeGetIncidentByTypeRequest,
    executeGetIncidentDetectionsTaxiRequest,
} from "api/app-taxi-ranking/taxi-incidents.api";
import { Location } from "reducers/newLocation.reducer";
import { FetchFunctionParams, makeApiCallWithUpdateTime } from "utils/common";
import { useAppDispatch } from "store";
import { getGroupAndLocationIds, getLevel } from "utils/location";
import { LocationLevel } from "enums/location.enums";
import { SCOPES } from "enums/scopes.enum";
import ProtectedView from "components/common/ProtectedView";
import DoughnutChartCard from "../../../components/cards/DoughnutChartCard";

interface IncidentsStatisticsCardsProps {
    group?: Location;
}

interface FetchDataParam extends FetchFunctionParams {
    level: number;
    startTime: string;
    endTime: string;
}

const IncidentsStatisticsCards: React.FC<IncidentsStatisticsCardsProps> = ({ group }) => {
    const incidentByTypeAbortControllerRef = useRef<AbortController | null>(null);
    const incidentByTaxiTypeAbortControllerRef = useRef<AbortController | null>(null);
    const incidentByLgaAbortControllerRef = useRef<AbortController | null>(null);
    const incidentsAbortControllerRef = useRef<AbortController | null>(null);
    const { groupId, locationId } = useParams();
    const { startTime, endTime, selectedRange } = useSelector((state) => state.time);
    const [incidentsByLGA, setIncidentsByLGA] = useState<any>([]);
    const [incidentsByType, setIncidentsByType] = useState<any>([]);
    const [incidentsByTaxiType, setIncidentsByTaxiType] = useState<any>([]);
    const { locations } = useSelector((state) => state.newLocation);
    const [selectedLocation, setSelectedLocation] = useState<Location>();
    const [isLoadingIncidentsByLGA, setIsLoadingIncidentsByLGA] = useState(false);
    const [isLoadingIncidentsByType, setIsLoadingIncidentsByType] = useState(false);
    const [isLoadingIncidentsByTaxiType, setIsLoadingIncidentsByTaxiType] = useState(false);
    const isAllLocations = () => groupId === undefined && locationId === undefined;
    const [incidentsCount, setIncidentsCount] = useState<string>("0");
    const dispatch = useAppDispatch();
    const config = {
        label: "",
        backgroundColor: ["rgba(50, 209, 219, 1)", "rgba(198, 32, 52, 1)", "rgba(107, 67, 157, 1)"],
        hoverOffset: 4,
        maintainAspectRatio: false,
    };

    const fetchData = async ({ level, startTime, endTime }: FetchDataParam) => {
        const selectedLocation = locations?.find((element) => element.id === (groupId || locationId));
        setSelectedLocation(selectedLocation);
        await Promise.all([
            isAllLocations()
                ? getIncidentsByLga({
                      ...getGroupAndLocationIds(selectedLocation),
                      fromTime: startTime,
                      toTime: endTime,
                      level: LocationLevel.ZONE,
                  })
                : getIncidents({
                      locationId: selectedLocation?.id,
                      fromTime: startTime,
                      toTime: endTime,
                      type: CLASS_INCIDENTS,
                      level: getLevel(selectedLocation),
                  }),
            getIncidentsByType({
                locationId: selectedLocation?.id,
                fromTime: startTime,
                toTime: endTime,
                level: getLevel(selectedLocation),
            }),
            getIncidentsByTaxiType({
                locationId: selectedLocation?.id,
                fromTime: startTime,
                toTime: endTime,
                level: getLevel(selectedLocation),
            }),
        ]);
    };

    async function getIncidents(data?: any) {
        try {
            incidentsAbortControllerRef.current = new AbortController();
            const { signal } = incidentsAbortControllerRef.current;
            setIsLoadingIncidentsByLGA(true);
            const response = await executeGetIncidentDetectionsTaxiRequest(data, { signal });
            if (response && Array.isArray(response)) {
                setIncidentsByLGA(response);
                let total = 0;
                response.forEach((element) => {
                    total += +element.count;
                });
                setIncidentsCount(total.toString());
            }
            setIsLoadingIncidentsByLGA(false);
        } catch (e) {
        } finally {
            // setLoading(false);
        }
    }

    async function getIncidentsByLga(data?: any) {
        try {
            incidentByLgaAbortControllerRef.current = new AbortController();
            const { signal } = incidentByLgaAbortControllerRef.current;
            setIsLoadingIncidentsByLGA(true);
            const response = await executeGetIncidentByLgaRequest(data, {
                signal,
            });
            if (response && Array.isArray(response)) {
                const incidents = response.map((elm) => {
                    const group = locations.find((group) => group.id === elm.locationId);

                    return {
                        ...elm,
                        groupName: group?.name,
                    };
                });
                setIncidentsByLGA(incidents);
            }
            setIsLoadingIncidentsByLGA(false);
        } catch (e) {
        } finally {
            // setLoading(false);
        }
    }
    async function getIncidentsByType(data?: any) {
        try {
            incidentByTypeAbortControllerRef.current = new AbortController();
            const { signal } = incidentByTypeAbortControllerRef.current;
            setIsLoadingIncidentsByType(true);
            const response = await executeGetIncidentByTypeRequest(data, {
                signal,
            });
            if (response && Array.isArray(response)) {
                setIncidentsByType(response);
            }
            setIsLoadingIncidentsByType(false);
        } catch (e) {
        } finally {
            // setLoading(false);
        }
    }

    async function getIncidentsByTaxiType(data?: any) {
        try {
            incidentByTaxiTypeAbortControllerRef.current = new AbortController();
            const { signal } = incidentByTaxiTypeAbortControllerRef.current;
            setIsLoadingIncidentsByTaxiType(true);
            const response = await executeGetIncidentByTaxiTypeRequest(data, {
                signal,
            });
            if (response && Array.isArray(response)) {
                setIncidentsByTaxiType(response);
            }
            setIsLoadingIncidentsByTaxiType(false);
        } catch (e) {
        } finally {
            // setLoading(false);
        }
    }

    useEffect(() => {
        let level = 0;
        if (isAllLocations()) {
            level = -1;
        } else if (locationId !== undefined) {
            level = 3;
        } else if (group?.type === "region") {
            level = 1;
        } else if (group?.type === "zone") {
            level = 2;
        }

        makeApiCallWithUpdateTime<FetchDataParam>(selectedRange, { startTime, endTime, level }, fetchData, dispatch);
        return () => {
            if (isAllLocations()) {
                incidentByLgaAbortControllerRef.current?.abort();
            } else {
                incidentsAbortControllerRef.current?.abort();
            }
            incidentByTypeAbortControllerRef.current?.abort();
            incidentByTaxiTypeAbortControllerRef.current?.abort();
        };
    }, [groupId, startTime, endTime, locationId]);

    return (
        <Grid container className="stats-container">
            <ProtectedView filterScopes={[SCOPES.READ_INCIDENTS]} shouldHide>
                <DoughnutChartCard
                    isLoading={isLoadingIncidentsByLGA}
                    title={isAllLocations() ? "Incidents by LGA" : "Total number of incidents"}
                    count={incidentsCount}
                    icon={<IoWarning />}
                    isChart={!!isAllLocations()}
                    className="donut-Icon-wrapper donut-red-Icon-wrapper"
                    data={incidentsByLGA}
                    labelName="groupName"
                />
            </ProtectedView>

            <DoughnutChartCard
                isLoading={isLoadingIncidentsByType}
                title="Incidents by incident type"
                count="0"
                icon={<IoWarning />}
                isChart
                className="donut-Icon-wrapper donut-red-Icon-wrapper"
                data={incidentsByType}
                labelName="incident"
            />

            <DoughnutChartCard
                isLoading={isLoadingIncidentsByTaxiType}
                title="Incidents by taxi type"
                count="0"
                icon={<IoWarning />}
                isChart
                className="donut-Icon-wrapper donut-red-Icon-wrapper"
                data={incidentsByTaxiType}
                labelName="type"
            />

            <DoughnutChartCard
                isLoading={isLoadingIncidentsByTaxiType}
                title="Incidents by status"
                count="0"
                icon={<IoWarning />}
                isChart={false}
                className="donut-Icon-wrapper donut-red-Icon-wrapper"
                data={incidentsByTaxiType}
            />
        </Grid>
    );
};

export default IncidentsStatisticsCards;
