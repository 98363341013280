export enum ReportFileFormats {
    CSV = "CSV",
    JSON = "JSON",
}

export enum FileStatus {
    PENDING = "pending",
    CREATED = "created",
    UNKNOWN = "unknown",
}
