import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { ALL_ENTITY_ACCESS } from "../../constants";

export type ProtectedViewProps = {
    filterScopes: string[];
    children: JSX.Element;
    entityId?: string;
    alternateView?: JSX.Element;
    shouldHide?: boolean;
};

// This HOC checks whether the passed scope is present in the users scopes list, if so it renders the 
// Child components. If an entityID is passed along as a prop, it is checked if the user has access to 
// the provided entityId. 

/**
 * 
 * @param filterScopes array of scopes to be checked against the users set of scopes
 * @param children children to be rendered after validation of scopes
 * @param entityId optional entityId paramter to be used in cases where scope validation should be done at entity level.
 * @param alternateView alternative component to be rendered when scope validation fails.
 * @returns child components based on the scope validation
 */
const ProtectedView = ({
    filterScopes,
    children,
    entityId,
    alternateView,
    shouldHide = false,
}: ProtectedViewProps): JSX.Element => {
    const { scopes } = useSelector((state) => state.auth);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    const AlternateView = alternateView ?? <></>;
    const matchedScope = useMemo(() => {
        return scopes.filter((scp) => {
            const splitScope = scp.split(":");
            splitScope.pop();
            const joinedScope = splitScope.join(":");
            return filterScopes.includes(joinedScope);
        });
    }, [filterScopes, scopes]);
    if (!matchedScope?.length) {
        if (shouldHide) return AlternateView;
        return React.cloneElement(children, {disabled: true});
    }
    const entities = matchedScope?.map((scp) => scp.split(":").pop());
    if (entities?.some((scp) => scp === ALL_ENTITY_ACCESS)) return children;
    if(!entityId) return children
    const hasAccessToEntity = entities?.includes(entityId);
    if (hasAccessToEntity) return children;
    const clonedChild = React.cloneElement(children, {disabled: true});
    return shouldHide ? AlternateView : clonedChild;
};
export default ProtectedView;