/* eslint-disable react/prop-types */
import { LocalizationProvider, MobileDateTimePicker } from "@mui/lab";
import React from "react";
import EventIcon from "@mui/icons-material/Event";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Stack, styled } from "@mui/material";
import { DateTimePickerView } from "@mui/lab/DateTimePicker/shared";
import { format } from "date-fns";
import { DEFAULT_DATE_FORMAT } from "constants/time.constants";
import IotVisionTextFiled from "./IotVisionTextFiled";

interface CommonDateTimePickerProps {
    value: Date | null;
    onChange: (date: Date | null) => void;
    maxDate?: Date;
    minDate?: Date;
    shouldDisableFutureDate?: boolean;
    isDisabled?: boolean;
    errorMessage?: string;
    views?: DateTimePickerView[];
    dateFormat?: string;
}

const CustomizedIconButton = styled(IconButton)(() => ({
    visibility: "hidden",
}));

const CustomizedIotVisionTextFiled = styled(IotVisionTextFiled)(() => ({
    "&:hover .Date-picker-clear-button": {
        visibility: "visible",
    },
    "& .Mui-focused .Date-picker-clear-button": {
        visibility: "visible",
    },
}));

const CommonDateTimePicker: React.FC<CommonDateTimePickerProps> = ({
    onChange,
    value,
    maxDate,
    minDate,
    shouldDisableFutureDate = true,
    isDisabled = false,
    errorMessage,
    views,
    dateFormat = DEFAULT_DATE_FORMAT,
}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDateTimePicker
                InputProps={{
                    endAdornment: (
                        <Stack direction="row" alignItems="center" spacing={1}>
                            {value && !isDisabled && (
                                <CustomizedIconButton
                                    className="Date-picker-clear-button"
                                    size="small"
                                    onClick={() => onChange(null)}
                                >
                                    <CloseIcon fontSize="small" />
                                </CustomizedIconButton>
                            )}
                            <EventIcon sx={{ color: "gray" }} />
                        </Stack>
                    ),
                }}
                maxDateTime={maxDate}
                minDateTime={minDate}
                disableFuture={shouldDisableFutureDate}
                disabled={isDisabled}
                views={views}
                renderInput={(props) => {
                    props.inputProps = {
                        ...props.inputProps,
                        value: value ? format(value, dateFormat) : "",
                    };
                    return (
                        <CustomizedIotVisionTextFiled
                            {...props}
                            fullWidth
                            error={!!errorMessage}
                            helperText={errorMessage}
                        />
                    );
                }}
                value={value}
                onChange={(date) => onChange(date)}
            />
        </LocalizationProvider>
    );
};

export default CommonDateTimePicker;
