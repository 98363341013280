/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { MarkerClusterer } from "@react-google-maps/api";
import InfoMarkerTaxiRanking from "./InfoMarker";

interface MarkerClustererTaxiRankingProps {
    markerClustererCalculator: (markers: Array<any>) => any;
    clusterStyles: any;
    locations: any[];
}

const MarkerClustererTaxiRanking: React.FC<MarkerClustererTaxiRankingProps> = ({
    markerClustererCalculator,
    clusterStyles,
    locations,
}) => {
    return (
        <MarkerClusterer
            options={{
                clusterClass: "map-cluster-icon",
                calculator: markerClustererCalculator,
                styles: clusterStyles,
            }}
        >
            {(clusterer) =>
                locations.map((location, i) => (
                    <InfoMarkerTaxiRanking key={i} location={location} clusterer={clusterer} />
                ))
            }
        </MarkerClusterer>
    );
};

export default MarkerClustererTaxiRanking;
