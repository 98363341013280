import { styled } from "@mui/material";

const CardHeader = styled("div")({
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    color: "#001B4F",
    lineHeight: "27px",
});

export default CardHeader;
