import React, { useEffect } from "react";
import { AutocompleteChangeReason, AutocompleteRenderInputParams, Grid, styled } from "@mui/material";
import IotVisionTextFiled from "components/common/IotVisionTextFiled";
import IotVisionButton from "components/common/IotVisionButton";
import { URLSearchParamsInit } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { CommonObjectLiteral } from "types/reports/interfaces";
import IotVisionAutoComplete from "components/common/IotVisonAutoComplete";

const CustomizedIotVisionSearchButton = styled(IotVisionButton)(() => ({
    color: "white !important",
    height: "37px",
}));

const CustomizedIotVisionClearButton = styled(IotVisionButton)(({theme}) => ({
    height: "37px",
    backgroundColor: "white",
    color: theme.palette.primary.main,
    "&:hover": {
        background: "white",
    },
}));

const reportScheduleTypes = [
    {
        id: "Alert",
        label: "Alert",
    },
    {
        id: "Historical",
        label: "Historical",
    },
]; 

interface AutoLabelId {
    label: string;
    id: string;
}
interface IReportListFilter {
    licensePlate?: string;
    type?: AutoLabelId;
}
export interface ISearchParams {
    metaField1: string;
    reportScheduleType: string;
}
interface IReportListFilterPanelProps {
    onClear: () => void;
    setSearchParams: (params: CommonObjectLiteral) => void;
    searchParams: CommonObjectLiteral;
}

const ReportListFilterPanel: React.FC<IReportListFilterPanelProps> = ({ onClear, searchParams, setSearchParams }) => {
    const { reportScheduleType, metaField1 } = searchParams || {};

    const {
        handleSubmit,
        setValue,
        control,
        trigger,
        reset,
        formState: { isValid },
    } = useForm<IReportListFilter>({
        defaultValues: {
            licensePlate: "",
            type: {
                label: "",
                id: "",
            },
        },
    });

    useEffect(() => {
        reset({
            licensePlate: metaField1 || "",
            type: reportScheduleTypes.find(item => item.id === reportScheduleType) || {
                label: "",
                id: "",
            },
        });
    }, [searchParams]);

    const handleClear = (): void => {
        onClear();
        reset({
            licensePlate: "",
            type: {
                label: "",
                id: "",
            },
        });
        setSearchParams({});
    };

    const onSubmit: SubmitHandler<IReportListFilter> = (data: IReportListFilter): void => {
        const searchParamData: URLSearchParamsInit | undefined = {
            ...(data?.type?.id ? { reportScheduleType: data?.type.id } : {}),
            ...(data?.licensePlate ? { metaField1: data?.licensePlate } : {}),
        };
        setSearchParams(searchParamData);
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1} marginTop={{xs: "15px"}}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                        name="licensePlate"
                        control={control}
                        render={({ field: { value }, fieldState: { invalid, error } }) => {
                            return (
                                <IotVisionTextFiled
                                    fullWidth
                                    placeholder="License Plate"
                                    error={invalid}
                                    label={error?.message}
                                    value={value}
                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                        setValue("licensePlate", event.target.value);
                                        if (invalid) trigger("licensePlate");
                                    }}
                                />
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                        name="type"
                        control={control}
                        render={({ field: { value } }) => (
                            <IotVisionAutoComplete
                                options={reportScheduleTypes}
                                value={value?.label}
                                onChange={(
                                    event: React.SyntheticEvent,
                                    value: AutoLabelId,
                                    reason: AutocompleteChangeReason,
                                ) => {
                                    if (reason === "clear") {
                                        setValue(
                                            "type",
                                            {
                                                label: "",
                                                id: "",
                                            },
                                            { shouldDirty: true },
                                        );
                                    } else {
                                        setValue(
                                            "type",
                                            {
                                                label: value?.label,
                                                id: value?.id,
                                            },
                                            { shouldDirty: true },
                                        );
                                    }
                                }}
                                renderInput={(params: AutocompleteRenderInputParams) => (
                                    <IotVisionTextFiled
                                        {...params}
                                        placeholder="Type"
                                        fullWidth
                                    />
                                )}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Grid container spacing={1}>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <CustomizedIotVisionSearchButton
                                type="submit"
                                disabled={!isValid}
                                fullWidth
                            >
                                Search
                            </CustomizedIotVisionSearchButton>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <CustomizedIotVisionClearButton
                                fullWidth
                                onClick={handleClear}
                            >
                                Clear
                            </CustomizedIotVisionClearButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default ReportListFilterPanel;
