import { Box, Grid, Typography } from "@mui/material";
import { AppDeptAqTitlePrefix } from "constants/app.constants";
import React from "react";
import { Helmet } from "react-helmet";
import PlatformityLogo from "assets/images/platformity-favicon-logo.png";
import { RuleTable } from "../../../features/app-dept-air-quality/rule-alerts/RuleTable";
import { TriggerTable } from "../../../features/app-dept-air-quality/rule-alerts";

interface Props {}

const RuleAlert: React.FC<Props> = () => {
    return (
        <>
            <Helmet>
                <title>{AppDeptAqTitlePrefix} | Rules and Alerts</title>
                <link rel="icon" id="aq-incident" type="image/png" href={PlatformityLogo} />
            </Helmet>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="header1">Rules and Alerts</Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "41px" }}>
                    <Grid container>
                        <Box
                            sx={{
                                padding: "25px",
                                width: "100%",
                                minHeight: "812px",
                                height: "100%",
                                background: "#FFFFFF",
                                borderRadius: "15px",
                            }}
                        >
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        fontWeight: 600,
                                        fontSize: "26px",
                                        lineHeight: "60px",
                                        color: "#001B4F",
                                    }}
                                >
                                    Rules
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {/* Rule table */}
                                <RuleTable />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        fontWeight: 600,
                                        fontSize: "26px",
                                        lineHeight: "60px",
                                        color: "#001B4F",
                                    }}
                                >
                                    Alerts
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {/* Trigger table */}
                                <TriggerTable />
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default RuleAlert;
