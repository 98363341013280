import { useEffect, useRef, useState } from "react";
import { loadImage } from "utils/image";
import NoImage from "./NoImage";

interface IncidentImageProps {
    incidentImageUrl: string;
    metaData: {
        queuePos: number;
        startX: number;
        startY: number;
        endX: number;
        endY: number;
        cameraId: string;
        appName: string;
        organisation: string;
    }[];
}

function IncidentImage({ incidentImageUrl, metaData }: IncidentImageProps) {
    const DRAWING_LINE_WIDTH = 2;
    const DRAWING_LINE_COLOR = "rgba(255, 99, 71, 0.6)";

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [image, setImage] = useState<HTMLImageElement>();
    const [scale, setScale] = useState<number>(1);
    const [isImageValid, setIsImageValid] = useState<boolean>(true);

    const getCanvasWidth = () => {
        const domRect = canvasRef.current?.getBoundingClientRect();
        return domRect ? domRect.right - domRect.left : 0;
    };

    const calculateScale = () => {
        if (image) {
            setScale(getCanvasWidth() / image.width);
        }
    };

    const getScaledValue = (value: number) => value * scale;

    const getRectangularPoints = () => {
        const allRectangular: number[][][] = [];
        metaData.forEach((metaDataItem) => {
            const topLeftPoint = [getScaledValue(metaDataItem.startX), getScaledValue(metaDataItem.startY)];
            const topRightPoint = [getScaledValue(metaDataItem.endX), getScaledValue(metaDataItem.startY)];
            const bottomRightPoint = [getScaledValue(metaDataItem.endX), getScaledValue(metaDataItem.endY)];
            const bottomLeftPoint = [getScaledValue(metaDataItem.startX), getScaledValue(metaDataItem.endY)];

            allRectangular.push([topLeftPoint, topRightPoint, bottomRightPoint, bottomLeftPoint, topLeftPoint]);
        });

        return allRectangular;
    };

    const drawImageOnCanvas = () => {
        const ctx = canvasRef.current?.getContext("2d");
        const canvas = canvasRef.current;
        if (ctx && canvas && image) {
            const imageWidth = getScaledValue(image.width);
            const imageHeight = getScaledValue(image.height);
            canvas.width = imageWidth;
            canvas.height = imageHeight;
            ctx.drawImage(image, 0, 0, imageWidth, imageHeight);
            return ctx;
        }
    };

    const drawRectangleOnImage = (ctx: CanvasRenderingContext2D) => {
        ctx.beginPath();
        ctx.lineWidth = DRAWING_LINE_WIDTH;
        ctx.strokeStyle = DRAWING_LINE_COLOR;
        getRectangularPoints().forEach((rectangular) => {
            rectangular.forEach((point, index) => {
                if (index === 0) {
                    ctx.moveTo(point[0], point[1]);
                    return;
                }
                ctx.lineTo(point[0], point[1]);
                ctx.stroke();
            });
        });
    };

    useEffect(() => {
        loadImage(incidentImageUrl)
            .then((img) => {
                setImage(img);
                calculateScale();
                const ctx = drawImageOnCanvas();

                if (ctx) {
                    drawRectangleOnImage(ctx);
                }
            })
            .catch((_e) => {
                setIsImageValid(false);
            });
    });

    return <div>{isImageValid ? <canvas ref={canvasRef} style={{ width: "100%" }} /> : <NoImage />}</div>;
}

export default IncidentImage;
