import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import AlertsFilter from "./filter/AlertsFilter";
import AlertsTable from "./table/AlertsTable";

interface PageAlertsProps {
    setLastUpdated: React.Dispatch<React.SetStateAction<Date | null>>;
}

const PageAlerts: React.FC<PageAlertsProps> = ({ setLastUpdated }) => {
    return (
        <Grid container>
            <Box
                sx={(theme) => ({
                    padding: "25px",
                    borderRadius: "15px",
                    width: "100%",
                    height: "750px",
                    [theme.breakpoints.up("lg")]: {
                        height: "720px",
                    },
                    background: "#FFFFFF",
                    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.03)",
                })}
            >
                <Grid item xs={12}>
                    <AlertsFilter />
                </Grid>
                <Grid item xs={12}>
                    <AlertsTable setLastUpdated={setLastUpdated} />
                </Grid>
            </Box>
        </Grid>
    );
};

export default PageAlerts;
