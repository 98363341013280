import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export interface FilterableObject {
    scope: string;
}

const useDataFilterByScope = <T extends FilterableObject>(scopesList: string[], data: T[]): T[] => {
    const [filteredData, setFilteredData] = useState<T[]>([]);
    const { scopes } = useSelector((state) => state.auth);
    const scopesWithoutEntity: string[] = [];
    useEffect(() => {
        scopes.forEach((scp) => {
            const splitScope = scp.split(":");
            splitScope.pop();
            const joinedScope = splitScope.join(":");
            scopesList.forEach((itm) => {
                if (joinedScope === itm) {
                    scopesWithoutEntity.push(joinedScope);
                }
            });
        });
        // eslint-disable-next-line array-callback-return
        const finalDataList = data.filter(item => {
            if (!item.scope) return item;
            if (scopesWithoutEntity.some((itm) => item.scope === itm)) return item;
        });
        setFilteredData(finalDataList);
    }, [data, scopes]);
    return filteredData;
};

export default useDataFilterByScope;
