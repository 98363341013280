import { ReportGraphColors, ReportGraphTypes } from "types/reports/enums";
import { IReportConfig } from "types/reports/interfaces";
import formatDate from "utils/date-format";
import {
    // AverageWaitingTimeBeforeProcessedData,
    IncidentsRaisedVsProcessedData,
    UnprocessedVsExpiredData,
} from "types/reports/interfaces/workflow-management-report";

// TODO: The following code was commented as per the request of Achintha and would be uncommented/ revisited 
// once the changes to the "Average Waiting time before processed" graph are finalized by the client.

// import {
//     REPORT_FONT_FAMILY,
//     REPORT_TITLE_FONT_SIZE,
//     REPORT_LEGEND_FONT_SIZE,
//     REPORT_TOOLTIP_TITLE_FONT_SIZE,
//     REPORT_TOOLTIP_BODY_FONT_SIZE,
//     REPORT_SCALES_FONT_SIZE,
// } from "constants/report.constants";

// Custom options to be passed into the chart.js graph to customize the base graph.
// const averageWaitingTimeBeforeProcessedCustomOptions = {
//     maintainAspectRatio: false,
//     plugins: {
//         title: {
//             display: false,
//             text: "Average Waiting Time Before Processed",
//             font: {
//                 family: REPORT_FONT_FAMILY,
//                 size: REPORT_TITLE_FONT_SIZE,
//             },
//         },
//         legend: {
//             labels: {
//                 font: {
//                     family: REPORT_FONT_FAMILY,
//                     size: REPORT_LEGEND_FONT_SIZE,
//                 },
//             },
//         },
//         tooltip: {
//             titleFont: {
//                 family: REPORT_FONT_FAMILY,
//                 size: REPORT_TOOLTIP_TITLE_FONT_SIZE,
//             },
//             bodyFont: {
//                 family: REPORT_FONT_FAMILY,
//                 size: REPORT_TOOLTIP_BODY_FONT_SIZE,
//             },
//         },
//     },
//     scales: {
//         x: {
//             grid: {
//                 display: true,
//             },
//             title: {
//                 font: {
//                     family: REPORT_FONT_FAMILY,
//                     size: REPORT_SCALES_FONT_SIZE,
//                 },
//                 align: "end",
//                 text: "Dates",
//                 display: true,
//             },
//         },
//         y: {
//             grid: {
//                 display: true,
//             },
//             title: {
//                 font: {
//                     family: REPORT_FONT_FAMILY,
//                     size: REPORT_SCALES_FONT_SIZE,
//                 },
//                 text: "No. of Days",
//                 display: true,
//                 align: "end",
//             },
//         },
//     },
//     responsive: true,
// };

// Processor functions to be used by each graph to format the response data to the chart accepted format
const dateOptions: Intl.DateTimeFormatOptions = { day: "2-digit", month: "2-digit", year: "2-digit" };
const procsessIncidentsRaisedVsProcessedByWeek = (data: IncidentsRaisedVsProcessedData[]) => {
    const labels = data?.map((item) => formatDate(item.endDate, dateOptions));
    const raised = data?.map((item) => item.data.raised);
    const processed = data?.map((item) => item.data.processed);
    const datasets = [
        {
            label: "Raised",
            data: raised,
            backgroundColor: ReportGraphColors.DARK_SALMON,
            borderColor: ReportGraphColors.DARK_SALMON,
            type: "line",
            lineTension: 0.25,
        },
        {
            label: "Processed",
            data: processed,
            backgroundColor: ReportGraphColors.DARK_PURPLE,
            borderColor: ReportGraphColors.DARK_PURPLE,
            type: "line",
            lineTension: 0.25,
        },
    ];
    return { labels, datasets };
};

const processUnprocessedVsExpired = (data: UnprocessedVsExpiredData[]) => {
    const labels = data?.map((item) => formatDate(item.endDate, dateOptions));
    const expired = data?.map((item) => item.data.expired);
    const unprocessed = data?.map((item) => item.data.unprocessed);
    const datasets = [
        {
            label: "Expired",
            data: expired,
            backgroundColor: ReportGraphColors.DARK_SALMON,
        },
        {
            label: "Unprocessed",
            data: unprocessed,
            backgroundColor: ReportGraphColors.DARK_PURPLE,
        },
    ];
    return { labels, datasets };
};

// const processAverageWaitingTimeBeforeProcessed = (data: AverageWaitingTimeBeforeProcessedData[]) => {
//     const labels = data?.map((item) => formatDate(item.week, dateOptions));
//     const averageWaitingTime = data?.map((item) => item.value);
//     const datasets = [
//         {
//             label: "Average Waiting Time",
//             data: averageWaitingTime,
//             backgroundColor: ReportGraphColors.DARK_PURPLE,
//         },
//     ];
//     return { labels, datasets };
// };

export const WorkflowManagementSummaryReportConfig: IReportConfig = {
    reportTitle: "Workflow Management",
    cards: [
        {
            text: "Incidents Raised",
            icon: "FaExclamationTriangle",
            attributeName: "raised",
        },
        {
            text: "Incidents Processed",
            icon: "FaEye",
            attributeName: "processed",
        },
        {
            text: "Expiring in < 7 days",
            icon: "FaHourglassHalf",
            attributeName: "expiringInLessThan7Days",
        },
        {
            text: "Expired Last Week",
            icon: "FaHourglassEnd",
            attributeName: "expiredLastWeek",
        },
    ],
    graphs: [
        {
            graphTitle: "Incidents Raised vs Processed",
            graphType: ReportGraphTypes.BAR,
            attributeName: "incidentsRaisedVsProcessed",
            processorFunction: procsessIncidentsRaisedVsProcessedByWeek,
            shouldAddPeriodSuffix: true,
        },
        {
            graphTitle: "Unprocessed vs Expired",
            graphType: ReportGraphTypes.STACKED_BAR,
            attributeName: "unprocessedVsExpired",
            processorFunction: processUnprocessedVsExpired,
            shouldAddPeriodSuffix: false,
        },
        // {
        //     graphTitle: "Average Waiting Time Before Processed",
        //     graphType: ReportGraphTypes.BAR,
        //     attributeName: "averageWaitTimeBeforeProcessed",
        //     processorFunction: processAverageWaitingTimeBeforeProcessed,
        //     options: averageWaitingTimeBeforeProcessedCustomOptions,
        //     shouldAddPeriodSuffix: false,
        // },
    ],
};
