import { ButtonProps, styled } from "@mui/material";
import IotVisionButton from "components/common/IotVisionButton";

const PrimaryButton = styled((PrimaryButtonProps: ButtonProps) => (
    <IotVisionButton
        {...PrimaryButtonProps}
        sx={{
            backgroundColor: "#001B4F",
            fontWeight: 400,
            height: "25px",
            width: "120px",
            fontSize: "12px",
            "&:hover": {
                background: "#001B4F",
            },
        }}
    />
))(() => ({}));

export default PrimaryButton;
