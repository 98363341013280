export enum ReportGraphColors {
    LIGHT_BLUE = "#ACDDDE",
    LIGHT_GREEN = "#E1F8DC",
    LIGHT_ORANGE = "#FFE7C7",
    LIGHT_YELLOW = "#FEF8DD",
    LIGHT_RED = "#F7D8BA",
    DARK_PURPLE = "#7b83aa",
    DARK_SALMON = "#e9847d",
    DARK_YELLOW = "#f7d378",
    DARK_BLUE = "#21315B",
}
