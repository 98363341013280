import { Grid } from "@mui/material";
import { AppDeptAqTitlePrefix } from "constants/app.constants";
import PageAlertHeader from "features/app-dept-air-quality/alerts/header/PageAlertHeader";
import PageAlerts from "features/app-dept-air-quality/alerts/PageAlerts";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import PlatformityLogo from "assets/images/platformity-favicon-logo.png";

interface AlertsProps {}

const Alerts: React.FC<AlertsProps> = () => {
    const [lastUpdated, setLastUpdated] = useState<Date | null>(null);

    return (
        <>
            <Helmet>
                <title>{AppDeptAqTitlePrefix} | Incidents</title>
                <link rel="icon" id="aq-incident" type="image/png" href={PlatformityLogo} />
            </Helmet>
            <Grid container>
                <Grid item xs={12}>
                    <PageAlertHeader lastUpdated={lastUpdated} />
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "24px" }}>
                    <PageAlerts setLastUpdated={setLastUpdated} />
                </Grid>
            </Grid>
        </>
    );
};

export default Alerts;
