import React from "react";
import { MarkerClusterer } from "@react-google-maps/api";
import InfoMarkerCycleways from "./InfoMarker";

/* eslint @typescript-eslint/no-explicit-any: ["off"] */
interface MarkerClustererCyclewaysProps {
    markerClustererCalculator: (markers: Array<any>) => any;
    clusterStyles: any;
    locations: any[];
}

const MarkerClustererCycleways: React.FC<MarkerClustererCyclewaysProps> = ({
    markerClustererCalculator,
    clusterStyles,
    locations,
}) => {
    return (
        <MarkerClusterer
            options={{
                clusterClass: "map-cluster-icon",
                calculator: markerClustererCalculator,
                styles: clusterStyles,
            }}
        >
            {
                (clusterer) =>
                    locations.map((location, i) => (
                        <InfoMarkerCycleways key={i} location={location} clusterer={clusterer} />
                    ))
                // eslint-disable-next-line react/jsx-curly-newline
            }
        </MarkerClusterer>
    );
};

export default MarkerClustererCycleways;
