import React, { useEffect, useRef, useState } from "react";
import { Grid, IconButton, MenuItem, SelectChangeEvent } from "@mui/material";
import PaperWrapper from "components/wrappers/PaperWrapper";
import { CgArrowsExpandRight } from "react-icons/cg";
import FullScreenDialog from "components/dialogs/FullScreenDialog";
import AverageWaitingTimeChart, {
    IAverageWaitingTimeData,
} from "features/app-taxi-ranking/chart/AverageWaitingTimeChart";
import { useParams } from "react-router-dom";
import {
    executeGetAverageWaitingTimeTaxiRequest,
    executeGetOvertimeDetectionsTaxiRequest,
    executeGetOvertimeIncidentDetectionsTaxiRequest,
} from "api/common/detections.api";
import { useSelector } from "react-redux";
import {
    CLASS_INCIDENTS,
    CLASS_TAXIES,
    CLASS_EMBARK_PASSENGERS,
    CLASS_NONTAXIES,
    CLASS_PASSENGERS,
} from "constants/class.constants";
import IotVisionSelectFiled from "components/common/IotVisionSelectField";
import DetectedCountChart, { IDetectedCountData } from "features/app-taxi-ranking/chart/DetectedCountChart";
import { useAppDispatch } from "store";
import { checkLocationIdChanged, FetchFunctionParams, makeApiCallWithUpdateTime } from "utils/common";
import { SCOPES } from "enums/scopes.enum";
import useDataFilterByScope from "hooks/useDataFilterByScope";
import useHasScope from "hooks/useHasScope";

interface TaxiChartLayoutProps {
    regionType: string;
    region: string;
}
export interface IWaitingTimeChartParams {
    fromTime: string;
    toTime: string;
    classification: string;
    scaleUnit: string | undefined;
    locationId: string | undefined;
}

export interface IDetectedCountParams {
    fromTime: string;
    toTime: string;
    classification: string;
    scaleUnit: string | undefined;
}

export interface Option {
    value: string;
    title: string;
}

const detectedoptions = [
    {
        value: CLASS_TAXIES,
        title: "Taxis",
    },
    {
        value: CLASS_NONTAXIES,
        title: "Non Taxis",
    },
];

const passengerDetectedOptions = [
    {
        scope: "",
        value: CLASS_EMBARK_PASSENGERS,
        title: "Passengers",
    },
    {
        scope: "",
        value: CLASS_PASSENGERS,
        title: "Waiting Passengers",
    },
    {
        scope: SCOPES.READ_INCIDENTS,
        value: CLASS_INCIDENTS,
        title: "Incidents",
    },
];

const TaxiChartLayout: React.FC<TaxiChartLayoutProps> = () => {
    const hasReadIncidentsScope = useHasScope(SCOPES.READ_INCIDENTS);
    const filteredDataArray = useDataFilterByScope([SCOPES.READ_INCIDENTS], passengerDetectedOptions);
    const [detectedTaxiesData, setDetectedTaxiesData] = useState<IDetectedCountData[]>([]);
    const [detectedPassengersData, setDetectedPassengersData] = useState<IDetectedCountData[]>([]);
    const [detectionFilterClass, setDetectionFilterClass] = useState<string>(CLASS_TAXIES);
    const [passengerDetectionFilterClass, setPassengerDetectionFilterClass] = useState<string>(CLASS_EMBARK_PASSENGERS);
    const [passengerWaitingTimeData, setPassengerWaitingTimeData] = useState<IAverageWaitingTimeData[]>([]);
    const [taxiWaitingTimeData, setTaxiWaitingTimeData] = useState<IAverageWaitingTimeData[]>([]);
    const { locationId } = useParams();
    const { startTime, endTime, selectedRange, timeType } = useSelector((state) => state.time);
    const dispatch = useAppDispatch();
    const [pageIndex, setPageIndex] = React.useState(1);
    const [detectedTaxiModalShow, setDetectedTaxiModalShow] = React.useState(false);
    const [detectedPassengerModalShow, setDetectedPassengerModalShow] = React.useState(false);
    const [taxiChartHeading, setTaxiChartHeading] = React.useState("Detected taxis count");
    const [passengerChartHeading, setPassengerChartHeading] = React.useState("Detected passengers count");
    const [detectedTaxiLabel, setDetectedTaxiLabel] = React.useState("Taxi Count");
    const [detectedPassengerLabel, setDetectedPassengerLabel] = React.useState("Passenger Count");
    const incidentAbortControllerRef = useRef<AbortController | null>(null);
    const taxiDetectionAbortControllerRef = useRef<AbortController | null>(null);
    const passengerDetectionAbortControllerRef = useRef<AbortController | null>(null);
    const incidentDetectionAbortControllerRef = useRef<AbortController | null>(null);
    const taxiWaitingAbortControllerRef = useRef<AbortController | null>(null);
    const passengerWaitingAbortControllerRef = useRef<AbortController | null>(null);
    const { locations } = useSelector((state) => state.newLocation);

    const detectedTaxisGraphConfig = {
        backgroundColor: "rgba(107, 67, 157, 1)",
        borderColor: "rgba(107, 67, 157, 1)",
        borderWidth: 1,
        maintainAspectRatio: true,
    };

    const configPassengersCount = {
        backgroundColor: "rgb(75, 192, 192)",
        borderColor: "rgb(75, 192, 192)",
        borderWidth: 1,
        maintainAspectRatio: true,
    };

    const fetchTaxiDetectionsData = async ({ startTime, endTime }: FetchFunctionParams) => {
        await getTaxiDetections({
            fromTime: startTime,
            toTime: endTime,
            classification: detectionFilterClass,
            scaleUnit: timeType,
        });
    };

    const fetchPassengerWaitingTimeData = async ({ startTime, endTime }: FetchFunctionParams) => {
        await Promise.all([
            getPassengerWaitingTime({
                fromTime: startTime,
                toTime: endTime,
                classification: CLASS_PASSENGERS,
                scaleUnit: timeType,
                locationId,
            }),
            getTaxiWaitingTime({
                fromTime: startTime,
                toTime: endTime,
                classification: CLASS_TAXIES,
                scaleUnit: timeType,
                locationId,
            }),
        ]);
    };

    const fetchPassengerDetectionData = ({ startTime, endTime }: FetchFunctionParams) => {
        if (
            passengerDetectionFilterClass === CLASS_EMBARK_PASSENGERS ||
            passengerDetectionFilterClass === CLASS_PASSENGERS
        ) {
            getPassengerDetections({
                fromTime: startTime,
                toTime: endTime,
                classification: passengerDetectionFilterClass,
                scaleUnit: timeType,
            });
        } else if (hasReadIncidentsScope) {
            getIncidentDetections({
                fromTime: startTime,
                toTime: endTime,
                classification: passengerDetectionFilterClass,
                scaleUnit: timeType,
                locationId,
            });
        }
    };

    async function getTaxiDetections(data?: IDetectedCountParams) {
        try {
            taxiDetectionAbortControllerRef.current = new AbortController();
            const { signal } = taxiDetectionAbortControllerRef.current;
            const response = await executeGetOvertimeDetectionsTaxiRequest(
                locationId !== undefined ? locationId : "",
                data,
                { signal, disableNotification: checkLocationIdChanged(locationId, locations) },
            );
            if (response && Array.isArray(response)) {
                if (detectionFilterClass === CLASS_TAXIES) {
                    setDetectedTaxiLabel("Taxi Count");
                } else {
                    setDetectedTaxiLabel("Non Taxi Count");
                }
                const mergeDuplicateKeyResponse = mergeDuplicateKeyObject(response);
                setDetectedTaxiesData(mergeDuplicateKeyResponse);
            }
        } catch (e) {}
    }

    async function getPassengerDetections(data?: IDetectedCountParams) {
        try {
            passengerDetectionAbortControllerRef.current = new AbortController();
            const { signal } = passengerDetectionAbortControllerRef.current;
            const response = await executeGetOvertimeDetectionsTaxiRequest(
                locationId !== undefined ? locationId : "",
                data,
                { signal, disableNotification: checkLocationIdChanged(locationId, locations) },
            );
            if (response && Array.isArray(response)) {
                if (data?.classification === CLASS_EMBARK_PASSENGERS) {
                    setDetectedPassengerLabel("Passenger Count");
                } else {
                    setDetectedPassengerLabel("Waiting Passengers Count");
                }
                const mergeDuplicateKeyResponse = mergeDuplicateKeyObject(response);
                setDetectedPassengersData(mergeDuplicateKeyResponse);
            }
        } catch (e) {}
    }

    async function getIncidentDetections(data?: IWaitingTimeChartParams) {
        try {
            incidentDetectionAbortControllerRef.current = new AbortController();
            const { signal } = incidentDetectionAbortControllerRef.current;
            const response = await executeGetOvertimeIncidentDetectionsTaxiRequest(data, {
                signal,
            });
            if (response && Array.isArray(response)) {
                setDetectedPassengerLabel("Incident count");
                const mergeDuplicateKeyResponse = mergeDuplicateKeyObject(response);
                setDetectedPassengersData(mergeDuplicateKeyResponse);
            }
        } catch (e) {}
    }

    async function getPassengerWaitingTime(data?: IWaitingTimeChartParams) {
        try {
            passengerWaitingAbortControllerRef.current = new AbortController();
            const { signal } = passengerWaitingAbortControllerRef.current;
            const response = await executeGetAverageWaitingTimeTaxiRequest(
                locationId !== undefined ? locationId : "",
                data,
                { signal },
            );
            if (response && Array.isArray(response)) {
                setPassengerWaitingTimeData(response);
            }
        } catch (e) {}
    }

    async function getTaxiWaitingTime(data?: IWaitingTimeChartParams) {
        try {
            taxiWaitingAbortControllerRef.current = new AbortController();
            const { signal } = taxiWaitingAbortControllerRef.current;
            const response = await executeGetAverageWaitingTimeTaxiRequest(
                locationId !== undefined ? locationId : "",
                data,
                { signal },
            );
            if (response && Array.isArray(response)) {
                setTaxiWaitingTimeData(response);
            }
        } catch (e) {}
    }

    const mergeDuplicateKeyObject = (response: IDetectedCountData[]): IDetectedCountData[] => {
        const tempObjectArray: IDetectedCountData[] = [];
        response.forEach((item: IDetectedCountData) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const existing = tempObjectArray.filter((data: any) => {
                return data.inTime === item.inTime;
            });
            if (existing.length > 0) {
                const existingIndex = tempObjectArray.indexOf(existing[0]);
                if (existingIndex > -1) {
                    const modifiedObject: IDetectedCountData = {
                        inTime: tempObjectArray[existingIndex].inTime,
                        count: (
                            parseInt(tempObjectArray[existingIndex].count, 10) + parseInt(item?.count, 10)
                        ).toString(),
                    };
                    tempObjectArray.splice(existingIndex, 1);
                    tempObjectArray.push(modifiedObject);
                }
            } else {
                tempObjectArray.push(item);
            }
        });
        return tempObjectArray;
    };
    const handleDetectionFilterChange = (v: SelectChangeEvent<string>) => {
        if (v.target.value === CLASS_TAXIES) {
            setTaxiChartHeading("Detected taxis count");
        } else {
            setTaxiChartHeading("Detected non taxis count");
        }
        setDetectionFilterClass(v.target.value);
    };

    const handlePassengerDetectionFilterChange = (v: SelectChangeEvent<string>) => {
        if (v.target.value === CLASS_EMBARK_PASSENGERS) {
            setPassengerChartHeading("Detected passengers count");
        } else if (v.target.value === CLASS_PASSENGERS) {
            setPassengerChartHeading("Waiting passenger count");
        } else {
            setPassengerChartHeading("Detected incidents count");
        }
        setPassengerDetectionFilterClass(v.target.value);
    };

    useEffect(() => {
        (() => {
            if (pageIndex !== 1 && setPageIndex) {
                setPageIndex(1);
            }
        })();
        return () => {
            incidentAbortControllerRef.current?.abort();
        };
    }, []);

    useEffect(() => {
        makeApiCallWithUpdateTime(selectedRange, { startTime, endTime }, fetchTaxiDetectionsData, dispatch);

        return () => {
            taxiDetectionAbortControllerRef.current?.abort();
        };
    }, [startTime, endTime, detectionFilterClass, locationId]);

    useEffect(() => {
        makeApiCallWithUpdateTime(selectedRange, { startTime, endTime }, fetchPassengerWaitingTimeData, dispatch);

        return () => {
            passengerWaitingAbortControllerRef.current?.abort();
            taxiWaitingAbortControllerRef.current?.abort();
        };
    }, [startTime, endTime, locationId]);

    useEffect(() => {
        makeApiCallWithUpdateTime(selectedRange, { startTime, endTime }, fetchPassengerDetectionData, dispatch);
    }, [startTime, endTime, passengerDetectionFilterClass, locationId, hasReadIncidentsScope]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <PaperWrapper>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div className="sec-header">
                            <Grid container spacing={1}>
                                <Grid item xs={7} sm={8} md={7} lg={8} xl={8} className="chart-header-alignment">
                                    <span className="chartHearder">{taxiChartHeading}</span>
                                </Grid>
                                <Grid item xs={4} sm={3} md={4} lg={3} xl={3} className="chart-padding-0">
                                    <IotVisionSelectFiled
                                        sx={{
                                            marginTop: "-3px",
                                        }}
                                        fullWidth
                                        value={detectionFilterClass}
                                        onChange={handleDetectionFilterChange}
                                    >
                                        {detectedoptions?.map((option: { value: string; title: string }) => {
                                            return (
                                                <MenuItem key={option?.value} value={option?.value}>
                                                    {option?.title}
                                                </MenuItem>
                                            );
                                        })}
                                    </IotVisionSelectFiled>
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                    <IconButton
                                        className="expand-btn"
                                        onClick={() => setDetectedTaxiModalShow(true)}
                                        disableRipple
                                    >
                                        <CgArrowsExpandRight />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <FullScreenDialog
                                modalShow={detectedTaxiModalShow}
                                onClose={() => setDetectedTaxiModalShow(false)}
                                childComponent={
                                    <div>
                                        <DetectedCountChart
                                            data={detectedTaxiesData}
                                            config={{
                                                ...detectedTaxisGraphConfig,
                                                label:
                                                    detectionFilterClass === CLASS_TAXIES
                                                        ? "Taxi Count"
                                                        : "Non Taxi Count",
                                            }}
                                        />
                                    </div>
                                }
                            />
                        </div>
                        <DetectedCountChart
                            data={detectedTaxiesData}
                            config={{
                                ...detectedTaxisGraphConfig,
                                label: detectedTaxiLabel,
                            }}
                        />
                    </Grid>
                </PaperWrapper>
                <AverageWaitingTimeChart
                    data={taxiWaitingTimeData}
                    title=" Average waiting time for taxis"
                    config={{
                        ...detectedTaxisGraphConfig,
                        label: "Avg. Taxi Wait Time",
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <PaperWrapper>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div className="sec-header">
                            <Grid container spacing={1}>
                                <Grid item xs={7} sm={8} md={7} lg={7} xl={8} className="chart-header-alignment">
                                    <span className="chartHearder">{passengerChartHeading}</span>
                                </Grid>
                                <Grid item xs={4} sm={3} md={4} lg={4} xl={3} className="chart-padding-0">
                                    <IotVisionSelectFiled
                                        sx={{
                                            marginTop: "-3px",
                                        }}
                                        fullWidth
                                        value={passengerDetectionFilterClass}
                                        onChange={handlePassengerDetectionFilterChange}
                                    >
                                        {filteredDataArray?.map((option: Option) => {
                                            return (
                                                <MenuItem key={option?.value} value={option?.value}>
                                                    {option?.title}
                                                </MenuItem>
                                            );
                                        })}
                                    </IotVisionSelectFiled>
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                    <IconButton
                                        className="expand-btn"
                                        onClick={() => setDetectedPassengerModalShow(true)}
                                        disableRipple
                                    >
                                        <CgArrowsExpandRight />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <FullScreenDialog
                                modalShow={detectedPassengerModalShow}
                                onClose={() => setDetectedPassengerModalShow(false)}
                                childComponent={
                                    <div>
                                        <DetectedCountChart
                                            data={detectedPassengersData}
                                            config={{
                                                ...configPassengersCount,
                                                label: detectedPassengerLabel,
                                            }}
                                        />
                                    </div>
                                }
                            />
                        </div>
                        <DetectedCountChart
                            data={detectedPassengersData}
                            config={{
                                ...configPassengersCount,
                                label: detectedPassengerLabel,
                            }}
                        />
                    </Grid>
                </PaperWrapper>
                <AverageWaitingTimeChart
                    data={passengerWaitingTimeData}
                    title=" Average waiting time for passengers"
                    config={{
                        ...configPassengersCount,
                        label: "Avg. Passenger Wait Time",
                    }}
                />
            </Grid>
        </Grid>
    );
};
export default TaxiChartLayout;
