import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Auth0UserInfo } from "auth-types";
import { Applications } from "configs";
import { useAppDispatch } from "store";
import {
    appPrefix as appTaxiRankingPrefix,
    appDefaultRoute as appTaxiRankingDefaultRoute,
} from "pages/app-taxi-ranking";
import { appPrefix as appCycleWaysPrefix, appDefaultRoute as appCycleWaysDefaultRoute } from "pages/app-cycle-ways";
import { appAQPrefix, appAQDefaultRoute } from "pages/app-air-quality";
import { appPrefix as appDEPTAQPrefix, appDefaultRoute as appDEPTAQDefaultRoute } from "pages/app-dept-air-quality";
import { useNavigate, useLocation } from "react-router-dom";
import { setAppCode, setScopes } from "reducers/auth.reducer";
import tokenInterceptor from "utils/api-client/interceptors";
import LoadingLayout from "layouts/common/loader/LoadingLayout.tsx";
import { cleanUrl } from "utils/common";
import { appOVPrefix, appOVDefaultRoute } from "pages/app-oversized-vehicle";
import {
    appPrefix as appParkAndRidePrefix,
    appDefaultRoute as appParkAndRideDefaultRoute,
} from "pages/app-park-and-ride";
import {
    appPrefix as appUngatedParkingPrefix,
    appDefaultRoute as appUngatedParkingDefaultRoute,
} from "pages/app-ungated-parking";
import { executeGetCurrentUserScopesRequest } from "api/common/auth.api";
import { ERROR_SCOPES_FETCHING_FAILED } from "constants/scope.constants";

const {
    AppIdTaxiRanking,
    AppIdCycleways,
    AppIdAirQuality,
    AppIdOversizedVehicles,
    AppIdDEPTAirQuality,
    AppIdParkAndRide,
    AppIdUngatedParking,
} = Applications;

type AuthLayoutProps = {
    children: ReactNode;
};

const AuthLayout = ({ children }: AuthLayoutProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const scopesAbortControllerRef = useRef<AbortController | null>(null);
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [isAppReady, setIsAppReady] = useState<boolean>(false);
    const setTokenSystemWide = async () => {
        const token = await getAccessTokenSilently();
        tokenInterceptor(token);
    };

    const getUserScopes = async () => {
        try {
            scopesAbortControllerRef.current = new AbortController();
            const { signal } = scopesAbortControllerRef.current;
            const response = await executeGetCurrentUserScopesRequest({ signal });
            dispatch(setScopes(response?.scopes || []));
            // eslint-disable-next-line
        } catch (error: any) {
            if (error.response) {
                throw new Error(error.response.message);
            }
            if (error.request) {
                throw new Error(ERROR_SCOPES_FETCHING_FAILED);
            }
            throw error;
        }
    };
    const loadUserApp = useCallback(() => {
        // eslint-disable-next-line camelcase
        const { org_id } = user as Auth0UserInfo;
        let path = "";
        const locationPath = cleanUrl(location.pathname);
        // eslint-disable-next-line camelcase
        switch (org_id) {
            case AppIdTaxiRanking:
                dispatch(setAppCode(appTaxiRankingPrefix));
                path = locationPath === "/" ? appTaxiRankingDefaultRoute : locationPath + window.location.search;
                navigate(path, { replace: true });
                break;

            case AppIdCycleways:
                dispatch(setAppCode(appCycleWaysPrefix));
                path = locationPath === "/" ? appCycleWaysDefaultRoute : locationPath + window.location.search;
                navigate(path, { replace: true });
                break;

            case AppIdAirQuality:
                dispatch(setAppCode(appAQPrefix));
                path =
                    locationPath === "/" || locationPath === `/${appAQPrefix}`
                        ? appAQDefaultRoute
                        : locationPath + window.location.search;
                navigate(path, { replace: true });
                break;
            case AppIdOversizedVehicles:
                dispatch(setAppCode(appOVPrefix));
                path =
                    locationPath === "/" || locationPath === `/${appOVPrefix}`
                        ? appOVDefaultRoute
                        : locationPath + window.location.search;
                navigate(path, { replace: true });
                break;
            case AppIdDEPTAirQuality:
                dispatch(setAppCode(appDEPTAQPrefix));
                path =
                    locationPath === "/" || locationPath === `/${appDEPTAQPrefix}`
                        ? appDEPTAQDefaultRoute
                        : locationPath + window.location.search;
                navigate(path, { replace: true });
                break;
            case AppIdParkAndRide:
                dispatch(setAppCode(appParkAndRidePrefix));
                path = locationPath === "/" ? appParkAndRideDefaultRoute : locationPath + window.location.search;
                navigate(path, { replace: true });
                break;
            case AppIdUngatedParking:
                dispatch(setAppCode(appUngatedParkingPrefix));
                path = locationPath === "/" ? appUngatedParkingDefaultRoute : locationPath + window.location.search;
                navigate(path, { replace: true });
                break;
            default:
        }
    }, [location.pathname, user]);
    useEffect(() => {
        if (isAuthenticated) {
            (async () => {
                await setTokenSystemWide();
                await getUserScopes();
                setIsAppReady(true);
            })();
        }
    }, [user, isAuthenticated]);
    useEffect(() => {
        if (isAppReady) {
            loadUserApp();
        }
    }, [isAppReady]);
    return isAppReady ? <>{children}</> : <LoadingLayout />;
};

export default withAuthenticationRequired(AuthLayout, {
    onRedirecting: () => <LoadingLayout />,
});
