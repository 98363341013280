import { Grid, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

interface IncidentTableHeaderProps {
    lastUpdated: string;
}

const StyledTypography = styled(Typography)(
    () => `
    margin: 0;
    font-family: Poppins;
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
    color: #001B4F;
`,
);

const IncidentTableHeader: React.FC<IncidentTableHeaderProps> = ({ lastUpdated }) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <StyledTypography variant="header1">Detections</StyledTypography>
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    marginTop: "-12px",
                    marginLeft: "5px",
                }}
            >
                <Typography variant="body">{lastUpdated}</Typography>
            </Grid>
        </Grid>
    );
};

export default IncidentTableHeader;
