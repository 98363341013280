import React from "react";
import CycleWaysLayout from "layouts/app-cycle-ways";
import { ProtectedLayout } from "layouts/common/general";
import Logs from "pages/common/logs/logs";

export const appPrefix = "cycle-ways";
export const appDefaultRoute = `/${appPrefix}/logs`;

const root = {
    path: appDefaultRoute,
    element: (
        <ProtectedLayout>
            <CycleWaysLayout />
        </ProtectedLayout>
    ),
    children: [{ path: "", element: <Logs /> }],
};
export default root;
